import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { recaudoUI } from '../../helpers/RecaudoUI';
import { ModeloRespuesta, ModeloQuery } from '../../helpers/Models';
import { ColaFunciones } from '../../helpers/ColaFunciones';
import * as _ from 'node_modules/underscore';

@Injectable()
export class ModeloService {
    private cola = new ColaFunciones();

    constructor(private http: HttpClient) { }

    /**
     * Obtiene un registro del modelo especificado por Id.
     * @param modelo Modelo
     * @param id Id del registro
     * @param extraQuery query de oData para aplicar (opcional)
     */
    getById(modelo: string, id: string, extraQuery: string = null) {
        return this.http.get<Array<ModeloRespuesta>>(recaudoUI.getBaseUrl() + "api/Modelo/" + modelo + "/" + id + (extraQuery ? '?' + extraQuery : ''));
    }

    /**
     * Guarda cambios de una lista de elementos.
     * Los items con id <> 0 son considerados editados, id = 0 nuevos.
     * @param modelo nombre del  modelo al que se van a guardar los cambios.
     * @param data datos a guardar.
     */
    save(modelo: string, data: Array<any>) {
        return this.http.post<Array<ModeloRespuesta>>(recaudoUI.getBaseUrl() + "api/Modelo/" + modelo, data);
    }

    saveAuth(modelo: string, data: Object) {
        return this.http.post<Object>(recaudoUI.getBaseUrl() + "api/auth/" + modelo, data);
    }

    /**
     * Elimina la lista de ids especificados.
     * @param modelo nombre del  modelo al que se van a eliminar los registros.
     * @param ids ids separados por coma a eliminar.
     */
    delete(modelo: string, ids: string) {
        return this.http.delete<Array<ModeloRespuesta>>(recaudoUI.getBaseUrl() + "api/Modelo/" + modelo + "/" + ids);
    }

    deleteManager(modelo: string, data: Object) {
        return this.http.delete<Array<ModeloRespuesta>>(recaudoUI.getBaseUrl() + "api/auth/" + modelo + "/" + data);
    }

    /**
     * Obtiene datos de las entidades especificadas del año actual o un año espeficado.
     * @param models lista de modelos, opcional-mente puede especificar una query oData después de un punto y coma...Ej: ModeloX;$filter=x eq 0
     */
    getQueries(models: Array<string>, año: object = null) {

        let args: Array<string> = [];
        let querys: Array<any> = [];

        _.each(models, (model: string) => {
            args = model.split(";");
            let extraQuery: string = null;

            //si se especificó una query oData
            if (args[1]) {
                model = args[0];
                extraQuery = args[1];
            }

            switch (model) {
                case 'Servicio':
                case 'ResponsablePago':
                    querys.push(new ModeloQuery(model, (año ? "$filter=IdAño eq " + año["Id"] : "") + ((extraQuery && año) ? "&" : "") + extraQuery ? extraQuery : ""));
                    break;
                case 'TipoConcepto':
                case 'Persona':
                case 'DefinicionGrado':
                case 'TipoServicio':
                case 'SedeJornada':
                case 'TipoCostoEducativo':
                case 'Estudiante':
                case 'Cuenta':
                    querys.push(new ModeloQuery(model, (año ? "$filter=IdColegio eq " + año["IdColegio"] : "") + ((extraQuery && año) ? "&" : "") + extraQuery ? extraQuery : ""));
                    break;
                case 'Mes':
                case 'Grado':
                    querys.push(new ModeloQuery(model, (año ? "$filter=IdAnio eq " + año["Id"] : "") + ((extraQuery && año) ? "&" : "") + extraQuery ? extraQuery : ""));
                    break;
                //se accede al año por medio de relaciones
                case 'Grupo':
                    querys.push(new ModeloQuery(model, (año ? "$filter=Grado.IdAnio eq " + año["Id"] : "") + ((extraQuery && año) ? "&" : "") + extraQuery ? extraQuery : ""));
                    break;
                //en estos casos no es necesario filtro de año o colegio o relaciones
                case 'EstadoMovimiento':
                case 'TipoRecibo':
                case 'Movimiento':
                case 'DetalleRecibo':
                case 'Año':
                case 'EstadoMes':
                case 'CostoEducativo':
                case 'Matricula':
                case 'Recibo':
                case 'Extracto':
                case 'TareaServidor':
                case 'Informe':
                case 'Parametro':
                case 'Colegio':
                case 'Cobro':
                case 'UnidadNegocio':
                case 'Rango':
                case 'EncargadoDistribucion':
                    querys.push(new ModeloQuery(model, (extraQuery ? extraQuery : null)));
                    break;
                default:
                    throw "ModeloService.getQueries(): Modelo no soportado " + model + ".";

            };
        });
        return this.http.post<object>(recaudoUI.getBaseUrl() + "api/ModeloQueries", querys);
    }

    /**
     * Obtiene la fecha actual del servidor
     */
    getFechaActual() {
        return this.http.get<object>(recaudoUI.getBaseUrl() + "api/Matricula/Fecha");
    }
}

@Injectable()
export class AñoService {

    constructor(private http: HttpClient) { }

    getAños() {
        return this.http.get<Array<any>>(recaudoUI.getBaseUrl() + "api/Anios?$expand=Colegio.UnidadesNegocio.Rangos");
    }

    getAños2() {
        return this.http.get<Array<any>>(recaudoUI.getBaseUrl() + "api/Anios?$expand=Colegio");
    }
}

@Injectable()
export class ExtractoService {

    constructor(private http: HttpClient) { }

    /**
     * Realiza la subida de archivos al servidor
     * @param tokenUser
     */
    subirArchivos(tokenUser) {

        let token = tokenUser;

        return {
            url: recaudoUI.getBaseUrl() + "api/Extractos/Imagen",
            isHTML5: true,

            authToken: "Authorization",
            authTokenHeader: "Bearer " + token,
            headers: [
                { name: "Authorization", value: "Bearer " + token }
            ]
        };
    }

    /**
     * Consulta todos los movimientos que están relacionados al filtro
     * @param filtro
     */
    buscarMovimientos(filtro) {
        return this.http.post<Array<any>>(recaudoUI.getBaseUrl() + `api/Extractos/Movimientos`, filtro);
    }

    /**
     * Obtiene el extracto por medio del nombre
     * @param extracto
     */
    getExtractoPorNombre(extracto) {
        console.log("ex", extracto);
        return this.http.get<any>(recaudoUI.getBaseUrl() + `api/Extractos/${extracto.id}`);
    }

    /**
     * Obtiene los extractos
     * @param extracto
     */
    getPaginacionExtracto(extracto) {
        return this.http.get<any>(recaudoUI.getBaseUrl() + `api/Extractos/Paginacion/${extracto["idColegio"]}/${extracto["pagina"]}/${extracto["top"]}/${extracto["desde"]}/${extracto["hasta"]}`);
    }

    /**
     * Trae los extractos subidos en un mes determinado
     * @param filtroExtracto
     */
    getExtractosPorMes(filtroExtracto) {
        return this.http.get<any>(recaudoUI.getBaseUrl() + `api/Extractos/mes/${filtroExtracto.anio}/${filtroExtracto.mes}/${filtroExtracto.idCuenta}`);
    }
}

@Injectable()
export class MatriculaService {
    constructor(private http: HttpClient) { }
    /**
     * Busca matriculas
     * @param busquedaMatriculaDto
     */
    buscar(busquedaMatriculaDto) {
        return this.http.post<Array<any>>(recaudoUI.getBaseUrl() + "api/Matricula/Buscar", busquedaMatriculaDto);
    }

    /**
     * Busca las matriculas para recaudo
     */
    buscarParaRecaudo(formulario) {
        return this.http.get<Array<any>>(recaudoUI.getBaseUrl() + `api/Matricula/Buscar/${formulario["coincidencia"]}/${formulario["idAnio"]}/Recudo`);
    }

    /**
    * Busca las matriculas para recaudoapi/Matricula/{idMatricula}/Relacionadas/Estudiante
    */
    getSaldoPendiente(matricula) {
        return this.http.get<Array<any>>(recaudoUI.getBaseUrl() + `api/Matricula/${matricula.idMatricula}/Saldo/Pendiente/Recibos`);
    }

    /**
     * Obtiene todas las matrículas que tiene el estudiante en el colegio
     * @param idMatricula
     */
    getOtrasMatriculasEstudiante(matricula) {
        return this.http.get<Array<any>>(recaudoUI.getBaseUrl() + `api/Matricula/${matricula.idMatricula}/Relacionadas/Estudiante`);
    }


}

@Injectable()
export class ResponsablePagoService {

    constructor(private http: HttpClient) { }

    /**
     * Retorna la cantidad de nombres arreglados
     */
    separarNombres(responsablePagoDto) {
        return this.http.post<Array<any>>(recaudoUI.getBaseUrl() + "api/ResponsablePagos/Separar/Nombres", responsablePagoDto)
    }

    /**
     * Busca responsables de pago relazionados
     * @param busquedaMatriculaDto
     */
    buscarResponsables(responsablePagoDto) {
        return this.http.post<Array<any>>(recaudoUI.getBaseUrl() + "api/ResponsablePagos/Buscar", responsablePagoDto);
    }
}

@Injectable()
export class EstudianteService {
    constructor(private http: HttpClient) { }


    /* test(){
         return this.http.post<Array<any>>(recaudoUI.getBaseUrl() + "api/Integracion/Estudiante/99996", {})
     }*/

    /**
     * Busca matriculas
     * @param busquedaMatriculaDto
     */
    buscar(busquedaMatriculaDto) {
        return this.http.post<Array<any>>(recaudoUI.getBaseUrl() + "api/Matricula/Buscar", busquedaMatriculaDto);
    }

    /**
     * Consulta el recibo seleccionado
     */
    buscarEstudiantes(estudianteDto: object) {
        return this.http.post<object>(recaudoUI.getBaseUrl() + `api/Estudiante/Buscar`, estudianteDto);
    }

    /**
     * Obtiene información relacionada a al estudiante
     * @param id
     */
    getDetallesEstudiante(estudiante: object) {
        return this.http.get<object>(recaudoUI.getBaseUrl() + `api/Estudiante/${estudiante["idEstudiante"]}/Detalles`);
    }
}

@Injectable()
export class OtrasPersonasService {
    constructor(private http: HttpClient) { }

    /**
     * Realiza la consulta de todas las personas que coincidan el nombre y/o identificación
     * @param personaDto
     */
    buscarPersonas(personaDto: any) {
        return this.http.post<Array<any>>(recaudoUI.getBaseUrl() + `api/Persona/BuscarPersonas`, personaDto);
    }

    /**
     * Permite crear la persona con su respectivo responsable de pago
     * @param persona
     */
    crearPersona(persona: object) {
        return this.http.post<Array<any>>(recaudoUI.getBaseUrl() + `api/Persona`, persona);
    }
}

@Injectable()
export class CobrosService {

    constructor(private http: HttpClient) { }

    /**
     *
     * @param idCobro
     */
    getCobro(cobro: object) {
        return this.http.get<Array<any>>(recaudoUI.getBaseUrl() + `api/Cobros/${cobro["IdCobro"]}`);
    }

    /**
     * Obtiene un total de las deudas anteriores y las actuales
     * @param codigo : el codigo del estudiante
     * @param anio : El anio que se requiere
     */
    getDeudaEstudiante(codigo, anio){
        return this.http.get<Array<any>>(recaudoUI.getBaseUrl() + `api/Cobros/Deudas/${codigo}?anio=${anio}`);
    }
}

@Injectable()
export class RecibosService {
    constructor(private http: HttpClient) { }

    /**
    * Obtiene los detalles de un recibo
    * @param oQuery  Query de odata
    */
    GetDetallesRecibo(recibo: object) {
        return this.http.get<Array<any>>(recaudoUI.getBaseUrl() + `api/DetallesRecibo/Recibo/${recibo["idRecibo"]}`);
    }

    /**
     * Obtiene los cobros del detalle recibo acorde a la matrícula
     * @param matricula
     */
    GetDetallesReciboPorMatricula(recibo: object) {
        return this.http.get<Array<any>>(recaudoUI.getBaseUrl() + `api/Cobros/Matricula/${recibo["idRecibo"]}`);
    }

    /**
     *
     * @param recibo
     */
    buscarRecibo(recibo) {
        return this.http.post<Array<any>>(recaudoUI.getBaseUrl() + "api/Recibo/Buscar", recibo);
    }

    /**
     * Buscar el recibo por medio de la referencia del recibo
     * @param recibo
     */
    buscarReciboPorCodigoBarras(reciboCodigoBarras) {
        return this.http.get<Array<any>>(recaudoUI.getBaseUrl() + `api/Recibo/Buscar/CodigoBarras/${reciboCodigoBarras.referencia}`);
    }

    /**
     * Realiza el abono al recibo seleccionado
     * @param reciboAbono
     */
    postRealizarAbono(reciboAbono) {
        return this.http.post<Array<any>>(recaudoUI.getBaseUrl() + "api/Recibo/Abono", reciboAbono);
    }

    postEnviarDian(idRecibo) {
        return this.http.post<Array<any>>(recaudoUI.getBaseUrl() + "api/Recibo/Dian?idRecibo=" + idRecibo, {});
    }

    postGenerarPdf(idRecibo) {
        return this.http.post<Array<any>>(recaudoUI.getBaseUrl() + "api/Recibo/Pdf?idRecibo=" + idRecibo, {});
    }

    anular(idRecibo, observacion) {
        return this.http.post<any>(recaudoUI.getBaseUrl() + "api/Recibo/Anular?idRecibo=" + idRecibo + "&observacion=" + observacion, {});
    }

    crearNotaDebito(args) {
        return this.http.post<any>(recaudoUI.getBaseUrl() + "api/Recibo/CrearNotaDebito", args);
    }

    crearNotaCredito(args){
        return this.http.post<any>(recaudoUI.getBaseUrl() + "api/Recibo/CrearNotaCredito", args);
    }

    /**
     * Elimina los recibos y a los cobros les cambia el valor a 0
     * @param idsRecibos
     */
    eliminarRecibosYCobros(idsRecibos) {
        return this.http.delete(recaudoUI.getBaseUrl() + "api/Recibos/Cobros/" + idsRecibos);
    }
}

@Injectable()
export class ManagerService {

    constructor(private http: HttpClient) { }

    /**
     * Realiza su respectivo logeo a la aplicación
     * @param UsuarioLogin
     */
    login(UsuarioLogin: any) {
        return this.http.post<object>(recaudoUI.getBaseUrl() + "api/Auth/Login", UsuarioLogin);
    }

    /**
     * Obtiene recibos y aplica query de Odata a los parámetros
     * @param oQuery  Query de odata
     */
    getUsuarios() {
        return this.http.get<Array<any>>(recaudoUI.getBaseUrl() + "api/Auth/Usuarios");
    }

    /**
     * Consulta el usuario seleccionado
     * @param idUsuario
     */
    getUsuario(idUsuario: number) {
        return this.http.get<Array<any>>(recaudoUI.getBaseUrl() + `api/Auth/Usuarios/${idUsuario}`);
    }

    /**
     * Consulta todos los colegios
     */
    getColegios() {
        return this.http.get<Array<any>>(recaudoUI.getBaseUrl() + "api/Auth/Colegios");
    }

    /**
     * Consulta solo un colegio en especifico
     * @param idColegio
     */
    getColegio(idColegio) {
        return this.http.get<Array<any>>(recaudoUI.getBaseUrl() + `api/Auth/Colegios/${idColegio}`);
    }

    /**
     * Quita el eliminado al colegio
     * @param idColegio
     */
    restaurarColegio(idColegio) {
        return this.http.get<Array<any>>(recaudoUI.getBaseUrl() + `api/Auth/Colegios/Restaurar/${idColegio}`);
    }

    /**
     * Filtra el log
     * @param idColegio
     */
    getLog(filtro) {
        return this.http.get<Array<any>>(recaudoUI.getBaseUrl() + `api/Auth/Log?desde=${filtro["Desde"]}&hasta=${filtro["Hasta"]}&evento=${filtro["Evento"]}&idColegio=${filtro["IdColegio"]}&idUsuario=${filtro["IdUsuario"]}&usuario=${filtro["Usuario"]}&mensaje=${filtro["Mensaje"]}&ip=${filtro["Ip"]}&skip=${(filtro["pagina"] - 1 ) * filtro["cantidadPorPagina"]}&take=${filtro["cantidadPorPagina"]}`);
    }

    getRevisiones() {
        return this.http.get<Array<any>>(recaudoUI.getBaseUrl() + "api/Revisiones");
    }

    getParametros(consulta) {
        return this.http.get<Array<any>>(recaudoUI.getBaseUrl() + "api/Revisiones/Parametros?consulta=" + consulta );
    }

    postRevision(idRevision, params, exportar=false) {
        return this.http.post<any>(recaudoUI.getBaseUrl() + "api/Revisiones/" + idRevision + (exportar ? "?exportar=true" : ""), params);
    }

    /**
     * Realiza el cambio de colegio al usuario que está logeado
     */
    postCambioColegio(idColegio) {
        return this.http.get<any>(recaudoUI.getBaseUrl() + `api/Auth/Cambiar/Colegio/${idColegio}`);
    }

    deleteManager(modelo: string, data: Object) {
        return this.http.delete<Array<ModeloRespuesta>>(recaudoUI.getBaseUrl() + "api/auth/" + modelo + "/" + data);
    }
}

@Injectable()
export class TareaServidorService {
    constructor(private http: HttpClient) { }

    postTareaServicioSinc(tarea) {
        return this.http.post<object>(recaudoUI.getBaseUrl() + "api/TareasServidor/EjecutarSync", tarea);
    }
}

