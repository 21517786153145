import { Component, OnInit, Input, ViewChild, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import * as _ from 'underscore';
import { WjFlexGrid, WjFlexGridColumn, } from 'wijmo/wijmo.angular2.grid';
import { DataMap } from 'wijmo/wijmo.grid';
import { CollectionView } from 'wijmo/wijmo';

import { ModeloService, MatriculaService, ResponsablePagoService, EstudianteService, OtrasPersonasService, CobrosService, RecibosService, ExtractoService, TareaServidorService } from '../../shared/services/services'
import { WijmoHelper, ModeloCollectionView } from '../../helpers/WijmoHelper';
import { recaudoUI } from '../../helpers/RecaudoUI';
import { NgbModal, } from '@ng-bootstrap/ng-bootstrap';
import { GradoComponent } from '../grado/grado.component'
import { RecaudoFormHelper } from '../../helpers/RecaudoFormHelper';
import { Año, ObjetoModelo } from '../../helpers/Models';
import { ToastrService } from 'ngx-toastr';
import { TableService } from 'src/app/shared/services/table';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ModeloDatos } from '../../_models/modeloDatos';
import { ModalConfirmacionComponent } from 'src/app/shared/components/modal-confirmacion/modal-confirmacion.component';
import { ModalAlertaComponent } from 'src/app/shared/components/modal-alerta/modal-alerta.component';

@Component({
    selector: 'app-anio',
    templateUrl: './anio.component.html',
    styleUrls: ['./anio.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnioComponent extends RecaudoFormHelper implements OnInit, AfterViewInit {

    @Input('anioDto') public anioDto: object;
    //    @Input('anio') public anio: object;
    anio: any;
    anioObjetoModelo = new ObjetoModelo('Año');
    hayCambios = false;

    //CollectionViews
    cvGrados: CollectionView;
    cvMeses: CollectionView;
    cvServicios: CollectionView;

    //Instancias de las modals que se puede utilizar
    @ViewChild(ModalConfirmacionComponent) modalConfirmacion: ModalConfirmacionComponent;
    @ViewChild(ModalAlertaComponent) modalAlerta: ModalAlertaComponent;

    //Referencia a los controles de wijmo
    @ViewChild('flexGrados') flexGrados: WjFlexGrid;
    @ViewChild('flexMeses') flexMeses: WjFlexGrid;
    @ViewChild('flexServicios') flexServicios: WjFlexGrid;

    //Referencia al filtro de wijmo
    @ViewChild('filterServicios') filterServicios: WjFlexGrid;
    @ViewChild('filterMeses') filterMeses: WjFlexGrid;
    @ViewChild('filterGrados') filterGrados: WjFlexGrid;

    //Datos de trabajo auxiliares
    modelsDatos: ModeloDatos;
    meses: Array<any> = [];
    definicionesGrados: Array<any> = [];
    estadosMeses: Array<any> = [];
    tiposServicio: Array<any> = [];
    sedesJornadas: Array<any> = [];
    UnidadesNegocio: Array<any> = [];

    //Filtro del grid
    filtro = {
        buscarGrados: "",
        buscarMeses: "",
        buscarServicios: "",
    };

    //declaración de servicios
    constructor(
        private _toastr: ToastrService,
        private _wjHelper: WijmoHelper,
        private _changeDetector: ChangeDetectorRef,
        private modalService: NgbModal,
        private _sTable: TableService,
        private _sModelo: ModeloService,
        private _sAuth: AuthenticationService,
        private _sMatricula: MatriculaService,
        private _sResponsablePago: ResponsablePagoService,
        private _sEstudiante: EstudianteService,
        private _sOtrasPersonas: OtrasPersonasService,
        private _sCobro: CobrosService,
        private _sRecibo: RecibosService,
        private _sExtracto: ExtractoService,
        private _sTarea: TareaServidorService
    ) {
        super(_toastr, _wjHelper, _sModelo, _changeDetector, _sTable, _sAuth, _sMatricula,
            _sResponsablePago, _sEstudiante, _sOtrasPersonas, _sCobro, _sRecibo, _sExtracto, _sTarea);
    }

    /**
     * Los componentes generados dinámicamente deben implementar esta interfaz
     */
    ngAfterViewInit() {
    }

    ngOnInit(): void {

        //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
        this.modelsDatos = {
            identificador: "Odata",
            odata: [
                'Año;$filter=Id eq ' + this.anioDto["Id"] + '&$expand=Grados.UnidadNegocio,Servicio',
                'Mes;$filter=IdAnio eq ' + this.anioDto["Id"],
                'DefinicionGrado;$orderby=Orden',
                'EstadoMes',
                'TipoServicio',
                'SedeJornada',
                'UnidadNegocio;$filter=IdColegio eq ' + this.anioDto["IdColegio"]
            ]
        };;

        this.setModelosTrabajo2(this.modelsDatos);
        this.setAñoTrabajo(this.anioDto as Año);
        this.cargarDatos("Odata", () => { });
    }

    /**
   * Trae los datos de las consultas realizadas
   * @param data El array u objeto que se esta consultado
   * @param identificador El identificador que ha sido asignado a cada consulta
   */
    onDatosObtenidos(data: any, identificador: string) {

        //Asigna los datos que se traen en el Odata
        if (identificador == "Odata") {

            this.meses = data['Mes'];
            this.definicionesGrados = data['DefinicionGrado'];
            this.estadosMeses = data['EstadoMes'];
            this.tiposServicio = data['TipoServicio'];
            this.sedesJornadas = data['SedeJornada'];
            this.UnidadesNegocio = data['UnidadNegocio'];

            //console.log(this.UnidadesNegocio, this.estadosMeses);

            this.cvMeses = new CollectionView(data['Mes']);
            this.cvMeses.trackChanges = true;

            //Genera el objeto con el que se crea el mes
            this.cvMeses.newItemCreator = () => {
                return { IdAnio: this.anioDto["Id"], TipoActividad: 0 }
            }

            //if (data['Año'] && data['Año'].length) {

            //asigna a cada grado su definición.
            _.each(data['Año'][0]['Grados'], (g) => {
                g.DefinicionGrado = _.findWhere(this.definicionesGrados, { Id: g.IdDefinicionGrado });
            });

            this.anio = _.first(data['Año']);
            this.anioObjetoModelo.objeto = this.anio;
            this.anioObjetoModelo.actualizarCopia();

            this.cvGrados = new CollectionView(this.anio['Grados']);
            this.cvGrados.trackChanges = true;

            //Genera el objeto con el que se crea el Grado
            this.cvGrados.newItemCreator = () => {
                let maxGrado = _.max(this.definicionesGrados, function (dg) { return dg.Numero; });

                return {
                    IdAnio: this.anioDto["Id"], IdDefinicionGrado: this.definicionesGrados[0].Id, IdSedeJornada: this.sedesJornadas[0].Id                    
                };
            }

            this.cvServicios = new CollectionView(this.anio['Servicio']);
            this.cvServicios.trackChanges = true;

            //Genera el objeto con el que se crea el Servicio
            this.cvServicios.newItemCreator = () => {
                return { IdAño: this.anioDto["Id"], IdTipoServicio: this.tiposServicio[0].Id }
            }
            //}

            this.registrarObjetosModelo([this.anioObjetoModelo])
            this.registrarModeloCollectionViews([
                new ModeloCollectionView('Mes', 'Id', this.cvMeses, this.flexMeses),
                new ModeloCollectionView('Grado', 'Id', this.cvGrados, this.flexGrados),
                new ModeloCollectionView('Servicio', 'Id', this.cvServicios, this.flexServicios)
            ]);
        }
    }

    /**
    * Actualiza las columnas que va tener el grid
    * @param column dato de la columna
    * @param modelo nombre del primer parametro en el new ModeloCollectionView
    */
    onAjusteColumna(column: WjFlexGridColumn, modelo: string) {
        if (modelo == "Grado") {
            switch (column.binding) {
                case "IdDefinicionGrado":
                    //crea el select de meses
                    column.dataMap = new DataMap(this.definicionesGrados, "Id", "Nombre");
                    break;
                case "IdSedeJornada":
                    //crea el select de meses
                    column.dataMap = new DataMap(this.sedesJornadas, "Id", "Nombre");
                    break;
                case "IdUnidadNegocio":
                    column.width = 180;
                    //crea el select de unidadNegocio
                    column.dataMap = new DataMap(this.UnidadesNegocio, "IdUnidadNegocio", "Nombre");
                    break;
                default:
            }
        }
        if (modelo == "Mes") {
            switch (column.binding) {
                case "IdEstadoMes":
                    //crea el select de meses
                    column.dataMap = new DataMap(this.estadosMeses, "Id", "Nombre");
                    break;
                default:
            }
        }
        if (modelo == "Servicio") {
            switch (column.binding) {
                case "IdMesDesde":
                case "IdMesHasta":
                    //crea el select de meses
                    column.dataMap = new DataMap(this.meses, "Id", "Nombre");
                    break;
                case "IdTipoServicio":
                    column.dataMap = new DataMap(this.tiposServicio, "Id", "Nombre");
                    break;
                default:
            }
        }
    }

    /**
     * abre el detalle de un grado
     * @param item grado
     */
    verGrado(item) {

        //Si no trae el registro seleccionado, entonces lo busca el registro seleccionado por medio del flexgrid
        if (!item)
            item = this.obtenerRegistroSeleccionado(this.flexGrados);

        if (!this.verificarSiHayCambios() && item) {

            let definicionGrado = _.find(this.definicionesGrados, (dg) => { return dg.Id == item.IdDefinicionGrado });

            recaudoUI.tabsManager.agregar("Grado - " + definicionGrado["Nombre"] + " - " + this.anioDto["Etiqueta"], null, GradoComponent, { gradoDto: recaudoUI.copyObject(item), anio: recaudoUI.copyObject(this.anioObjetoModelo.objetoCopy) });
        }
    }

    /**
     * Permite al tap detectar si hay cambios o no, para el momento de ser cerrado
     */
    consultarSiHayCambios() {
        this.hayCambios = this.getTieneCambios();
        return this.hayCambios;
    }

    /**
     * Inicia los grados del año
     */
    iniciarGrados() {

        if (this.verificarSiHayCambios())
            return

        this.modalConfirmacion.abrirConfirmar({ textoHeader: "¿Está seguro de iniciar los grados?" });
        this.modalConfirmacion.modalRef.result.then((dataResult) => {

            if (dataResult)
                this.crearTareaSync("IniciarGrados", this.anioDto["Id"], null, null, null, null, null);
            //this.crearTarea("IniciarGrados", this.anioDto["Id"], null, null, null, null, null);

        }, (error) => {
            console.log("Cancelado", error);
        });
    }

    /**
     * Inicia los meses del año
     */
    iniciarMeses() {

        if (this.verificarSiHayCambios())
            return

        this.modalConfirmacion.abrirConfirmar({ textoHeader: "¿Está seguro de iniciar los meses?" });
        this.modalConfirmacion.modalRef.result.then((dataResult) => {

            if (dataResult)
                this.crearTareaSync("IniciarMeses", this.anioDto["Id"], null, null, null, null, null);
            //this.crearTarea("IniciarMeses", this.anioDto["Id"], null, null, null, null, null);

        }, (error) => {
            console.log("Cancelado", error);
        });
    }

    /**
     * Genera el mensaje al cual se va a mostrar al usuario cuando la tarea sea finalizada
     * @param tareaServidor 
     */
    public mostrarMensajeTareaRevisada(tareaServidor) {

        let mensaje = "";
        let titulo = "";

        if (tareaServidor["Estado"] == 1) {

            titulo = "Éxito"

            if (tareaServidor["Nombre"] == "IniciarGrados")
                mensaje = `Se ha iniciado ${tareaServidor["Resultado"]} grados`;
            //this.toastr.success(`Se ha iniciado ${tareaServidor["Resultado"]} grados`);


            if (tareaServidor["Nombre"] == "IniciarMeses")
                mensaje = `Se ha iniciado ${tareaServidor["Resultado"]} meses`;
            //this.toastr.success(`Se ha iniciado ${tareaServidor["Resultado"]} meses`);
        }

        else {
            titulo = "Error";
            mensaje = tareaServidor["Excepcion"];
            //this.toastr.error("Error, " + tareaServidor["Excepcion"]);
        }


        this.cargarDatos(null, () => { });

        this.modalAlerta.abrirConfirmar({ textHeader: titulo, textBody: mensaje });
        this.modalAlerta.modalRef.result.then((dataResult) => {
        }, (info) => {
            console.log("Info", info);
        });

    }
}

