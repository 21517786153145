import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NgbModalRef, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ResponsablePagoService } from '../../services/services';
import * as _ from 'underscore';

@Component({
  selector: 'app-modal-buscar-responsable-pago',
  templateUrl: './modal-buscar-responsable-pago.component.html',
  styleUrls: ['./modal-buscar-responsable-pago.component.css']
})
export class ModalBuscarResponsablePagoComponent implements OnInit {


  //Datos que utiliza la modal de buscarResponsablePago
  @ViewChild('buscarResponsablePagoModal') private buscarResponsablePagoModal;
  private resultadoResponsablePago: object;
  private responsablePagoDto: object;

  private anio: object;

  modalRef: NgbModalRef;

  constructor(
    private _modalService: NgbModal,
    private toastr: ToastrService,
    private config: NgbModalConfig,
    private router: Router,
    private sResponsablePago: ResponsablePagoService,
    public changeDetector: ChangeDetectorRef,
  ) { }

  ngOnInit() {
  }

  /**
  * Consulta el responsable de pago por medio de la identificación
  * @param anio 
  */
  public abrirConsultaResponsablePago(anio?): NgbModalRef {

    this.inicializarVariables();

    this.responsablePagoDto["IdAño"] = anio ? anio.Id : null;
    this.responsablePagoDto["IdAnio"] = anio ? anio.Id : 0;

    //Abre la modal con su respectiva configuración
    this.config.backdrop = "static";
    this.config.keyboard = false;

    this.modalRef = this._modalService.open(this.buscarResponsablePagoModal, { size: "lg" });
    return this.modalRef;
  }

  /**
   * Buscar la persona por medio del número de itenticación
   */
  private buscarPersona() {

    if (!this.responsablePagoDto["Identificacion"]){
      this.toastr.error("Ingrese el número de identificación");
      return;
    }

    this.sResponsablePago.buscarResponsables(this.responsablePagoDto).subscribe(data => {

      this.resultadoResponsablePago = _.pick(_.first(data), "Año", "IdAnio");
      this.resultadoResponsablePago["Id"] = _.first(data)["IdResponsablePago"];
      this.resultadoResponsablePago["Persona"] = _.omit(_.first(data), "Año", "IdAnio", "IdResponsablePago");
    });
  }

  /**
   * Cada vez que se abre la modal se inicializan las variables
   */
  private inicializarVariables() {
    this.resultadoResponsablePago = null;
    this.responsablePagoDto = {
      Persona: {
        Nombre: null,
      },
      Identificacion: null,
      IdAño: 0,
      IdAnio: 0
    };
  }

  /**
   * Retorna el responsable de pago
   */
  private aceptar() {
    this.modalRef.close(this.resultadoResponsablePago);
  }

  /**
   * Cierra la modal
   */
  private cerrarModal() {
    this.modalRef.dismiss("Cerrar");
  }
}
