import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { modalAlerta } from 'src/app/_models/modals.model';
import { NgbModalRef, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'underscore';

@Component({
  selector: 'app-modal-generar-recibos',
  templateUrl: './modal-generar-recibos.component.html',
  styleUrls: ['./modal-generar-recibos.component.css']
})
export class ModalGenerarRecibosComponent implements OnInit {


  //Variables que se utiliza en la modal de confirmación
  @ViewChild('generarRecibosModal') private generarRecibosModal;
  private tiposConcepto: Array<Object>;
  private titulo: string;
  private filtro: object = { concepto: ""};
  private hayConceptoSeleccionado = false;
  private oprimioBotonGuardar = false;

  modalRef: NgbModalRef;

  constructor(
    private _modalService: NgbModal,
    private toastr: ToastrService,
    public changeDetector: ChangeDetectorRef,
    private config: NgbModalConfig
  ) { }

  ngOnInit() {
  }

  /**
   * modal de confirmacion, trae dos botones Cancelar, aceptar
   * @param configuracion
   */
  public abrirModal(tiposConcepto: Array<Object>, titulo: string): NgbModalRef {

    this.oprimioBotonGuardar = false;
    this.titulo = titulo;
    this.tiposConcepto = tiposConcepto;
    this.filtro = { concepto: ""};

    //Abre la modal con su respectiva configuración
    this.config.backdrop = "static";
    this.config.keyboard = false;
    this.modalRef = this._modalService.open(this.generarRecibosModal, { size: "lg" });
    return this.modalRef;
  }

  /**
   * Valida que el usuario haya seleccionado por lo menos un recibo
   */
  validarFormulario() {
    if (_.find(this.tiposConcepto, (tc) => { return tc.seleccionado == true; }))
      this.hayConceptoSeleccionado = true;
    else
      this.hayConceptoSeleccionado = false;

    return !this.hayConceptoSeleccionado;
  }

  /**
   * Retorna los recibos seleccionados
   * @param seleccion
   */
  private crearRecibos() {
    this.oprimioBotonGuardar = true;

    if (!this.hayConceptoSeleccionado)
      return;

    let conceptos = _.filter(this.tiposConcepto, (tc) => { return tc.seleccionado == true; });

    this.modalRef.close(conceptos);
  }

  /**
   * Cierra la modal
   */
  private cerrarModal() {
    this.modalRef.close();
  }

}
