//import * as uuid from '../../libs/uuid';
import * as _ from 'node_modules/underscore';
import { Type } from '@angular/core';
import { recaudoUI } from './RecaudoUI';

/**
 * Modelo básico de una pestaña
 */
export class Tab {
  public header: string;
  public icon: string;
  public component: Type<any>;
  public componentData: object;
  public permitirMultiplicar: boolean;
  public permitirCerrar: boolean;
  // Ayuda a identificar cada pestaña como única para facilitar su gestión.
  public guid: string;
  public currentComponent: Type<any>;

  /**
   * Crea una nuevo objeto de tipo Tab
   * @param header Texto que se muestra en el header de la pestaña.
   * @param icon Icono que se muestra en la pestaña.
   * @param component Nombre del componente que se mostrará en el contenido de la tab.
   * @param componentData Objecto que define los parámetros que se le injectarán al componente.
   * @param permitirMultiplicar Indica pueden haber otras pestañas con instancias del componente que se indica en la actual
   * @param permitirCerrar Indica si la pestaña se puede cerrar
   */
  constructor(header: string, icon: string, component: Type<any>, componentData: object = {}, permitirMultiplicar: boolean = true, permitirCerrar: boolean = true) {
    this.header = header;
    this.icon = icon;
    this.component = component;
    this.componentData = componentData;
    this.guid = _.uniqueId('');// uuid();
    this.permitirMultiplicar = permitirMultiplicar;
    this.permitirCerrar = permitirCerrar;
    this.currentComponent = null;
  }


  /**
   * La tab se clona, tomando como referencia otra tab
   * @param tab
   */
  clonar(tab: Tab): void {
    this.header = tab.header;
    this.icon = tab.icon;
    this.component = tab.component;
    this.guid = tab.guid;
    this.permitirMultiplicar = tab.permitirMultiplicar;
    this.permitirCerrar = tab.permitirCerrar;
    this.currentComponent = null;
  }
}

/**
 * Sirve de modelo para los submenús del sidebar, los cuales están asociados a un objeto de tipo 'Tab' el cual se mostrará al hacer click sobre el item
 */
export class SubMenuItem {
  private nombre: string;
  private funcion: Function;
  private funcionArgs: Array<any>;
  private icon: string;
  private tab: Tab;

  /**
   * Inicializa un objeto de tipo submenú para usar en el sidebar
   * @param nombre  Nombre que aparece en el submenú.
   * @param funcion Función que se ejecuta al hacer click en el submenú
   * @param funcionArgs Parámetros de invocación para la función
   * @param icon  Icono que se muestra junto al submenú
   * @param tab Tab que se abre al hacer click
   */
  constructor(nombre: string, icon: string = null, tab: Tab = null, funcion: Function = function () { }, funcionArgs: Array<any> = []) {
    this.nombre = nombre;
    this.funcion = funcion;
    this.funcionArgs = funcionArgs;
    this.icon = icon;
    this.tab = tab;
  }

  /**
   * Ejecuta la función asociada al submenú
   * @param argumentos  Argumentos de invocación.
   */
  ejecutarFuncion(argumentos: Array<any>) {
    this.funcion.call(undefined, argumentos);
  }
}

export class EstadoMovimiento {
  public Id: number;
  public Nombre: string;
  public Error: boolean;
  constructor(
    Id: number,
    Nombre: string,
    Error: boolean
  ) {
    this.Id = Id;
    this.Nombre = Nombre;
    this.Error = Error;
  }

}

export class Año {
  public Id: number
  public IdBase: number
  public IdColegio: number
  public Numero: number
  public NumeroRecibo: number
  public TasaInteres: number
  public Timestamp: number
  public TipoFacturacion: number
  public Etiqueta: string
  public Colegio: Colegio

  constructor(
    Id: number,
    IdBase: number,
    IdColegio: number,
    Numero: number,
    NumeroRecibo: number,
    TasaInteres: number,
    Timestamp: number,
    TipoFacturacion: number,
    Etiqueta: string,
    Colegio: Colegio) {
    this.Id = Id;
    this.IdBase = IdBase;
    this.IdColegio = IdColegio;
    this.Numero = Numero;
    this.NumeroRecibo = NumeroRecibo;
    this.TasaInteres = TasaInteres;
    this.Timestamp = Timestamp;
    this.TipoFacturacion = TipoFacturacion;
    this.Etiqueta = Etiqueta;
    this.Colegio = Colegio;
  }
}

interface Colegio {
  Id: number;
  Nombre: string;
  Direccion: string;
  Barrio: string;
  Telefono1: string;
  Telefono2: string;
  Fax: string;
  Email: string;
  Ciudad: string;
  Naturaleza: string;
  Nit: string;
  Dane: string;
  FuenteNombre: string;
  FuenteItalica: boolean;
  FuenteNegrita: boolean;
  Escudo: string;
  CarpetaExtractos: string;
}

/**
 * Modelo de respuesta para los request ModeloService
 */
export class ModeloRespuesta {

  public Id: string
  public Guid: string
  public Object: object

  /**
   * Modelo de respuesta para los request ModeloService
   * @param Id Id Nuevo en caso de que aplique
   * @param Guid Identidad del objeto
   * @param Object El objeto de transacción
   */
  constructor(Id: string, Guid: string, Object: object) {
    this.Id = Id;
    this.Guid = Guid;
    this.Object = Object;
  }
}

export
  class ModeloQuery {
  public Modelo: string;
  public Query: string;

  constructor(Modelo: string, Query: string) {
    this.Modelo = Modelo;
    this.Query = Query;
  }
}

/**
 * Representa un objeto asociado un Modelo
 */
export
  class ObjetoModelo {
  public modelo: string;
  //objeto original
  public objeto: object;
  //copia del objeto original
  public objetoCopy: object;

  /**
   * Representa un objeto asociado un Modelo
   * @param modelo indica el modelo asociado al objeto
   */
  constructor(modelo: string) {
    this.modelo = modelo;
  }

  /**
   * copia los datos del objeto original a la copia
   */
  actualizarCopia() {
    this.objetoCopy = recaudoUI.copyObject(this.objeto);
  }

  /**
   * devuelve true en caso de que el objeto tenga cambios con respecto a la copia
   */
  getTieneCammbios() {

    let ret: boolean = false;

    //Se hace la validación por aparte con las fechas
    let propiedadesDeFecha = ["Fecha", "Creado", "Modificado", "FechaPago", "Impreso", "FechaMatricula", "FechaRetiro", "SaldoAFavorFecha"];

    //Verifica por separado las fechas si son iguales
    _.each(propiedadesDeFecha, (pf) => {

      if (!ret && (this.objeto[pf] || this.objetoCopy[pf]))
        ret = !(recaudoUI.formatDate(this.objeto[pf]) == recaudoUI.formatDate(this.objetoCopy[pf]) ||
          (this.objeto[pf] == null && this.objeto[pf] == this.objetoCopy[pf])
        );

    });

    //Se omiten los objects y arrays porque el idEquals puede generar falsos positivos
    for (var key in this.objeto)
      if (_.isArray(this.objeto[key]) || _.isObject(this.objeto[key]))
        propiedadesDeFecha.push(key);

    //Verifica el objecto sin ningna propiedad que sea dateTime
    if (!ret)
      ret = !_.isEqual(_.omit(this.objeto, propiedadesDeFecha), _.omit(this.objetoCopy, propiedadesDeFecha));

    return ret; //!_.isEqual(this.objeto, this.objetoCopy);
  }
}

