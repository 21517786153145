import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NgbModalRef, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { modalConfirmar } from 'src/app/_models/modals.model';
import { ToastrService } from 'ngx-toastr';
import { ModalConfirmacionComponent } from '../modal-confirmacion/modal-confirmacion.component';

@Component({
  selector: 'app-modal-eliminar-recibos',
  templateUrl: './modal-eliminar-recibos.component.html',
  styleUrls: ['./modal-eliminar-recibos.component.css']
})
export class ModalEliminarRecibosComponent implements OnInit {

  //Variables que se utiliza en la modal de confirmación
  @ViewChild('eliminarRecibosModal') private eliminarRecibosModal;
  @ViewChild(ModalConfirmacionComponent) modalConfirmacion: ModalConfirmacionComponent;

  modalRef: NgbModalRef;

  constructor(
    private _modalService: NgbModal,
    private toastr: ToastrService,
    public changeDetector: ChangeDetectorRef,
    private config: NgbModalConfig
  ) { }

  ngOnInit() {
  }

  /**
   * modal de confirmacion, trae dos botones Cancelar, aceptar
   * @param configuracion
   */
  public abrirConfirmar(): NgbModalRef {

    //Abre la modal con su respectiva configuración
    this.config.backdrop = "static";
    this.config.keyboard = false;
    this.modalRef = this._modalService.open(this.eliminarRecibosModal, { size: "lg" });
    return this.modalRef;
  }

  /**
   * Abre la modal de confirmación para saber si el usuario está seguro de eliminar los recibos y cobros
   */
  private eliminarRecibosYCobros() {

    this.modalConfirmacion.abrirConfirmar({ textoHeader: "Al eliminar los recibos y cobros, los cobros se marcarán con un valor de cero. ¿Está seguro de continuar?" });
    this.modalConfirmacion.modalRef.result.then((dataResult) => {

      if (dataResult)
        this.cerrarConfirmacion("RecibosCobros");

    }, (info) => {
      console.log("Info", info);
    });
  }

  /**
   * Obtiene la seleccion del ususario 
   * @param seleccion
   */
  private cerrarConfirmacion(resultado?) {
    this.modalRef.close(resultado);
  }

  /**
   * Cierra la modal
   */
  private cerrarModal() {
    this.modalRef.dismiss("Cerrar");
  }

}
