import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NgbModalRef, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ModeloService } from '../../services/services';
import * as _ from 'underscore';

@Component({
  selector: 'app-modal-gestor-costos-educativos',
  templateUrl: './modal-gestor-costos-educativos.component.html',
  styleUrls: ['./modal-gestor-costos-educativos.component.css']
})
export class ModalGestorCostosEducativosComponent implements OnInit {

  //Variables que se utiliza en la modal de confirmación
  @ViewChild('gestorCostosEducativosModal') private gestorCostosEducativosModal;

  public anio = {};

  public grados = [];

  public gradoSeleccionado;

  public costosEducativo = [];

  public checkSeleccionTodo = false;

  public cargando = false;

  modalRef: NgbModalRef;

  constructor(
    private _modalService: NgbModal,
    private toastr: ToastrService,
    public changeDetector: ChangeDetectorRef,
    private config: NgbModalConfig,
    private sModelo: ModeloService,
  ) { }

  ngOnInit() {
  }

  /**
   * modal de confirmacion, trae dos botones Cancelar, aceptar
   * @param configuracion
   */
  public abrirModal(anio): NgbModalRef {

    this.anio = anio;

    //Abre la modal con su respectiva configuración
    this.config.backdrop = "static";
    this.config.keyboard = false;
    this.modalRef = this._modalService.open(this.gestorCostosEducativosModal, { size: "lg" });

    this.buscarGrados();

    return this.modalRef;
  }

  /**
   * Busca todos los grados con el gual se va a poder replicar los costos educativos
   */
  private buscarGrados() {

    this.cargando = true;

    //Realiza la consulta del grado
    this.sModelo.getQueries([`Grado;$filter=IdAnio eq ${this.anio["Id"]}&$expand=DefinicionGrado`]).subscribe(data => {

      this.cargando = false;

      this.grados = data["Grado"];

      this.gradoSeleccionado = _.first(this.grados);

      this.buscarCostosEducativo();

    }, (error) => {
      this.capturarError(error);
    });
  }

  /**
   * Selecciona todos los costos educativos
   */
  seleccionarTodo() {

    this.costosEducativo = _.each(this.costosEducativo, (ce) => { ce.seleccionado = this.checkSeleccionTodo; });
  }

  /**
   * Verifica si el check de seleccionar todos debe estar marcado o no
   */
  verificarSeleccion() {

    var costosSeleccionados = _.filter(this.costosEducativo, (ce) => { return ce.seleccionado; });

    this.checkSeleccionTodo = costosSeleccionados.length == this.costosEducativo.length;

  }

  /**
   * Busca los costos educativos segun con la seleccion del grado
   * @param grado 
   */
  public buscarCostosEducativo() {

    this.cargando = true;

    console.log("Grado", this.gradoSeleccionado);

    //Realiza la consulta del grado
    this.sModelo.getQueries([`CostoEducativo;$filter=IdGrado eq ${this.gradoSeleccionado["Id"]} &$orderby=IdTipoCostoEducativo&$expand=Mes,TipoCostoEducativo`]).subscribe(data => {

      this.cargando = false;

      this.costosEducativo = data["CostoEducativo"];

      this.checkSeleccionTodo = false;

    }, (error) => {
      this.capturarError(error);
    });

  }

  /**
   * Si se genera un error inesperado
   * @param error 
   */
  private capturarError(error) {

    this.cargando = false;

    console.log("Hay error", error);;
  }

  /**
   * Retorna los recibos seleccionados
   * @param seleccion
   */
  private aceptar() {

    var costoEducativoSeleccionado = _.filter(this.costosEducativo, (ce) => {
      return ce.seleccionado;
    });

    this.modalRef.close(costoEducativoSeleccionado);
  }

  /**
   * Cierra la modal
   */
  private cerrarModal() {
    this.modalRef.close();
  }

}
