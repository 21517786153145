import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Año, ObjetoModelo } from 'src/app/helpers/Models';
import { recaudoUI } from 'src/app/helpers/RecaudoUI';
import { RecaudoFormHelper } from 'src/app/helpers/RecaudoFormHelper';
import { CollectionView } from 'wijmo/wijmo';
import { WjFlexGrid, WjFlexGridColumn, WjGridModule, WjFlexGridCellTemplate } from 'wijmo/wijmo.angular2.grid';
import { DataMap, CellRange } from 'wijmo/wijmo.grid';
import { ToastrService } from 'ngx-toastr';
import { WijmoHelper, ModeloCollectionView } from 'src/app/helpers/WijmoHelper';
import { ModeloService, MatriculaService, ResponsablePagoService, EstudianteService, OtrasPersonasService, CobrosService, RecibosService, ExtractoService, TareaServidorService } from 'src/app/shared/services/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AutoSizeMode } from 'wijmo/wijmo.grid';
import * as _ from 'underscore';
import { TableService } from 'src/app/shared/services/table';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
  selector: 'app-unidad-negocio',
  templateUrl: './unidad-negocio.component.html',
  styleUrls: ['./unidad-negocio.component.css']
})
export class UnidadNegocioComponent extends RecaudoFormHelper implements OnInit {
  //valores predeterminados
  anio: Año = recaudoUI.getAñoTrabajo();
  hayCambios = false;

  //recoge el valor que que se le envio
  @Input('idUnidadNegocio') public idUnidadNegocio: any;

  //variable
  unidadNegocio: object;
  unidadNegocioObjetoModelo = new ObjetoModelo('UnidadNegocio');

  //select TIpoDocumento
  tiposDocumento = [
    { propiedad: "", etiqueta: "" },
    { propiedad: "Factura", etiqueta: "Factura" },
    { propiedad: "NC", etiqueta: "NC" },
    { propiedad: "ND", etiqueta: "ND" }, 
  ];

  //CollectionViews
  cvRangos: CollectionView;

  //Referencia a los controles de wijmo
  @ViewChild('flexRangos') flexRangos: WjFlexGrid;

  constructor(
    private _toastr: ToastrService,
    private _wjHelper: WijmoHelper,
    private _changeDetector: ChangeDetectorRef,
    private _modalService: NgbModal,

    private _sModelo: ModeloService,
    private _sTable: TableService,
    private _sAuth: AuthenticationService,
    private _sMatricula: MatriculaService,
    private _sResponsablePago: ResponsablePagoService,
    private _sEstudiante: EstudianteService,
    private _sOtrasPersonas: OtrasPersonasService,
    private _sCobro: CobrosService,
    private _sRecibo: RecibosService,
    private _sExtracto: ExtractoService,
    private _sTarea: TareaServidorService
  ) {
    super(_toastr, _wjHelper, _sModelo, _changeDetector, _sTable, _sAuth, _sMatricula,
      _sResponsablePago, _sEstudiante, _sOtrasPersonas, _sCobro, _sRecibo, _sExtracto, _sTarea);
  }

  /**
   * Los componentes generados dinámicamente deben implementar esta interfaz
   */
  ngAfterViewInit() {}

  ngOnInit() {
    console.log("ngOnInit unidad de negocio", this.idUnidadNegocio);
    //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
    this.setModelosTrabajo2({
      identificador: "Odata",
      odata: [
        'UnidadNegocio;$filter=IdUnidadNegocio eq ' + this.idUnidadNegocio + '&$expand=Rangos'
      ]
    });

    //Carga la consulta con identificador 'Odata'
    this.cargarDatos("Odata", () => { });
  }


  /**
   * Trae los datos de las consultas realizadas
   * @param data El array u objeto que se esta consultado
   * @param identificador El identificador que ha sigo asignado a cada consulta
   */
   onDatosObtenidos(data: any, identificador: string) {

    //console.log('onDatosObtenidos-UnidadNegocio', data, identificador);
    //Carga los datos de la consulta de Odata
    if (identificador == "Odata") {
      
      this.unidadNegocio = _.first(data['UnidadNegocio']);

      //Actualiza el modelo con el que se va a trabajar
      this.unidadNegocioObjetoModelo.objeto = this.unidadNegocio;
      this.unidadNegocioObjetoModelo.actualizarCopia();
      this.registrarObjetosModelo([this.unidadNegocioObjetoModelo]);

      //console.log('onDatosObtenidos', this.idUnidadNegocio, this.unidadNegocioObjetoModelo);
      //Asigna los datos a los grids
      this.cvRangos = new CollectionView(this.unidadNegocio["Rangos"]);
      
      //envia datos predeterminados
      this.cvRangos.newItemCreator = () => {
        return { IdUnidadNegocio: this.idUnidadNegocio};
      }

      this.cvRangos.trackChanges = true;

      //Genera la copia de los flexgrids 
      this.registrarModeloCollectionViews([
        new ModeloCollectionView('Rango', 'IdRango', this.cvRangos, this.flexRangos)
      ]);
    }
  }

  onAjusteColumna(column: WjFlexGridColumn, modelo: string) {
    if (modelo) {
      switch (column.binding) {
        case "TipoDocumento":
          column.width = 150;
          //crea el select de tiposDocumento
          column.dataMap = new DataMap(this.tiposDocumento, "propiedad", "etiqueta");
          break;
        default:
      }
    }
  }

  /**
   * Permite al tap detectar si hay cambios o no, para el momento de ser cerrado
   */
  consultarSiHayCambios() {
    this.hayCambios = this.getTieneCambios();
    return this.hayCambios;
  }
}