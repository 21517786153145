import { Component, OnInit, ChangeDetectorRef, Input, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ExtractoService, ModeloService, MatriculaService, ResponsablePagoService, EstudianteService, OtrasPersonasService, CobrosService, RecibosService, TareaServidorService } from 'src/app/shared/services/services';
import { TableService } from 'src/app/shared/services/table';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { FileUploader, FileItem, Headers } from 'ng2-file-upload/ng2-file-upload';
import * as _ from 'underscore';
import { recaudoUI } from 'src/app/helpers/RecaudoUI';
import { ModalAlertaComponent } from 'src/app/shared/components/modal-alerta/modal-alerta.component';
import { RecaudoFormHelper } from 'src/app/helpers/RecaudoFormHelper';
import { Router } from '@angular/router';
import { WijmoHelper } from 'src/app/helpers/WijmoHelper';
import { ExtractoComponent } from '../extracto/extracto.component';

@Component({
  selector: 'app-asistente-extracto',
  templateUrl: './asistente-extracto.component.html',
  styleUrls: ['./asistente-extracto.component.css']
})
export class AsistenteExtractoComponent extends RecaudoFormHelper implements OnInit {

  @ViewChild(ModalAlertaComponent) modalAlerta: ModalAlertaComponent;

  @Input('anio') public anio: object;
  usuarioAuth = this.sAuth.getUser();

  uploader: FileUploader;
  tiposArchivosPermitidos: string;
  hasBaseDropZoneOver = false;
  hasAnotherDropZoneOver = false;
  log: string = "";
  mensajeModal = "";
  permiteGuardar = false;
  cargando = false;
  idExtractoSubido = null;
  parametros: [];
  tiposExtracto: [];
  tipoExtracto: string;

  //declaración de servicios
  constructor(
    private routes: Router,
    private _toastr: ToastrService,
    private _wjHelper: WijmoHelper,
    private _changeDetector: ChangeDetectorRef,
    private _sTable: TableService,

    private _sModelo: ModeloService,
    private _sAuth: AuthenticationService,
    private _sMatricula: MatriculaService,
    private _sResponsablePago: ResponsablePagoService,
    private _sEstudiante: EstudianteService,
    private _sOtrasPersonas: OtrasPersonasService,
    private _sCobro: CobrosService,
    private _sRecibo: RecibosService,
    public _sExtracto: ExtractoService,
    private _sTarea: TareaServidorService
  ) {
    super(_toastr, _wjHelper, _sModelo, _changeDetector, _sTable, _sAuth, _sMatricula,
      _sResponsablePago, _sEstudiante, _sOtrasPersonas, _sCobro, _sRecibo, _sExtracto, _sTarea);
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {

    this.setModelosTrabajo2({ identificador: 'oData', odata: ["Parametro"] });

    this.cargarDatos(null, () => { });

    this.uploader = new FileUploader({
      url: recaudoUI.getBaseUrl() + "api/Extractos/Archivo",
      authToken: "" + this.sAuth.getToken(),
    });

    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
    }

    //Cuando ha culminado la subida de los archivos
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {

      //Si se terminó de subir el archivo seleccionado
      if (this.uploader.progress == 100) {

        this.permiteGuardar = false;
        this.crearTarea("CargarExtracto", this.anio["IdColegio"], JSON.parse(response), this.tipoExtracto, null, null, null);
      }

      // Emite el cambio solo si el archivo se sube correctamente
      if (status == 200) {
      }

      //Actualiza las barras de progreso y el estado de los archivos
      this.changeDetector.detectChanges();
    };

    this.uploader.onErrorItem = (item, response) => {
      this.log = "Error al subir archivo.  " + response + "\n\n" + this.log;
      this.toastr.error("Error al subir archivo.  " + response);
      this.cargando = false;
    }

    this.uploader.onProgressItem = (file: FileItem, progress: any) => {
      this.changeDetector.detectChanges();
      return { file, progress };
    };
  }


  /**
   * Verifica que el archivo seleccionado se encuentra con el formato permitido
   * @param sender 
   */
  verificarArchivoSeleccionado(sender: any) {

    //Deja solo el ultimo archivo seleccionado
    this.uploader.queue = [_.last(this.uploader.queue)];

    //Verifica si el archivo que se está subiendo es valido
    if (this.uploader.queue.length == 0) {
      this.permiteGuardar = false;
      this.toastr.error("Error en el archivo");
      this.cargando = false;
      return;
    }
    this.permiteGuardar = true;
  }

  /**
   * Guarda el archivo cargado
   */
  guardarArchivo() {

    this.uploader.uploadAll();

    let nombreArchivos = "";
    _.each(this.uploader.queue, (item) => {
      nombreArchivos += item.file.name + " "
    });

    this.cargando = true;
    this.log = "\nSubiendo archivo " + nombreArchivos + " ...";
  }

  // Angular2 File Upload
  fileOverBase(e: any): void {

    console.log("over", e);
    this.hasBaseDropZoneOver = e;
  }

  fileOverAnother(e: any): void {
    console.log("overAnother", e);
    this.hasAnotherDropZoneOver = e;
  }

  OnDestroy() {
  }

  /**
   * Muestra el mensaje si la tarea finalizo con exito o si hubo un error
   * @param tareaServidor 
   */
  public mostrarMensajeTareaRevisada(tareaServidor) {

    let mensaje = "";
    let titulo = ""

    if (tareaServidor["Estado"] == 1) {
      titulo = "Éxito";

      if (tareaServidor["Nombre"] == "CargarExtracto") {

        this.mensajeModal = tareaServidor["Resultado"] + " movimiento(s) cargados(s)";

        //Guarda el id del extracto que se subio
        this.idExtractoSubido = tareaServidor["Parametro6"];

        mensaje += "\n" + tareaServidor["Resultado"] + " movimiento(s) cargados(s)";

        //Limpia el archivo subido 
        this.uploader.queue = [];
        this.permiteGuardar = false;

        this.crearTarea("CruzarMovimientos", this.anio["IdColegio"], null, null, null, null, null);
      }

      else if (tareaServidor["Nombre"] == "CruzarMovimientos") {

        this.cargando = false;

        this.mensajeModal += "<br><br>" + tareaServidor["Resultado"] + " movimiento(s) cruzado(s) ";
        mensaje += "Finalizado\n" + tareaServidor["Resultado"] + " movimiento(s) cruzado(s) ";
      }
    }
    else {
      titulo = "Error";
      this.cargando = false;
      mensaje += "\n" + tareaServidor["Excepcion"];
    }

    this.log = mensaje + "\n" + this.log;

    //Solo muestra una vez la modal cada vez que se sube un archivo
    if (tareaServidor["Nombre"] == "CruzarMovimientos" || titulo == "Error") {

      console.log("this.mensajeModal",this.mensajeModal);

      this.modalAlerta.abrirConfirmar({ textHeader: titulo, textBody: this.mensajeModal });
      this.modalAlerta.modalRef.result.then((dataResult) => {
      }, (info) => {
        console.log("Info", info);

        if (titulo != "Error") {
          //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
          this.setModelosTrabajo2({
            identificador: "buscarExtracto",
            servicio: "sExtracto",
            metodo: "getExtractoPorNombre",
            consulta: { id: this.idExtractoSubido }
          });

          //Carga solo la consulta con identificador 'Matricula'
          this.cargarDatos("buscarExtracto", () => { });
        }

      });
    }
  }

  /**
   * Trae los datos de las consultas realizadas
   * @param data El array u objeto que se esta consultado
   * @param identificador El identificador que ha sigo asignado a cada consulta
   */
  onDatosObtenidos(data: any, identificador: string) {

    //Abre el tab del extracto que se acaba de subir
    if (identificador == "buscarExtracto")
      recaudoUI.tabsManager.agregar("Extracto - " + data[0].Nombre, null, ExtractoComponent, { extracto: data[0] });

    if (identificador == "oData") {

      //Tipos de extractos disponibles para cargar
      this.parametros = data["Parametro"];
      let parametro = _.find(this.parametros, (p) => { return p.Nombre == 'TiposExtracto'; });
      if (parametro)
      {
        this.tiposExtracto = JSON.parse(parametro.Valor);
        if (this.tiposExtracto && this.tiposExtracto.length > 0) {
          var pos = 0;
          this.tipoExtracto = (this.tiposExtracto[pos])["Valor"];
        }
      }
    }
  }

}
