import { Component, OnInit, ChangeDetectorRef, Input, ViewChild } from '@angular/core';
import { RecaudoFormHelper } from 'src/app/helpers/RecaudoFormHelper';
import { ToastrService } from 'ngx-toastr';
import { WijmoHelper, ModeloCollectionView } from 'src/app/helpers/WijmoHelper';
import { ModeloService, CobrosService, MatriculaService, ResponsablePagoService, EstudianteService, OtrasPersonasService, RecibosService, ExtractoService, TareaServidorService } from 'src/app/shared/services/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TableService } from 'src/app/shared/services/table';
import * as _ from 'underscore';
import { ObjetoModelo } from 'src/app/helpers/Models';
import { CollectionView } from 'wijmo/wijmo';
import { WjFlexGrid, WjFlexGridColumn } from 'wijmo/wijmo.angular2.grid';
import { recaudoUI } from 'src/app/helpers/RecaudoUI';
import { ReciboComponent } from '../recibo/recibo.component';
import { MatriculaComponent } from '../matricula/matricula.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
  selector: 'app-cobro',
  templateUrl: './cobro.component.html',
  styleUrls: ['./cobro.component.css']
})
export class CobroComponent extends RecaudoFormHelper implements OnInit {

  @Input('idCobro') public idCobro: number;
  @Input('anio') public anio: object;

  cobroObjetoModelo = new ObjetoModelo('Cobro');

  cobro: object;
  detallesRecibo: Object;
  hayCambios = false;

  //CollectionViews
  cvDetalleRecibo: CollectionView;

  //Referencia a los controles de wijmo
  @ViewChild('flexDetalleRecibo') flexDetalleRecibo: WjFlexGrid;

  //declaración de servicios
  constructor(
    private _toastr: ToastrService,
    private _wjHelper: WijmoHelper,
    private _changeDetector: ChangeDetectorRef,
    private _sTable: TableService,
    //private sCobro: CobrosService,

    private _sModelo: ModeloService,
    private _sAuth: AuthenticationService,
    private _sMatricula: MatriculaService,
    private _sResponsablePago: ResponsablePagoService,
    private _sEstudiante: EstudianteService,
    private _sOtrasPersonas: OtrasPersonasService,
    private _sCobro: CobrosService,
    private _sRecibo: RecibosService,
    private _sExtracto: ExtractoService,
    private _sTarea: TareaServidorService
  ) {
    super(_toastr, _wjHelper, _sModelo, _changeDetector, _sTable, _sAuth, _sMatricula,
      _sResponsablePago, _sEstudiante, _sOtrasPersonas, _sCobro, _sRecibo, _sExtracto, _sTarea);
  }

  ngAfterViewInit(): void {
  }


  ngOnInit() {

    let objCobro = { IdCobro: this.idCobro };

    //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
    this.setModelosTrabajo2({
      servicio: "sCobro",
      identificador: "cobro",
      metodo: "getCobro",
      consulta: objCobro
    });

    //Carga solo la consulta con identificador 'cobro'
    this.cargarDatos("cobro", () => { });
  }

  /**
   * Trae los datos de las consultas realizadas
   * @param data El array u objeto que se esta consultado
   * @param identificador El identificador que ha sigo asignado a cada consulta
   */
  onDatosObtenidos(data: any, identificador: string) {

    //Carga los datos de la consulta los cobro que se estan buscando
    if (identificador = "cobro") {

      this.cobro = _.first(data);

      this.cobroObjetoModelo.objeto = this.cobro;
      this.cobroObjetoModelo.actualizarCopia();
      this.registrarObjetosModelo([this.cobroObjetoModelo]);

      this.detallesRecibo = this.cobro["DetallesRecibo"];

      this.cvDetalleRecibo = new CollectionView(this.detallesRecibo);
      this.cvDetalleRecibo.trackChanges = true;

      //Genera el grid de los deralles Recibos
      this.registrarModeloCollectionViews([
        new ModeloCollectionView('DetalleRecibo', 'Id', this.cvDetalleRecibo, this.flexDetalleRecibo)
      ]);
    }
  }

  /**
  * Actualiza las columnas que va tener el grid
  * @param column dato de la columna
  * @param modelo nombre del primer parametro en el new ModeloCollectionView
  */
  onAjusteColumna(column: WjFlexGridColumn, modelo: string) {
    if (modelo == "DetalleRecibo") {
      switch (column.binding) {
        default:
      }
    }
  }

  /**
   * Abre el tap de matrícula
   */
  abrirMatricula() {
    recaudoUI.tabsManager.agregar("Matrícula - " + this.cobro["CodigoEstudiante"], "fa-user", MatriculaComponent, { idMatricula: this.cobro["Matricula"].Id, anio: this.anio });
  }

  /**
   * Abre el Tap del recibo
   */
  verRecibo() {
    let item = this.obtenerRegistroSeleccionado(this.flexDetalleRecibo);

    if (!this.verificarSiHayCambios() && item)
      recaudoUI.tabsManager.agregar("Recibo - " + (item.Recibo["NumeroRecibo"] || item.Recibo["Referencia1"] || item.Recibo["Referencia2"]), "fa-file-text-o", ReciboComponent, { idRecibo: item.Recibo.Id, anio: recaudoUI.copyObject(this.anio) });
  }

  /**
   * Permite al tap detectar si hay cambios o no, para el momento de ser cerrado
   */
  consultarSiHayCambios() {
    this.hayCambios = this.getTieneCambios();
    return this.hayCambios;
  }
}
