import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { RecaudoFormHelper } from 'src/app/helpers/RecaudoFormHelper';
import { recaudoUI } from 'src/app/helpers/RecaudoUI';
import { Año, ObjetoModelo } from 'src/app/helpers/Models';
import { CollectionView } from 'wijmo/wijmo';
import { ToastrService } from 'ngx-toastr';
import { WijmoHelper } from 'src/app/helpers/WijmoHelper';
import { ModeloService, MatriculaService, RecibosService, OtrasPersonasService, EstudianteService, ResponsablePagoService, CobrosService, ExtractoService, TareaServidorService } from 'src/app/shared/services/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'node_modules/underscore';
import { TableService } from 'src/app/shared/services/table';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
  selector: 'app-tarea-servidor',
  templateUrl: './tarea-servidor.component.html',
  styleUrls: ['./tarea-servidor.component.css']
})
export class TareaServidorComponent extends RecaudoFormHelper implements OnInit {

  @Input('idTareaServidor') public idTareaServidor: any;
  tareaServidorObjetoModelo = new ObjetoModelo('TareaServidor');

  tareaServidor: object;
  hayCambios = false;

  constructor(
    private _toastr: ToastrService,
    private _wjHelper: WijmoHelper,
    private _changeDetector: ChangeDetectorRef,
    private _modalService: NgbModal,
    private _sTable: TableService,

    private _sModelo: ModeloService,
    private _sAuth: AuthenticationService,
    private _sMatricula: MatriculaService,
    private _sResponsablePago: ResponsablePagoService,
    private _sEstudiante: EstudianteService,
    private _sOtrasPersonas: OtrasPersonasService,
    private _sCobro: CobrosService,
    private _sRecibo: RecibosService,
    public _sExtracto: ExtractoService,
    private _sTarea: TareaServidorService
  ) {
    super(_toastr, _wjHelper, _sModelo, _changeDetector, _sTable, _sAuth, _sMatricula,
      _sResponsablePago, _sEstudiante, _sOtrasPersonas, _sCobro, _sRecibo, _sExtracto,_sTarea);
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {

    //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
    this.setModelosTrabajo2({
      identificador: "Odata",
      odata: [
        'TareaServidor;$filter=IdTareaServidor eq ' + this.idTareaServidor
      ]
    });

    //Carga la consulta con identificador 'Odata'
    this.cargarDatos("Odata", () => { });
  }

  /**
   * Trae los datos de las consultas realizadas
   * @param data El array u objeto que se esta consultado
   * @param identificador El identificador que ha sigo asignado a cada consulta
   */
  onDatosObtenidos(data: any, identificador: string) {

    //Carga los datos de la consulta de Odata
    if (identificador == "Odata") {

      this.tareaServidor = _.first(data['TareaServidor']);
      this.tareaServidor["Fecha"] = recaudoUI.formatDate(this.tareaServidor["Fecha"]);

      this.tareaServidorObjetoModelo.objeto = this.tareaServidor;
      this.tareaServidorObjetoModelo.actualizarCopia();      
      this.registrarObjetosModelo([this.tareaServidorObjetoModelo]);
    }
  }

  /**
   * Permite al tap detectar si hay cambios o no, para el momento de ser cerrado
   */
  consultarSiHayCambios() {
    this.hayCambios = this.getTieneCambios();
    return this.hayCambios;
  }
}
