import { Tab } from './Models';
import { Type, ViewChild } from '@angular/core';
import * as _ from 'node_modules/underscore';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { recaudoUI } from './RecaudoUI';

/**
 * Provee funciones que permiten administrar pestañas
 */
export class TabsManager {
  //lista de pestañas
  private tabs: Array<Tab> = [];
  //se inicializa para poder hacer uso de sus mienbros
  public tabActual: Tab = new Tab(null, null, null);

  //Maneja la tap que debe mostrarse despues de que una tap se haya eliminado
  @ViewChild('tabConfig') tabConfig: NgbTabset;

  /**
   * Agrega una nueva pestaña.
   * @param header Texto que se muestra en el header de la pestaña.
   * @param icon Icono que se muestra en la pestaña.
   * @param component Nombre del componente que se mostrará en el contenido de la tab.
   * @param componentData Objecto que define los parámetros que se le injectarán al componente.
   * @param permitirMultiplicar Indica pueden haber otras pestañas con instancias del componente que se referencia en la actual
   * @param permitirCerrar Indica si la pestaña se puede cerrar
   */
  agregar(header: string, icon: string, component: Type<any>, componentData: object = {}, permitirMultiplicar: boolean = true, permitirCerrar: boolean = true) {
    let tabMismoComponente = _.findWhere(this.tabs, { component: component });


    let tabsAbiertas = this.getTabs();
    let existeTab = _.find(tabsAbiertas, (tab) => { return tab.header == header; });

    //Si la tab que se está abriendo existe y su cabecera igual, entonces coloca visible la tab que está abierta
    if (existeTab)
      this.setTabConfigChangeTab(existeTab["guid"]);

    //Si no esta abierta, crea una nueva tab
    else {

      //si hay una tab que impide multiples instancias de un mismo componente en las pestañas.
      if (tabMismoComponente && !tabMismoComponente.permitirMultiplicar) {
        //Object.assign(this.tabActual, tabMismoComponente);
        return;
      }

      let tab = new Tab(header, icon, component, componentData, permitirMultiplicar, permitirCerrar);
      this.tabs.push(tab);

      //actualiza la tab actual
      this.tabActual.clonar(tab);
    }
  }

  /**
   * Agrega un objeto de tipo Tab a las pestañas.
   * @param tab
   */
  agregarTab(tab: Tab) {
    this.agregar(tab.header, tab.icon, tab.component, tab.componentData, tab.permitirMultiplicar, tab.permitirCerrar);
  }

  /**
   * 
   * @param tab 
   * @param component 
   */
  setTab(guid, component, tab?) {
    let i = _.findIndex(this.tabs, { guid: guid });

    if (i != -1) {
      if (component)
        this.tabs[i].currentComponent = component;

      //Sobreescribe las propiedades de la tab
      if (tab) {
        this.tabs[i].header = tab["header"];
        this.tabs[i].icon = tab["icon"];
      }
    }
  }

  /**
   * Cierra todas las tabs excepto la tab principal
   */
  cerrarTodasLasTabs() {
    for (var i = 0; i < this.tabs.length; i++) {
      
      if (this.tabs[i].header != "Principal") {
        let _i = _.findIndex(this.tabs, { guid: this.tabs[i].guid });

        if (_i != -1) {
          this.tabs.splice(_i, 1);
          i--;
        }
      }

    }
  }

  /**
   * Elimina una pestaña de la lista
   * @param guid
   */
  eliminar(guid: string) {
    let i = _.findIndex(this.tabs, { guid: guid });

    if (i != -1) {
      this.tabs.splice(i, 1);
    }
  }

  /**
   * Actualiza el modelo de tab
   */
  setTabConfig(tabConfig) {
    this.tabConfig = tabConfig;

  }

  /**
   * Cambia de  tab visible
   * @param index 
   */
  setTabConfigChangeTab(index) {
    this.tabConfig.select(index);
  }

  /**
   * Obtiene la configuracion de las tabs html
   */
  getTabConfig() {
    return this.tabConfig;
  }

  getTabs() {
    return this.tabs;
  }

  /**
   * Edita la teb actual a la que el usuario está viendo
   * @param tab 
   */
  setTabActual(tab: Tab){
    this.tabActual.clonar(tab);
  }

  /**
   * Obtiene el tab actual
   */
  getTabActual() {
    return this.tabActual;
  }
}
