import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { CollectionView } from 'wijmo/wijmo';
import { WjFlexGrid, WjFlexGridColumn } from 'wijmo/wijmo.angular2.grid';
import { RecaudoFormHelper } from 'src/app/helpers/RecaudoFormHelper';
import { ToastrService } from 'ngx-toastr';
import { WijmoHelper, ModeloCollectionView } from 'src/app/helpers/WijmoHelper';
import { ModeloService, ManagerService, MatriculaService, ResponsablePagoService, EstudianteService, OtrasPersonasService, CobrosService, RecibosService, ExtractoService, TareaServidorService } from 'src/app/shared/services/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TableService } from 'src/app/shared/services/table';
import { DataMap } from 'wijmo/wijmo.grid';
import { recaudoUI } from 'src/app/helpers/RecaudoUI';
import { UsuarioComponent } from '../usuario/usuario.component';
import * as _ from 'underscore';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ModalFormularioComponent } from 'src/app/shared/components/modal-formulario/modal-formulario.component';
import { HAMMER_LOADER } from '@angular/platform-browser';
import { ModalCambiarPasswordComponent } from 'src/app/shared/components/modal-cambiar-password/modal-cambiar-password.component';
import { throttleTime, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent extends RecaudoFormHelper implements OnInit {

  @ViewChild(ModalFormularioComponent) modalFormulario: ModalFormularioComponent;
  @ViewChild(ModalCambiarPasswordComponent) modalCambioPassword: ModalCambiarPasswordComponent;

  usuarios: Object;
  colegios: Object;
  logFiltro: object = {
    logs: [],
    cantidad: 0,
    pagina: 1,
    cantidadPorPagina: 25
  }

  //Contiene los modelos de grid que se utilizan en la página actual
  mdeloColection = [];

  copiaLogFiltro: object;

  //Tipos de eventos que puedesn ser en el log
  eventosLog = [
    { etiqueta: "Información", propiedad: "INFORMACION" },
    { etiqueta: "Advertencia", propiedad: "ADVERTENCIA" },
    { etiqueta: "Error", propiedad: "ERROR" }
  ];

  hayCambios = false;

  //CollectionViews
  cvUsuarios: CollectionView;
  cvColegios: CollectionView;
  cvLogs: CollectionView;

  //Referencia a los controles de wijmo
  @ViewChild('flexUsuarios') flexUsuarios: WjFlexGrid;
  @ViewChild('flexColegios') flexColegios: WjFlexGrid;
  @ViewChild('flexLogs') flexLogs: WjFlexGrid;
  @ViewChild('ngbPaginationElm') ngbPaginationElm: any;

  filtro = {
    buscaUsuario: "",
    buscaColegio: ""
  };

  constructor(
    private sUsuarios: ManagerService,
    private _toastr: ToastrService,
    private _wjHelper: WijmoHelper,
    private _changeDetector: ChangeDetectorRef,
    private _modalService: NgbModal,
    private _sTable: TableService,

    private _sModelo: ModeloService,
    private _sAuth: AuthenticationService,
    private _sMatricula: MatriculaService,
    private _sResponsablePago: ResponsablePagoService,
    private _sEstudiante: EstudianteService,
    private _sOtrasPersonas: OtrasPersonasService,
    private _sCobro: CobrosService,
    private _sRecibo: RecibosService,
    public _sExtracto: ExtractoService,
    private _sTarea: TareaServidorService
  ) {
    super(_toastr, _wjHelper, _sModelo, _changeDetector, _sTable, _sAuth, _sMatricula,
      _sResponsablePago, _sEstudiante, _sOtrasPersonas, _sCobro, _sRecibo, _sExtracto, _sTarea);
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {

    //Llena los valores predeterminados del log
    this.logFiltro["Hasta"] = moment().toDate();
    this.logFiltro["Desde"] = moment().add(-1, "months").toDate();
    this.logFiltro["Evento"] = "";
    this.logFiltro["IdColegio"] = null;
    this.logFiltro["IdUsuario"] = null;
    this.logFiltro["Usuario"] = "";
    this.logFiltro["Mensaje"] = "";
    this.logFiltro["Ip"] = "";

    this.copiaLogFiltro = recaudoUI.copyObject(this.logFiltro);

    this.sUsuarios.getUsuarios().subscribe(dataUsuarios => {
      this.sUsuarios.getColegios().subscribe(dataColegios => {

        this.usuarios = dataUsuarios;
        this.colegios = dataColegios;

        if (this.usuarioAuth.Rol == "SuperAdmin")
          this.actualizarLog();

        //Genera la consulta en el servicio RecaudoFormHelper 
        this.onDatosObtenidos();

      }, (error) => {
        console.log("Error", error);
      });
    }, (error) => {
      console.log("Error", error);
    });
  }

  /**
   * Realiza realiza la consulta del log
   */
  actualizarLog() {

    if (this.cargando)
      return;

    this.cargando = true;

    var propiedadesValidar = ["Hasta", "Desde", "Evento", "IdColegio", "IdUsuario", "Usuario", "Mensaje", "Ip"];
    let hayCambioFiltro = _.find(propiedadesValidar, (p) => {

      if (p == "Hasta" || p == "Desde")
        return recaudoUI.formatDate(this.copiaLogFiltro[p], "f") != recaudoUI.formatDate(this.logFiltro[p], "f");
      else
        return this.copiaLogFiltro[p] != this.logFiltro[p];
    });

    //Si hay cambios ejecuta la función actualizarLog por medio de la barra de navegación
    if (hayCambioFiltro) {
      this.logFiltro["pagina"] = 1;
      this.ngbPaginationElm.page = 1;
      this.copiaLogFiltro = recaudoUI.copyObject(this.logFiltro);
    }

    let filtroLog = recaudoUI.copyObject(this.logFiltro);

    filtroLog["pagina"] = this.ngbPaginationElm && this.ngbPaginationElm.page ? this.ngbPaginationElm.page : 1;

    filtroLog["Desde"] = recaudoUI.formatDate(this.logFiltro["Desde"], "f");
    filtroLog["Hasta"] = recaudoUI.formatDate(this.logFiltro["Hasta"], "f");

    this.sUsuarios.getLog(filtroLog).subscribe(dataLogs => {

      this.logFiltro["cantidad"] = dataLogs["Cantidad"];
      this.logFiltro["logs"] = dataLogs["Log"];
      this.cvLogs = new CollectionView(dataLogs["Log"]);
      this.cvLogs.trackChanges = true;

      this.cargando = false;
      this.agregarModeloCollection(new ModeloCollectionView('LogManager', 'IdLogManager', this.cvLogs, this.flexLogs), "LogManager");

    }, (error) => {
      console.log("Error", error);
    });
  }

  /**
   * Actualiza los modelos del grid
   * @param modelo 
   * @param nombreModelo 
   */
  agregarModeloCollection(modelo, nombreModelo) {

    this.mdeloColection = _.filter(this.mdeloColection, (m) => { return m.modelo != nombreModelo });

    this.mdeloColection.push(modelo);

    this.registrarModeloCollectionViews(this.mdeloColection);

    this.cargando = false;

    this.changeDetector.detectChanges();
  }

  /**
   * Obtiene la consulta de la funcion cargarDatos()
   * @param data 
   */
  onDatosObtenidos() {

    _.each(this.usuarios, (u) => {
      u.UltimoLogin = recaudoUI.formatDate(u.UltimoLogin);
    });

    this.cvUsuarios = new CollectionView(this.usuarios);
    this.cvUsuarios.trackChanges = true;

    this.agregarModeloCollection(new ModeloCollectionView('UsuarioManager', 'IdUsuario', this.cvUsuarios, this.flexUsuarios), "UsuarioManager");

    //Genera el collection view para el superAdmin
    if (this.usuarioAuth.Rol === 'SuperAdmin') {

      this.cvColegios = new CollectionView(this.colegios);
      this.cvColegios.trackChanges = true;
      this.agregarModeloCollection(new ModeloCollectionView('ColegioManager', 'IdColegio', this.cvColegios, this.flexColegios), "ColegioManager");
    }
  }

  onAjusteColumna(column: WjFlexGridColumn, modelo: string) {
    if (modelo == "UsuarioManager") {
      switch (column.binding) {
        case "IdColegio":
          column.width = 130;
          //crea el select de meses
          column.dataMap = new DataMap(this.colegios, "IdColegio", "Nombre");
          break;
        default:
      }
    }
    if (modelo == "LogManager") {
      switch (column.binding) {
        case "IdColegio":
          column.width = 130;
          //crea el select de meses
          column.dataMap = new DataMap(this.colegios, "IdColegio", "Nombre");
          break;
        default:
      }
    }
  }

  /**
   * Crea un nuevo usuario
   */
  crearUsuario() {
    if (!this.verificarSiHayCambios())
      recaudoUI.tabsManager.agregar("Usuario - ***", "fa fa-address-card-o", UsuarioComponent, { idUsuario: 0 });
  }

  /**
   * Abre una modal para el cambio de password
   */
  cambiarPassword() {

    if (this.usuarioAuth.Rol != "SuperAdmin") {
      this.toastr.error("No tiene permiso de superadministrador para editar al usuario seleccionado")
      return null;
    }

    let item = this.flexUsuarios.selectedItems[0];

    if (!item)
      return;

    //this.modal.abrirCambiarPassword(this.usuario["IdUsuario"]);
    this.modalCambioPassword.abrirCambiarPassword(item);

    //this.modal.modalRef.result.then((data) => {
    this.modalCambioPassword.modalRef.result.then((data) => {

    }, (info) => {
      console.log("info", info);
    });
  }

  /**
   * Abre el tap para editar el usuario
   */
  editarUsuario() {
    let item = this.flexUsuarios.selectedItems[0];

    if (item["Rol"] == "SuperAdmin" && this.usuarioAuth.Rol == "Administrador") {
      this.toastr.error("No tiene permiso de superadministrador para editar al usuario seleccionado")
      return null;
    }

    if (!this.verificarSiHayCambios())
      recaudoUI.tabsManager.agregar("Usuario - " + item["Nombre"], "fa fa-address-card-o", UsuarioComponent, { idUsuario: item.IdUsuario });

  }

  /**
   * Crea un nuevo colegio
   */
  editarColegio(idColegio) {

    if (!(!this.verificarSiHayCambios()))
      return;

    //Obtiene el colegio seleccionado o jenera el objeto para la creación del colegio
    let item = idColegio ? { Nombre: "", Conexion: "" } : this.flexColegios.selectedItems[0];

    //Crea el formulario 
    this.modalFormulario.abrirIngresarValor({
      titulo: idColegio ? "Editar Colegio" : "Crear Colegio",
      input: [
        { property: "Nombre", label: "Nombre ", type: "text", required: true, minLength: 2, placeholder: "Nombre del colegio" },
        { property: "Conexion", label: "Conexión ", type: "textarea", required: true, minLength: 20, messageErrorRequired: "La cadena de conexión no debe ser menor a 20 caracteres", placeholder: "Ingrese la cadena de conexión del colegio", rows: 6 },
        { property: "NombreDB", label: "NombreDB", type: "text", required: false, minLength: 0, placeholder: "Nombre clave para informes" },
      ],
      valor: _.clone(item)
    });
    this.modalFormulario.modalRef.result.then((dataResult) => {

      //Guarda el colegio
      this.sModelo.saveAuth("Colegio", dataResult).subscribe((data) => {

        dataResult["IdColegio"] = data["Id"];

        //Si el colegio fue editado
        if (item["IdColegio"]) {
          this.toastr.success("Se ha editado el colegio");
          item.Nombre = dataResult["Nombre"];
          item.Conexion = dataResult["Conexion"];
          item.NombreDB = dataResult["NombreDB"];
        }
        //Si el colegio fue creado
        else {
          this.toastr.success("Se ha creado el colegio");
          this.cvColegios.sourceCollection.push(dataResult);
        }

        //Guarda los cambios  
        this.cvColegios.refresh();

      }, (error) => { console.log("Error", error); });

    }, (info) => {
      console.log("Modal cerrada", info);
    });
  }

  /**
   * Restaura el acceso al colegio
   */
  restaurarColegio() {

    let item = this.flexColegios.selectedItems[0];
    if (!item)
      return;

    //Desmarca el eliminado al colegioç //Abre la modal de comfirmación
    this.modalConfirmacion.abrirConfirmar({ textoHeader: "¿Está seguro de activar el colegio " + item.Nombre + "?" });
    this.modalConfirmacion.modalRef.result.then((result: boolean) => {

      if (result) {
        this.sUsuarios.restaurarColegio(item["IdColegio"]).subscribe(data => {
          item.Eliminado = null;
          this.flexColegios.refresh();

        }, (error) => { console.log("Error", error); });
      }
    });
  }

  /**
   * permite ver o editar la informacion del log
   * @param item 
   */
  verDetallesLog(item) {

    //Crea el formulario 
    this.modalFormulario.abrirIngresarValor({
      titulo: "Detalle",
      input: [
        { property: "Hora", label: "Hora ", type: "datetime-local", required: false, disabled: true },
        { property: "Evento", label: "Evento ", type: "text", required: false, disabled: true },
        { property: "IdColegio", label: "Id Colegio ", type: "text", required: false, disabled: true },
        { property: "IdUsuario", label: "Id Usuario ", type: "text", required: false, disabled: true },
        { property: "Usuario", label: "Usuario ", type: "text", required: false, disabled: true },
        { property: "Mensaje", label: "Mensaje ", type: "text", required: false, disabled: true },
        { property: "Detalle", label: "Detalle ", type: "textarea", rows: 4, required: false, disabled: true },
        { property: "Excepcion", label: "Excepción ", type: "textarea", rows: 5, required: false, disabled: true },
        { property: "IP", label: "IP ", type: "text", required: false, disabled: true },
      ],
      valor: _.clone(item),
      muestraDetalles: true
    });
    this.modalFormulario.modalRef.result.then((dataResult) => {
    }, (info) => {
      console.log("Modal cerrada", info);
    });
  }

  /**
   * Permite al tap detectar si hay cambios o no, para el momento de ser cerrado
   */
  consultarSiHayCambios() {
    this.hayCambios = this.getTieneCambios();
    return this.hayCambios;
  }
}