import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css']
})
export class ProgressBarComponent implements OnInit {

  @Input() public tareaEnCurso?: object = null;

  //Texto a mostrar cuando se muestra el progressBar
  @Input() public texto?: string = "Tareas pendientes";

  //Contiene la cantidad de tareas realizadas 
  @Input() public totalTareas?: number = 0;

  //Contiene la cantidad de tareas que están pendientes
  @Input() public tareasEnCola?: number = 0;

  //Muestra u oculta el progress bar
    @Input() public mostrar = false;

  constructor() { }

  ngOnInit() {
  }
}
