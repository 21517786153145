import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { RecaudoFormHelper } from 'src/app/helpers/RecaudoFormHelper';
import { recaudoUI } from 'src/app/helpers/RecaudoUI';
import { Año } from 'src/app/helpers/Models';
import { CollectionView } from 'wijmo/wijmo';
import { WjFlexGrid, WjFlexGridColumn } from 'wijmo/wijmo.angular2.grid';
import { ToastrService } from 'ngx-toastr';
import { WijmoHelper, ModeloCollectionView } from 'src/app/helpers/WijmoHelper';
import { ModeloService, MatriculaService, ResponsablePagoService, EstudianteService, OtrasPersonasService, CobrosService, RecibosService, ExtractoService, TareaServidorService } from 'src/app/shared/services/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AutoSizeMode, DataMap } from 'wijmo/wijmo.grid';
import { TableService } from 'src/app/shared/services/table';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
  selector: 'app-parametros',
  templateUrl: './parametros.component.html',
  styleUrls: ['./parametros.component.css']
})
export class ParametrosComponent extends RecaudoFormHelper implements OnInit {

  anio: Año = recaudoUI.getAñoTrabajo();

  @Input('parametros') public parametros: any;
  @Input('colegios') public colegios: any;

  //CollectionViews
  cvParametros: CollectionView;
  hayCambios = false;
  
  //Referencia a los controles de wijmo
  @ViewChild('flexParametro') flexParametro: WjFlexGrid;

  //Filtro del grid
  filtro = {
    buscar: ""
  };

  constructor(
    private _toastr: ToastrService,
    private _wjHelper: WijmoHelper,
    private _changeDetector: ChangeDetectorRef,
    private _modalService: NgbModal,
    private _sTable: TableService,

    private _sModelo: ModeloService,
    private _sAuth: AuthenticationService,
    private _sMatricula: MatriculaService,
    private _sResponsablePago: ResponsablePagoService,
    private _sEstudiante: EstudianteService,
    private _sOtrasPersonas: OtrasPersonasService,
    private _sCobro: CobrosService,
    private _sRecibo: RecibosService,
    public _sExtracto: ExtractoService,
    private _sTarea: TareaServidorService
  ) {
    super(_toastr, _wjHelper, _sModelo, _changeDetector, _sTable, _sAuth, _sMatricula,
      _sResponsablePago, _sEstudiante, _sOtrasPersonas, _sCobro, _sRecibo, _sExtracto,_sTarea);
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {

    //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
    this.setModelosTrabajo2({
      identificador: "Odata",
      odata: [
        'Parametro;$filter=IdColegio eq ' + this.anio.Colegio.Id,
        'Colegio'
      ]
    });

    //Carga la consulta con identificador 'Odata'
    this.cargarDatos("Odata", () => { });
  }

  /**
   * Trae los datos de las consultas realizadas
   * @param data El array u objeto que se esta consultado
   * @param identificador El identificador que ha sigo asignado a cada consulta
   */
  onDatosObtenidos(data: any, identificador: string) {

    //Carga los datos de la consulta de Odata
    if (identificador == "Odata") {
     
      this.parametros = data['Parametro'];
      this.colegios = data['Colegio'];

      this.cvParametros = new CollectionView(data['Parametro']);
      this.cvParametros.newItemCreator = () => {
        return { IdColegio: this.anio.IdColegio };
      }

      this.cvParametros.trackChanges = true;

      //Crea una copia del flexgrid para saber si hubo cambios
      this.registrarModeloCollectionViews([
        new ModeloCollectionView('Parametro', 'Id', this.cvParametros, this.flexParametro)
      ]);
    }
  }

  /**
   * Actualiza las columnas que va tener el grid
   * @param column dato de la columna
   * @param modelo nombre del primer parametro en el new ModeloCollectionView
   */
  onAjusteColumna(column: WjFlexGridColumn, modelo: string) {
    if (modelo == "Parametro") {
      switch (column.binding) {
        case "IdColegio":
          //crea el select de movimientos
          column.dataMap = new DataMap(this.colegios, "Id", "Nombre");
          break;
        default:
      }
    }
  }

  /**
   * Permite al tap detectar si hay cambios o no, para el momento de ser cerrado
   */
  consultarSiHayCambios() {
    this.hayCambios = this.getTieneCambios();
    return this.hayCambios;
  }
}
