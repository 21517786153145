import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { RecaudoFormHelper } from 'src/app/helpers/RecaudoFormHelper';
import { recaudoUI } from 'src/app/helpers/RecaudoUI';
import { Año } from 'src/app/helpers/Models';
import { CollectionView } from 'wijmo/wijmo';
import { WjFlexGrid, WjFlexGridColumn } from 'wijmo/wijmo.angular2.grid';
import { ToastrService } from 'ngx-toastr';
import { WijmoHelper, ModeloCollectionView } from 'src/app/helpers/WijmoHelper';
import { ModeloService, MatriculaService, ResponsablePagoService, EstudianteService, OtrasPersonasService, CobrosService, RecibosService, ExtractoService, TareaServidorService } from 'src/app/shared/services/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AutoSizeMode } from 'wijmo/wijmo.grid';
import { ColegioComponent } from '../colegio/colegio.component';
import { TableService } from 'src/app/shared/services/table';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
  selector: 'app-colegios',
  templateUrl: './colegios.component.html',
  styleUrls: ['./colegios.component.css']
})
export class ColegiosComponent extends RecaudoFormHelper implements OnInit {


  anio: Año = recaudoUI.getAñoTrabajo();
  hayCambios = false;

  @Input('colegios') public colegios: any;

  //CollectionViews
  cvColegios: CollectionView;

  //Referencia a los controles de wijmo
  @ViewChild('flexColegios') flexColegios: WjFlexGrid;

  //Filtro del grid
  filtro = {
    buscar: ""
  };

  constructor(
    private _toastr: ToastrService,
    private _wjHelper: WijmoHelper,
    private _changeDetector: ChangeDetectorRef,
    private _modalService: NgbModal,

    private _sTable: TableService,
    private _sModelo: ModeloService,
    private _sAuth: AuthenticationService,
    private _sMatricula: MatriculaService,
    private _sResponsablePago: ResponsablePagoService,
    private _sEstudiante: EstudianteService,
    private _sOtrasPersonas: OtrasPersonasService,
    private _sCobro: CobrosService,
    private _sRecibo: RecibosService,
    private _sExtracto: ExtractoService,
    private _sTarea: TareaServidorService
  ) {
    super(_toastr, _wjHelper, _sModelo, _changeDetector, _sTable, _sAuth, _sMatricula,
      _sResponsablePago, _sEstudiante, _sOtrasPersonas, _sCobro, _sRecibo, _sExtracto,_sTarea);
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {

    this.flexColegios.autoSizeMode = AutoSizeMode.Both;

    //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
    this.setModelosTrabajo2({
      identificador: "Odata",
      odata: [
        'Colegio',
      ]
    });

    //Carga la consulta con identificador 'Odata'
    this.cargarDatos("Odata", () => { });
  }

  /**
   * Trae los datos de las consultas realizadas
   * @param data El array u objeto que se esta consultado
   * @param identificador El identificador que ha sigo asignado a cada consulta
   */
  onDatosObtenidos(data: any, identificador: string) {

    //Carga los datos de la consulta de Odata
    if (identificador == "Odata") {

      this.colegios = data['Colegio'];

      this.cvColegios = new CollectionView(this.colegios);

      this.cvColegios.trackChanges = true;

      this.registrarModeloCollectionViews([
        new ModeloCollectionView('Colegio', 'Id', this.cvColegios, this.flexColegios)
      ]);
    }
  }

  /**
   * Actualiza las columnas que va tener el grid
   * @param column dato de la columna
   * @param modelo nombre del primer parametro en el new ModeloCollectionView
   */
  onAjusteColumna(column: WjFlexGridColumn, modelo: string) {
    if (modelo == "Colegio") {
      switch (column.binding) {
        default:
      }
    }
  }

  /**
   * Edita o crea Colegios
   * @param esParaEdicion 
   */
  editar(esParaEdicion) {
    if (esParaEdicion) {
      let item = this.flexColegios.selectedItems[0];

      recaudoUI.tabsManager.agregar("Colegio - " + item["Nombre"], "fa-user", ColegioComponent, { idColegio: item.Id });
    }
  }

  /**
   * Permite al tap detectar si hay cambios o no, para el momento de ser cerrado
   */
  consultarSiHayCambios() {
    this.hayCambios = this.getTieneCambios();
    return this.hayCambios;
  }
}
