import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { LoginComponent } from './component/login/login.component';
import { HomeComponent } from './component/home/home.component';
import { AppComponent } from './app.component';
//import { LoginComponent } from './component/login/login.component';
//import { AppComponent }  from './component/main/app.component';

import { NgbdDatepickerBasicComponent } from './component_plantilla/datepicker/datepicker.component';
import { AuthGuard } from './security/auth.guard';
import { ExportarInformesComponent } from './component/exportar-informes/exportar-informes.component';

export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/principal', pathMatch: 'full' },

      {
        path: '',
        loadChildren: './component/recaudo.module#RecaudoModule'
      },

      {
        path: 'starter',
        loadChildren: './starter/starter.module#StarterModule'
      },
      {
        path: 'component',
        loadChildren: './component_plantilla/component.module#ComponentsModule'
      },
      {
        path: 'RecaudoInf/Informes',
        component: ExportarInformesComponent,
        /*data: {
          title: 'Informes',
          urls: [
          ]
        },*/
        //canActivate: [AuthGuard]
      },
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '**',
    redirectTo: '/login'
  },
];
