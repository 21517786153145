import { Directive, ViewContainerRef, Input, Type, ComponentFactoryResolver, OnInit, Output, EventEmitter } from '@angular/core';
import * as _ from 'underscore'


@Directive({
  selector: '[appComponentLoader]'
})
export class ComponentLoaderDirective implements OnInit {
  @Input('appComponentLoader') component: any;
  @Input('params') params: object;
  @Output() voted = new EventEmitter<any>();
  /**
   * 
   * @param viewContainerRef //Almacena una referencia al contenedor del componente app-component-loader
   * @param componentFactoryResolver  //Se usa para generar componentes dinámicamente
   */
  constructor(public viewContainerRef: ViewContainerRef, private componentFactoryResolver: ComponentFactoryResolver) {
  }

  ngOnInit(): void {

    //console.log("this", this.component);

    if (!this.component) {
      console.error("No se ha encontrado el componente ", this.component);

    } else {

      let comp = this.viewContainerRef.createComponent(this.componentFactoryResolver.resolveComponentFactory(this.component));

      //verifica que el componente implemente la interfaz AfterViewInit para realizar acciones cuando el componente esté rende-rizado
      if (comp.instance["ngAfterViewInit"]) {
        comp.instance["ngAfterViewInit"] = (function () {
          var cache = comp.instance["ngAfterViewInit"];

          return function () {
            window.dispatchEvent(new Event('resize'));
            console.info("appComponentLoader: resize")

            return cache.apply(this, arguments);
          }
        })();
      } else
        throw "appComponentLoader: las clases de los componentes cargados dinámica-mente deben implementar la interfaz AfterViewInit.";

      if (this.params)
        //comp.instance representa el objeto resultante instacia de la clase asociada al componente, el cual se puede modificar para cambiar los valores de los miembros de la misma
        _.each(Object.keys(this.params), (key) => {
          comp.instance[key] = this.params[key];
        });

        this.voted.emit({ component: comp });
    }
  }
}
