import { Injectable, NgZone } from '@angular/core';
import { ManagerService } from '../services/services';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { UsuarioAuth } from 'src/app/_models/usuarioAuth';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<UsuarioAuth>;
  public currentUser: Observable<UsuarioAuth>;

  constructor(private sUser: ManagerService, private routes: Router, 
    public afAuth: AngularFireAuth,
    public ngZone: NgZone,
    private sManager: ManagerService) {
    var usr = JSON.parse(sessionStorage.getItem('currentUser'));
    if (!usr)
      usr = JSON.parse(localStorage.getItem('currentUser'));


    console.log("Reconstruyendo usuario ", usr);
    this.currentUserSubject = new BehaviorSubject<UsuarioAuth>(usr);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  /**
   * Cierra la sesión iniciada
   */
  logout() {

    // remove user from local storage to log user out
    sessionStorage.removeItem('currentUser');
    localStorage.removeItem('currentUser');

    sessionStorage.removeItem("token");
    localStorage.removeItem("token");

    sessionStorage.removeItem("user");
    localStorage.removeItem("user");

    this.currentUserSubject.next(null);

    this.afAuth.auth.signOut().then(() => {
      //localStorage.removeItem('user');
      //this.router.navigate(['sign-in']);
    });

    //Recarga la página para no para cerrar las taps y lo direccione a la página de login
    location.reload();

    //this.routes.navigate(['/login']);
  }

  login(usuario) {
    
    return this.sManager.login(usuario).pipe(map( (data: any) => {

      //Si fue un exito logea el usuario y guarda el token
      if(!data["Error"]) {

        sessionStorage.setItem("token", data['Token']);
        sessionStorage.setItem("user", data['Usuario']);
        sessionStorage.setItem("currentUser", JSON.stringify(data['Usuario']));
      }

      return data;
    }));
  }

  /**
   * Obtiene los datos del usuario que está logeado
   */
  getUser(): UsuarioAuth {

    let usuario: UsuarioAuth = JSON.parse(sessionStorage.getItem('currentUser'));
    if (!usuario)
      usuario = JSON.parse(localStorage.getItem('currentUser'));

    return usuario;
  }

  /**
   * Obtiene el token del usuario logeado
   */
  getToken(): string {
    let token = sessionStorage.getItem('token');
    if (!token)
      token = localStorage.getItem('token');

    return token;
  }

  /**
   * Retorna el rol del usuario que está logeado
   */
  getRol(): string {
    return this.getUser()["Rol"];
  }

  //Authenticación con firebase
  firebaseAuth(proveedor, recordarme, ok, error) {

    var p = new auth.GoogleAuthProvider();
    if(proveedor == "facebook")
      p = new auth.FacebookAuthProvider();

    return this.authLogin(p, recordarme, ok, error);
  }

  // Auth logic to run auth providers
  authLogin(provider, recordarme, ok, error) {
    return this.afAuth.auth.signInWithPopup(provider)
    .then((result) => {

      //Obtiene el identificador de usuario en firebase para que el servidor puede validar el correo
      this.afAuth.auth.currentUser.getIdToken(true).then(idToken =>
      {
        var cred = { providerId: result.credential.providerId, signInMethod: result.credential.signInMethod, idToken: result.credential["idToken"], firebaseIdToken: "" };
      
        cred.firebaseIdToken = idToken;

        //Realiza el login del usuario
        this.login({Credential: cred}).subscribe(data => {
          ok(data);
        }, err => error(err));
        
      }).catch(function(err) {
          error(err);
      });
       
    }).catch((err) => {
      error(err);
    })
  }

}
