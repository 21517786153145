import { Injectable } from '@angular/core';
import * as _ from 'node_modules/underscore';
import { recaudoUI } from 'src/app/helpers/RecaudoUI';

@Injectable()
export class GestorCodigoBarras {

    private a415 = 13;
    private a8020 = 8;
    private a3900 = 7;

    constructor(
    ) { }

    /**
     * por medio del parametro genera el código de barras para torcoroma o GS1
     * @param parametro 
     * @param longitud 
     * @param codigoBarras 
     */
    generarCodigoBarras(parametro, codigoBarras, longitud?) {

        let infoCodigoBarras = {};

        if (parametro == "Torcoroma")
            infoCodigoBarras = this.generarCodigoBarrasTorcoroma(codigoBarras);

        if (parametro == "GS1")
            infoCodigoBarras = this.generarCodigoBarrasGS1(longitud, codigoBarras);

        return infoCodigoBarras;
    }

    /**
     * separa el codigo de barras para sacar la fecha,  valor, codigo y referencia
     */
    generarCodigoBarrasGS1(longitud, codigoBarras) {

        let l = longitud.split(',');
        if (l.length == 0)
            l = longitud.split(';');

        if (l.length < 3)
            throw new Error("Error en la configuración de la longitud del código de barras");

        this.a415 = parseInt(l[0]);
        this.a8020 = parseInt(l[1]);
        this.a3900 = parseInt(l[2]);

        let cuenta = codigoBarras.slice(0, (this.a415 + 3));
        if (cuenta.indexOf("415") == -1)
            throw new Error("Código mal formateado. 415 no encontrado. " + codigoBarras);

        let referencia = codigoBarras.slice((this.a415 + 3), (this.a8020 + this.a415 + 7));
        if (referencia.indexOf("8020") == -1)
            throw new Error("Código mal formateado. 8020 no encontrado. " + codigoBarras);

        let valor = codigoBarras.slice((this.a8020 + this.a415 + 7), (this.a8020 + this.a415 + this.a3900 + 11));
        if (valor.indexOf("3900") == -1)
            throw new Error("Código mal formateado. 3900 no encontrado. " + codigoBarras);

        let fecha = codigoBarras.slice((this.a8020 + this.a415 + this.a3900 + 11), codigoBarras.length)

        return {
            referencia: referencia.replace("8020", ""),
            cuenta: cuenta.replace("415", ""),
            codigo: Math.round(referencia.replace("8020", "") / 100),
            valor: valor.replace("3900", ""),
            fecha: recaudoUI.formatDate(fecha.slice(2, 6) + "/" + fecha.slice(6, 8) + "/" + fecha.slice(8, fecha.length) + " 00:00"),
            anioSeleccionado: fecha.slice(2, 6)
        };
    }

    /**
     * Separa el código de barras para torcoroma
     */
    generarCodigoBarrasTorcoroma(codigoBarras) {

        let cod = codigoBarras.replace(/\s/g, "'").split("'");

        if (!(cod.length == 3 || cod == 4 || cod == 5))
            throw new Error("Código de barras no válido");

        let codigo: number;
        let mes: number;
        let valor: number;
        let args: string;
        let grado: number = null;

        try {
            //Obtiene los valores del código de barras
            codigo = parseInt(cod[0]);
            mes = parseInt(cod[1]);
            valor = parseInt(cod[2]);

            //Obtiene el grado
            if (cod.length >= 4)
                grado = parseInt(cod[3].trim());

        }
        catch (error) {
            throw new Error("Código de barras no válido. " + codigoBarras);
        }

        //Calcula la referencia del recibo
        if (mes == 13)
            mes = 1;

        return {
            referencia: codigo * 100 + mes,
            valor: valor,
            cuenta: "Torco",
            fecha: null,
            codigo: codigo,
            mes: mes
        };
    }

}
