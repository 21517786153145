import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NgbModalRef, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ModeloService } from '../../services/services';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-modal-cambiar-password',
  templateUrl: './modal-cambiar-password.component.html',
  styleUrls: ['./modal-cambiar-password.component.css']
})
export class ModalCambiarPasswordComponent implements OnInit {

  //El objeto para cambiar la contraseña
  @ViewChild("cambioPasswordModal") private cambioPasswordModal;
  private showPassword1: boolean;
  private showPassword2: boolean;
  private showPassword3: boolean;
  private cambioPassword: object;

  usuario : object;
  rol: string;

  modalRef: NgbModalRef;

  constructor(
    public sAuth: AuthenticationService,
    private _modalService: NgbModal,
    private toastr: ToastrService,
    private config: NgbModalConfig,
    private router: Router,
    private sModelo: ModeloService,
    public changeDetector: ChangeDetectorRef,
  ) {
    this.rol = sAuth.getRol();
  }

  ngOnInit() {
  }

  /**
   * Abre la modal para cambiar el Password de un usuario o de él mismo
   * @param idUsuario 
   */
  public abrirCambiarPassword(usuario): NgbModalRef {

    //Inicializa las bariables que se van a utilizar al cambiar el password
    this.inicializarVariables();

    this.usuario = usuario;
    this.cambioPassword["IdUsuario"] = usuario["IdUsuario"];

    //Abre la modal con su respectiva configuración
    this.config.backdrop = "static";
    this.config.keyboard = false;

    this.modalRef = this._modalService.open(this.cambioPasswordModal, { size: "lg" });
    return this.modalRef;
  }

  /**
   * Cada vez que se abre la modal se inicializan las variables
   */
  private inicializarVariables() {
    this.showPassword1 = false;
    this.showPassword2 = false;
    this.showPassword3 = false;
    this.cambioPassword = {
      IdUsuario: null,
      Password: "",
      PasswordNuevo: "",
      ConfirmacionPassword: ""
    }
  }

  /**
   * Retorna el estudiante seleccionado
   */
  private aceptar() {

    //Valida que se haya escrito las contraseñas
    if (this.cambioPassword["PasswordNuevo"] != this.cambioPassword["ConfirmacionPassword"] || this.cambioPassword["Password"].length < 1)
      return null

    //Guarda el password 
    this.sModelo.saveAuth("CambioContrasenia", this.cambioPassword).subscribe((data) => {

      this.toastr.success("Se ha cambiado el password satisfactoriamente");
      this.modalRef.close();

    }, (error) => {
      console.log(error);
      this.toastr.error(error.error.ExceptionMessage);
    });
  }

  /**
   * Cierra la modal
   */
  private cerrarModal() {
    this.modalRef.dismiss("Cerrar");
  }
}
