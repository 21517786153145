import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { RecaudoFormHelper } from 'src/app/helpers/RecaudoFormHelper';
import { recaudoUI } from 'src/app/helpers/RecaudoUI';
import { Año } from 'src/app/helpers/Models';
import { CollectionView } from 'wijmo/wijmo';
import { WjFlexGrid, WjFlexGridColumn } from 'wijmo/wijmo.angular2.grid';
import { ToastrService } from 'ngx-toastr';
import { WijmoHelper, ModeloCollectionView } from 'src/app/helpers/WijmoHelper';
import { ModeloService, EstudianteService, MatriculaService, ResponsablePagoService, OtrasPersonasService, CobrosService, RecibosService, ExtractoService, TareaServidorService } from 'src/app/shared/services/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AutoSizeMode } from 'wijmo/wijmo.grid';
import { EstudianteComponent } from '../estudiante/estudiante.component';
import { TableService } from 'src/app/shared/services/table';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
  selector: 'app-buscar-estudiantes',
  templateUrl: './buscar-estudiantes.component.html',
  styleUrls: ['./buscar-estudiantes.component.css']
})
export class BuscarEstudiantesComponent extends RecaudoFormHelper implements OnInit {

  @Input('anio') public anio: Año;
  @Input('listaEstudiantes') public listaEstudiantes: any;

  estudianteDto = {
    Apellidos: null,
    Nombres: null,
    Codigo: null,
    Identificacion: null,
    IdColegio: null
  }

  /*//Contiene los tipos del wijmo
  wijmoDataType = {
    Object: 0,
    String: 1,
    Number: 2,
    Boolean: 3,
    Date: 4,
    Array: 5
  };*/

  hayCambios = false;

  //CollectionViews
  cvListaEstudiantes: CollectionView = new CollectionView([]);

  //Referencia a los controles de wijmo
  @ViewChild('flexListaEstudiantes') flexListaEstudiantes: WjFlexGrid;

  constructor(
    private _toastr: ToastrService,
    private _wjHelper: WijmoHelper,
    private _changeDetector: ChangeDetectorRef,
    private _modalService: NgbModal,
    private routes: Router,
    //private sEstudiante: EstudianteService,
    private _sModelo: ModeloService,
    private _sTable: TableService,
    private _sAuth: AuthenticationService,
    private _sMatricula: MatriculaService,
    private _sResponsablePago: ResponsablePagoService,
    private _sEstudiante: EstudianteService,
    private _sOtrasPersonas: OtrasPersonasService,
    private _sCobro: CobrosService,
    private _sRecibo: RecibosService,
    private _sExtracto: ExtractoService,
    private _sTarea: TareaServidorService
  ) {
    super(_toastr, _wjHelper, _sModelo, _changeDetector, _sTable, _sAuth, _sMatricula,
      _sResponsablePago, _sEstudiante, _sOtrasPersonas, _sCobro, _sRecibo, _sExtracto,_sTarea);
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {

    setTimeout(() => {
      this.cvListaEstudiantes.trackChanges = true;

      //Genera el objeto con el que se crea el estudiante
      this.cvListaEstudiantes.newItemCreator = () => {
        return { IdColegio: this.anio.IdColegio }
      }

      //Permite crear estudiantes antes de tener que buscarlos
      this.registrarModeloCollectionViews([
        new ModeloCollectionView('Estudiante', 'Id', this.cvListaEstudiantes, this.flexListaEstudiantes)
      ]);
    }, 500);
  }

  /**
   * Realiza la busqueda de acuerdo al filtro
   */
  buscar() {

    this.estudianteDto.IdColegio = this.anio.IdColegio;

    //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
    this.setModelosTrabajo2({
      identificador: "buscarEstudiantes",
      servicio: "sEstudiante",
      metodo: "buscarEstudiantes",
      consulta: this.estudianteDto
    });

    //Carga solo la consulta con identificador 'Matricula'
    this.cargarDatos("buscarEstudiantes", () => { });
  }

  /**
   * Trae los datos de las consultas realizadas
   * @param data El array u objeto que se esta consultado
   * @param identificador El identificador que ha sigo asignado a cada consulta
   */
  onDatosObtenidos(data: any, identificador: string) {

    //Carga los datos de la consulta de Odata
    if (identificador == "buscarEstudiantes") {

      if (data) {
        this.cvListaEstudiantes = new CollectionView(data);

        this.cvListaEstudiantes.trackChanges = true;

        //Genera el objeto con el que se crea el estudiante
        this.cvListaEstudiantes.newItemCreator = () => {
          return { IdColegio: this.anio.IdColegio }
        }

        //Cuando se finaliza la edición se convierte el texto en mayuscula cuando se edita el nombre o apellido
        this.flexListaEstudiantes.cellEditEnded.addHandler(function (s, e) {

          var val = s.getCellData(e["row"], e["col"], false);

          if ((s.columns[e["col"]].binding == "Apellidos" || s.columns[e["col"]].binding == "Nombres") && s.columns[e["col"]].dataType == 1)
            s.setCellData(e["row"], e["col"], val.toUpperCase());
        });

        this.registrarModeloCollectionViews([
          new ModeloCollectionView('Estudiante', 'Id', this.cvListaEstudiantes, this.flexListaEstudiantes)
        ]);
      }
    }
  }

  /**
   * Actualiza las columnas que va tener el grid
   * @param column dato de la columna
   * @param modelo nombre del primer parametro en el new ModeloCollectionView
   */
  onAjusteColumna(column: WjFlexGridColumn, modelo: string) {
    if (modelo == "Estudiantes") {
      switch (column.binding) {
        default:
      }
    }
  }

  /**
   * Crea o edita un registro
   */
  editar(esParaEdicion: boolean) {

    //Edita la persona seleccionada
    if (esParaEdicion) {
      let item = this.flexListaEstudiantes.selectedItems[0];
      
      recaudoUI.tabsManager.agregar("Estudiante - " + item["Codigo"], "fa fa-users", EstudianteComponent, { estudiante: item, anio: this.anio });
    }
  }


  /**
   * Permite al tap detectar si hay cambios o no, para el momento de ser cerrado
   */
  consultarSiHayCambios() {
    this.hayCambios = this.getTieneCambios();
    return this.hayCambios;
  }

  /**
   * Permite crear una nueva matrícula
   */
  crearMatricula() {

  }
}
