import { Component, OnInit, Input, ViewChild, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'underscore';
import { WjFlexGrid, WjFlexGridColumn } from 'wijmo/wijmo.angular2.grid';
import { AutoSizeMode, DataMap } from 'wijmo/wijmo.grid';
import { CollectionView } from 'wijmo/wijmo';

import { ModeloService, MatriculaService, ResponsablePagoService, EstudianteService, OtrasPersonasService, CobrosService, RecibosService, ExtractoService, TareaServidorService } from '../../shared/services/services';
import { ColaFunciones } from '../../helpers/ColaFunciones';
import { WijmoHelper, ModeloCollectionView } from '../../helpers/WijmoHelper';
import { recaudoUI } from '../../helpers/RecaudoUI';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { RecaudoFormHelper } from '../../helpers/RecaudoFormHelper';
import { ObjetoModelo, Año } from '../../helpers/Models';
import { TableService } from 'src/app/shared/services/table';
import { MatriculaComponent } from '../matricula/matricula.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
  selector: 'app-grupo',
  templateUrl: './grupo.component.html',
  styleUrls: ['./grupo.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GrupoComponent extends RecaudoFormHelper implements OnInit, AfterViewInit {

  @Input('grupo') grupo: object;
  @Input('anio') anio: Año;

  hayCambios = false;

  grupoOjetoModelo: ObjetoModelo;
  cvMatriculas: CollectionView;

  //Referencia a los controles de wijmo
  @ViewChild('flexMatriculas') flexMatriculas: WjFlexGrid;

  grados: object[];

  constructor(
    private _toastr: ToastrService,
    private _wjHelper: WijmoHelper,
    private _changeDetector: ChangeDetectorRef,
    private _sTable: TableService,

    private _sModelo: ModeloService,
    private _sAuth: AuthenticationService,
    private _sMatricula: MatriculaService,
    private _sResponsablePago: ResponsablePagoService,
    private _sEstudiante: EstudianteService,
    private _sOtrasPersonas: OtrasPersonasService,
    private _sCobro: CobrosService,
    private _sRecibo: RecibosService,
    public _sExtracto: ExtractoService,
    private _sTarea: TareaServidorService
  ) {
    super(_toastr, _wjHelper, _sModelo, _changeDetector, _sTable, _sAuth, _sMatricula,
      _sResponsablePago, _sEstudiante, _sOtrasPersonas, _sCobro, _sRecibo, _sExtracto,_sTarea);
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {

    //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
    this.setModelosTrabajo2({
      identificador: "Odata",
      odata: [
        'Grupo;$filter=Id eq ' + this.grupo["Id"] + '&$expand=Matriculas.Estudiante', 'Grado;$expand=DefinicionGrado'
      ]
    });

    this.setAñoTrabajo(this.anio);

    //Carga la consulta con identificador 'Odata'
    this.cargarDatos("Odata", () => { });
  }


  /**
   * Trae los datos de las consultas realizadas
   * @param data El array u objeto que se esta consultado
   * @param identificador El identificador que ha sigo asignado a cada consulta
   */
  onDatosObtenidos(data: any, identificador: string) {

    //Carga los datos de la consulta de Odata
    if (identificador == "Odata") {

      this.grupoOjetoModelo = new ObjetoModelo('Grupo');
      this.grupoOjetoModelo.objeto = _.first(data["Grupo"]);
      this.grupoOjetoModelo.actualizarCopia();


      this.cvMatriculas = new CollectionView(this.grupoOjetoModelo.objeto["Matriculas"]);
      this.cvMatriculas.trackChanges = true;
      this.cvMatriculas.newItemCreator = () => {
        return { IdGrupo: this.grupo["Id"] }
      }

      this.grados = data["Grado"];

      //Genera la copia del formulario para verificar si hubieron cambios 
      this.registrarObjetosModelo([this.grupoOjetoModelo]);

      //Genera la copia de cada flexGrid para saber si hubieron cambios
      this.registrarModeloCollectionViews([new ModeloCollectionView('Matricula', 'Id', this.cvMatriculas, this.flexMatriculas)]);
    }
  }
  
  /**
   * Abre el tap de la matricula
   * @param item 
   */
  verMatricula(item: any) {
    recaudoUI.tabsManager.agregar("Matrícula - " + item["Estudiante"]["Codigo"], "fa-user", MatriculaComponent, { idMatricula: item["Id"], anio: this.anio });
  }

  /**
   * Permite al tap detectar si hay cambios o no, para el momento de ser cerrado
   */
  consultarSiHayCambios() {
    this.hayCambios = this.getTieneCambios();
    return this.hayCambios;
  }
}
