import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NgbModalRef, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ModalInputs } from 'src/app/_models/modals.model';
import * as _ from 'underscore';

@Component({
  selector: 'app-modal-formulario',
  templateUrl: './modal-formulario.component.html',
  styleUrls: ['./modal-formulario.component.css']
})
export class ModalFormularioComponent implements OnInit {

  private valor: any;
  private configuracion: ModalInputs;
  private oprimioBotonGuardar = false;
  //Contiene los valores de los totales
  private totales: Array<any>;
  //Contiene el valor total a pagar
  private valorTotal: any;
  //Contiene un mensaje de error en un segundo campo
  private mensajeError2: string;

  //porcentaje de descuento
  pValorDescuento = 0;
    //Indica si tiene algún descuento en algún valor
  valoresCompletos = true;

  @ViewChild('ingresarValorModal') private ingresarValorModal;

  modalRef: NgbModalRef;

  constructor(
    private _modalService: NgbModal,
    private toastr: ToastrService,
    public changeDetector: ChangeDetectorRef,
    private config: NgbModalConfig,
  ) { }

  ngOnInit() {
  }

  /**
   * Busca a un estudiante por medio del código
   * @param anio
   */
  public abrirIngresarValor(configuracion: ModalInputs): NgbModalRef {
    //inicializa el valor
    this.pValorDescuento = 0;

    this.inicializarVariables();

    if (configuracion.valor){
        this.valoresCompletos = true;

        this.valor = configuracion.valor;
        //obtiene los valores de los toltales
        if(configuracion.valor["Totales"]){
            var total = [];
            //Obtiene los distintos totales
            var keys = _.keys(configuracion.valor["Totales"]);
            var valores = configuracion.valor["Totales"];

            //Obtiene en un array los totales con su respectivo valor
            _.each(keys, function(k){
                //Ingresa el nombre del campo
                var nombre = null;
                if(k == "TotalAPagar" )
                    nombre = "Total a pagar";

                if(k == "TotalConDescuento")
                    nombre = "Total con descuento";

                if(k == "TotalConIntereses")
                    nombre = "Total con intereses";

                if(k == "TotalConIntereses2")
                    nombre = "Total con intereses 2";

                //Guarda el total que contenga un valor
                if(valores[k]){
                    var valorTotal = _.clone(valores[k]);
                    total.push({Seleccionado: true, Tipo: k, Nombre: nombre, ValorCompleto: valores[k], Descuento: 0, ValorDescuento: valorTotal});
                }

            });
            //Indica el valor completo a pagar
            var totalAPagar = _.find(total, function(t){ return t.Tipo == "TotalAPagar" });
            this.valorTotal = totalAPagar.ValorCompleto;
            //Obtiene todos los datos
            this.totales = total;
            this.valor["Totales"] = total;
            this.valor["Observacion"] = null;
            this.mensajeError2 = null;
            this.valoresCompletos = false;
        }

    }


    this.configuracion = configuracion;

    //Inicializa el mensaje de error en la modal
    _.each(this.configuracion.input, (i) => { i.messageError = ""; });

    //Abre la modal con su respectiva configuración
    this.config.backdrop = "static";
    this.config.keyboard = false;
    this.modalRef = this._modalService.open(this.ingresarValorModal, { size: "lg" });
    return this.modalRef;
  }

  //reescribe valor[property]
  public cambioPorcentaje(property, valorMax){
    //console.log('cambioPorcentaje ', property, this.pValorDescuento, this.valor, valorMax);
    if(this.pValorDescuento > 0 && this.pValorDescuento <= 1){
        //Obtiene el valor
        if(this.totales.length > 0){
            this.valor[property] = Math.round((this.pValorDescuento * this.valorTotal));
            this.actualizarDescuento(this.valor[property]);
        }
        else
            this.valor[property] = Math.round((this.pValorDescuento * Math.trunc(valorMax)));

        this.valoresCompletos = true;
    }
    else{
        this.valor[property] = null;
        this.valoresCompletos = false;
        this.actualizarDescuento(0);
    }

  }

  //re escribe pValorDescuento
  public cambioValor(property, valorMax){
    //console.log('cambioValor ', property, this.pValorDescuento, this.valor, valorMax);
    if(this.valor[property] != '' && this.valor[property] !== null){
        //Obtiene el porcentaje
        var a = null;
        if(this.totales.length > 0){
            a = parseInt(this.valor[property]) / this.valorTotal;
            this.actualizarDescuento(this.valor[property]);
        }
        else
            a = parseInt(this.valor[property]) / valorMax;

        this.pValorDescuento = parseFloat(a.toFixed(4));//6 //2
        this.valoresCompletos = true;
    }else{
        this.pValorDescuento = 0;
        this.actualizarDescuento(0);
        this.valoresCompletos = false;
    }

  }

/**
 * Actualiza el descuento a los valores totales
 * @param valorDescuento valor del descuento ingresado
 */
  public actualizarDescuento (valorDescuento){
    var valorAPagar = true;
    var totales = this.totales;

    //actualiza los valores con el descuento aplicado
    _.each(totales, function(t){
        var descuento = 0;
        //Pone en cero los valores en descuento
        if(t.tipo == "TotalAPagar" ){
            descuento = t.ValorCompleto - valorDescuento;
            if(descuento <= 0)
                valorAPagar = false;
        }

        //Ingresa los valores del descuento en los campos seleccionados
        if(t.Seleccionado && valorDescuento){
            descuento = t.ValorCompleto - valorDescuento;
            t.Descuento = valorDescuento;
        }
        else{
            descuento =  t.ValorCompleto;
            t.Descuento = 0;
        }

        //Ingresa el valor del descuento
        if(descuento >= 0 && valorAPagar)
            t.ValorDescuento = descuento;
        else
            t.ValorDescuento = 0
    });
    this.totales = totales;
}

  /**
   * Obtiene los descuentos de cada valor
   */
  public getDescuento(valor, descuento){
    if(valor.Seleccionado && (descuento && descuento != 0) && valor.ValorDescuento > 0){
        valor.Descuento = descuento;
        valor.ValorDescuento = valor.ValorCompleto - descuento;
    }else{
        valor.Descuento = 0;
        valor.ValorDescuento = valor.ValorCompleto;
    }
  }

  /**
   * Cada vez que se abre la modal se inicializan las variables
   */
  private inicializarVariables() {
    this.valor = {};
    this.oprimioBotonGuardar = false;
    this.totales = [];
    this.valorTotal = null;
  }

  /**
   * Valida cada input y si encuentra un error genera su respectivo message
   * @param f Control
   * @param i input a validar
   */
  private validarFormulario(f, i, valor, label) {

    console.log(f, i, valor, label);
    
    if ((f && f.touched) || this.oprimioBotonGuardar) {
      i.messageError = "";

      if (f && !f.valid)
        i.messageError = i.messageErrorRequired ? i.messageErrorRequired : `El campo es requerido`;

      else if (i.required && (!valor || valor == 0))
        i.messageError = i.messageErrorRequired ? i.messageErrorRequired : `El campo es requerido`;

      else if ((i.min || i.min === 0) && i.min > valor)
        i.messageError = i.messageErrorMin ? i.messageErrorMin : `No debe ser menor que ${i.min}`;

      else if ((i.max || i.max === 0) && i.max < valor)
        i.messageError = i.messageErrorMax ? i.messageErrorMax : `No debe ser mayor que ${i.max}`;

      else if (i.maxLength && i.maxLength < valor.maxLength)
        i.messageError = i.messageErrorMax ? i.messageErrorMax : `La máxima cantidad de caracteres es de ${i.maxLength}`;

      else if (i.minLength && i.minLength > valor.maxLength)
        i.messageError = i.messageErrorMax ? i.messageErrorMax : `La mínima cantidad de caracteres es de ${i.minLength}`;
    }

    return i.messageError;
  }

  /**
   * Valida el segundo input y si encuentra un error genera su respectivo message
   */
  private validarCampo2(propety, valor){
    if(!valor || valor == '')
        this.mensajeError2 = propety + " es requerido";
    else
        this.mensajeError2 = null;

  }

  /**
   * Le asigna el formato al texto que se está ingresando ejemplo  todo el texto en mayuscula o  en minuscula
   */
  private formatearTexto(i, valor) {

    if (!i.upperCase && !i.lowerCase)
      return;

    if (i.upperCase)
      valor[i.property] = valor[i.property].toUpperCase();

    else if (i.lowerCase)
      valor[i.property] = valor[i.property].toLowerCase();
  }

  /**
   * Retorna el formulario ya validado
   */
  private aceptar() {
      //Obtiene los valores
      var valores = _.clone(this.valor);

      //Valida que los valores tengan un valor
      _.each(this.configuracion.input, function(input){
        var valor = _.has(valores, input.property) ? valores[input.property] : null;

        if(input.required && (!valor))
            input.messageError = input.messageErrorRequired ? input.messageErrorRequired : `El campo es requerido`;
    });

    let propiedadConError = _.find(this.configuracion.input, (i) => { return (i.required && i.messageError !== "") || (!i.required && i.messageError !== ""); });

    if (propiedadConError)
      return;

    this.oprimioBotonGuardar = true;

    //Obtiene los campos seleccionados
    if(this.totales.length > 0){
        if((!this.valor["Observacion"] || this.valor["Observacion"] == "")){
            this.mensajeError2 = "Observación es requerida";
            return;
        }

        this.valor["Totales"] = _.filter(this.totales, function(t){return t.Seleccionado && t.Descuento > 0});
        if(this.valor["Totales"].length <= 0)
            return this.toastr.error("No se ha seleccionado ningún campo");
    }

    this.modalRef.close(this.valor);
  }

  /**
   * Cierra la modal
   */
  private cerrarModal() {
    this.modalRef.dismiss("Cerrar");
  }
}
