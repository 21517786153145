import { Directive, ElementRef, OnInit, AfterViewChecked } from '@angular/core';
import * as $ from 'jquery';
import * as _ from 'underscore';
import { recaudoUI } from 'src/app/helpers/RecaudoUI';

/**
 * agrega un evento a todas las tabs decendiantes, para refrescar la ventana al hacer click sobre ellas.
 */
@Directive({
  selector: '[appRefreshWindowOnClick]'
})
export class RefreshWindowOnClickDirective implements AfterViewChecked {
  private cuenta: number = 0;

  ngAfterViewChecked(): void {
    this.tabsPush($(this.e.nativeElement).find("a.nav-link"))
  }

  private tabsPush(tabsNew: Array<HTMLElement>) {
    if (tabsNew.length != this.cuenta) {
      this.cuenta = tabsNew.length;

      if (!recaudoUI.getProyectoEstaEnProduccion())
        console.info("tab nueva");

      _.each(tabsNew, (tab: HTMLElement) => {
        if (!tab.onclick) {
          tab.onclick = this.resize;
        }
      });
    }
  }

  resize = function () {
    if (!recaudoUI.getProyectoEstaEnProduccion())
      console.info("resize tab click");

    window.dispatchEvent(new Event('resize'));
  }
  constructor(private e: ElementRef) { }
}
