import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'underscore';
import { WjFlexGrid, WjFlexGridColumn } from 'wijmo/wijmo.angular2.grid';
import { AutoSizeMode, DataMap } from 'wijmo/wijmo.grid';
import { CollectionView } from 'wijmo/wijmo';

import { ModeloService, MatriculaService, RecibosService, CobrosService, OtrasPersonasService, EstudianteService, ResponsablePagoService, ExtractoService, TareaServidorService } from '../../shared/services/services'
import { WijmoHelper, ModeloCollectionView } from '../../helpers/WijmoHelper';
import { recaudoUI } from '../../helpers/RecaudoUI';
import { AnioComponent } from '../anio/anio.component'
import { Año } from '../../helpers/Models';
import { RecaudoFormHelper } from '../../helpers/RecaudoFormHelper';
import { TableService } from 'src/app/shared/services/table';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
    selector: 'app-anios',
    templateUrl: './anios.component.html',
    styleUrls: ['./anios.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AniosComponent extends RecaudoFormHelper implements OnInit, AfterViewInit {
    //CollectionViews
    cvAnios: CollectionView;
    //Referencia a los controles de wijmo
    @ViewChild('flexAnios') flexAnios: WjFlexGrid;

    anio: Año = recaudoUI.getAñoTrabajo();
    //Datos de trabajo auxiliares
    modelsDatos: Array<string> = [];
    colegio: object[];

    test: Año;

    //Filtro del grid
    filtro = {
        buscar: ""
    };
    hayCambios = false;

    //declaración de servicios
    constructor(
        private _toastr: ToastrService,
        private _wjHelper: WijmoHelper,
        private _changeDetector: ChangeDetectorRef,

        private _sModelo: ModeloService,
        private _sTable: TableService,
        private _sAuth: AuthenticationService,
        private _sMatricula: MatriculaService,
        private _sResponsablePago: ResponsablePagoService,
        private _sEstudiante: EstudianteService,
        private _sOtrasPersonas: OtrasPersonasService,
        private _sCobro: CobrosService,
        private _sRecibo: RecibosService,
        private _sExtracto: ExtractoService,
        private _sTarea: TareaServidorService
      ) {
        super(_toastr, _wjHelper, _sModelo, _changeDetector, _sTable, _sAuth, _sMatricula,
          _sResponsablePago, _sEstudiante, _sOtrasPersonas, _sCobro, _sRecibo, _sExtracto,_sTarea);
      }

    /**
     * Los componentes generados dinámicamente deben implementar esta interfaz
     */
    ngAfterViewInit() {

    }

    ngOnInit(): void {

        //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
        this.setModelosTrabajo2({
            identificador: "Odata",
            odata: [
                'Año', //;$expand=Colegio',
                `Colegio`//;$filter=Id eq ${this.anio.IdColegio}`
            ]
        });

        /*this.setModelosTrabajo2({
            servicio: "sEstudiante",
            metodo: "test",
            identificador: "test",
            consulta: {}
        });
        //Realiza la prueba de la consulta test
        this.cargarDatos("test", () => { });
*/

        //Carga la consulta con identificador 'Odata'
        this.cargarDatos("Odata", () => { });


    }

    /**
     * Trae los datos de las consultas realizadas
     * @param data El array u objeto que se esta consultado
     * @param identificador El identificador que ha sido asignado a cada consulta
     */
    onDatosObtenidos(data: any, identificador: string) {

        //Asigna los datos que se traen en el Odata
        if (identificador == "Odata") {

            this.colegio = data["Colegio"];

            this.cvAnios = new CollectionView(data["Año"]);

            //this.cvAnios.trackChanges = this.usuarioAuth.Rol == "Administrador";
            this.cvAnios.trackChanges = true;

            //Genera el objeto con el que se crea el año
            this.cvAnios.newItemCreator = () => {
                return { Numero: 0, NumeroRecibo: 0, TasaInteres: 0, IdColegio: this.anio.IdColegio };
            }

            this.registrarModeloCollectionViews(
                [new ModeloCollectionView('Año', 'Id', this.cvAnios, this.flexAnios)]
            );
        }
    }

    /**
   * Actualiza las columnas que va tener el grid
   * @param column dato de la columna
   * @param modelo nombre del primer parametro en el new ModeloCollectionView
   */
    onAjusteColumna(column: WjFlexGridColumn, modelo: string) {
        if (modelo == "Año") {
            switch (column.binding) {
                case "IdColegio":
                    //crea el select del colegio, es solo para que cuando se cree un año le asigne el colegio
                    column.dataMap = new DataMap(this.colegio, "Id", "Nombre");
                    break;
                default:
            }
        }
    }

    /**
     * abre la tab del detalle de un año.
     */
    ver() {
        let item = this.obtenerRegistroSeleccionado(this.flexAnios);

        if (!this.verificarSiHayCambios() && item)
            recaudoUI.tabsManager.agregar("Año - " + item['Etiqueta'], "fa-calendar-o", AnioComponent, { anioDto: item });
    }

    /**
     * Permite al tap detectar si hay cambios o no, para el momento de ser cerrado
     */
    consultarSiHayCambios() {
        this.hayCambios = this.getTieneCambios();
        return this.hayCambios;
    }
}
