

import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, ɵConsole } from '@angular/core';
import { CollectionView } from 'wijmo/wijmo';
import { ToastrService } from 'ngx-toastr';
import { WijmoHelper, ModeloCollectionView } from 'src/app/helpers/WijmoHelper';
import { ModeloService, ResponsablePagoService, EstudianteService, MatriculaService, OtrasPersonasService, CobrosService, RecibosService, ExtractoService, TareaServidorService } from 'src/app/shared/services/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { WjFlexGrid, WjFlexGridColumn } from 'wijmo/wijmo.angular2.grid';
import { RecaudoFormHelper } from 'src/app/helpers/RecaudoFormHelper';
import { Persona } from 'src/app/_models/persona';
import { collectExternalReferences, ThrowStmt } from '@angular/compiler';
import { AutoSizeMode, DataMap } from 'wijmo/wijmo.grid';
import { Año, ObjetoModelo } from 'src/app/helpers/Models';
import { recaudoUI } from 'src/app/helpers/RecaudoUI';
import { PersonaComponent } from '../persona/persona.component';
import * as _ from 'underscore';
import { MatriculaBusquedaDto } from 'src/app/helpers/Dtos';
import { MatriculaComponent } from '../matricula/matricula.component';
import { ReciboComponent } from '../recibo/recibo.component';
import { TableService } from 'src/app/shared/services/table';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ModalFormularioComponent } from 'src/app/shared/components/modal-formulario/modal-formulario.component';
import { ModalCrearMatriculaComponent } from 'src/app/shared/components/modal-crear-matricula/modal-crear-matricula.component';
import { ModalBuscarResponsablePagoComponent } from 'src/app/shared/components/modal-buscar-responsable-pago/modal-buscar-responsable-pago.component';
import { ModalAlertaComponent } from 'src/app/shared/components/modal-alerta/modal-alerta.component';

@Component({
  selector: 'app-responsable',
  templateUrl: './responsable.component.html',
  styleUrls: ['./responsable.component.css']
})
export class ResponsableComponent extends RecaudoFormHelper implements OnInit {

  @ViewChild(ModalBuscarResponsablePagoComponent) modalBuscarResponsablePago: ModalBuscarResponsablePagoComponent;
  @ViewChild(ModalFormularioComponent) modalFormulario: ModalFormularioComponent;
  @ViewChild(ModalCrearMatriculaComponent) modaCrearMatricula: ModalCrearMatriculaComponent;
  @ViewChild(ModalAlertaComponent) modalAlerta: ModalAlertaComponent;

  //idPersona: this.persona.Id, idAnio: item.Id
  //private anio = recaudoUI.getAñoTrabajo();
  @Input('personaDto') public personaDto: Persona;
  @Input('anioDto') public anioDto: Año;

  responsablePagoObjetoModelo = new ObjetoModelo('ResponsablePago');

  //habilita los botones del tap de recibo acorde a la selección
  deshabilitaBotonesRecibo = {
    descuento: true
  };

  //Filtro para el grid de recibos y matriculas
  filtro = {
    buscaRecibo: '',
    buscaMatricula: ''
  }
  parametros = [];

  respaldoTipoRecibo: object[] = [
    { IdTipoRecibo: 0, Tipo: "Estado de Cuenta", Clase: "any" },
    { IdTipoRecibo: 0, Tipo: "Abono", Clase: "Abono" },
    { IdTipoRecibo: 0, Tipo: "Pago Adelantado", Clase: "PagoAdelantado" },
    { IdTipoRecibo: 0, Tipo: "Cobro Prejurídico", Clase: "CobroPrejuridico" },
    { IdTipoRecibo: 0, Tipo: "Recibo Matrícula", Clase: "Matricula" },
  ];

  mediosPago = [
    { propiedad: "", etiqueta: "" },
    { propiedad: "Banco", etiqueta: "Banco" },
    { propiedad: "PSE", etiqueta: "PSE" },
    { propiedad: "Datafono", etiqueta: "Datafono" },
    { propiedad: "Colegio", etiqueta: "Colegio" },
    { propiedad: "Transferencia", etiqueta: "Transferencia" }
  ];

  generaRecibos = {
    tipoRecibo: "",
    idMes: 0
  };

  hayCambios = false;

  recibosCopia = [];
  responsablePago: object;
  recibos: any;
  matriculas: any;
  meses: any;
  grados: any = [];
  grupos: any;
  tiposRecibo: any[];
  encargadosDistricubion: object[];



  //CollectionViews
  cvRecibos: CollectionView;
  cvMatriculas: CollectionView;

  //Referencia a los controles de wijmo
  @ViewChild('flexRecibos') flexRecibos: WjFlexGrid;
  @ViewChild('flexMatriculas') flexMatriculas: WjFlexGrid;

  constructor(
    private _toastr: ToastrService,
    private _wjHelper: WijmoHelper,
    private _changeDetector: ChangeDetectorRef,
    private routes: Router,
    //private SResponsablePago: ResponsablePagoService,
    //private sEstudiante: EstudianteService,
    private _modalService: NgbModal,
    private _sModelo: ModeloService,
    private _sTable: TableService,
    private _sAuth: AuthenticationService,
    private _sMatricula: MatriculaService,
    private _sResponsablePago: ResponsablePagoService,
    private _sEstudiante: EstudianteService,
    private _sOtrasPersonas: OtrasPersonasService,
    private _sCobro: CobrosService,
    private _sRecibo: RecibosService,
    public _sExtracto: ExtractoService,
    private _sTarea: TareaServidorService
  ) {
    super(_toastr, _wjHelper, _sModelo, _changeDetector, _sTable, _sAuth, _sMatricula,
      _sResponsablePago, _sEstudiante, _sOtrasPersonas, _sCobro, _sRecibo, _sExtracto, _sTarea);
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {

    //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
    this.setModelosTrabajo2({
      identificador: "Odata",
      odata: [
        `TipoRecibo;$orderby=Orden`,
        `Mes;$filter=IdAnio eq ${this.anioDto.Id}`,
        `Grado;$filter=IdAnio eq ${this.anioDto["Id"]}&$expand=DefinicionGrado,Grupos,Año`,
        `ResponsablePago;$filter=IdPersona eq ${this.personaDto.Id} and IdAño eq ${this.anioDto["Id"]}&$expand=Recibos.DetallesRecibo,Matriculas.Estudiante,Persona,EncargadoDistribucion&$orderby=Persona.Apellido1`,
        'Parametro'
      ]
    });

    //Carga la consulta con identificador 'Odata'
    this.cargarDatos("Odata", () => { });
  }


  /**
   * Actualiza los recibos cuando éstos son modificados
   */
  actualizarRecibo() {

    //Guarda una copia del recibo para saber si algún recibo se modificó
    this.recibosCopia = [];
    _.each(this.cvRecibos.items, (r) => { this.recibosCopia.push(recaudoUI.copyObject(r)); });

    //Actualiza los valores cuando el recibo cambia el valor
    this.flexRecibos.onRowEditEnded = (e) => {

      let r = this.cvRecibos.items[e.row];
      let _r = _.find(this.recibosCopia, (rec) => { return rec.Id == r.Id; });

      //Si se cambio la fecha de pago del recibo
      if (recaudoUI.formatDate(r.FechaPago) != recaudoUI.formatDate(_r.FechaPago)) {
        r.MedioPago = recaudoUI.formatDate(r.FechaPago) ? "Colegio" : "";

        _r.FechaPago = r.FechaPago;
      }
    };
  }

  /**
   * Trae los datos de las consultas realizadas
   * @param data El array u objeto que se esta consultado
   * @param identificador El identificador que ha sigo asignado a cada consulta
   */
  onDatosObtenidos(data: any, identificador: string) {

    //Carga los datos de la consulta de Odata
    if (identificador == "Odata") {

      this.grupos = [];

      this.parametros = data["Parametro"];

      this.encargadosDistricubion = data["EncargadoDistribucion"];

      this.responsablePago = _.first(data["ResponsablePago"]);
      this.responsablePagoObjetoModelo.objeto = this.responsablePago;
      this.responsablePagoObjetoModelo.actualizarCopia();

      //Permite saber si hubieron cambios en el formulario
      this.registrarObjetosModelo([this.responsablePagoObjetoModelo]);

      if (data["TipoRecibo"].length == 0)
        this.tiposRecibo = this.respaldoTipoRecibo;
      else
        this.tiposRecibo = data["TipoRecibo"];

      this.meses = data["Mes"];

      this.responsablePago["Recibos"] = _.sortBy(this.responsablePago["Recibos"], function (r) {

        r["Creado"] = recaudoUI.formatDate(r["Creado"]);
        r["Modificado"] = recaudoUI.formatDate(r["Modificado"]);

        return r.IdMes + (r.DetallesRecibo.length != 0 ? r.DetallesRecibo[0].IdCobro : "") + r.TipoRecibo;
      });

      this.recibos = this.responsablePago["Recibos"];
      this.matriculas = this.responsablePago["Matriculas"];

      this.grados = data['Grado'];

      //Se arma los grupos por medio de los grados
      _.each(this.grados, (g) => {
        g.NombreGrado = g.DefinicionGrado.Nombre + "-" + g.Año.Etiqueta;
        this.grupos = this.grupos.concat(g.Grupos);
      });

      this.cvRecibos = new CollectionView(this.recibos);
      this.cvRecibos.trackChanges = true;

      //Cuando se hace focus a alguna celda, esto para habilitar o deshabilitar los botones
      this.flexRecibos.focus = () => {
        let item = this.obtenerRegistroSeleccionado(this.flexRecibos);

        //Depende del registro seleccionado habilita el botón para realizar una accion como tambien lo puede habilitar
        if (item)
          this.deshabilitarBotonesTapRecibo(item);
      }

      this.cvMatriculas = new CollectionView(this.matriculas);
      this.cvMatriculas.trackChanges = true;

      //Genera el grid de los años
      this.registrarModeloCollectionViews([
        new ModeloCollectionView('Recibo', 'Id', this.cvRecibos, this.flexRecibos),
        new ModeloCollectionView('Matricula', 'Id', this.cvMatriculas, this.flexMatriculas)
      ]);

      this.actualizarRecibo();
    }
  }

  /**
   * habilita o deshabilita los botones acorde con el registro seleccionado
   * @param item 
   */
  deshabilitarBotonesTapRecibo(item) {

    //Deshabilita los botones
    if (item.FechaPago) {
      this.deshabilitaBotonesRecibo.descuento = true;
    }

    //Habilita los botones
    else {
      this.deshabilitaBotonesRecibo.descuento = false;
    }

    //Actualiza el html 
    this.changeDetector.markForCheck();
  }

  /**
   * Actualiza las columnas que va tener el grid
   * @param column dato de la columna
   * @param modelo nombre del primer parametro en el new ModeloCollectionView
   */
  onAjusteColumna(column: WjFlexGridColumn, modelo: string) {
    if (modelo == "Recibo") {

      switch (column.binding) {
        case "IdMes":
          column.width = 130;
          //crea el select de meses
          column.dataMap = new DataMap(this.meses, "Id", "Nombre");
          break;
        case "MedioPago":
          column.dataMap = new DataMap(this.mediosPago, "propiedad", "etiqueta");
          break;
        default:
      }
    } else if (modelo == "Matricula") {
      switch (column.binding) {
        case "IdMes":
          column.dataMap = new DataMap(this.meses, "Id", "Nombre");
          break;
        case "IdDelMes":
          column.dataMap = new DataMap(this.meses, "Id", "Nombre");
          break;
        case "IdGrado":
          column.dataMap = new DataMap(this.grados, "Id", "NombreGrado");
          break;
        case "IdGrupo":
          column.dataMap = new DataMap(this.grupos, "Id", "Nombre");
          break;
        default:
      }
    }
  }

  /**
   * Cambia el responsable de pago
   */
  cambiarResponsablePago() {

    this.modalBuscarResponsablePago.abrirConsultaResponsablePago();

    //Capta el resultado de la modal
    this.modalBuscarResponsablePago.modalRef.result.then((dataResponsablePago) => {

      this.responsablePago["IdPersona"] = dataResponsablePago["Persona"]["Id"];
      this.responsablePago["Persona"] = dataResponsablePago["Persona"];

    }, () => {
      console.log("Cancelado");
    });
  }

  /**
   * Se crea la matricula para un estudiante por medio del c{odigo
   */
  crearMatricula() {

    //this.modal.abrirConsultarEstudiante(this.anioDto);
    this.modaCrearMatricula.abrirCrearMatricula(null, this.anioDto, this.responsablePago["Id"]);

    //Capta el resultado de la modal
    this.modaCrearMatricula.modalRef.result.then((dataMatricula) => {

      this.cvMatriculas.sourceCollection.push(dataMatricula);

      this.cvMatriculas.refresh();

    }, (info) => {
      console.log(info);
    });
  }

  /**
   * Abre el tap para ver la persona
   */
  verPersona() {
    recaudoUI.tabsManager.agregar("Persona - " + this.personaDto.Nombre, "fa fa-users", PersonaComponent, { idPersona: this.personaDto.Id });
  }

  /**
   * Abre el tap de la matrícula seleccionada
   */
  verMatricula() {

    let item = this.obtenerRegistroSeleccionado(this.flexMatriculas);

    if (!this.verificarSiHayCambios() && item)
      recaudoUI.tabsManager.agregar("Matrícula - " + item["Estudiante"]["Codigo"], "fa-user", MatriculaComponent, { idMatricula: item["Id"], anio: this.anioDto });
  }

  /**
   * Abre el tap del recibo seleccionado
   */
  verRecibo() {

    let item = this.obtenerRegistroSeleccionado(this.flexRecibos);

    if (!this.verificarSiHayCambios() && item)
      recaudoUI.tabsManager.agregar("Recibo - " + (item["NumeroRecibo"] || item["Referencia1"] || item["Referencia2"]), "fa-file-text-o", ReciboComponent, { idRecibo: item["Id"], anio: recaudoUI.copyObject(this.anioDto) });

  }

  /**
   * Imprime el recibo seleccionado
   */
  imprimirRecibo() {

    let item = this.obtenerRegistroSeleccionado(this.flexRecibos);

    if (!(!this.verificarSiHayCambios() && item))
      return;

    let parametro = _.find(this.parametros, (p) => { return p.Nombre == 'NombreDB'; });

    window.open("https://apps.vpsnotas.com/RecaudoInf/Informes/Informe.aspx?DB=" + parametro.Valor + "&Nombre=" + item["TipoRecibo"] + "&NumeroRecibo=" + item["NumeroRecibo"] + "&Exportar=PDF", '_blank');

    //let url = this.routes.createUrlTree([`/RecaudoInf/Informes`, { DB: parametro.Valor, Nombre: item["TipoRecibo"], NumeroRecibo: item["NumeroRecibo"], Exportar: "PDF" }]);

    //window.open(url.toString(), '_blank');
  }

  /**
   * Permite al tap detectar si hay cambios o no, para el momento de ser cerrado
   */
  consultarSiHayCambios() {
    this.hayCambios = this.getTieneCambios();
    return this.hayCambios;
  }

  /**
   * Genera el descuento del recibo seleccionado
   */
  generarDescuentoRecibo() {
    let item = this.obtenerRegistroSeleccionado(this.flexRecibos);

    if (!(!this.verificarSiHayCambios() && item))
      return;

    //Obtiene el abono máximo que se puede realizar
    let valorMaximoAbono = 0;
    if (item.TotalConIntereses2)
      valorMaximoAbono = item.TotalConIntereses2;
    else if (item.TotalConIntereses)
      valorMaximoAbono = item.TotalConIntereses;
    else if (item.TotalAPagar)
      valorMaximoAbono = item.TotalAPagar;

    let titulo = "Ingrese el Valor del Descuento " + (item["NumeroRecibo"] ? item["NumeroRecibo"] : "");
    //{ property: "valorAbono", label: "Valor del abono", type: "number", required: true, min: 0, max: (valorMaximoAbono - 1), placeholder: "Valor del  abono", messageErrorMax: `El valor del abono no debe ser igual ni mayor al valor del recibo. Recibo ${valorMaximoAbono}` }


    //Configura como desea la modal y la cantidad de inputs 
    this.modalFormulario.abrirIngresarValor({
      titulo: "Aplicar descuento " + (item.NumeroRecibo ? "Recibo # " + item.NumeroRecibo : ""),
      input: [
        { property: "descuento", label: "Valor del Descuento", type: "number", required: true, min: 0, max: (valorMaximoAbono - 1), placeholder: "Descuento", messageErrorMax: `El valor del abono no debe ser igual ni mayor al valor del recibo. Recibo ${valorMaximoAbono}` }
      ]
    });
    this.modalFormulario.modalRef.result.then((dataResult) => {

      //Crea la tarea y la despacha
      this.crearTarea("AplicarDescuento", item["NumeroRecibo"], dataResult.descuento, null, null, null, null);

    }, (info) => {
      console.log("Info ", info);
    });
  }


  /**
   * Genera los recibos acorde al tipo de seleccion y el mes seleccionados
   */
  generarRecibos() {

    //Valida la seleccion obligatoria
    if (this.generaRecibos.idMes == 0)
      this.toastr.warning("Es necesario seleccionar el mes");

    //Valida la seleccion obligatoria
    else if (!this.generaRecibos.tipoRecibo || !this.generaRecibos.tipoRecibo["Clase"])
      this.toastr.warning("Es necesario seleccionar el tipo de recibo");

    else if (this.generaRecibos.tipoRecibo && this.generaRecibos.tipoRecibo["Clase"] == "Abono") {

      //Configura como desea la modal y la cantidad de inputs 
      this.modalFormulario.abrirIngresarValor({
        titulo: "Valor",
        input: [
          { property: "valorAbono", label: "Valor del abono", type: "number", required: true, min: 0, placeholder: "Valor del abono" }
        ]
      });
      this.modalFormulario.modalRef.result.then((dataValor) => {

        this.crearTareaSync("GenerarAbono", this.responsablePago["Id"], this.generaRecibos.idMes, dataValor["valorAbono"], this.generaRecibos.tipoRecibo["Tipo"], null, null);

      }, (info) => {
        console.log("Info", info);
      });
    }

    else {

      //Genera la tarea correspondiente
      if (this.generaRecibos.tipoRecibo["Clase"] == "EstadoCuenta")
        this.crearTareaSync("GenerarEstadoCuenta", this.responsablePago["Id"], this.generaRecibos.idMes, this.generaRecibos.tipoRecibo["Tipo"], null, null, null);

      else if (this.generaRecibos.tipoRecibo["Clase"] == "CobroPrejuridico")
        this.crearTareaSync("GenerarCobroPrejuridico", this.responsablePago["Id"], this.generaRecibos.idMes, null, null, null, null);

      else if (this.generaRecibos.tipoRecibo["Clase"] == "PagoAdelantado")
        this.crearTareaSync("GenerarPagoAdelantado", this.responsablePago["Id"], this.generaRecibos.idMes, null, null, null, null);

      else if (this.generaRecibos.tipoRecibo["Clase"] == "Matricula")
        this.crearTareaSync("GenerarReciboMatricula", this.responsablePago["Id"], this.generaRecibos.tipoRecibo["Tipo"], null, null, null, null);

      else
        this.toastr.error("Tipo de recibo no soportado");
    }
  }

  /**
     * Genera el mensaje al cual se va a mostrar al usuario cuando la tarea sea finalizada
     * @param tareaServidor 
     */
  public mostrarMensajeTareaRevisada(tareaServidor) {

    let mensaje = "";
    let titulo = "";

    if (tareaServidor["Estado"] == 1) {

      titulo = "Éxito"

      if (tareaServidor["Nombre"] == "GenerarReciboMatricula")
        mensaje = `Se ha generado ${tareaServidor["Resultado"]} recibos de matrícula`;

      else if (tareaServidor["Nombre"] == "GenerarEstadoCuenta")
        mensaje = `Se ha generado ${tareaServidor["Resultado"]} recibos`;

      else if (tareaServidor["Nombre"] == "GenerarEstadoCuenta")
        mensaje = `Se ha generado ${tareaServidor["Resultado"]} recibos de ruta`;

      else if (tareaServidor["Nombre"] == "AplicarDescuento")
        mensaje = `Se ha realizado el descuento exitosamente`;
      //mensaje = `Se ha realizado el descuento de $ ${tareaServidor["Parametro2"]}`;

      else
        mensaje = `Se Se ha creado la tarea ${tareaServidor["Resultado"]}`;
    }

    else {
      titulo = "Error";
      mensaje = tareaServidor["Excepcion"];
    }

    //Carga la consulta con identificador 'Odata de los recibos que coinciden a la matrícula seleccionada'
    this.cargarDatos(null, () => { });

    this.modalAlerta.abrirConfirmar({ textHeader: titulo, textBody: mensaje });
    this.modalAlerta.modalRef.result.then((dataResult) => {
    }, (info) => {
      console.log("Info", info);
    });
  }

  actualizarDatosDespuesDeGuardar(modulos) {

    this.cargarDatos(null, () => { });
  }
}
