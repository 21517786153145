import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NgbModalRef, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { EstudianteService, ModeloService } from '../../services/services';
import * as _ from 'underscore';

@Component({
  selector: 'app-modal-crear-matricula',
  templateUrl: './modal-crear-matricula.component.html',
  styleUrls: ['./modal-crear-matricula.component.css']
})
export class ModalCrearMatriculaComponent implements OnInit {

  @ViewChild('crearMatriculaModal') private crearMatriculaModal;

  private anio;
  private estudiante: Object;
  private gradoSeleccionado: object = { Grupos: {} };
  private matricula: Object;
  private grados: object[];
  private busquedaEstudiante: object;
  private responsablePagoDto: object;

  modalRef: NgbModalRef;

  constructor(
    private _modalService: NgbModal,
    private toastr: ToastrService,
    public changeDetector: ChangeDetectorRef,
    private config: NgbModalConfig,
    private sEstudiante: EstudianteService,
    private sModelo: ModeloService,
  ) { }

  ngOnInit() {
  }

  /**
   * Abre la modal para crear la matrícula
   * @param meses 
   */
  public abrirCrearMatricula(estudiante, anio, idResponsablePago?): NgbModalRef {

    this.inicializarVariables();

    //Se esta creando la matrícula por el tap del estudiante
    if (estudiante != null) {
      this.busquedaEstudiante["esBuscarEstudiante"] = false;
      this.estudiante = estudiante;
    }

    this.matricula = {
      IdGrado: null,
      IdGrupo: null,
      IdResponsablePago: idResponsablePago
    };

    this.anio = anio;

    //Realiza la consulta del grado
    this.sModelo.getQueries([`Grado;$filter=IdAnio eq ${anio.Id}&$expand=Año,DefinicionGrado,Grupos`]).subscribe(data => {

      this.grados = data["Grado"];

    }, (error) => {
      console.log("Error en el grado", error);
    });

    //Abre la modal con su respectiva configuración
    this.config.backdrop = "static";
    this.config.keyboard = false;

    this.modalRef = this._modalService.open(this.crearMatriculaModal, { size: "lg" });
    return this.modalRef;
  }

  /**
   * Inicializa las variables cada vez que se abre la modal
   */
  inicializarVariables() {

    this.anio = null;
    this.estudiante = null;
    this.gradoSeleccionado = { Grupos: {} };
    this.matricula = null;
    this.grados = [];
    this.busquedaEstudiante = { esBuscarEstudiante: true, Codigo: "" };
    this.responsablePagoDto = {
      Persona: {
        Nombre: null,
      },
      Identificacion: null,
      IdAnio: 0,
      IdAño: 0
    }
  }

  /**
   * Buscar al estudiante por medio del código
   */
  private buscarEstudiante() {

    this.busquedaEstudiante["IdColegio"] = this.anio["IdColegio"];

    if (this.busquedaEstudiante["Codigo"] == null || this.busquedaEstudiante["Codigo"] == "") {
      this.toastr.error("Ingrese el Código del estudiante");
      return;
    }

    this.sEstudiante.buscarEstudiantes(this.busquedaEstudiante).subscribe(data => {

      this.estudiante = _.first(data);

    });
  }

  /** 
   * Crea la matrícula
   * @param resultado 
   */
  private aceptar() {

    if (this.responsablePagoDto["Identificacion"] == null && this.responsablePagoDto["Identificacion"] == "") {
      this.toastr.error("Ingrese el número de identificación");
      return;
    }

    if (this.matricula["IdGrado"]) {

      this.matricula["Activo"] = true;
      this.matricula["Matriculado"] = false;
      this.matricula["Nuevo"] = false;
      this.matricula["Becado"] = false;
      this.matricula["Ruta"] = false;
      this.matricula["Comedor"] = false;
      this.matricula["IdEstudiante"] = this.estudiante["Id"];

      this.sModelo.save("Matricula", [this.matricula]).subscribe((result) => {


        if (this.matricula) {
          let grupo = _.find(this.gradoSeleccionado["Grupos"], (g) => { return g.Id == this.matricula["IdGrupo"]; });
          this.matricula["Grupo"] = grupo;
        }

        this.matricula["Estudiante"] = this.estudiante;
        this.matricula["GradoNombre"] = this.gradoSeleccionado["DefinicionGrado"].Nombre + " - " + this.anio["Etiqueta"];
        this.matricula["Grado"] = this.gradoSeleccionado;

        this.matricula["Id"] = result[0].Id;

        //Retorna la matrícula
        this.modalRef.close(this.matricula);

      }, (error) => {
        console.log("No se pudo crear", error);
      });
    }
    else
      this.toastr.error("El grado es requerido")
  }

  /**
   * Cierra la modal
   */
  private cerrarModal() {
    this.modalRef.dismiss("Cerrar");
  }
}
