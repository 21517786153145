import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { NgbModalRef, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { EstudianteService } from '../../services/services';
import * as _ from 'underscore';

@Component({
  selector: 'app-modal-buscar-estudiante',
  templateUrl: './modal-buscar-estudiante.component.html',
  styleUrls: ['./modal-buscar-estudiante.component.css']
})
export class ModalBuscarEstudianteComponent implements OnInit {

  private anio: object;
  private estudiante: Object;
  private busquedaEstudiante;

  @ViewChild('buscarEstudianteModal') private buscarEstudianteModal;

  modalRef: NgbModalRef;

  constructor(
    private _modalService: NgbModal,
    private toastr: ToastrService,
    public changeDetector: ChangeDetectorRef,
    private config: NgbModalConfig,
    private sEstudiante: EstudianteService,
  ) { }

  ngOnInit() {
  }

  /**
   * Busca a un estudiante por medio del código
   * @param anio 
   */
  public abrirBuscarEstudiante(anio): NgbModalRef {

    this.inicializarVariables();

    this.anio = anio;

    //Abre la modal con su respectiva configuración
    this.config.backdrop = "static";
    this.config.keyboard = false;

    this.modalRef = this._modalService.open(this.buscarEstudianteModal, { size: "lg" });
    return this.modalRef;
  }

  /**
   * Cada vez que se abre la modal se inicializan las variables
   */
  private inicializarVariables(){
    this.anio = null;
    this.busquedaEstudiante = {
      esBuscarEstudiante: true,
      Codigo: ""
    };
    this.estudiante = null;
  }

  /**
   * Buscar al estudiante por medio del código
   */
  private buscarEstudiante() {

    this.busquedaEstudiante["IdColegio"] = this.anio["IdColegio"];

    if (this.busquedaEstudiante.Codigo == null || this.busquedaEstudiante.Codigo == "") {
      this.toastr.error("Ingrese el Código del estudiante");
      return;
    }

    this.sEstudiante.buscarEstudiantes(this.busquedaEstudiante).subscribe(data => {

      this.estudiante = _.first(data);

    });
  }

  /**
   * Retorna el estudiante seleccionado
   */
  private aceptar() {
    if (this.estudiante)
      this.modalRef.close(this.estudiante);
  }

  /**
   * Cierra la modal
   */
  private cerrarModal() {
    this.modalRef.dismiss("Cerrar");
  }

}
