import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { TableService } from 'src/app/shared/services/table';
import { WijmoHelper, ModeloCollectionView } from 'src/app/helpers/WijmoHelper';
import { ModeloService, RecibosService, MatriculaService, ResponsablePagoService, EstudianteService, OtrasPersonasService, CobrosService, ExtractoService, TareaServidorService } from 'src/app/shared/services/services';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { RecaudoFormHelper } from 'src/app/helpers/RecaudoFormHelper';
import { GestorCodigoBarras } from 'src/app/helpers/gestorCodigoBarras';
import * as _ from 'node_modules/underscore';
import { recaudoUI } from 'src/app/helpers/RecaudoUI';
import { CollectionView } from 'wijmo/wijmo';
import { WjFlexGrid, WjFlexGridColumn } from 'wijmo/wijmo.angular2.grid';
import { MatriculaComponent } from '../matricula/matricula.component';
import { ConfiguracionInput } from 'src/app/_models/modals.model';
import { ModalFormularioComponent } from 'src/app/shared/components/modal-formulario/modal-formulario.component';
import { ModalConfirmacionComponent } from 'src/app/shared/components/modal-confirmacion/modal-confirmacion.component';
import { DataMap } from 'wijmo/wijmo.grid';

@Component({
  selector: 'app-recaudo',
  templateUrl: './recaudo.component.html',
  styleUrls: ['./recaudo.component.css']
})
export class RecaudoComponent extends RecaudoFormHelper implements OnInit {

  anioCargado = false;
  anios: object[];
  parametros: object[];
  gestorCodigoBarrasLongitud;
  gestorCodigoBarras;
  fechaActual;
  infoCodigoBarras;
  reciboParaPagar;
  ejecutaFuncionDespuesDelGuardado = {};
  
  mediosPago = [
    { propiedad: "", etiqueta: "" },
    { propiedad: "Banco", etiqueta: "Banco" },
    { propiedad: "PSE", etiqueta: "PSE" },
    { propiedad: "Datafono", etiqueta: "Datafono" },
    { propiedad: "Colegio", etiqueta: "Colegio" }, 
    { propiedad: "Transferencia", etiqueta: "Transferencia" }, 
  ];

  formulario = {
    codigoBarras: "",
    idAnio: 0,
    tipo: "",
    fehaValida: true,
    mensaje: "",
    coincidencia: "",
    colorMensaje: "white"
  };

  recibo = [];
  matriculaSeleccionada = {};
  recibosCopia = [];

  //CollectionViews
  cvMatriculas: CollectionView;
  cvRecibos: CollectionView;

  //Referencia a los controles de wijmo
  @ViewChild('flexMatriculas') flexMatriculas: WjFlexGrid;
  @ViewChild('flexRecibos') flexRecibos: WjFlexGrid;

  //modals
  @ViewChild(ModalFormularioComponent) modalFormulario: ModalFormularioComponent;
  @ViewChild(ModalConfirmacionComponent) modalConfirmacion: ModalConfirmacionComponent;

  //Referencia al filtro de wijmo
  @ViewChild('filterParametro') filterParametro: WjFlexGrid;


  //declaración de servicios
  constructor(
    private gestorCodigo: GestorCodigoBarras,
    private _toastr: ToastrService,
    private _sTable: TableService,
    private _wjHelper: WijmoHelper,
    private _changeDetector: ChangeDetectorRef,

    private _sModelo: ModeloService,
    private _sAuth: AuthenticationService,
    //private sRecibo: RecibosService,
    private _sMatricula: MatriculaService,
    private _sResponsablePago: ResponsablePagoService,
    private _sEstudiante: EstudianteService,
    private _sOtrasPersonas: OtrasPersonasService,
    private _sCobro: CobrosService,
    private _sRecibo: RecibosService,
    public _sExtracto: ExtractoService,
    private _sTarea: TareaServidorService
  ) {
    super(_toastr, _wjHelper, _sModelo, _changeDetector, _sTable, _sAuth, _sMatricula,
      _sResponsablePago, _sEstudiante, _sOtrasPersonas, _sCobro, _sRecibo, _sExtracto, _sTarea);
  }

  /**
   * Los componentes generados dinámicamente deben implementar esta interfaz
   */
  ngAfterViewInit() {

  }

  ngOnInit() {

    //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
    this.setModelosTrabajo2({
      identificador: "Odata",
      odata: [
        'Año',
        'Parametro'
      ]
    });

    //Carga la consulta con identificador 'Odata'
    this.cargarDatos("Odata", () => { });
  }

  /**
   * Trae los datos de las consultas realizadas
   * @param data El array u objeto que se esta consultado
   * @param identificador El identificador que ha sigo asignado a cada consulta
   */
  onDatosObtenidos(data: any, identificador: string) {

    //Carga los datos de la consulta de Odata
    if (identificador == "Odata") {

      this.anios = data["Año"];

      //Asigna el ultimo año creado al select de Año
      if (!this.anioCargado && this.anios.length) {
        this.anioCargado = true;
        this.formulario.idAnio = _.last(this.anios)["Id"];
      }

      //Contiene los parametros del colegio
      this.parametros = data["Parametro"];
      this.gestorCodigoBarrasLongitud = _.find(this.parametros, (p) => { return p.Nombre == "GestorCodigoBarrasLongitud" });
      this.gestorCodigoBarras = _.find(this.parametros, (p) => { return p.Nombre == "GestorCodigoBarras" });
      let tipoRuta = _.find(this.parametros, (p) => { return p.Nombre == "RecaudoFiltroTipoRecibo" });

      if (tipoRuta)
        this.formulario.tipo = tipoRuta.Valor;
    }

    //Carga los datos de la consulta de las matriculas
    else if (identificador == "matriculas") {

      _.each(data, (m) => {

        m.FechaPago = recaudoUI.formatDate(m.FechaPago);
        m.Estudiante.nombres = `${m.Estudiante.Apellido} ${m.Estudiante.Nombre}`;
        m.Grupo = m.Grupo ? m.Grupo : { Etiqueta: "" };
      });

      this.cvMatriculas = new CollectionView(data);
      this.cvMatriculas.trackChanges = true;

      //Recarga los recibos
      if (data.length != 0)
        this.seleccionarMatricula(_.first(data), null, null);
      else
        this.cvRecibos = new CollectionView([]);
    }

    //Carga los datos de la consulta de los recibos que coinciden a la matrícula seleccionada
    else if (identificador == "OdataRecibo") {

      data["Recibo"] = _.sortBy(data["Recibo"], function(r) {
          
        r.FechaPago = recaudoUI.formatDate(r.FechaPago);
        r["Creado"] = recaudoUI.formatDate(r["Creado"]);
        r["Modificado"] = recaudoUI.formatDate(r["Modificado"]);

        return r.IdMes + (r.DetallesRecibo.length != 0 ? r.DetallesRecibo[0].IdCobro: "") + r.TipoRecibo;
      });

      this.cvRecibos = new CollectionView(data["Recibo"]);
      this.cvRecibos.trackChanges = true;


      this.flexRecibos.hostElement.style.height = "350px !important";

      //Genera los grids
      this.registrarModeloCollectionViews([
        new ModeloCollectionView('Recibo', 'Id', this.cvRecibos, this.flexRecibos),
        new ModeloCollectionView('Matricula', 'Id', this.cvMatriculas, this.flexMatriculas)
      ]);
      setTimeout(() => {
        this.filtrarRowsGrid();
        this.actualizarRecibo();
      }, 60);
    }
  }

  onAjusteColumna(column: WjFlexGridColumn, modelo: string) {
    if (modelo == "Recibo") {
      switch (column.binding) {
        case "MedioPago":
          column.dataMap = new DataMap(this.mediosPago, "propiedad", "etiqueta");
          break;
        default:
      }
    } 
  }

  /**
   * Actualiza los recibos cuando éstos son modificados
   */
  actualizarRecibo() {

    //Guarda una copia del recibo para saber si algún recibo se modificó
    this.recibosCopia = [];
    _.each(this.cvRecibos.items, (r) => { this.recibosCopia.push(recaudoUI.copyObject(r)); });

    //Actualiza los valores cuando el recibo cambia el valor
    this.flexRecibos.onRowEditEnded = (e) => {

      let r = this.cvRecibos.items[e.row];
      let _r = _.find(this.recibosCopia, (rec) => { return rec.Id == r.Id; });

      //Si se cambio la fecha de pago del recibo
      if (recaudoUI.formatDate(r.FechaPago) != recaudoUI.formatDate(_r.FechaPago)) {
        r.MedioPago = recaudoUI.formatDate(r.FechaPago) ? "Colegio" : "";

        _r.FechaPago = r.FechaPago;
      }
    };
  }


  /**
   * Busca el recibo por medio del código de barras
   */
  buscarPorCodigoDeBarras() {

    if (!this.formulario.codigoBarras) {
      //this.formulario.mensaje = "";
      //this.formulario.colorMensaje = "white";
      return;
    }

    //Almacena la información del código de barras
    this.infoCodigoBarras = {};

    //Obtiene los datos que van en el codigo de barras
    try {
      //console.log("-->", this.gestorCodigoBarras, this.gestorCodigoBarrasLongitud, this.formulario);
      this.infoCodigoBarras = this.gestorCodigo.generarCodigoBarras(this.gestorCodigoBarras["Valor"], this.formulario.codigoBarras, this.gestorCodigoBarrasLongitud ? this.gestorCodigoBarrasLongitud["Valor"] : null);

    } catch (error) {
      //this.toastr.error(error);

      this.formulario.mensaje = error;
      this.formulario.colorMensaje = "red";
      this.formulario.codigoBarras = "";
    }

    //Realiza la consulta del código de barras
    this.sRecibo.buscarReciboPorCodigoBarras(this.infoCodigoBarras).subscribe(dataRecibo => {

      try {

        this.fechaActual = dataRecibo["Fecha"];
        this.reciboParaPagar = dataRecibo["Recibo"];

        //Si el recibo que se va pagar no existe
        if (this.reciboParaPagar == null)
          throw new Error("El recibo no se ha encontrado");


        //Realiza la busqueda de la matricula por medio del código del estudiante
        this.buscarMatriculas(this.infoCodigoBarras["codigo"]);

        //Si el recibo no está vigente
        if (!this.reciboParaPagar["Vigente"])
          throw new Error("El recibo no está vigente");

        //Si el recibo aun no ha sido pagado
        if (this.reciboParaPagar["FechaPago"] == null) {

          console.log(this.infoCodigoBarras, this.reciboParaPagar);

          //Si el valor que posee el codigo de barras no coincide con el valor del recibo
          if (!(this.reciboParaPagar["TotalAPagar"] == this.infoCodigoBarras["valor"] ||
            this.reciboParaPagar["TotalConIntereses"] == this.infoCodigoBarras["valor"] ||
            this.reciboParaPagar["TotalConIntereses2"] == this.infoCodigoBarras["valor"] ||
            this.reciboParaPagar["TotalConDescuento"] == this.infoCodigoBarras["valor"]))
            throw new Error("Error en el valor del recibo");

          //Si el código de barras trae fecha de pago  
          if (this.formulario.fehaValida && this.infoCodigoBarras["fecha"] != null) {

            if (this.fechaActual > this.infoCodigoBarras["fecha"])
              throw new Error("La fecha de pago con este código de barras ya está vencida" + ". " + recaudoUI.formatDate(this.infoCodigoBarras["fecha"], "f"));

            //Si no es el recibo de pago oportuno, y la fecha del pago oportuno no ha pasado, genera error
            if (this.reciboParaPagar["Mes"].PagoOportuno != null &&
              recaudoUI.formatDate(this.reciboParaPagar["Mes"].PagoOportuno, "f") != recaudoUI.formatDate(this.infoCodigoBarras["fecha"], "f") &&
              recaudoUI.fechaEsMayor(this.fechaActual,/*<=*/ this.reciboParaPagar["Mes"].PagoOportuno))

              throw new Error("La fecha de pago con este código de barras aún no está habilitada" + ". " + recaudoUI.formatDate(this.infoCodigoBarras["fecha"], "f"));

            //Recibos con doble fecha
            if (this.reciboParaPagar["Mes"].PagoInteres1 != null &&
              this.reciboParaPagar["Mes"].PagoOportuno != null) {

              //Si es recibo de tercera fecha, deben haber pasado la fecha de oportuno e interés 1
              if (recaudoUI.formatDate(this.reciboParaPagar["Mes"].PagoInteres1, "f") != recaudoUI.formatDate(this.infoCodigoBarras["fecha"], "f") &&
                recaudoUI.formatDate(this.reciboParaPagar["Mes"].PagoOportuno, "f") != recaudoUI.formatDate(this.infoCodigoBarras["fecha"], "f") &&
                recaudoUI.fechaEsMayor(this.fechaActual,/*<=*/ this.reciboParaPagar["Mes"].PagoInteres1))

                throw new Error("La fecha de pago con este código de barras aún no está habilitada" + ". " + recaudoUI.formatDate(this.infoCodigoBarras["fecha"], "f"));
            }
          }

          //Si el recibo con descuento y fecha de pago oportuno pasó
          if(this.formulario.fehaValida && this.reciboParaPagar["TotalConDescuento"] == this.infoCodigoBarras["valor"] && this.reciboParaPagar["Mes"].PagoOportuno != null && recaudoUI.fechaEsMayor(this.reciboParaPagar["Mes"].PagoOportuno,this.fechaActual)) {
            this.modalConfirmacion.abrirConfirmar({ textoHeader: `La fecha de pago oportuno ya pasó ¿Desea registar el pago por el valor con descuento?` });
            this.modalConfirmacion.modalRef.result.then((success: boolean) => {

              //Si acepto la modal
              if (success) {      
                this.crearTareaSync("PagarRecibo", this.reciboParaPagar["Id"], this.infoCodigoBarras["valor"], this.fechaActual, null, null, null);
              }              
            });
          }
          //Si el recibo con descuento y fecha de pago oportuno no ha pasado, pero se paga más
          else if (this.formulario.fehaValida && this.reciboParaPagar["TotalConDescuento"] && this.reciboParaPagar["TotalAPagar"] == this.infoCodigoBarras["valor"] && this.reciboParaPagar["Mes"].PagoOportuno != null && recaudoUI.fechaEsMayor(this.fechaActual,this.reciboParaPagar["Mes"].PagoOportuno)) {
            this.modalConfirmacion.abrirConfirmar({ textoHeader: `La fecha de pago oportuno no ha pasado ¿Desea registar el pago por el valor sin descuento?` });
            this.modalConfirmacion.modalRef.result.then((success: boolean) => {

              //Si acepto la modal
              if (success) {      
                this.crearTareaSync("PagarRecibo", this.reciboParaPagar["Id"], this.infoCodigoBarras["valor"], this.fechaActual, null, null, null);
              }              
            });
          }
          else
            //Se crea la tarea
            this.crearTareaSync("PagarRecibo", this.reciboParaPagar["Id"], this.infoCodigoBarras["valor"], this.fechaActual, null, null, null);
          //this.crearTarea("PagarRecibo", this.reciboParaPagar["Id"], this.infoCodigoBarras["valor"], this.fechaActual, null, null, null);
        }

        //El recibo ya fue pagado
        else {
          this.formulario.mensaje = `El pago ya está registrado. Código: ${this.infoCodigoBarras["codigo"]}, Mes: ${this.reciboParaPagar["Mes"].Nombre}`;
          this.formulario.colorMensaje = "yellow";
        }

      } catch (error) {
        //this.toastr.error(`${error}. Código: ${this.infoCodigoBarras["codigo"]}, Referencia: ${this.infoCodigoBarras["referencia"]}`);
        this.formulario.mensaje = `${error}. Código: ${this.infoCodigoBarras["codigo"]}, Referencia: ${this.infoCodigoBarras["referencia"]}`;
        this.formulario.colorMensaje = "red";
        this.formulario.codigoBarras = "";
      }

      this.formulario.codigoBarras = "";

    }, (error) => {
      console.log("Error ", error);
    });
  }

  /**
   * Diferencia que funcion debe ejecutar, si buscarCodigo o BuscarMatricula
   */
  buscar() {

    //Guarda los cambios realizado para luego seguir con la función seleccionada
    if (this.cvRecibos && (this.cvRecibos.itemsAdded.length || this.cvRecibos.itemsEdited.length || this.cvRecibos.itemsRemoved.length)) {
      if (this.formulario.codigoBarras)
        this.ejecutaFuncionDespuesDelGuardado = { funcion: "codigoBarras", param1: null }; //[this.buscarPorCodigoDeBarras()];
      else
        this.ejecutaFuncionDespuesDelGuardado = { funcion: "matriculas", param1: null }; //[this.buscarMatriculas()];

      this.guardar(null, null);
    }
    else {
      if (this.formulario.codigoBarras)
        this.buscarPorCodigoDeBarras();
      else
        this.buscarMatriculas();
    }
  }

  /**
   * Busca las matriculas que coinciden con el código del estudiante o 
   */
  buscarMatriculas(codigo?) {

    //Si fue escanado el codigo de barras
    if (codigo) {
      this.formulario.coincidencia = codigo;

      let anio = _.find(this.anios, (a) => { return a.Id == this.reciboParaPagar["Mes"].IdAnio; });
      if (anio)
        this.formulario.idAnio = anio["Id"];
    }

    if (!this.formulario.coincidencia && !this.formulario.codigoBarras)
      return;


    //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
    this.setModelosTrabajo2({
      servicio: "sMatricula",
      identificador: "matriculas",
      metodo: "buscarParaRecaudo",
      consulta: this.formulario
    });

    //Carga solo la consulta con identificador 'buscar las matriculas'
    this.cargarDatos("matriculas", () => { });
  }

  /**
   * Cuando se selecciona una matricula, carga los recibos de ésta
   * @param s 
   * @param e 
   */
  seleccionarMatricula(matriculaDto, s, e) {

    let item;

    if (matriculaDto)
      item = matriculaDto;

    else if (!item)
      item = this.obtenerRegistroSeleccionado(this.flexMatriculas);

    if (this.cvRecibos && (this.cvRecibos.itemsAdded.length || this.cvRecibos.itemsEdited.length || this.cvRecibos.itemsRemoved.length)) {

      this.ejecutaFuncionDespuesDelGuardado = { funcion: "cambioMatricula", param1: item };
      //this.ejecutaFuncionDespuesDelGuardado = this.consultarMatricula(matriculaDto);
      this.guardar(null, null);
    }
    else
      this.consultarMatricula(item);
  }

  /**
   * Cuando se selecciona una matricula, carga los recibos de ésta
   */
  consultarMatricula(matriculaDto) {

    let item = matriculaDto;


    //No realiza la consulta de los recibos si se ha seleccionado la misma matrícula
    if (_.isEqual(this.matriculaSeleccionada == item))
      return;

    //Almacena la matricula seleccionada para que no tenga que cargar la query cada vez que cambie la seleccion de la propiedad en el registro  
    this.matriculaSeleccionada = item;


    //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
    this.setModelosTrabajo2({
      identificador: "OdataRecibo",
      odata: [
        `Recibo;$filter=IdMatricula eq ${this.matriculaSeleccionada["Id"]}&$expand=Mes,DetallesRecibo`
      ]
    });

    //Carga la consulta con identificador 'Odata de los recibos que coinciden a la matrícula seleccionada'
    this.cargarDatos("OdataRecibo", () => { });
  }

  /**
   * Genera el mensaje al cual se va a mostrar al usuario cuando la tarea sea finalizada
   * @param tareaServidor 
   */
  mostrarMensajeTareaRevisada(tareaServidor) {

    if (tareaServidor["Estado"] == 1) {
      let mensajeMes = this.reciboParaPagar ? ", Mes: " + this.reciboParaPagar["Mes"]["Nombre"] : "";
      let valor = recaudoUI.formatearMoneda(this.infoCodigoBarras["valor"].toLocaleString());

      this.formulario.mensaje = `Pago registrado. Código: ${this.infoCodigoBarras["codigo"]}${mensajeMes}, Valor: ${valor}`;
      this.formulario.colorMensaje = "greenyellow";
    }
    else {
      this.toastr.error("Error No se pudo crear la tarea " + tareaServidor["Nombre"]);
      this.formulario.mensaje = `No válido, verifique que el recibo no tenga fecha de pago`;
      this.formulario.colorMensaje = "red";
    }

    //Carga la consulta con identificador 'Odata de los recibos que coinciden a la matrícula seleccionada'
    this.cargarDatos("matriculas", () => { });
  }

  /**
   * Abre el tap de la matrícula seleccionada
   */
  verMatricula(item2) {

    let item = this.obtenerRegistroSeleccionado(this.flexMatriculas);

    if (!(!this.verificarSiHayCambios() && item))
      return;

    console.log("item", item);

    recaudoUI.tabsManager.agregar("Matrícula - " + item["Estudiante"]["Codigo"], "fa-user", MatriculaComponent, { idMatricula: item["Id"], anio: { Id: this.formulario.idAnio } });
  }

  /**
   * Realiza el pago del recibo que aun no ha sido pagado
   */
  pagarNormalRecibo() {

    let item = this.obtenerRegistroSeleccionado(this.flexRecibos);

    if (!(!this.verificarSiHayCambios() && item))
      return;

    //Crea el formulario 
    this.modalFormulario.abrirIngresarValor({
      titulo: "Realizar el pago normal del recibo con referencia " + item.Referencia1,
      input: [
        { property: "valorPago", label: "Valor ", type: "number", required: true, min: 0, placeholder: "Valor" },
        { property: "fechaPago", label: "Fecha ", type: "date", required: true, messageErrorRequired: "La fecha de pago es requeria" }
      ],
      valor: {
        valorPago: item["TotalAPagar"],
        fechaPago: recaudoUI.getFechaActual()
      }
    });
    this.modalFormulario.modalRef.result.then((dataResult) => {

      this.crearTareaSync("PagarRecibo", item["Id"], dataResult["valorPago"], dataResult["fechaPago"], null, null, null);
      //this.crearTarea("PagarRecibo", item["Id"], dataResult["valorPago"], dataResult["fechaPago"], null, null, null);
    }, (info) => {
      console.log("Info", info);
    });
  }

  /**
   * Realiza el pago con interes
   */
  pagarConInteres2FechaRecibo() {
    let item = this.obtenerRegistroSeleccionado(this.flexRecibos);

    if (!(!this.verificarSiHayCambios() && item))
      return;

    //Crea el formulario 
    this.modalFormulario.abrirIngresarValor({
      titulo: "Pago con Interés del recibo con referencia " + item.Referencia1,
      input: [
        { property: "valorPagoConInteres", label: "Valor ", type: "number", required: true, min: 0, placeholder: "Valor" },
        { property: "fechaPago", label: "Fecha ", type: "date", required: true, messageErrorRequired: "La fecha de pago es requeria" }
      ],
      valor: {
        valorPagoConInteres: item["TotalConIntereses"],
        fechaPago: recaudoUI.getFechaActual()
      }
    });
    this.modalFormulario.modalRef.result.then((dataResult) => {

      this.crearTareaSync("PagarRecibo", item["Id"], dataResult["valorPagoConInteres"], dataResult["fechaPago"], null, null, null);
      //this.crearTarea("PagarRecibo", item["Id"], dataResult["valorPagoConInteres"], dataResult["fechaPago"], null, null, null);
    }, (info) => {
      console.log("Info", info);
    });
  }

  /**
   * Realiza el pago del recibo despues de la segunda fecha
   */
  pagarConInteresDespues2FechaRecibo() {

    //Obtiene el item seleccionado del flex grid
    let item = this.obtenerRegistroSeleccionado(this.flexRecibos);
    if (!(!this.verificarSiHayCambios() && item))
      return;

    //Contiene la configuración de la modal para mostrar las etiquetas asignadas
    let configuracionDeInputsModal: ConfiguracionInput[] = [
      { property: "valorPago", label: "Valor pagado", type: "number", required: true, min: 0, placeholder: "Valor del pago", messageErrorMin: "El valor de pago no debe ser menor a cero" }
    ];

    //Si no es rol de secretaria se muestra el input para seleccionar la fecha de pago
    if (this.usuarioAuth.Rol == 'Administrador' || this.usuarioAuth.Rol == 'SuperAdmin' || this.usuarioAuth.Rol == 'Contador')
      configuracionDeInputsModal.push(
        { property: "fechaPago", label: "Fecha", type: "date", required: true, messageErrorRequired: "La fecha de pago es requeria" }
      );

    //Configura como desea la modal y la cantidad de inputs 
    this.modalFormulario.abrirIngresarValor(
      {
        titulo: "Pago con interés del recibo con referencia " + item.Referencia1,
        input: configuracionDeInputsModal,
        valor: {
          valorPago: item.TotalConIntereses2,
          fechaPago: recaudoUI.getFechaActual()
        }
      }
    );
    this.modalFormulario.modalRef.result.then((dataValor) => {

      this.crearTareaSync("PagarRecibo", item.Id, dataValor["valorPago"], dataValor["fechaPago"], null, null, null);
      //this.crearTarea("PagarRecibo", item.Id, dataValor["valorPago"], dataValor["fechaPago"], null, null, null);

    }, (info) => {
      console.log("Info", info);
    });
  }

  /**
  * Realiza el filtro re los recibos
  */
  filtrarRowsGrid() {

    var rows = this.flexRecibos.rows;

    _.each(rows, (row) => {

      let registroVisible = true;

      let item = row.dataItem;

      if (this.formulario.tipo == null || this.formulario.tipo == "")
        registroVisible = true;

      if (item["TipoRecibo"].toString().toLowerCase().indexOf(this.formulario.tipo.toString().toLowerCase()) == -1)
        registroVisible = false;

      row.visible = registroVisible;
    });
  }

  /**
   * Actualiza los datos despues de guardar
   * @param modulos 
   */
  actualizarDatosDespuesDeGuardar(modulos) {

    console.log("asdasdasd", this.ejecutaFuncionDespuesDelGuardado);

    if (this.ejecutaFuncionDespuesDelGuardado["funcion"] == "codigoBarras")
      this.buscarPorCodigoDeBarras();

    else if (this.ejecutaFuncionDespuesDelGuardado["funcion"] == "matriculas")
      this.buscarMatriculas();

    else if (this.ejecutaFuncionDespuesDelGuardado["funcion"] == "cambioMatricula")
      this.consultarMatricula(this.ejecutaFuncionDespuesDelGuardado["param1"]);

    this.ejecutaFuncionDespuesDelGuardado = {};
  }
}
