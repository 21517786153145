import { Component, AfterViewInit, OnInit } from '@angular/core';
import { ROUTES } from './menu-items';
import { RouteInfo } from './sidebar.metadata';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;

import { recaudoUI } from '../../helpers/RecaudoUI';
import { AuthenticationService } from '../services/authentication.service';

import { UsuarioAuth } from 'src/app/_models/usuarioAuth';
import { Tab } from 'src/app/helpers/Models';

import * as _ from 'underscore';

import { BuscarMatriculasComponent } from 'src/app/component/buscar-matriculas/buscar-matriculas.component';
import { AdministracionComponent } from 'src/app/component/administracion/administracion.component';
import { ExtractosComponent } from 'src/app/component/extractos/extractos.component';
import { AniosComponent } from 'src/app/component/anios/anios.component';
import { InformesComponent } from 'src/app/component/informes/informes.component';
import { ParametrosComponent } from 'src/app/component/parametros/parametros.component';
import { OtrasPersonasComponent } from 'src/app/component/otras-personas/otras-personas.component';
import { BuscarEstudiantesComponent } from 'src/app/component/buscar-estudiantes/buscar-estudiantes.component';
import { BuscarRecibosComponent } from 'src/app/component/buscar-recibos/buscar-recibos.component';
import { BuscarResponsablesComponent } from 'src/app/component/buscar-responsables/buscar-responsables.component';
import { PrincipalComponent } from 'src/app/component/principal/principal.component';
import { AdministracionInformesComponent } from 'src/app/component/administracion-informes/administracion-informes.component';
import { TareasServidorComponent } from 'src/app/component/tareas-servidor/tareas-servidor.component';
import { ColegiosComponent } from 'src/app/component/colegios/colegios.component';
import { UsuariosComponent } from 'src/app/component/usuarios/usuarios.component';
import { UsuarioComponent } from 'src/app/component/usuario/usuario.component';
import { RecaudoComponent } from 'src/app/component/recaudo/recaudo.component';
import { RevisionesComponent } from '../../component/revisiones/revisiones.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {

  usuarioLogeado: UsuarioAuth;

  //Contiene los tabs, para que al oprimir en el menú y éste tiene tab genere el tab
  tabs: {};

  tapsContador = {};

  showMenu = '';
  showSubMenu = '';
  public sidebarnavItems: any[];
  proyectoEnProduccion = false;

  /**
   * Es para abrir, cerrar el menú principal como tambien trabaja con la url o abre tabs
   * @param element
   */
  controlarMenu(sidebar: RouteInfo) {

    //Direcciona a la ruta seleccionada
    if (!sidebar.tab && sidebar.class === '')
      this.router.navigate([sidebar.path]);

    //Verifica si debe abrir un tab
    else if (sidebar.tab) {

      //Si El usuario se encuentra en una url distinta de la principal
      if (sidebar.path != this.router.url)
        this.router.navigate([sidebar.path]);

      this.abrirTap(sidebar.tab);
    }

    if (sidebar.title === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = sidebar.title;
    }
  }

  /**
   * Es para abrir, cerrar el Submenú como tambien trabaja la url o abre tabs
   * @param sidebar 
   */
  controlarSubMenu(sidebar: RouteInfo) {

    if (!sidebar.tab && sidebar.submenu.length == 0)
      this.router.navigate([sidebar.path]);

    //Verifica si debe abrir un tab
    else if (sidebar.tab) {

      //Si El usuario se encuentra en una url distinta de la principal
      if (sidebar.path != this.router.url)
        this.router.navigate([sidebar.path]);

      this.abrirTap(sidebar.tab);
    }

    if (sidebar.title === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = sidebar.title;
    }
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private sAuth: AuthenticationService,
  ) { }

  /**
   * 
   */
  ngOnInit() {

    this.usuarioLogeado = this.sAuth.getUser();

    //Carga las rutas de ejemplo
    this.agregarRutasDelMenu();

    //Carga y refresca las tabs 
    this.cargarTabs();
  }


  /**
   * Carga las rutas del menú izquierdo
   */
  agregarRutasDelMenu() {

    let menu = [];
    this.proyectoEnProduccion = recaudoUI.getProyectoEstaEnProduccion();

    //Muestra el menú que el usuario va ver dependiendo del rol
    _.each(ROUTES.filter(sidebarnavItem => sidebarnavItem), (m) => {

      //Genera el filtro de los componentes que no van cuando esta el proyecto está en producción
      if ((this.proyectoEnProduccion && m.title != "UI Components" && m.title != "Component") || !this.proyectoEnProduccion) {

        //Permite  filtrar el menú acorde al rol con el que se haya ingresado
        if (!m.rolesPermitidos || (m.rolesPermitidos && m.rolesPermitidos[this.usuarioLogeado.Rol])) {

          let copiaMenu = _.omit(m, "submenu");

          //Arma el sub menú
          copiaMenu["submenu"] = [];

          //Separa los submenus
          if (m.submenu && m.submenu.length != 0)
            _.each(m.submenu, (sm) => {

              //Arma el submenú que puede ver el usuario acorde a su rol
              if (!sm.rolesPermitidos || (sm.rolesPermitidos && sm.rolesPermitidos[this.usuarioLogeado.Rol]))
                copiaMenu["submenu"].push(sm);

            });

          //Va armando el menú 
          menu.push(copiaMenu);
        }
      }
    });

    this.sidebarnavItems = menu;
  }

  /**
   * Abre la tab seleccionada en el menú
   */
  abrirTap(tab: any) {

    if (tab == "Salir")
      this.logout();

    //Abre la tab seleccionada
    else if (this.tabs && this.tabs[tab]) {

      recaudoUI.tabsManager.agregarTab(this.tabs[tab]);
    }
  }

  /**
   * Carga la tab de inicio y refresca las tabs cuando se cambia de año
   */
  cargarTabs() {

    //agrega por defecto la tab de inicio
    recaudoUI.tabsManager.agregar("Principal", "fa-home", PrincipalComponent, null, false, false);

    //Cuando se cambia el año se recargan todos los controllers
    recaudoUI.onAñoCambiado(() => {

      //Actualiza los controladores que tienen tabs
      this.tabs = {

        //Menú princial
        Principal: new Tab("Principal", "fa-home", PrincipalComponent, null, false, false),
        Matrículas: new Tab("Matrículas " + recaudoUI.getAñoTrabajo().Etiqueta, "fa-address-book-o", BuscarMatriculasComponent, null, false),
        BuscarResponsables: new Tab("Responsables", "fa fa-search", BuscarResponsablesComponent, { anio: recaudoUI.getAñoTrabajo() }, false),
        Recaudo: new Tab("Recaudo", "fa fa-barcode", RecaudoComponent, null, false),
        Administracion: new Tab("Panel de Control " + recaudoUI.getAñoTrabajo().Etiqueta, "fa fa-newspaper-o", AdministracionComponent, { anio: recaudoUI.getAñoTrabajo() }, false),

        //Menú general
        BuscarRecibos: new Tab("Buscar Recibos", "fa fa-search-plus", BuscarRecibosComponent, { anio: recaudoUI.getAñoTrabajo() }, false),
        Estudiantes: new Tab("Estudiantes", "fa fa-user-circle-o", BuscarEstudiantesComponent, { anio: recaudoUI.getAñoTrabajo() }, false),
        OtrasPersonas: new Tab("Otras Personas", "fa fa-users", OtrasPersonasComponent, { anio: recaudoUI.getAñoTrabajo() }, false),
          Extractos: new Tab("Extractos ", "fa-archive", ExtractosComponent, { anio: recaudoUI.getAñoTrabajo() }, false),
          Revisiones: new Tab("Revisiones ", "fa-archive", RevisionesComponent, { anio: recaudoUI.getAñoTrabajo() }, false),
        Informes: new Tab("Informes ", "fa fa-folder-open-o", InformesComponent, { anio: recaudoUI.getAñoTrabajo() }, false),

        //Menú administración
        Usuarios: new Tab("Usuarios ", "", UsuariosComponent, { anio: recaudoUI.getAñoTrabajo() }, false),
        TareaServidor: new Tab("Tareas Servidor", "", TareasServidorComponent, { anio: recaudoUI.getAñoTrabajo() }, false),
        Colegios: new Tab("Colegios", "", ColegiosComponent, { anio: recaudoUI.getAñoTrabajo() }, false),
        Años: new Tab("Años", null, AniosComponent, null, false),
        Parametros: new Tab("Parámetros", null, ParametrosComponent, { anio: recaudoUI.getAñoTrabajo() }, false),
        AdministracionInformes: new Tab("Administración Informes ", "", AdministracionInformesComponent, { anio: recaudoUI.getAñoTrabajo() }, false),
      }
    });
  }

  /**
   * Edita el perfil del usuario logeado
   */
  editarPerfil() {
    if (this.usuarioLogeado)
      recaudoUI.tabsManager.agregar("Usuario - " + this.usuarioLogeado.Nombre, "fa fa-address-card-o", UsuarioComponent, { idUsuario: this.usuarioLogeado.IdUsuario });
  }

  /**
   * Cierra la sesión
   */
  logout() {
    //console.log("Logout");
    this.sAuth.logout();
  }
}
