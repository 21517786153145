import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { recaudoUI } from '../../helpers/RecaudoUI';
//import { ToastrService } from 'ngx-toastr';
import { ToastrService } from 'ngx-toastr';
import { AñoService, ManagerService, MatriculaService } from '../../shared/services/services';
import * as _ from 'underscore'
import { UsuarioAuth } from 'src/app/_models/usuarioAuth';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { modalConfirmar } from 'src/app/_models/modals.model';
import { ModalConfirmacionComponent } from 'src/app/shared/components/modal-confirmacion/modal-confirmacion.component';
import { ModalAlertaComponent } from 'src/app/shared/components/modal-alerta/modal-alerta.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { MatriculaComponent } from '../matricula/matricula.component';

@Component({
    selector: 'app-principal',
    templateUrl: './principal.component.html',
    styleUrls: ['./principal.component.css']
})
export class PrincipalComponent implements OnInit, AfterViewInit {

    colegio: string = null;
    colegios: object[];
    idColegio: number;
    cargando: false;

    //variables para unidadNegocio
    UnidadesNegocio: object[];
    filtroAdvertencias: object[];
    filtroVencido: object[];

    year: object = null;
    years: Array<any> = [];

    @ViewChild(ModalConfirmacionComponent) modalConfirmacion: ModalConfirmacionComponent;
    @ViewChild(ModalAlertaComponent) modalAlerta: ModalAlertaComponent;

    //Tiene los datos del usuario autenticado
    public usuarioAuth: UsuarioAuth;

    constructor(
        private SAños: AñoService,
        public toastr: ToastrService,
        private sManager: ManagerService,
        public sAuth: AuthenticationService,
        private changeDetector: ChangeDetectorRef,
        private modalService: NgbModal,
        private routes: Router,
        private route: ActivatedRoute,
        private sMatricula: MatriculaService
    ) {
        this.usuarioAuth = sAuth.getUser();
    }

    ngAfterViewInit(): void { }

    /**
     * Inicializa los datos de los colegios y del año
     */
    ngOnInit() {

        this.usuarioAuth = this.sAuth.getUser();

        //Si el usuario que está logeado tiene rol de superadministrador
        if (this.usuarioAuth.Rol == "SuperAdmin") {

            //Almacena el id del colegio al cual el usuario está logeado            
            this.idColegio = this.usuarioAuth.IdColegio;

            this.sManager.getColegios().subscribe(dataColegios => {
                this.colegios = dataColegios.sort((a, b) => (a.Nombre > b.Nombre) ? 1 : ((b.Nombre > a.Nombre) ? -1 : 0));
            }, (error) => {
                console.log('getColegios',error);
            });
        }

        this.SAños.getAños().subscribe(años => {

            this.years = años;
            //console.log(años);
            this.selecionarAnio();

        }, (error) => {

            console.log('getAños, Colegio no tiene la tabla unidad de negocio ni rango', error);
            this.SAños.getAños2().subscribe(años => {

                this.years = años;
                //console.log(años);
                this.selecionarAnio();

            });
        });
    }

    selecionarAnio() {
        //inicia los filtros
        this.filtroAdvertencias = [];
        this.filtroVencido = [];

        //Si en la url trae el código y el anio busca la matrícula del estudiante
        if (this.route.snapshot.params.anio && this.route.snapshot.params.codigo) {

            let anio = _.find(this.years, (y) => { return y.Numero == this.route.snapshot.params.anio; });

            this.sMatricula.buscar({ Codigo: this.route.snapshot.params.codigo, IdAnio: anio.Id }).subscribe((dataMatriculas) => {

                if (dataMatriculas.length > 0)
                    recaudoUI.tabsManager.agregar("Matrícula - " + this.route.snapshot.params.codigo, "fa-user", MatriculaComponent, { idMatricula: dataMatriculas[0]["Id"], anio: anio });

            }, (error) => {
                console.log("Error", error);
            });
        }

        if (this.years.length) {
            //último año por defecto
            recaudoUI.setAñoTrabajo(_.last(this.years))
            this.year = recaudoUI.getAñoTrabajo();
            this.colegio = this.year["Colegio"]["Nombre"];


            //para DBs nuevas que tengan las tablas de unidad de negocio y rango
            if (this.year["Colegio"]['UnidadesNegocio'] && this.year["Colegio"]['UnidadesNegocio'].length > 0) {

                //console.log(this.year["Colegio"]['UnidadesNegocio']);
                this.UnidadesNegocio = this.year["Colegio"]['UnidadesNegocio'];
                var UnidadesVencido = [];
                //se recorren la unidades de negocio
                this.UnidadesNegocio.forEach(function(un) {
                    //se recorren los rangos de la unidad de negocio
                    un['Rangos'].forEach(function(r){
                        //console.log(r);
                        //solos los rangos que tengan FechaHasta lleno, Activo en true y tipo documento igual a factura son los que se evaluan
                        if(r['FechaHasta'] && r['Activo'] == true && r['TipoDocumento'] == 'Factura'){
                            //Crear las fechas
                            var fechaRango = new Date(r['FechaHasta']);
                            var fechaHoy = new Date();

                            //quita la hora de la fecha actual
                            fechaHoy.setHours(0, 0, 0, 0);
                            
                            //se agrega un mes a la fecha actual y se guarda en una variable
                            var fechaLimite = new Date();
                            fechaLimite.setMonth((fechaLimite.getMonth() + 1));

                            //console.log(fechaHoy, fechaRango, fechaLimite);
                            //un dia en milisegundos
                            var dayMilliseconds = 86400000;
                            //saca los dias faltantes
                            var diasR = Math.round((Math.abs((fechaRango.getTime() - fechaHoy.getTime())) / dayMilliseconds));

                            //comparar fechas
                            if (fechaRango < fechaHoy) 
                                //ya paso
                                UnidadesVencido.push({ unidad: un['Nombre'], resolucion: r.NumeroResolucion, dias: diasR, categoria: 'vencido' });
                            else if(fechaRango.getTime() == fechaHoy.getTime())
                                //hoy vence
                                UnidadesVencido.push({ unidad: un['Nombre'], resolucion: r.NumeroResolucion, dias: diasR, categoria: 'advertencia' });
                            else if(fechaRango < fechaLimite)
                                //advertencia
                                UnidadesVencido.push({ unidad: un['Nombre'], resolucion: r.NumeroResolucion, dias: diasR, categoria: 'advertencia' }); 
                        }
                    });
                });

                this.filtroAdvertencias = UnidadesVencido.filter(function(uv) {
                    return uv.categoria == 'advertencia';
                });
                this.filtroVencido = UnidadesVencido.filter(function(uv) {
                    return uv.categoria == 'vencido';
                });
            }
        }
    }

    /**
     * Cambia el año de trabajo de la aplicación
     */
    cambiarAnio() {
        let year = _.find(this.years, (year) => {
            return year["Id"] == this.year["Id"];
        });

        if (year)
            recaudoUI.setAñoTrabajo(year);
        else
            this.toastr.error("Año no encontrado.")
    }

    /**
     * Permite cambiar de colegio al superadministrador
     */
    cambiarColegio() {

        if (this.usuarioAuth.Rol != "SuperAdmin")
            return;

        this.changeDetector.detectChanges();

        this.modalConfirmacion.abrirConfirmar({ textoHeader: "Ha realizado el cambio de colegio, aconsejamos guardar todos los cambios realizados porque el navegador va ser recargado, ¿está seguro de continuar?." })
        this.modalConfirmacion.modalRef.result.then((result) => {

            //Realiza el cambio en la base de datos y luego recarga la página
            if (result) {
                this.sManager.postCambioColegio(this.idColegio).subscribe((data) => {

                    sessionStorage.setItem("token", data['Token']);
                    sessionStorage.setItem("user", data['Usuario']);
                    sessionStorage.setItem("currentUser", JSON.stringify(data['Usuario']));

                    recaudoUI.tabsManager.cerrarTodasLasTabs();
                    this.ngOnInit();

                    this.toastr.success("Se ha cambiado de colegio");
                    ////location.reload();

                }, (error) => {
                    console.log("Se ha generado un error ", error);
                });
            }

            //Si cancelo el cambio de colegio
            else
                this.idColegio = this.usuarioAuth.IdColegio;


        }, (error) => {
            console.log("Error", error);
        });
    }
}
