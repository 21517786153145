import { Injectable } from '@angular/core';
import * as _ from 'node_modules/underscore';
import { recaudoUI } from 'src/app/helpers/RecaudoUI';

@Injectable()
export class GestorNombres {

    /*private apellidos = {
        "RODRIGUEZ": 0, "GAMBOA": 1, "RUBIO": 3, "BRICEÑO": 4, "GUTIERREZ": 5, "MARCILLO": 6, "BELTRAN": 7, "HERNANDEZ": 8,
        "VELASQUEZ": 9 
    };*/

    private persona: object;

    /**
     * Arma el objeto de persona
     * @param nombre1 
     * @param nombre2 
     * @param apellido1 
     * @param apellido2 
     */
    private llenarDatosPersona(nombre1: string, nombre2: string, apellido1: string, apellido2: string) {

        this.persona = { Nombre1: nombre1, Nombre2: nombre2, Apellido1: apellido1, Apellido2: apellido2 };
    }

    /**
     * 
     * @param nombre 
     * @param formato Nombre-Apellido(1), Apellido-Nombre(2)
     */
    public separarNombres(nombre: string, formato: string) {

        //formatea el objeto persona
        this.llenarDatosPersona("", "", "", ";")

        if (!nombre)
            return this.persona;

        //Separar el nombre por espacios            
        let cads: string[] = nombre.trim().replace("  ", " ").replace("  ", " ").split(' ');

        //Si el nombre o apellido es compuesto
        if (cads.length > 2) {

            let nombresCompuesto = [];
            var construccionNombre = "";

            for (var i = 0; i < cads.length; i++) {
                var InsertarNombre = true;

                //DIONICIO DE JESUS QUINTERO GARCIA
                if (cads[i].toUpperCase() == " ")
                    InsertarNombre = false;

                else if (cads[i].toUpperCase() == "DE") {
                    construccionNombre += construccionNombre == "" ? cads[i] : " " + cads[i];
                    InsertarNombre = false;
                }

                else if (cads[i].toUpperCase() == "DEL") {
                    construccionNombre += construccionNombre == "" ? cads[i] : " " + cads[i];
                    InsertarNombre = false;
                }

                else if (cads[i].toUpperCase() == "LA") {
                    construccionNombre += construccionNombre == "" ? cads[i] : " " + cads[i];
                    InsertarNombre = false;
                }

                //Agrega el nombre o apellido compuesto
                if (InsertarNombre) {
                    construccionNombre += construccionNombre == "" ? cads[i] : " " + cads[i];
                    nombresCompuesto.push(construccionNombre);
                    construccionNombre = "";
                }
            }

            //Transformo el nuevo array de los nombres y apellidos
            cads = nombresCompuesto;
        }

        if (formato.toLowerCase() == "apellido-nombre" || formato == "2" /*|| this.apellidos[cads[1]]*/)
            this.generarApellidosNombres(cads);

        else if (formato.toLowerCase() == "nombre-apellido" || formato == "1")
            this.generarNombresApellidos(cads);

        return this.persona;
    }

    /**
     * Por medio de un array que contiene el los nombres y apellidos genera el objeto de persona
     */
    private generarNombresApellidos(cads: string[]) {

        // JUAN 
        if (cads.length == 1)
            this.llenarDatosPersona(cads[0], "", "", "");

        // JUAN TORO 
        else if (cads.length == 2)
            this.llenarDatosPersona(cads[0], "", cads[1], "");

        // JUAN TORO CASTILLO
        else if (cads.length == 3)
            this.llenarDatosPersona(cads[0], "", cads[1], cads[2]);

        // JUAN ALVERTO TORO CASTILLO
        else if (cads.length >= 4) {

            let apellido2 = "";

            for (var i = 3; i < cads.length; i++)
                apellido2 += cads[i] + " ";

            this.llenarDatosPersona(cads[0], cads[1], cads[2], apellido2);
        }
    }

    /**
     * Por medio de un array que contiene el los nombres y apellidos genera el objeto de persona
     * @param cads 
     */
    private generarApellidosNombres(cads: string[]) {

        if (cads.length == 1)
            this.llenarDatosPersona(cads[0], "", "", "");

        // JUAN TORO 
        else if (cads.length == 2)
            this.llenarDatosPersona(cads[1], "", cads[0], "");

        // JUAN TORO CASTILLO
        else if (cads.length == 3)
            this.llenarDatosPersona(cads[2], "", cads[0], cads[1]);

        // JUAN ALVERTO TORO CASTILLO
        else if (cads.length >= 4) {

            let apellido2 = "";

            for (var i = 1; i < cads.length - 2; i++)
                apellido2 += cads[i] + " ";

            this.llenarDatosPersona(cads[cads.length - 2], cads[cads.length - 1], cads[0], apellido2);
        }
    }
}
