import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import { WjGridModule } from 'wijmo/wijmo.angular2.grid';
import { WjInputModule } from 'wijmo/wijmo.angular2.input';
import { WjGridFilterModule } from 'wijmo/wijmo.angular2.grid.filter';

import { Interceptor } from './services/interceptor';

import { FilterPipe } from './pipes/filter';
import { AuthGuard } from '../security/auth.guard';
import { EstudianteService, AñoService, MatriculaService, RecibosService, ModeloService, CobrosService, ManagerService, TareaServidorService } from './services/services';
import { WijmoHelper } from '../helpers/WijmoHelper';
import { ComponentLoaderDirective } from './directives/component-loader/component-loader.directive';
import { ResizeHeightToWindowDirective } from './directives/resize-height-to-window/resize-height-to-window.directive';
import { RefreshWindowOnClickDirective } from './directives/refresh-window-on-click/refresh-window-on-click.directive';
import { TableService } from './services/table';
import { ModalAjustarInteresDescuentoComponent } from './components/modal-ajustar-interes-descuento/modal-ajustar-interes-descuento.component';
import { ModalCargaCobroComponent } from './components/modal-carga-cobro/modal-carga-cobro.component';
import { FileUploadModule } from 'ng2-file-upload/ng2-file-upload';
import { ModalBuscarEstudianteComponent } from './components/modal-buscar-estudiante/modal-buscar-estudiante.component';
import { ModalCrearMatriculaComponent } from './components/modal-crear-matricula/modal-crear-matricula.component';
import { ModalFormularioComponent } from './components/modal-formulario/modal-formulario.component';
import { GestorCodigoBarras } from '../helpers/gestorCodigoBarras';
import { GestorNombres } from '../helpers/gestorNombres';
import { ModalConfirmacionComponent } from './components/modal-confirmacion/modal-confirmacion.component';
import { CargandoComponent } from './components/cargando/cargando.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { ModalCambiarPasswordComponent } from './components/modal-cambiar-password/modal-cambiar-password.component';
import { ModalBuscarResponsablePagoComponent } from './components/modal-buscar-responsable-pago/modal-buscar-responsable-pago.component';
import { ModalEliminarRecibosComponent } from './components/modal-eliminar-recibos/modal-eliminar-recibos.component';
import { ModalAlertaComponent } from './components/modal-alerta/modal-alerta.component';
import { ModalGenerarRecibosComponent } from './components/modal-generar-recibos/modal-generar-recibos.component';
import { ModalGestorCostosEducativosComponent } from './components/modal-gestor-costos-educativos/modal-gestor-costos-educativos.component';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    //BrowserModule,
    //BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    PerfectScrollbarModule,
    ToastrModule.forRoot(),
    WjGridModule,
    WjInputModule,
    WjGridFilterModule,
    FileUploadModule
  ],
  declarations: [
    //Aquí va los componentes
    FilterPipe,
    ComponentLoaderDirective,
    ResizeHeightToWindowDirective,
    RefreshWindowOnClickDirective,
    ModalAjustarInteresDescuentoComponent,
    ModalCargaCobroComponent,
    ModalBuscarEstudianteComponent,
    ModalCrearMatriculaComponent,
    ModalFormularioComponent,
    ModalConfirmacionComponent,
    CargandoComponent,
    ProgressBarComponent,
    ModalCambiarPasswordComponent,
    ModalBuscarResponsablePagoComponent,
    ModalEliminarRecibosComponent,
    ModalAlertaComponent,
    ModalGenerarRecibosComponent,
    ModalGestorCostosEducativosComponent,
  ],
  exports: [
    //Aquí va los componentes que van ser compartidos entre modulos
    FilterPipe,
    ComponentLoaderDirective,
    ResizeHeightToWindowDirective,
    RefreshWindowOnClickDirective,
    ModalAjustarInteresDescuentoComponent,
    ModalCargaCobroComponent,
    ModalBuscarEstudianteComponent,
    ModalCrearMatriculaComponent,
    ModalFormularioComponent,
    ModalConfirmacionComponent,
    CargandoComponent,
    ProgressBarComponent,
    ModalCambiarPasswordComponent,
    ModalBuscarResponsablePagoComponent,
    ModalEliminarRecibosComponent,
    ModalAlertaComponent,
    ModalGenerarRecibosComponent,
    ModalGestorCostosEducativosComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true,
      //provide: PERFECT_SCROLLBAR_CONFIG,
      //useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    AuthGuard,
    EstudianteService,
    AñoService,
    MatriculaService,
    RecibosService,
    ModeloService,
    CobrosService,
    ManagerService,
    WijmoHelper,
    GestorCodigoBarras,
    GestorNombres,
    TableService,
    TareaServidorService,
  ],
  entryComponents: [ModalAlertaComponent],
})
export class SharedModule { }
