import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { WjFlexGrid } from 'wijmo/wijmo.angular2.grid';
import { AutoSizeMode } from 'wijmo/wijmo.grid';
//import { WjFlexGrid } from '../../../../wijmo/NpmImages/wijmo-commonjs-min/wijmo.angular2.grid';
//import { AutoSizeMode } from '../../../../wijmo/NpmImages/wijmo-commonjs-min/wijmo.grid';

import { MatriculaService, ModeloService, ResponsablePagoService, EstudianteService, OtrasPersonasService, CobrosService, RecibosService, ExtractoService, TareaServidorService } from '../../shared/services/services';
import { MatriculaBusquedaDto } from '../../helpers/Dtos';
import { MatriculaComponent } from '../matricula/matricula.component';
import *  as _ from 'underscore';
import { recaudoUI } from '../../helpers/RecaudoUI';
import { ToastrService } from 'ngx-toastr';
import { WijmoHelper, ModeloCollectionView } from 'src/app/helpers/WijmoHelper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TableService } from 'src/app/shared/services/table';
import { RecaudoFormHelper } from 'src/app/helpers/RecaudoFormHelper';
import { CollectionView } from 'wijmo/wijmo';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ResponsableComponent } from '../responsable/responsable.component';
import { AsistenteNuevaMatriculaComponent } from '../asistente-nueva-matricula/asistente-nueva-matricula.component';
import { FindValueOperator } from 'rxjs/internal/operators/find';

@Component({
  selector: 'app-buscar-matriculas',
  templateUrl: './buscar-matriculas.component.html',
  styleUrls: ['./buscar-matriculas.component.css']
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuscarMatriculasComponent extends RecaudoFormHelper implements OnInit {
  //copia del año con el cual se instanció el componente
  anio = recaudoUI.getAñoTrabajo();
  matriculaBusqueda = {
    Apellidos: "",
    Nombres: "",
    Codigo: "",
    Documento: "",
    IdAnio: this.anio.Id,
    FiltroPropiedad: "",
    IdGrado: null
  };

  matriculas: Array<any> = [];
  hayCambios = false;

  //CollectionViews
  cvMatriculas: CollectionView;

  //Referencia a los controles de wijmo
  @ViewChild('flexMatriculas') flexMatriculas: WjFlexGrid;

  gradosPorAnio: Object[];
  grados: Object[];
  anios: object[];

  filtro: Object[] = [
    { propiedad: "" },
    { propiedad: "Retirados" },
    { propiedad: "Becados" },
    { propiedad: "Ruta" },
  ];

  constructor(
    private _toastr: ToastrService,
    private _wjHelper: WijmoHelper,
    private _changeDetector: ChangeDetectorRef,

    private _sModelo: ModeloService,
    private _sTable: TableService,
    private _sAuth: AuthenticationService,
    private _sMatricula: MatriculaService,
    private _sResponsablePago: ResponsablePagoService,
    private _sEstudiante: EstudianteService,
    private _sOtrasPersonas: OtrasPersonasService,
    private _sCobro: CobrosService,
    private _sRecibo: RecibosService,
    private _sExtracto: ExtractoService,
    private _sTarea: TareaServidorService
  ) {
    super(_toastr, _wjHelper, _sModelo, _changeDetector, _sTable, _sAuth, _sMatricula,
      _sResponsablePago, _sEstudiante, _sOtrasPersonas, _sCobro, _sRecibo, _sExtracto, _sTarea);
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {

    //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
    this.setModelosTrabajo2({
      identificador: "Odata",
      odata: [
        `Grado;$expand=DefinicionGrado`,
        `Año`
      ]
    });

    //Carga la consulta con identificador 'Odata'
    this.cargarDatos("Odata", () => { });
  }

  /**
   * Actualiza la lista de grados por el año seleccionado
   */
  actualizarSeleccionGrado() {

    var anio = _.find(this.anios, (a) => { return a.Id == this.matriculaBusqueda.IdAnio; });

    let tabActual = recaudoUI.tabsManager.getTabActual();

    tabActual.header = `Matrículas ${anio["Etiqueta"]}`;
    recaudoUI.tabsManager.setTab(tabActual["guid"], null, tabActual);
    
    this.gradosPorAnio = _.filter(this.grados, (g) => { return g.IdAnio == this.matriculaBusqueda.IdAnio; });
    //Actualiza el grado seleccionado con respecto al año
    if (this.matriculaBusqueda && this.matriculaBusqueda.IdGrado) {

      var gradoOld = _.find(this.grados, (g) => { return g.Id == this.matriculaBusqueda.IdGrado; });

      var gradoNew = _.find(gradoOld.DefinicionGrado.Grados, (g) => { return g.IdAnio == this.matriculaBusqueda.IdAnio; });

      if (gradoNew)
        this.matriculaBusqueda.IdGrado = gradoNew.Id;
    }

    this.changeDetector.detectChanges();
  }

  /**
   * Realiza la busqueda de las matriculas
   */
  buscar() {

    //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
    this.setModelosTrabajo2({
      identificador: "buscarMatricula",
      servicio: "sMatricula",
      metodo: "buscar",
      consulta: this.matriculaBusqueda
    });

    //Carga solo la consulta con identificador 'Matricula'
    this.cargarDatos("buscarMatricula", () => { });
  }

  /**
   * Obtiene el objeto del año por medio del id
   * @param idAnio 
   */
  getAnioPorId(idAnio: number) {
    return _.find(this.anios, (a) => { return a.Id == idAnio; });
  }

  /**
   * Trae los datos de las consultas realizadas
   * @param data El array u objeto que se esta consultado
   * @param identificador El identificador que ha sigo asignado a cada consulta
   */
  onDatosObtenidos(data: any, identificador: string) {

    //Carga los datos de la consulta de Odata
    if (identificador == "Odata") {

      this.grados = data["Grado"];
      this.anios = data["Año"];
      this.actualizarSeleccionGrado();
    }

    //Carga los datos de la consulta Matricula
    else if (identificador == "buscarMatricula") {

      if (data) {

        _.each(data, function (matricula) {
          matricula.Estudiante.NombreCompleto = matricula.Estudiante.Apellidos + " " + matricula.Estudiante.Nombres;
        });

        this.cvMatriculas = new CollectionView(data);

        this.cvMatriculas.trackChanges = true;

        this.registrarModeloCollectionViews([
          new ModeloCollectionView('Matricula', 'Id', this.cvMatriculas, this.flexMatriculas)
        ]);

        this.matriculas = data;
      }
    }
  }

  /**
   * Abre el tap de la matricula
   * @param item 
   */
  verMatricula(item: any) {

    //Si no trae el registro seleccionado, entonces lo busca el registro seleccionado por medio del flexgrid
    if (!item)
      item = this.obtenerRegistroSeleccionado(this.flexMatriculas);

    console.log("filtro", this.matriculaBusqueda);

    if (!this.verificarSiHayCambios() && item)
      recaudoUI.tabsManager.agregar("Matrícula - " + item["Estudiante"]["Codigo"], "fa-user", MatriculaComponent, { idMatricula: item["Id"], anio: this.getAnioPorId(this.matriculaBusqueda.IdAnio) });
  }

  /**
   * Abre el tap del responsable
   */
  verResponsable() {
    let item = this.obtenerRegistroSeleccionado(this.flexMatriculas);

    if (!this.verificarSiHayCambios() && item)
      recaudoUI.tabsManager.agregar("Responsable - " + item["NombreResponsable"], "fa fa-users", ResponsableComponent, { personaDto: { Id: item["IdResponsable"], Nombre: item["NombreResponsable"] }, anioDto: this.getAnioPorId(this.matriculaBusqueda.IdAnio) });
  }

  /**
   * Permite crear nuevas matriculas
   */
  crearMatricula() {
    console.log("Anio", this.anio);
    recaudoUI.tabsManager.agregar("Asistente Nueva Matrícula", null, AsistenteNuevaMatriculaComponent, { anio: this.getAnioPorId(this.matriculaBusqueda.IdAnio) });
  }

  /**
   * Permite al tap detectar si hay cambios o no, para el momento de ser cerrado
   */
  consultarSiHayCambios() {
    this.hayCambios = this.getTieneCambios();
    return this.hayCambios;
  }

}
