import { Component, OnInit, AfterViewInit, Input, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ModeloService, MatriculaService, ResponsablePagoService, EstudianteService, OtrasPersonasService, CobrosService, RecibosService, ExtractoService, TareaServidorService } from '../../shared/services/services';
import { Año } from '../../helpers/Models';
import { ToastrService } from 'ngx-toastr';
import { WijmoHelper } from 'src/app/helpers/WijmoHelper';
import { TableService } from 'src/app/shared/services/table';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { RecaudoFormHelper } from 'src/app/helpers/RecaudoFormHelper';
import { ModalConfirmacionComponent } from 'src/app/shared/components/modal-confirmacion/modal-confirmacion.component';
import { ModalFormularioComponent } from 'src/app/shared/components/modal-formulario/modal-formulario.component';
import * as _ from 'underscore';
import { ModalAlertaComponent } from 'src/app/shared/components/modal-alerta/modal-alerta.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

declare var bootbox: any;

@Component({
  selector: 'app-administracion',
  templateUrl: './administracion.component.html',
  styleUrls: ['./administracion.component.css']
})
export class AdministracionComponent extends RecaudoFormHelper implements OnInit, AfterViewInit {

  @Input('anio') anio: Año;
  mesSeleccionado: Object;
  tipoRecibo: any;
  meses: Array<any> = [];
  anios: Array<any> = [];
  tiposRecibos: Array<any> = [];
  tiposConceptos: Array<any> = [];

  //Contiene los checks de la tab de importar
  importaDatos = {
    soloMatricula: false,
    actualizaDatos: true,
    actualizaRuta: false,
    verificaResponsable: false,
    actualizaObservaciones: false,
    grado: false,
    idAnioSeleccionado: 0

  };

  //Contiene los el objeto que permite generar el PDF en la Tab generar
  objGenerar = {
    path: "",
    cantidad: "",
    tipoRecibo: "",
    xY: "-3,-14",
    referencias: ""
  };

  log = "";

  @ViewChild(ModalConfirmacionComponent) modalConfirmacion: ModalConfirmacionComponent;
  @ViewChild(ModalAlertaComponent) modalAlerta: ModalAlertaComponent;
  @ViewChild(ModalFormularioComponent) modalFormulario: ModalFormularioComponent;

  //declaración de servicios
  constructor(
    private _toastr: ToastrService,
    private _wjHelper: WijmoHelper,
    private _changeDetector: ChangeDetectorRef,
    private _sTable: TableService,

    private _sModelo: ModeloService,
    private _sAuth: AuthenticationService,
    private _sMatricula: MatriculaService,
    private _sResponsablePago: ResponsablePagoService,
    private _sEstudiante: EstudianteService,
    private _sOtrasPersonas: OtrasPersonasService,
    private _sCobro: CobrosService,
    private _sRecibo: RecibosService,
    public _sExtracto: ExtractoService,
    private _sTarea: TareaServidorService
  ) {
    super(_toastr, _wjHelper, _sModelo, _changeDetector, _sTable, _sAuth, _sMatricula,
      _sResponsablePago, _sEstudiante, _sOtrasPersonas, _sCobro, _sRecibo, _sExtracto, _sTarea);
  }

  ngAfterViewInit(): void {
  }


  ngOnInit() {

    //Le asigna el año actual
    this.importaDatos.idAnioSeleccionado = this.anio.Id;

    let query = [
      `Mes;$filter=IdAnio eq ${this.anio.Id}&$expand=EstadoMes`,
      `TipoRecibo`,
      `TipoConcepto;$filter=IdColegio eq ${this.anio.Colegio.Id}`,
    ];

    //Si es superadministrador permite consultar el año
    if (this.usuarioAuth.Rol == "SuperAdmin")
      query.push("Año");

    //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
    this.setModelosTrabajo2({
      identificador: "oData",
      odata: query
    });

    //Carga la consulta con identificador 'Odata'
    this.cargarDatos("oData", () => { });
  }

  /**
    * Trae los datos de las consultas realizadas
    * @param data El array u objeto que se esta consultado
    * @param identificador El identificador que ha sido asignado a cada consulta
    */
  onDatosObtenidos(data: any, identificador: string) {

    //Asigna los datos que se traen en el Odata
    if (identificador == "oData") {
      this.meses = data['Mes'];
      this.anios = data["Año"];
      this.tiposRecibos = data["TipoRecibo"];
      this.tiposConceptos = data["TipoConcepto"];

      this.tiposConceptos.forEach((t) => {
        t.Valor = t.Id;
      });
    }

  }

  /**
   * Trae los meses que tiene el año seleccionado en la vista principal
   */
  cargarDatoss() {
    this.cargando = true;

    this.sModelo.getQueries([`Mes;$filter=IdAnio eq ${this.anio.Id}&$expand=EstadoMes`], this.anio).subscribe(data => {
      this.meses = data['Mes'];
      this.cargando = false;
    });
  }

  /**
   * Ejecuta la tarea de asignar encargados
   */
  asignarEncargadosDistribucion() {
    this.crearTarea("AsignarEncargadoDistribucion", this.anio.Id, null, null, null, null, null);
    }

    /**
   * Ejecuta la tarea de asignar encargados
   */
    enviarFacturasDian() {
        this.crearTarea("EnviarFacturasDian", this.anio.Colegio.Id, null, null, null, null, null);
    }

    enviarPdfFacturasDian() {
        this.crearTarea("EnviarPdfFacturasDian", this.anio.Colegio.Id, null, null, null, null, null);
    }

    fixRecibosConFactura() {
        this.crearTarea("FixRecibosConFactura", this.anio.Colegio.Id, this.mesSeleccionado["Id"], null, null, null, null);
    }

  /**
   * Ejecuta la tarea de cruzar movimientos
   */
  cruzarMovimientos() {
    this.crearTarea("CruzarMovimientos", this.anio.Colegio.Id, null, null, null, null, null);
  }

  /**
   * Crea la tarea para marcar los recibos impresos
   */
  marcarRecibosImpresos() {
    this.crearTarea("MarcarRecibosImpresos", this.anio.Colegio.Id, null, null, null, null, null);
  }

  /**
   * Ejecuta la tarea para jenerar los recibos prejuridicos
   */
  generarCobrosPrejuridicos() {

    if (this.mesSeleccionado && this.mesSeleccionado["Id"])
      this.crearTarea("GenerarCobrosPrejuridicos", this.mesSeleccionado["Id"], null, null, null, null, null);
  }

  /**
   * Ejecuta la tarea para cerrar los meses
   */
  cerrarMes() {

    if (this.mesSeleccionado && this.mesSeleccionado["Id"] && this.mesSeleccionado["EstadoMes"] &&
      this.mesSeleccionado["EstadoMes"].Nombre == "Facturado")
      this.crearTarea("CerrarMes", this.mesSeleccionado["Id"], null, null, null, null, null);

    //Si en el  estado del mes seleccionado no es facturado  
    else
      this.toastr.error("No es posible cerrar un mes no facturado");
  }

  hacerCierreMes() {

    if (this.mesSeleccionado && this.mesSeleccionado["Id"]) {      
      var fecha = this.anio.Numero + "-" + ("0" + this.mesSeleccionado["Numero"]).slice(-2) + "-01";
      confirm("Si ya existe un cierre será reemplazado ¿Desea continuar?") ? this.crearTarea("HacerCierreMes", fecha, null, null, null, null, null) : null;      
    }
    //Si en el  estado del mes seleccionado no es facturado  
    else
      this.toastr.error("No ha seleccionado unmes");
  }

  /**
   * Ejecuta la tarea para  generar la facturación
   */
  generarFacturacion() {

    if (!this.tipoRecibo || !this.tipoRecibo.Tipo)
      return this.toastr.error("Seleccione el tipo de recibo");

    if (this.mesSeleccionado && this.mesSeleccionado["Id"])
      this.crearTarea("GenerarEstadosCuenta", this.mesSeleccionado["Id"], this.tipoRecibo.Tipo, null, null, null, null);
  }

  /**
   * Ejecuta la tarea para aplicar el descuento por Nómina
   */
  aplicarDescuentosNomina() {

    if (this.mesSeleccionado && this.mesSeleccionado["Id"])
      this.crearTarea("PagarRecibosDescuentoNomina", this.mesSeleccionado["Id"], null, null, null, null, null);
  }

  /**
   * Ejecuta la tarea para aplicar el descuento para los recibos matricula
   */
  generarRecibosMatricula() {
    this.crearTarea("GenerarRecibosMatricula", this.anio.Id, null, null, null, null, null);
  }

  /**
   * Ejecuta la tarea para importar los grupos
   */
  importarGrupo() {
    this.crearTarea("ImportarGrupos", this.importaDatos.idAnioSeleccionado, this.importaDatos.soloMatricula, null, null, null, null);
    }

    aplicarSaldosAFavor() {
        this.crearTarea("AplicarSaldosAFavor", this.importaDatos.idAnioSeleccionado, null, null, null, null, null);
    }

  importarCostos() {
    this.crearTarea("ImportarCostosEducativos", this.importaDatos.idAnioSeleccionado, this.importaDatos.soloMatricula, null, null, null, null);
  }

  importarEstudiantes() {
    this.crearTarea("ImportarEstudiantes", this.importaDatos.idAnioSeleccionado, this.importaDatos.actualizaDatos, this.importaDatos.actualizaRuta, this.importaDatos.verificaResponsable, this.importaDatos.actualizaObservaciones, this.importaDatos.grado);
  }

  importarBecados() {
    this.crearTarea("ImportarBecados", this.importaDatos.idAnioSeleccionado, null, null, null, null, null);
  }

  cargarConceptosPension() {
    this.crearTarea("CargarConceptoPensiones", this.importaDatos.idAnioSeleccionado, null, null, null, null, null);
  }

  marcarRecibosPagosNoVigentes() {
    this.crearTarea("MarcarRecibosPagosNoVigentes", this.mesSeleccionado["Id"], null, null, null, null, null);
  }

  actualizarGrupoEnRecaudo() {
    this.crearTarea("ActualizarGrupoEstudianteEnRecaudo", this.importaDatos.idAnioSeleccionado, null, null, null, null, null);
  }

  /**
   * Asigna los encargados de districubion
   */
  asignarEncargadosDistribucionSuperAdmin() {
    this.crearTarea("AsignarEncargadoDistribucion", this.importaDatos.idAnioSeleccionado, null, null, null, null, null);
  }

  eliminarRecibosRetirados() {
    this.crearTarea("EliminarRecibosRetirados", this.importaDatos.idAnioSeleccionado, null, null, null, null, null);
  }

  ordenarGrupos() {
    this.crearTarea("OrdenarGrupos", this.importaDatos.idAnioSeleccionado, null, null, null, null, null);
  }


  /**
   * Genera le pdf acorde a los datos ingresados
   */
  generarPdfRecibos() {

    let cads = this.objGenerar.xY.split(",");

    let x = parseInt(cads[0]);
    let y = parseInt(cads[1]);

    if (!x || !y || !this.objGenerar.referencias || !this.objGenerar.tipoRecibo || !this.objGenerar.path)
      return;

    this.crearTarea("GenerarPdfRecibos", this.objGenerar.path, this.objGenerar.tipoRecibo, x, y, this.objGenerar.cantidad ? this.objGenerar.cantidad : 0, this.objGenerar.referencias);

  }


  importarPagosSanLukas() {
    this.crearTarea("ImportarPagos", this.importaDatos.idAnioSeleccionado, null, null, null, null, null);
  }

  marcarRecibosPagos() {
    this.crearTarea("MarcarRecibosPagos", this.importaDatos.idAnioSeleccionado, null, null, null, null, null);
  }

  actualizarNumerosRecibos() {
    this.crearTarea("ActualizarNumerosRecibos", this.importaDatos.idAnioSeleccionado, null, null, null, null, null);
  }

  cargarIntereses() {
    this.crearTarea("CargarIntereses", this.mesSeleccionado["Id"], null, null, null, null, null);
  }

  generarExogena() { }

  revisarExogena() { }

  convertirRecibosMatriculaEnFactura() {
    this.crearTarea("ConvertirRecibosMatriculaEnFacturas", this.importaDatos.idAnioSeleccionado, null, null, null, null, null);
    }

    mostrarModalConvertirRecibos(mat){

      var tRec = "";
      if(mat)
        tRec = "ReciboMatricula";

        var t = this;

      bootbox.dialog({title:"Convertir Recibos en Facturas", message: 
      `<div>
        <div class='col-md-12'>Tipo Recibo:<br/> <input class='form-control input-sm' id='fact_tipo' value='${tRec}' placeholder='Todos'/> </div>
        <div class='col-md-12'>Mes:  <br/>
          <label class="radio-inline">
            <input type="radio" name="fac_mes" ${mat ? 'disabled' : ''} checked value='0'>Automático
          </label>&nbsp;&nbsp;&nbsp;&nbsp;
          <label class="radio-inline">
            <input type="radio" name="fac_mes" ${mat ? 'disabled' : ''} value='1'>Actual
          </label>
        </div>
        <div class='col-md-12'>Tipo de Pago:  <br/>
          <label class="radio-inline">
            <input type="radio" name="fac_solobanco" checked value='0'>Cualquiera
          </label>&nbsp;&nbsp;&nbsp;&nbsp;
          <label class="radio-inline">
            <input type="radio" name="fac_solobanco" value='1'>Sólo Banco
          </label>
        </div>
        <div class='col-md-12'>Recibos: <br/> 
          <label class="radio-inline">
            <input type="radio" name="fac_solopago" checked value='1'>Sólo pagos
          </label>&nbsp;&nbsp;&nbsp;&nbsp;
          <label class="radio-inline">
            <input type="radio" name="fac_solopago" value='0'>Todos
          </label>
        </div>
        <div class='col-md-12'>Id Recibos: <br/> <input class='form-control input-sm' id='fact_idRecibos' placeholder='Opcional'/> </div>
      </div>`, buttons: {
            ok: {
                  label: "Aceptar",
                  callback:  function() { 
                  
                    var tipo = window.document.getElementById('fact_tipo')["value"];;

                    var ids = window.document.getElementById('fact_idRecibos')["value"];;

                    var soloBanco = window.document.querySelector('input[name="fac_solobanco"]:checked')["value"];

                    var soloPagos = window.document.querySelector('input[name="fac_solopago"]:checked')["value"];

                    var mes = window.document.querySelector('input[name="fac_mes"]:checked')["value"];

                    console.log(tipo, soloBanco, soloPagos, tipo, ids, typeof(soloBanco));                    

                    if(mat)
                      t.crearTarea("ConvertirRecibosMatriculaEnFacturas", tipo, t.importaDatos.idAnioSeleccionado, soloBanco == "1", ids, null, null);
                    else
                      t.crearTarea("ConvertirRecibosEnFacturas", tipo, mes == '1' ? t.mesSeleccionado["Id"] : null,  soloPagos == "1", soloBanco == "1", ids, null);
                  
              } 
            },
            cancel:{
              label:"Cancelar",
              callback:{

              }
            }
          }
      });
    }

    /**
  * Crea la tarea para marcar los recibos impresos
  */
    verificarConsecutivos() {
        this.crearTarea("VerificarConsecutivos", this.anio.Colegio.Id, null, null, null, null, null);
    }

    verificarCufe() {
        this.crearTarea("VerificarCufe", this.anio.Colegio.Id, null, null, null, null, null);
    }


  /**
   * Genera el mensaje al cual se va a mostrar al usuario cuando la tarea sea finalizada
   * @param tareaServidor 
   */
  public mostrarMensajeTareaRevisada(tareaServidor) {

    let mensaje = "";
    let titulo = "";

    if (tareaServidor["Estado"] == 1) {

      titulo = "Éxito";

        if (tareaServidor["Nombre"] == "AsignarEncargadoDistribucion") {
            mensaje = `Encargados asignados: ${tareaServidor["Resultado"]}`;
            this.log += `\nEncargados asignados: ${tareaServidor["Resultado"]} \n\n`;
        }

        else if (tareaServidor["Nombre"] == "CruzarMovimientos") {
            mensaje = `Movimientos cruzados: ${tareaServidor["Resultado"]}`;
            this.log += `\nMovimientos cruzados: ${tareaServidor["Resultado"]} \n\n`;
        }

        else if (tareaServidor["Nombre"] == "MarcarRecibosImpresos") {
            mensaje = `Recibos marcados: ${tareaServidor["Resultado"]}`;
            this.log += `\nRecibos marcados: ${tareaServidor["Resultado"]} \n\n`;
        }

        else if (tareaServidor["Nombre"] == "GenerarCobrosPrejuridicos") {
            mensaje = `Recibos generados: ${tareaServidor["Resultado"]} Recibos generados`;
            this.log += `\nRecibos generados: ${tareaServidor["Resultado"]} Recibos generados \n\n`;
        }

        else if (tareaServidor["Nombre"] == "CerrarMes") {
            let mes = _.find(this.meses, (m) => { return m.Id == tareaServidor["Parametro1"]; });
            mensaje = `Mes cerrado ${mes.Nombre}. Cobros creados: ${tareaServidor["Resultado"]} Recibos generados`;
            this.log += `\nMes cerrado ${mes.Nombre}. Cobros creados: ${tareaServidor["Resultado"]} Recibos generados \n\n`;
        }

        else if (tareaServidor["Nombre"] == "GenerarEstadosCuenta") {
            mensaje = `"Recibos generados: ${tareaServidor["Resultado"]} Recibos generados`;
            this.log += `\nRecibos generados: ${tareaServidor["Resultado"]} Recibos generados \n\n`;
        }

        else if (tareaServidor["Nombre"] == "PagarRecibosDescuentoNomina") {
            mensaje = `Recibos descontados por nómina: ${tareaServidor["Resultado"]}`;
            this.log += `\nRecibos descontados por nómina: ${tareaServidor["Resultado"]} \n\n`;
        }

        else if (tareaServidor["Nombre"] == "GenerarRecibosMatricula") {
            mensaje = `Recibos de matrícula generados: ${tareaServidor["Resultado"]}`;
            this.log += `\nRecibos de matrícula generados: ${tareaServidor["Resultado"]} \n\n`;
        }


        //Parte de importación        
        else if (tareaServidor["Nombre"] == "ImportarGrupos") {
            mensaje = `Grupos importados: ${tareaServidor["Resultado"]}`;
            this.log += `\nGrupos importados: ${tareaServidor["Resultado"]} \n\n`;
        }

        else if (tareaServidor["Nombre"] == "ImportarCostosEducativos") {
            mensaje = `Costos educativos importados: ${tareaServidor["Resultado"]}`;
            this.log += `\nCostos educativos importados: ${tareaServidor["Resultado"]} \n\n`;
        }

        else if (tareaServidor["Nombre"] == "ImportarEstudiantes") {
            mensaje = `Estudiantes importados: ${tareaServidor["Resultado"]}`;
            this.log += `\nEstudiantes importados: ${tareaServidor["Resultado"]} \n\n`;
        }

        else if (tareaServidor["Nombre"] == "ImportarBecados") {
            mensaje = `Importar Becados Finalizado`;
            this.log += `\nImportar Becados Finalizado \n\n`;
        }

        else if (tareaServidor["Nombre"] == "CargarConceptoPensiones") {
            mensaje = `Cargar Concepto Pensiones`;
            this.log += `\nCargar Concepto Pensiones \n\n`;
        }

        else if (tareaServidor["Nombre"] == "CargarConceptos") {
          mensaje = `Cargar Concepto ${tareaServidor["Parametro2"]} <br>`;
          mensaje += `Número de filas afectadas ` + tareaServidor['Resultado'] + '<br>';

          this.log += `\nCargar Concepto ${tareaServidor["Parametro2"]}\n`;
          this.log += `Número de filas afectadas ` + tareaServidor['Resultado'] + '\n\n';
        }
        else if (tareaServidor["Nombre"] == "ActualizarResponsableRecibos") {
          mensaje = `Actualizar Responsable Recibos ${tareaServidor["Parametro2"]} <br>`;
          mensaje += `Número de filas afectadas ` + tareaServidor['Resultado'] + '<br>';

          this.log += `\nActualizar Responsable Recibos ${tareaServidor["Parametro2"]}\n`;
          this.log += `Número de filas afectadas ` + tareaServidor['Resultado'] + '\n\n';
        }
        else if (tareaServidor["Nombre"] == "MarcarRecibosPagosNoVigentes") {
            mensaje = `Marcar Recibos No Vigentes: ${tareaServidor["Resultado"]}`;
            this.log += `\nMarcar Recibos No Vigentes: ${tareaServidor["Resultado"]} \n\n`;
        }
        //este no
        else if (tareaServidor["Nombre"] == "ActualizarGrupoEstudianteEnRecaudo") {
            mensaje = `Actualizar Grupos Estudiantes En Recaudo Finalizado <br>`;
            var total = 0;
            var actualizados = [];
            //si llega algo a resultado muestra la cantidad de actualizados
            if(tareaServidor["Resultado"]){
              actualizados = tareaServidor["Resultado"].split(';');
              //Asignados;Cambiados;Desasignados;Retirados;
              total = parseInt(actualizados[0]) + parseInt(actualizados[1]) + parseInt(actualizados[2]) + parseInt(actualizados[3]);

              mensaje += `Estudiantes Asignados: ` + actualizados[0] + '<br>';
              mensaje += `Estudiantes Cambiados: ` + actualizados[1] + '<br>';
              mensaje += `Estudiantes Desasignados: ` + actualizados[2] + '<br>';
              mensaje += `Estudiantes Retirados: ` + actualizados[3] + '<br>';
              mensaje += `Total de estudiantes actualizados: ` + total;
            }
              
            this.log += `\nActualizar Grupos Estudiantes En Recaudo Finalizado \n\n`;
            if(actualizados.length > 0){
              this.log += `Estudiantes Asignados: ` + actualizados[0] + '\n';
              this.log += `Estudiantes Cambiados: ` + actualizados[1] + '\n';
              this.log += `Estudiantes Desasignados: ` + actualizados[2] + '\n';
              this.log += `Estudiantes Retirados: ` + actualizados[3] + '\n';
              this.log += `Total de estudiantes actualizados: ` + total + '\n';
            }
        }

        else if (tareaServidor["Nombre"] == "EliminarRecibosRetirados") {
            mensaje = `Recibos retirados que fuerón eliminados: ${tareaServidor["Resultado"]}`;
            this.log += `\nRecibos retirados que fuerón eliminados: ${tareaServidor["Resultado"]} \n\n`;
        }

        else if (tareaServidor["Nombre"] == "OrdenarGrupos") {
            mensaje = `Grupos ordenados`;
            this.log += `\nGrupos ordenados \n\n`;
        }

        //Parte de Otros
        else if (tareaServidor["Nombre"] == "ActualizarNumerosRecibos") {
            mensaje = `Se ha actualizado los números de los recibos`;
            this.log += `\nSe ha actualizado los números de los recibos \n\n`;
        }

        else if (tareaServidor["Nombre"] == "MarcarRecibosPagos") {
            mensaje = `MarcarRecibosPagos Finalizado`;
            this.log += `\nMarcarRecibosPagos Finalizado \n\n`;
        }

        else if (tareaServidor["Nombre"] == "ImportarPagos") {
            console.log("?????");
        }

        //Parte de Generar
        else if (tareaServidor["Nombre"] == "generarPdfRecibos") {
            mensaje = `${tareaServidor["Resultado"]}`;
            this.log += `\n${tareaServidor["Resultado"]}\n\n`;
        }
        //Parte de Generar
        else {
            mensaje = `${tareaServidor["Resultado"]}`;
            this.log = `\n${tareaServidor["Resultado"]}\n\n` + this.log;
      }
    }

    else {
      titulo = "Error";

      mensaje = tareaServidor["Excepcion"];
      this.log += `\nError: ${tareaServidor["Excepcion"]}\n\n`;
    }

    this.modalAlerta.abrirConfirmar({ textHeader: titulo, textBody: mensaje });
    this.modalAlerta.modalRef.result.then((dataResult) => {
    }, (info) => {
      console.log("Info", info);
    });
  }

  cargarConceptos(){
    this.modalFormulario.abrirIngresarValor({
      titulo: "Carga de conceptos",
      input: [
        { property: "CargarConcepto", label: "Seleccione un Tipo de concepto", type: "select", required: true, messageErrorRequired: `El concepto debe estar diligenciado` }
      ],
      valor: {
        arraySelectCargarConcepto: this.tiposConceptos,
        "CargarConcepto": ""
      }
    });

    this.modalFormulario.modalRef.result.then((dataValor) => {
      console.log(dataValor);
      var name = this.tiposConceptos.find((t) => { return t.Id == dataValor["CargarConcepto"] }).Nombre;

      this.crearTarea("CargarConceptos", this.importaDatos.idAnioSeleccionado, name, null, null, null, null);
    }, (info) => {
      console.log("Info", info);
    });
  }

  actualizarResponsableRecibos(){    
      this.crearTarea("ActualizarResponsableRecibos", this.importaDatos.idAnioSeleccionado, null, null, null, null, null);
  }

}
