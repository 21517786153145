import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [

  {
    path: '/principal',
    title: 'Principal',
    icon: 'mdi mdi-home',
    class: '',
    extralink: false,
    submenu: [],
    tab: "Principal",
  },
  {
    path: '/principal',
    title: 'Buscar Matrículas',
    icon: 'fa fa-address-book-o',
    class: '',
    extralink: false,
    submenu: [],
    tab: "Matrículas",
  },
  {
    path: '/principal',
    title: 'Buscar Responsables',
    icon: 'fa fa-search',
    class: '',
    extralink: false,
    submenu: [],
    tab: "BuscarResponsables",
  },
  {
    path: '/principal',
    title: 'Recaudo',
    icon: 'fa fa-barcode',
    class: '',
    extralink: false,
    submenu: [],
    tab: "Recaudo",
  },
  {
    path: '/principal',
    title: 'Extractos',
    icon: 'fa fa-archive',
    class: '',
    extralink: false,
    submenu: [],
    tab: "Extractos",
    },
    {
        path: '/principal',
        title: 'Revisiones',
        icon: 'fa fa-check',
        class: '',
        extralink: false,
        submenu: [],
        tab: "Revisiones",
    },
  {
    path: '/principal',
    title: 'Informes',
    icon: 'fa fa-folder-open-o',
    class: '',
    extralink: false,
    submenu: [],
    tab: "Informes",
  },
  /*{
    path: '',
    title: 'Año Actual ',
    icon: 'mdi mdi-calendar-blank',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/principal',
        title: 'Principal',
        icon: 'mdi mdi-home',
        class: '',
        extralink: false,
        submenu: [],
        tab: "Principal",
      },
      {
        path: '/principal',
        title: 'Buscar Matrículas',
        icon: 'fa fa-address-book-o',
        class: '',
        extralink: false,
        submenu: [],
        tab: "Matrículas",
      },
      {
        path: '/principal',
        title: 'Buscar Responsables',
        icon: 'fa fa-search',
        class: '',
        extralink: false,
        submenu: [],
        tab: "BuscarResponsables",
      },
      {
        path: '/principal',
        title: 'Recaudo',
        icon: 'fa fa-barcode',
        class: '',
        extralink: false,
        submenu: [],
        tab: "Recaudo",
      },
      {
        path: '/principal',
        title: 'Administración',
        icon: 'fa fa-newspaper-o',
        class: '',
        extralink: false,
        submenu: [],
        tab: "Administracion",
      }
    ]
  },*/
  {
    path: '',
    title: 'General',
    icon: 'fa fa-university',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/principal',
        title: 'Panel de Control',
        icon: 'fa fa-newspaper-o',
        class: '',
        extralink: false,
        submenu: [],
        tab: "Administracion",
      },
      {
        path: '/principal',
        title: 'Buscar Recibos',
        icon: 'fa fa-search-plus',
        class: '',
        extralink: false,
        submenu: [],
        tab: "BuscarRecibos",
      },
      {
        path: '/principal',
        title: 'Estudiantes',
        icon: 'fa fa-user-circle-o',
        class: '',
        extralink: false,
        submenu: [],
        tab: "Estudiantes",
      },
      {
        path: '/principal',
        title: 'Otras Personas',
        icon: 'fa fa-users',
        class: '',
        extralink: false,
        submenu: [],
        tab: "OtrasPersonas",
      },
    ]
  },
  {
    path: '',
    title: 'Administración',
    icon: 'fa fa-wrench',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/principal',
        title: 'Usuarios',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: [],
        tab: "Usuarios",
        rolesPermitidos: { "SuperAdmin": "SuperAdmin", "Administrador": "Administrador" }
      },
      {
        path: '/principal',
        title: 'Tarea Servidor',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: [],
        tab: "TareaServidor",
        rolesPermitidos: { "SuperAdmin": "SuperAdmin" }
      },
      {
        path: '/principal',
        title: 'Colegios',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: [],
        tab: "Colegios",
      },
      {
        path: '/principal',
        title: 'Años',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: [],
        tab: "Años",
      },
      {
        path: '/principal',
        title: 'Parámetros',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: [],
        tab: "Parametros",
      },
      {
        path: '/principal',
        title: 'Administración informes',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: [],
        tab: "AdministracionInformes",
        rolesPermitidos: { "SuperAdmin": "SuperAdmin", "Administrador": "Administrador" }
      },
    ]
  },
  {
    path: '/principal',
    title: 'Salir',
    icon: 'fa fa-sign-out',
    class: '',
    extralink: false,
    submenu: [],
    tab: "Salir"
  },
  /*Menú de pruebas*/
  {
    path: '',
    title: 'UI Components',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    submenu: [],
    rolesPermitidos: { "SuperAdmin": "SuperAdmin" }
  },
  {
    path: '',
    title: 'Component',
    icon: 'mdi mdi-bullseye',
    class: 'has-arrow',
    extralink: false,
    rolesPermitidos: { "SuperAdmin": "SuperAdmin" },
    submenu: [
      {
        path: '/component/accordion',
        title: 'Accordion',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/alert',
        title: 'Alert',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/carousel',
        title: 'Carousel',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/dropdown',
        title: 'Dropdown',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/modal',
        title: 'Modal',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/pagination',
        title: 'Pagination',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/poptool',
        title: 'Popover & Tooltip',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/progressbar',
        title: 'Progressbar',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/rating',
        title: 'Ratings',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/tabs',
        title: 'Tabs',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/timepicker',
        title: 'Timepicker',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/buttons',
        title: 'Button',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/cards',
        title: 'Card',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/Icons',
        title: 'Icons',
        icon: 'mdi mdi-clippy',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  }
  /*Fin menú de pruebas*/
];
