import { ChangeDetectionStrategy, Component, OnInit, Input, ChangeDetectorRef, ViewChild } from '@angular/core';
import { RecaudoFormHelper } from 'src/app/helpers/RecaudoFormHelper';
import { Año } from 'src/app/helpers/Models';
import { ToastrService } from 'ngx-toastr';
import { WijmoHelper, ModeloCollectionView } from 'src/app/helpers/WijmoHelper';
import { ModeloService, MatriculaService, ResponsablePagoService, EstudianteService, OtrasPersonasService, CobrosService, RecibosService, ExtractoService, TareaServidorService } from 'src/app/shared/services/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CollectionView } from 'wijmo/wijmo';
import { WjFlexGrid, WjFlexGridColumn } from 'wijmo/wijmo.angular2.grid';
import { AutoSizeMode } from 'wijmo/wijmo.grid';
import { recaudoUI } from '../../helpers/RecaudoUI';
import * as _ from 'underscore';
import { Parametro } from 'src/app/_models/Parametro';
import { Informe } from 'src/app/_models/informe';
import { Router } from '@angular/router';
import { TableService } from 'src/app/shared/services/table';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
  selector: 'app-informes',
  templateUrl: './informes.component.html',
  styleUrls: ['./informes.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InformesComponent extends RecaudoFormHelper implements OnInit {

  anio: Año = recaudoUI.getAñoTrabajo();

  @Input('informes') public informes: Informe[];
  @Input('parametros') public parametros: Parametro[];

  //CollectionViews
  cvInformes: CollectionView;

  //Referencia a los controles de wijmo
  @ViewChild('flexInformes') flexInformes: WjFlexGrid;

  //Filtro del grid
  filtro = {
    buscar: ""
  };
  hayCambios = false;

  constructor(
    private routes: Router,
    private _toastr: ToastrService,
    private _wjHelper: WijmoHelper,
    private _changeDetector: ChangeDetectorRef,
    private _modalService: NgbModal,
    private _sTable: TableService,

    private _sModelo: ModeloService,
    private _sAuth: AuthenticationService,
    private _sMatricula: MatriculaService,
    private _sResponsablePago: ResponsablePagoService,
    private _sEstudiante: EstudianteService,
    private _sOtrasPersonas: OtrasPersonasService,
    private _sCobro: CobrosService,
    private _sRecibo: RecibosService,
    public _sExtracto: ExtractoService,
    private _sTarea: TareaServidorService
  ) {
    super(_toastr, _wjHelper, _sModelo, _changeDetector, _sTable, _sAuth, _sMatricula,
      _sResponsablePago, _sEstudiante, _sOtrasPersonas, _sCobro, _sRecibo, _sExtracto,_sTarea);
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {


    //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
    this.setModelosTrabajo2({
      identificador: "Odata",
      odata: [
        'Informe;$filter=VisibleMenu eq true',
        'Parametro;$filter=IdColegio eq ' + this.anio.Colegio.Id
      ]
    });

    //Carga la consulta con identificador 'Odata'
    this.cargarDatos("Odata", () => { });

  }

  /**
   * Trae los datos de las consultas realizadas
   * @param data El array u objeto que se esta consultado
   * @param identificador El identificador que ha sigo asignado a cada consulta
   */
  onDatosObtenidos(data: any, identificador: string) {

    //Carga los datos de la consulta de Odata
    if (identificador == "Odata") {

      this.informes = data['Informe'];
      this.parametros = data['Parametro'];

      this.cvInformes = new CollectionView(data['Informe']);

      this.cvInformes.trackChanges = true;

      //Realiza una copia del flexgrid para saber si hubieron cambios
      this.registrarModeloCollectionViews([
        new ModeloCollectionView('Informe', 'Id', this.cvInformes, this.flexInformes)
      ]);
    }
  }

  /**
   * Actualiza las columnas que va tener el grid
   * @param column dato de la columna
   * @param modelo nombre del primer parametro en el new ModeloCollectionView
   */
  onAjusteColumna(column: WjFlexGridColumn, modelo: string) {
    if (modelo == "Informe") {
      switch (column.binding) {
        default:
      }
    }
  }

  /**
   * Abre el tap del registro seleccionado para editarlo
   * @param registro
   */
  editarInforme(item: Informe) {

    let parametro: Parametro = _.find(this.parametros, (p: Parametro) => { return p.Nombre == 'NombreDB'; });


    window.open("https://apps.vpsnotas.com/RecaudoInf/Informes/Informe.aspx?DB=" + parametro.Valor + "&Nombre=" + item.Nombre, '_blank');

    //let url = this.routes.createUrlTree([`/RecaudoInf/Informes`, { DB: parametro.Valor, Nombre: item.Nombre }]);//?DB=${parametro.Valor}&Nombre=${item.Nombre}`]);

    //window.open(url.toString(), '_blank');
  }

  /**
   * Permite al tap detectar si hay cambios o no, para el momento de ser cerrado
   */
  consultarSiHayCambios() {
    this.hayCambios = this.getTieneCambios();
    return this.hayCambios;
  }
}
