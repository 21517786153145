import { Directive, ElementRef, OnInit } from '@angular/core';
import { recaudoUI } from '../../../helpers/RecaudoUI'
import * as $ from 'jquery';

@Directive({
  selector: '[appResizeHeightToWindow]'
})

export class ResizeHeightToWindowDirective implements OnInit {

  constructor(private e: ElementRef) { }

  resize(e) {
    if (e)
    //se restan 30px para evitar que salga scroll vertical
      $(e).css("height", ($(window).height() - $(e).offset().top) - 30); //.css("max-height", ($(window).height() - $(e).offset().top) - 30);
     else 
      console.error("appResizeHeightToWindow: elemento inválido " + e);
  }

  //implementar: en móviles no aplica esta directiva.
  ngOnInit(): void {
    this.resize(this.e.nativeElement);
    
    $(window).on('resize', () => {
      //mejora de rendimiento: evita que los elementos ocultos se re-dimensionen también.
      //ej: elementos alojados en tabs diferentes a la actual cuentan como ocultos.
      if (!$(this.e.nativeElement).is(":hidden")) {
        //si es móvil las grid ocupan el 70% de la pantalla
        recaudoUI.isMobile() || $(window).width() < 768? $(this.e.nativeElement).css("height", ($(window).height() / 100) * 70).css("max-height", ($(window).height() / 100) * 70) : this.resize(this.e.nativeElement);
      }
    });
  }
}
