export class ColaFunciones {
  private fncs: Array<Function> = [];
  private fnc: Function = new Function();
  private ejecutandoCola: boolean = false;

  /**
   * Agrega una función a la lista
   * @param fnc
   */
  agregar(fnc: Function) {
    this.fncs.push(fnc);
  }

  /**
   * Invoca la siguiente función registrada en el lista
   * @param argumentos Argumentos de invocación de la siguiente función.
   */
  ejecutar = (argumentos: Array<any> = []) => {
    this.ejecutandoCola = true;

    if (this.fncs.length) {
      this.fnc = this.fncs.shift();
      this.fnc();
    } else
      this.terminar();
  }


  /**
   * limpia los datos de trabajo
   */
  dispose() {
    if (this.ejecutandoCola) 
      throw "Se ha invocado ColaFunciones.dispose() cuando aún habían funciones pendientes por ejecutar. las colas deben invocar ejecutar() para iniciar y terminar() para terminar."; 
    
    this.fncs = [];
    this.fnc = new Function();
  }
  

  /**
   * limpia las funciones agregadas
   */
  terminar() {
    this.ejecutandoCola = false;

    this.dispose();
  }
}
