import { TabsManager } from './TabsManager';
import { Año } from './Models';
import * as moment from 'moment';
import * as _ from 'node_modules/underscore';
import { isDevMode } from '@angular/core';
import { MatriculaService } from '../shared/services/services';
import { Router } from '@angular/router';
/**
 * Almacena variables que controlan la interfaz de usuario
 */
class RecaudoUI {
  private static _instance: RecaudoUI;
  //se usa para mostrar el usuario en la interfaz
  public usuarioApp: string = "";

  //se usa en los request para traer datos del año actual
  private año: Año;

  private fecha;

  //se usa en los componentes que requieran administración de pestañas
  public tabsManager: TabsManager = new TabsManager();
  //almacena funciones que se ejecutan cuando cambia el año de trabajo
  private añoCambiadoCallbacks: Array<Function> = [];

  private constructor() {
    //...
  }

  public static get Instance() {
    // Do you need arguments? Make it a regular method instead.
    return this._instance || (this._instance = new this());
  }

  getBaseUrl() {
    //lógica para resolver url base
    //return "http://localhost:64717/";
    return "https://recaudoapp.vpsnotas.com/"; 
    //return "";
  }

  /**
   * Obtiene la versión actual de la aplicación
   */
  getVersion() {
    return "1.44";
  }

  /**
   * Retorna false si se está ejecutando en el local host
   */
  getProyectoEstaEnProduccion() {
    if (this.getBaseUrl().indexOf("http://localhost") != -1)
      return false;

    return true;
  }

  isMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  /**
   * devuelve una copia libre de referencias del objeto especificado.
   * @param obj Objeto a copiar
   */
  copyObject(obj: object): object {
    return JSON.parse(JSON.stringify(obj));
  }

  /**
   * Convierte el tipo que entra a strig de JSON
   * @param items 
   */
  convertirAString(items) {
    return JSON.stringify(items);
  }

  /**
   * realiza el formato de las fechas para la zona horaria actual.
   * @param fecha
   * @param format: el formato de la fecha ('f' => "YYYY-MM-DD", null => "YYYY-MM-DD hh:mm")
   */
  formatDate(fecha: any, format?: string) {
    //formato a las fechas
    if (fecha && !format)
      fecha = moment(fecha).format("YYYY-MM-DD hh:mm");
    else if (fecha && format.toLowerCase() == "f")
      fecha = moment(fecha).format("YYYY-MM-DD");

    return fecha;
  }

  /**
   * Genera la diferencia entre dos fechas
   */
  fechaEsMayor(menor, mayor) {

    return moment(menor).isSameOrBefore(mayor);
  }

  /**
   * Permite formatear un valor a la moneda que se utiliza ej: 1234 = $1.234
   * @param valor Valor a convertir
   * @param simboloMoneda ej: $, € 
   */
  formatearMoneda(valor: number, simboloMoneda?: string) {
    return (simboloMoneda ? simboloMoneda : '$') + valor.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  /**
   * Obtiene la fecha del servidor
   */
  getFechaActual() {
    let f = this.fecha ? moment(this.fecha).format("YYYY-MM-DD hh:mm") : this.fecha;
    return f;
  }

  /**
   * Edita la fecha
   */
  setFechaActual(fecha) {
    this.fecha = fecha
  }

  /**
   * obtiene el año de trabajo actual
   */
  getAñoTrabajo() {
    return this.copyObject(this.año) as Año;
  }

  /**
   * cambia el año de trabajo actual
   */
  setAñoTrabajo(año: Año) {
    this.año = año;

    this.añoCambiado();

    if (!this.getProyectoEstaEnProduccion)
      console.info("cambio de año." + año.Etiqueta);
  }

  private añoCambiado() {
    _.each(this.añoCambiadoCallbacks, (callback: Function) => {
      callback();
    });
  }

  /**
   * se invoca cundo cambia el año de trabajo
   */
  onAñoCambiado(fnc: Function) {
    this.añoCambiadoCallbacks.push(fnc);
  }
}

export const recaudoUI = RecaudoUI.Instance;
