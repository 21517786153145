import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NgbModalRef, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { modalAlerta } from 'src/app/_models/modals.model';

@Component({
  selector: 'app-modal-alerta',
  templateUrl: './modal-alerta.component.html',
  styleUrls: ['./modal-alerta.component.css']
})
export class ModalAlertaComponent implements OnInit {

  //Variables que se utiliza en la modal de confirmación
  @ViewChild('alertaModal') private alertaModal;
  private configuracion: modalAlerta;

  modalRef: NgbModalRef;

  constructor(
    private _modalService: NgbModal,
    private toastr: ToastrService,
    public changeDetector: ChangeDetectorRef,
    private config: NgbModalConfig  
  ) { }

  ngOnInit() {
  }

  /**
   * modal de confirmacion, trae dos botones Cancelar, aceptar
   * @param configuracion
   */
  public abrirConfirmar(configuracion: modalAlerta): NgbModalRef {

    this.configuracion = configuracion;

    //Abre la modal con su respectiva configuración
    //this.config.backdrop = "static";
    //this.config.keyboard = false;
    this.modalRef = this._modalService.open(this.alertaModal, { size: "lg" });
    return this.modalRef;
  }

  /**
   * Cierra la modal
   */
  private cerrarModal() {
    this.modalRef.close();
  }

}
