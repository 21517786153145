import { Component, OnInit, Input, ChangeDetectorRef, ViewChild } from '@angular/core';
import { RecaudoFormHelper } from 'src/app/helpers/RecaudoFormHelper';
import { ToastrService } from 'ngx-toastr';
import { WijmoHelper, ModeloCollectionView } from 'src/app/helpers/WijmoHelper';
import { ModeloService, MatriculaService, ResponsablePagoService, EstudianteService, OtrasPersonasService, CobrosService, RecibosService, ExtractoService, TareaServidorService } from 'src/app/shared/services/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Persona } from 'src/app/_models/persona';
import { Año, ObjetoModelo } from 'src/app/helpers/Models';
import { CollectionView } from 'wijmo/wijmo';
import { WjFlexGrid, WjFlexGridColumn } from 'wijmo/wijmo.angular2.grid';
import { recaudoUI } from 'src/app/helpers/RecaudoUI';
import { ResponsableComponent } from '../responsable/responsable.component';
import { TableService } from 'src/app/shared/services/table';
import * as _ from 'underscore';
import { DataMap } from 'wijmo/wijmo.grid';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
  selector: 'app-persona',
  templateUrl: './persona.component.html',
  styleUrls: ['./persona.component.css']
})
export class PersonaComponent extends RecaudoFormHelper implements OnInit {

  anio = recaudoUI.getAñoTrabajo();
  @Input('idPersona') public idPersona: number;

  personaObjetoModelo = new ObjetoModelo('Persona');

  responsablesPago: any[];
  persona: object;
  anios: Año[];
  hayCambios = false;

  //CollectionViews
  cvResponsablePago: CollectionView;

  //Referencia a los controles de wijmo
  @ViewChild('flexResponsablePago') flexResponsablePago: WjFlexGrid;

  constructor(
    private _toastr: ToastrService,
    private _wjHelper: WijmoHelper,
    private _changeDetector: ChangeDetectorRef,
    private _modalService: NgbModal,
    private _sTable: TableService,

    private _sModelo: ModeloService,
    private _sAuth: AuthenticationService,
    private _sMatricula: MatriculaService,
    private _sResponsablePago: ResponsablePagoService,
    private _sEstudiante: EstudianteService,
    private _sOtrasPersonas: OtrasPersonasService,
    private _sCobro: CobrosService,
    private _sRecibo: RecibosService,
    public _sExtracto: ExtractoService,
    private _sTarea: TareaServidorService
  ) {
    super(_toastr, _wjHelper, _sModelo, _changeDetector, _sTable, _sAuth, _sMatricula,
      _sResponsablePago, _sEstudiante, _sOtrasPersonas, _sCobro, _sRecibo, _sExtracto,_sTarea);
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {

    //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
    this.setModelosTrabajo2({
      identificador: "Odata",
      odata: [
        `Persona;$filter=Id eq ${this.idPersona}`,
        `ResponsablePago;$filter=IdPersona eq ${this.idPersona}&$expand=Año`,
        `Año`
      ]
    });

    //Carga la consulta con identificador 'Odata'
    this.cargarDatos("Odata", () => { });
  }


  /**
   * Trae los datos de las consultas realizadas
   * @param data El array u objeto que se esta consultado
   * @param identificador El identificador que ha sigo asignado a cada consulta
   */
  onDatosObtenidos(data: any, identificador: string) {

    //Carga los datos de la consulta de Odata
    if (identificador == "Odata") {

      this.anios = data["Año"];
      this.responsablesPago = data["ResponsablePago"];

      this.cvResponsablePago = new CollectionView(this.responsablesPago);
      this.cvResponsablePago.trackChanges = true;
      this.cvResponsablePago.newItemCreator = () => {
        return { IdAño: this.anio.Id, IdPersona: this.idPersona, DescuentoPorNomina: 0, NombreAnio: this.anio.Etiqueta };
      }

      this.persona = _.first(data["Persona"]);
      this.personaObjetoModelo.objeto = this.persona;
      this.personaObjetoModelo.actualizarCopia();

      this.registrarObjetosModelo([this.personaObjetoModelo]);

      //Crea una copia del flexgrid para saber si hubo cambios
      this.registrarModeloCollectionViews([
        new ModeloCollectionView('ResponsablePago', 'Id', this.cvResponsablePago, this.flexResponsablePago)
      ]);
    }
  }

  /**
   * Permite crear el año si no existe el año actual
   */
  crearAnio() {
    //Falta validar con el año del servidor
    if (!_.find(this.cvResponsablePago.sourceCollection, (a) => { return a.Año.Etiqueta == this.anio.Etiqueta }))
      this.agregar(this.flexResponsablePago, this.cvResponsablePago);

    else
      this.toastr.error("Ya se creo el responsable de pago para el año " + this.anio.Etiqueta);
  }

  /**
  * Actualiza las columnas que va tener el grid
  * @param column dato de la columna
  * @param modelo nombre del primer parametro en el new ModeloCollectionView
  */
  onAjusteColumna(column: WjFlexGridColumn, modelo: string) {
    if (modelo == "ResponsablePago") {
      switch (column.binding) {
        case "IdAño":
          column.dataMap = new DataMap(this.anios, "Id", "Etiqueta");
          break;
        default:
      }
    }
  }

  /**
   * Muestra la lista de responsables
   * @param esParaEdicion 
   */
  verResponsable() {

    let item = this.obtenerRegistroSeleccionado(this.flexResponsablePago);

    if (!this.verificarSiHayCambios() && item) {
      let anioActual = _.find(this.anios, (a) => { return a.Id == item["IdAño"]; });
      recaudoUI.tabsManager.agregar("Responsable - " + this.persona["Nombre"], "fa fa-users", ResponsableComponent, { personaDto: this.persona, anioDto: anioActual });
    }
  }

  /**
   * Permite al tap detectar si hay cambios o no, para el momento de ser cerrado
   */
  consultarSiHayCambios() {
    this.hayCambios = this.getTieneCambios();
    return this.hayCambios;
  }
}
