import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { RecaudoFormHelper } from 'src/app/helpers/RecaudoFormHelper';
import { Año } from 'src/app/helpers/Models';
import { CollectionView } from 'wijmo/wijmo';
import { WjFlexGrid, WjFlexGridColumn } from 'wijmo/wijmo.angular2.grid';
import { ToastrService } from 'ngx-toastr';
import { WijmoHelper, ModeloCollectionView } from 'src/app/helpers/WijmoHelper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ModeloService, RecibosService, MatriculaService, ResponsablePagoService, EstudianteService, OtrasPersonasService, CobrosService, ExtractoService, TareaServidorService } from 'src/app/shared/services/services';
import { AutoSizeMode } from 'wijmo/wijmo.grid';
import { recaudoUI } from 'src/app/helpers/RecaudoUI';
import { ReciboComponent } from '../recibo/recibo.component';
import { TableService } from 'src/app/shared/services/table';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-buscar-recibos',
  templateUrl: './buscar-recibos.component.html',
  styleUrls: ['./buscar-recibos.component.css']
})
export class BuscarRecibosComponent extends RecaudoFormHelper implements OnInit {

  @Input('anio') public anio: Año;
  @Input('listaRecibos') public listaRecibos: any;

  recibosDto = {
    NumeroRecibo: null,
    Referencia1: null,
    Referencia2: null
  }

  parametros = [];

  hayCambios = false;
  buscando = false;

  //CollectionViews
  cvRecibos: CollectionView;

  //Referencia a los controles de wijmo
  @ViewChild('flexRecibos') flexRecibos: WjFlexGrid;

  constructor(
    private routes: Router,
    private _toastr: ToastrService,
    private _wjHelper: WijmoHelper,
    private _sModelo: ModeloService,
    private _changeDetector: ChangeDetectorRef,

    //private sRecibos: RecibosService,
    private _sTable: TableService,
    private _sAuth: AuthenticationService,
    private _sMatricula: MatriculaService,
    private _sResponsablePago: ResponsablePagoService,
    private _sEstudiante: EstudianteService,
    private _sOtrasPersonas: OtrasPersonasService,
    private _sCobro: CobrosService,
    private _sRecibo: RecibosService,
    private _sExtracto: ExtractoService,
    private _sTarea: TareaServidorService
  ) {
    super(_toastr, _wjHelper, _sModelo, _changeDetector, _sTable, _sAuth, _sMatricula,
      _sResponsablePago, _sEstudiante, _sOtrasPersonas, _sCobro, _sRecibo, _sExtracto,_sTarea);
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {

    //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
    this.setModelosTrabajo2({
      identificador: "Odata",
      odata: [
        `Grado;$filter=IdAnio eq ${this.anio["Id"]}&$expand=DefinicionGrado`,
        `Parametro`
      ]
    });

    //Carga la consulta con identificador 'Odata'
    this.cargarDatos("Odata", () => { });
  }

  /**
   * Trae los datos de las consultas realizadas
   * @param data El array u objeto que se esta consultado
   * @param identificador El identificador que ha sigo asignado a cada consulta
   */
  onDatosObtenidos(data: any, identificador: string) {

    //Carga los datos de la consulta de Odata
    if (identificador == "Odata"){
      this.parametros = data["Parametro"];
    }

    //Carga los datos de la consulta los recibos que se estan buscando
    else if (identificador = "buscarRecibo") {
     
      _.each(data["Creado"],(r) => {
        r["Creado"] = recaudoUI.formatDate(r["Creado"]);
        r["Modificado"] = recaudoUI.formatDate(r["Modificado"]);
      });

      this.cvRecibos = new CollectionView(data);

      this.cvRecibos.trackChanges = true;

      this.registrarModeloCollectionViews([
        new ModeloCollectionView('BuscarRecibos', 'Id', this.cvRecibos, this.flexRecibos)
      ]);
    }
  }

  /**
   * Actualiza las columnas que va tener el grid
   * @param column dato de la columna
   * @param modelo nombre del primer parametro en el new ModeloCollectionView
   */
  onAjusteColumna(column: WjFlexGridColumn, modelo: string) {
    if (modelo == "BuscarRecibos") {
      switch (column.binding) {
        default:
      }
    }
  }

  /**
   * Realiza la busqueda de acuerdo al filtro
   */
  buscar() {

    //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
    this.setModelosTrabajo2({
      servicio: "sRecibo",
      identificador: "buscarRecibo",
      metodo: "buscarRecibo",
      consulta: this.recibosDto
    });

    //Carga solo la consulta con identificador 'buscar recibo'
    this.cargarDatos("buscarRecibo", () => { });
  }

  /**
   * Crea o edita un registro
   */
  ver() {
    let item = this.flexRecibos.selectedItems[0];

    recaudoUI.tabsManager.agregar("Recibo - " + (item["NumeroRecibo"] || item["Referencia1"] || item["Referencia2"]), "fa-file-text-o", ReciboComponent, { idRecibo: item["Id"], anio: this.anio });

  }

  /**
   * Permite al tap detectar si hay cambios o no, para el momento de ser cerrado
   */
  consultarSiHayCambios() {
    this.hayCambios = this.getTieneCambios();
    return this.hayCambios;
  }

  /**
   * Direcciona a la url donde se imprime el recibo
   */
  imprimirRecibo() {

    let item = this.obtenerRegistroSeleccionado(this.flexRecibos);

    if (!this.verificarSiHayCambios() && item) {

      let parametro = _.find(this.parametros, (p) => { return p.Nombre == 'NombreDB'; });

      window.open(recaudoUI.getBaseUrl() + "Informes/Informe.aspx?DB=" + parametro.Valor + "&Nombre=" + item["TipoRecibo"] + "&NumeroRecibo=" + item["NumeroRecibo"] + "&Exportar=PDF", '_blank');
      //let url = this.routes.createUrlTree([`/RecaudoInf/Informes`, { DB: parametro.Valor, Nombre: item["TipoRecibo"], NumeroRecibo: item["NumeroRecibo"], Exportar: "PDF" }]);

      //window.open(url.toString());

    }

  }
}
