import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { RecaudoFormHelper } from 'src/app/helpers/RecaudoFormHelper';
import { recaudoUI } from 'src/app/helpers/RecaudoUI';
import { CollectionView } from 'wijmo/wijmo';
import { WjFlexGrid, WjFlexGridColumn } from 'wijmo/wijmo.angular2.grid';
import { ToastrService } from 'ngx-toastr';
import { WijmoHelper, ModeloCollectionView } from 'src/app/helpers/WijmoHelper';
import { ModeloService, ResponsablePagoService, MatriculaService, EstudianteService, OtrasPersonasService, CobrosService, RecibosService, ExtractoService, TareaServidorService } from 'src/app/shared/services/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ResponsableComponent } from '../responsable/responsable.component';
import { TableService } from 'src/app/shared/services/table';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import * as _ from 'underscore';
import { ModalConfirmacionComponent } from 'src/app/shared/components/modal-confirmacion/modal-confirmacion.component';
import { ModalAlertaComponent } from 'src/app/shared/components/modal-alerta/modal-alerta.component';
import { GestorNombres } from 'src/app/helpers/gestorNombres';

@Component({
  selector: 'app-buscar-responsables',
  templateUrl: './buscar-responsables.component.html',
  styleUrls: ['./buscar-responsables.component.css']
})
export class BuscarResponsablesComponent extends RecaudoFormHelper implements OnInit {

  anio = recaudoUI.getAñoTrabajo();
  @Input('listaResponsables') public listaResponsables: any;

  responsablePagoDto = {
    //Persona: {
    Nombre: null,
    Identificacion: null,
    //},
    DescuentoPorNomina: 0,
    IdAnio: this.anio.Id
  }

  anios: object[];
  buscando = false;
  hayCambios = false;

  //La selección del filtro del descuento por Nómina
  descuentoPorNomina = [
    { propiedad: 0, etiqueta: "" },
    { propiedad: 1, etiqueta: "Si" },
    { propiedad: 2, etiqueta: "No" },
  ];

  //CollectionViews
  cvListaResponsables: CollectionView;

  //Referencia a los controles de wijmo
  @ViewChild('flexListaResponsables') flexListaResponsables: WjFlexGrid;
  @ViewChild(ModalConfirmacionComponent) modalConfirmacion: ModalConfirmacionComponent;
  @ViewChild(ModalAlertaComponent) modalAlerta: ModalAlertaComponent;

  constructor(
    private gestorNombres: GestorNombres,
    private _toastr: ToastrService,
    private _wjHelper: WijmoHelper,
    private _changeDetector: ChangeDetectorRef,

    //private sResponsablePago: ResponsablePagoService,
    private _sModelo: ModeloService,
    private _sTable: TableService,
    private _sAuth: AuthenticationService,
    private _sMatricula: MatriculaService,
    private _sResponsablePago: ResponsablePagoService,
    private _sEstudiante: EstudianteService,
    private _sOtrasPersonas: OtrasPersonasService,
    private _sCobro: CobrosService,
    private _sRecibo: RecibosService,
    private _sExtracto: ExtractoService,
    private _sTarea: TareaServidorService
  ) {
    super(_toastr, _wjHelper, _sModelo, _changeDetector, _sTable, _sAuth, _sMatricula,
      _sResponsablePago, _sEstudiante, _sOtrasPersonas, _sCobro, _sRecibo, _sExtracto, _sTarea);
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {

    //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
    this.setModelosTrabajo2({
      identificador: "Odata",
      odata: [
        'Año'
      ]
    });

    //Carga la consulta con identificador 'Odata'
    this.cargarDatos("Odata", () => { });

    setTimeout(() => {
      this.cvListaResponsables = new CollectionView([]);
      this.cvListaResponsables.trackChanges = true;

      this.registrarModeloCollectionViews([
        new ModeloCollectionView('Persona', 'Id', this.cvListaResponsables, this.flexListaResponsables)
      ]);
    }, 500);
  }

  /**
   * Trae los datos de las consultas realizadas
   * @param data El array u objeto que se esta consultado
   * @param identificador El identificador que ha sigo asignado a cada consulta
   */
  onDatosObtenidos(data: any, identificador: string) {

    //Obtiene los años creados por medio del odata
    if (identificador == "Odata")
      this.anios = data["Año"];

    //Carga los datos de la consulta los responsables que se estan buscando
    else if (identificador == "buscarResponsables") {

      this.cvListaResponsables = new CollectionView(data);
      this.cvListaResponsables.trackChanges = true;

      this.registrarModeloCollectionViews([
        new ModeloCollectionView('Persona', 'Id', this.cvListaResponsables, this.flexListaResponsables)
      ]);
    }

    //Separa los nombres por apellidos y nombres de todos los registros que les hace falta separar
    else if (identificador == "separarNombres")
      this.cargarDatos("buscarResponsables", () => { });
  }

  /**
   * Realiza la busqueda de acuerdo al filtro
   */
  buscar() {

    this.responsablePagoDto["IdAño"] = this.responsablePagoDto.IdAnio;

    //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
    this.setModelosTrabajo2({
      servicio: "sResponsablePago",
      identificador: "buscarResponsables",
      metodo: "buscarResponsables",
      consulta: this.responsablePagoDto
    });

    //Carga solo la consulta con identificador 'Matricula'
    this.cargarDatos("buscarResponsables", () => { });
  }

  /**
   * Abre el tap del responsable
   */
  editar() {
    let item = this.flexListaResponsables.selectedItems[0];

    recaudoUI.tabsManager.agregar("Responsable - " + item.Nombre, "fa fa-users", ResponsableComponent, { personaDto: item, anioDto: item.Año });

  }

  /**
   * Actualiza las columnas que va tener el grid
   * @param column dato de la columna
   * @param modelo nombre del primer parametro en el new ModeloCollectionView
   */
  onAjusteColumna(column: WjFlexGridColumn, modelo: string) {
    if (modelo == "Responsables") {
      switch (column.binding) {
        default:
      }
    }
  }


  /**
   * Separa el nombre y los apellidos en el lado del cliente
   */
  separarNombres(formatoSeparacion) {

    let cantidad = 0;

    var textoUsuariosSeparados = "";

    _.each(this.cvListaResponsables.sourceCollection, (item) => {

      //Si el responsable no tiene dividido los nombres y apellidos
      if (!item.Nombre1) {

        var personaDto = this.gestorNombres.separarNombres(item.Nombre, formatoSeparacion);

        if (personaDto["Nombre1"] != "" && personaDto["Nombre1"]) {

          cantidad++;

          textoUsuariosSeparados += "<br>" + item.Nombre;

          this.cvListaResponsables.editItem(item);

          item.Nombre1 = personaDto["Nombre1"];
          item.Nombre2 = personaDto["Nombre2"];
          item.Apellido1 = personaDto["Apellido1"];
          item.Apellido2 = personaDto["Apellido2"];
        }
      }
    });

    this.modalAlerta.abrirConfirmar(
      {
        textHeader: "Se han separado " + cantidad + " nombres de responsables, con el formato " + formatoSeparacion.toLowerCase(),
        textBody: textoUsuariosSeparados,
      });
    this.modalAlerta.modalRef.result.then((result: boolean) => {
    }, (info) => {
      console.log("info", info);
    });

    this.cvListaResponsables.refresh();
    //this.toastr.success(`Se han separado ${cuenta} nombres`);
  }

  /**
   * Permite al tap detectar si hay cambios o no, para el momento de ser cerrado
   */
  consultarSiHayCambios() {
    this.hayCambios = this.getTieneCambios();
    return this.hayCambios;
  }
}
