import { Component, OnInit, Input, ViewChild, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChildren } from '@angular/core';
//import { ToastrService } from 'ngx-toastr';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'underscore';
import { WjFlexGrid, WjFlexGridColumn } from 'wijmo/wijmo.angular2.grid';
import { DataMap } from 'wijmo/wijmo.grid';
import { CollectionView } from 'wijmo/wijmo';

import { ModeloService, RecibosService, MatriculaService, ResponsablePagoService, EstudianteService, OtrasPersonasService, CobrosService, ExtractoService, TareaServidorService } from '../../shared/services/services';
import { WijmoHelper, ModeloCollectionView } from '../../helpers/WijmoHelper';
import { recaudoUI } from '../../helpers/RecaudoUI';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { RecaudoFormHelper } from '../../helpers/RecaudoFormHelper';
import { Año, ObjetoModelo } from '../../helpers/Models';
import { MatriculaComponent } from '../matricula/matricula.component';
import { ResponsableComponent } from '../responsable/responsable.component';
import { TableService } from 'src/app/shared/services/table';
import { WjInputDate, WjInputTime } from 'wijmo/wijmo.angular2.input';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ModalBuscarEstudianteComponent } from 'src/app/shared/components/modal-buscar-estudiante/modal-buscar-estudiante.component';
import { ModalBuscarResponsablePagoComponent } from 'src/app/shared/components/modal-buscar-responsable-pago/modal-buscar-responsable-pago.component';

@Component({
    selector: 'app-recibo',
    templateUrl: './recibo.component.html',
    styleUrls: ['./recibo.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReciboComponent extends RecaudoFormHelper implements OnInit, AfterViewInit {

    //@Input('recibo') public recibo: object;
    @Input('idRecibo') public idRecibo: number;
    @Input('anio') public anio: object;

    reciboObjetoModelo = new ObjetoModelo('Recibo');
    recibo: Object;
    copiaFechaPago;
    cobros: Object[];

    hayCambios = false;

    mediosPago = [
        { propiedad: "", etiqueta: "" },
        { propiedad: "Banco", etiqueta: "Banco" },
        { propiedad: "PSE", etiqueta: "PSE" },
        { propiedad: "Datafono", etiqueta: "Datafono" },
        { propiedad: "Colegio", etiqueta: "Colegio" },
        { propiedad: "Transferencia", etiqueta: "Transferencia" }
    ];

    tiposRecibo: Array<any> = [];

    //CollectionViews
    cvMovimientos: CollectionView;
    cvDetalleRecibo: CollectionView;

    //Referencia a los controles de wijmo
    @ViewChild('flexMovimientos') flexMovimientos: WjFlexGrid;
    @ViewChild('flexDetalleRecibo') flexDetalleRecibo: WjFlexGrid;

    @ViewChild(ModalBuscarResponsablePagoComponent) modalBuscarResponsablePago: ModalBuscarResponsablePagoComponent;
    @ViewChild(ModalBuscarEstudianteComponent) modalBuscarEstudiante: ModalBuscarEstudianteComponent;

    //Datos de trabajo auxiliares
    modelsDatos: Array<string> = [];
    meses: Array<any> = [];
    estadosMovimiento: Array<any> = [];

    //declaración de servicios
    constructor(
        private _toastr: ToastrService,
        private _wjHelper: WijmoHelper,
        private _changeDetector: ChangeDetectorRef,
        private modalService: NgbModal,
        private _sTable: TableService,

        private _sModelo: ModeloService,
        //private sRecibos: RecibosService,
        private _sAuth: AuthenticationService,
        private _sMatricula: MatriculaService,
        private _sResponsablePago: ResponsablePagoService,
        private _sEstudiante: EstudianteService,
        private _sOtrasPersonas: OtrasPersonasService,
        private _sCobro: CobrosService,
        private _sRecibo: RecibosService,
        public _sExtracto: ExtractoService,
        private _sTarea: TareaServidorService
    ) {
        super(_toastr, _wjHelper, _sModelo, _changeDetector, _sTable, _sAuth, _sMatricula,
            _sResponsablePago, _sEstudiante, _sOtrasPersonas, _sCobro, _sRecibo, _sExtracto, _sTarea);
    }

    ngOnInit(): void {

        //Afecta el orden al cual se realice la consulta por ende se realiza la consulta del detalleRecibo de primeras
        //Obtiene la selección del detalle recibo
        this.setModelosTrabajo2({
            servicio: "sRecibo",
            identificador: "detalleReciboPorMatricula",
            metodo: "GetDetallesReciboPorMatricula",
            consulta: { idRecibo: this.idRecibo }
        });

        //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
        this.setModelosTrabajo2({
            identificador: "Odata",
            odata: [
                'Recibo;$filter=Id eq ' + this.idRecibo + ' &$expand=Matricula.Estudiante,ResponsablePago.Persona,ResponsablePago.Año,DetallesRecibo',
                'Mes', 'EstadoMovimiento',
                'Movimiento;$filter=IdRecibo eq ' + this.idRecibo + '&$expand=Extracto',
                'TipoRecibo'
            ]
        });

        //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
        this.setModelosTrabajo2({
            servicio: "sRecibo",
            identificador: "detalleRecibo",
            metodo: "GetDetallesRecibo",
            consulta: { idRecibo: this.idRecibo }
        });

        //Carga todas las contas que están en el modelo de trabajo
        this.cargarDatos(null, () => { });
    }

    /**
     * Al cambiar la fecha de ago actualiza el medio de pago
     */
    actualizarMedioPago() {

        if (recaudoUI.formatDate(this.recibo["FechaPago"]) != recaudoUI.formatDate(this.copiaFechaPago)) {

            this.recibo["MedioPago"] = recaudoUI.formatDate(this.recibo["FechaPago"]) ? "Colegio" : "";
            this.copiaFechaPago = recaudoUI.formatDate(this.recibo["FechaPago"]);

        }
    }

    /**
     * Trae los datos de las consultas realizadas
     * @param data El array u objeto que se esta consultado
     * @param identificador El identificador que ha sigo asignado a cada consulta
     */
    onDatosObtenidos(data: any, identificador: string) {
        //console.log('resibo onDatosObtenidos', data, identificador);
        //Carga los datos de la consulta de Odata
        if (identificador == "Odata") {

            if (data['Recibo']) {
                this.reciboObjetoModelo.objeto = _.first(data['Recibo']);
                this.recibo = this.reciboObjetoModelo.objeto;
                this.recibo['FechaPago'] = recaudoUI.formatDate(this.recibo['FechaPago']);
                this.copiaFechaPago = this.recibo['FechaPago'];
                this.recibo['Creado'] = recaudoUI.formatDate(this.recibo['Creado']);
                this.recibo['Impreso'] = recaudoUI.formatDate(this.recibo['Impreso']);
                this.recibo['Modificado'] = recaudoUI.formatDate(this.recibo['Modificado']);

                this.reciboObjetoModelo.actualizarCopia();
            }

            //llena selec de tipo recibo
            this.tiposRecibo = data['TipoRecibo'];

            //this.cobros = data["Cobro"];
            this.meses = data['Mes'];
            this.estadosMovimiento = data['EstadoMovimiento'];

            this.cvMovimientos = new CollectionView(data["Movimiento"]);
            this.cvMovimientos.trackChanges = true;

            //llenan datos de detalleRecibo
            this.cvDetalleRecibo = new CollectionView(this.recibo['DetallesRecibo']);
            this.cvDetalleRecibo.trackChanges = true;

            this.cvDetalleRecibo.newItemCreator = () => {
                return this.crearModeloDetalleRecibo();
            }

            this.registrarObjetosModelo([this.reciboObjetoModelo]);
            this.registrarModeloCollectionViews([
                new ModeloCollectionView('DetalleRecibo', 'Id', this.cvDetalleRecibo, this.flexDetalleRecibo),
                new ModeloCollectionView('Movimiento', 'Id', this.cvMovimientos, this.flexMovimientos)
            ]);

        }

        //Carga los datos de la consulta del detalle recibo
        else if (identificador == "detalleRecibo") {
            //llenan datos de detalleRecibo
            this.cvDetalleRecibo = new CollectionView(data);
            this.cvDetalleRecibo.trackChanges = true;

            this.cvDetalleRecibo.newItemCreator = () => {
                return this.crearModeloDetalleRecibo();
            }

            this.registrarModeloCollectionViews([
                new ModeloCollectionView('DetalleRecibo', 'Id', this.cvDetalleRecibo, this.flexDetalleRecibo),
                new ModeloCollectionView('Movimiento', 'Id', this.cvMovimientos, this.flexMovimientos)
            ]);
        }

        //Obtiene la seleccion del cobro para la matrícula
        else if (identificador == "detalleReciboPorMatricula")
            this.cobros = data;
    }

    /**
     * Permite jenerar el modelo del detalle reciob
     */
    crearModeloDetalleRecibo() {
        return { IdRecibo: this.recibo["Id"], IdCobro: _.first(this.cobros)["Id"] };
    }

    /**
     * Ajusta los selects al flex grid
     * @param column 
     * @param modelo 
     */
    onAjusteColumna(column: WjFlexGridColumn, modelo: string) {
        if (modelo == "Movimiento") {
            switch (column.binding) {
                case "IdEstadoMovimiento":
                    column.width = 130;
                    //crea el select de meses
                    column.dataMap = new DataMap(this.estadosMovimiento, "Id", "Nombre");
                    break;
                default:
            }
        }
        if (modelo == "DetalleRecibo") {
            switch (column.binding) {
                case "IdCobro":
                    if (this.cobros && this.cobros.length)
                        column.dataMap = new DataMap(this.cobros, "Id", "CobroNombre");
                    break;
                default:
            }
        }
    }

    /**
     * Los componentes generados dinámicamente deben implementar esta interfaz
     */
    ngAfterViewInit() {
    }

    /**
     * Abre el Tap de matricula
     */
    verMatricula() {
        if (!this.verificarSiHayCambios())
            recaudoUI.tabsManager.agregar("Matrícula - " + this.recibo["Matricula"].Estudiante.Codigo, "fa-user", MatriculaComponent, { idMatricula: this.recibo["Matricula"].Id, anio: this.recibo["ResponsablePago"].Año });
    }

    /**
     * Abre el tap del responsable
     */
    verResponsable() {
        if (!this.verificarSiHayCambios())
            recaudoUI.tabsManager.agregar("Responsable - " + this.recibo["ResponsablePago"].Persona.Nombre, "fa fa-users", ResponsableComponent, { personaDto: this.recibo["ResponsablePago"].Persona, anioDto: this.recibo["ResponsablePago"].Año });
    }

    /**
   * Cambia el responsable de pago
   */
    cambiarResponsablePago() {

        this.modalBuscarResponsablePago.abrirConsultaResponsablePago(this.anio);
        this.modalBuscarResponsablePago.modalRef.result.then((dataResponsablePago) => {

            this.recibo["IdResponsablePago"] = dataResponsablePago.Id;
            this.recibo["ResponsablePago"] = dataResponsablePago;

        }, (info) => {
            console.log("Info", info);
        });
    }

    /**
     * Cambia el estudiante de la matrícula
     */
    cambiarEstudiante() {

        //this.modal.abrirConsultarEstudiante(this.anioDto);
        this.modalBuscarEstudiante.abrirBuscarEstudiante(this.anio);
        this.modalBuscarEstudiante.modalRef.result.then((dataEstudiante) => {

            this.recibo["Matricula"].Estudiante = dataEstudiante;
            this.recibo["Matricula"].IdEstudiante = dataEstudiante["Id"];

        }, (info) => {
            console.log("info",  info);
        });
    }

    /**
     * Permite al tap detectar si hay cambios o no, para el momento de ser cerrado
     */
    consultarSiHayCambios() {
        this.hayCambios = this.getTieneCambios();
        return this.hayCambios;
    }

    /**
     * Actualiza el tab cuando se realizan cambios en el detalle del recibo
     * @param modelo 
     */
    actualizarDatosDespuesDeGuardar(modelo) {

        //Si se guardo los cambios del modelo del grid detallerecibo recarga la consulta del odata
        if (modelo = "DetalleRecibo")
            this.cargarDatos("Odata", () => { });
    }
}

