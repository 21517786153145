import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { modalConfirmar } from 'src/app/_models/modals.model';
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'underscore';

@Component({
  selector: 'app-modal-confirmacion',
  templateUrl: './modal-confirmacion.component.html',
  styleUrls: ['./modal-confirmacion.component.css']
})
export class ModalConfirmacionComponent implements OnInit {

  //Variables que se utiliza en la modal de confirmación
  @ViewChild('confirmarModal') private confirmarModal;
  private configConfirmacion: modalConfirmar;

  modalRef: NgbModalRef;

  constructor(
    private _modalService: NgbModal,
    private toastr: ToastrService,
    public changeDetector: ChangeDetectorRef,
    private config: NgbModalConfig
  ) { }

  ngOnInit() {
  }

  /**
   * modal de confirmacion, trae dos botones Cancelar, aceptar
   * @param configuracion
   */
  public abrirConfirmar(configuracion: modalConfirmar): NgbModalRef {

    this.configConfirmacion = configuracion;

    //Abre la modal con su respectiva configuración
    this.config.backdrop = "static";
    this.config.keyboard = false;
    this.modalRef = this._modalService.open(this.confirmarModal, { size: "lg" });
    return this.modalRef;
  }

  /**
   * Obtiene la seleccion del ususario 
   * @param seleccion
   */
  private cerrarConfirmacion(resultado?: boolean) {
    this.modalRef.close(resultado);
  }

  /**
   * Cierra la modal
   */
  private cerrarModal() {
    this.modalRef.dismiss("Cerrar");
  }
}
