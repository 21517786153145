import { ColaFunciones } from './ColaFunciones';
import { Injectable } from '@angular/core';
import { WjFlexGrid } from 'wijmo/wijmo.angular2.grid';
import { CollectionView } from 'wijmo/wijmo';
import { ModeloService } from '../shared/services/services';
//import { Uuid} from 'uuid';
//import * as uuid from '../../libs/uuid';
import * as _ from 'node_modules/underscore';
import * as moment from 'moment';
import { recaudoUI } from './RecaudoUI';

/**
 * Provee funcionalidad que permite facilitar el manejo de los datos en los controles de wijmo
 */
@Injectable()
export class WijmoHelper {
    private colaFunciones = new ColaFunciones();

    constructor(private sModelo: ModeloService) {

    }

    /**
     * Guarda cambios realizados en cada collectionView de la lista de ModeloCollectionViews pasada como argumento y realiza el intercambio de datos en caso de los nuevos.
     * @param modeloCollectionViews lista de ModeloCollectionViews.
     * @param callback función que se ejecuta cuando termina de guardar los cambios.
     * @param callbackErr función que se ejecuta cuando se presenta un error al ejecutar las operaciones.
     */
    saveModeloCollectionViews(modeloCollectionViews: Array<ModeloCollectionView>, callback: Function, callbackErr: Function) {
        this.colaFunciones.dispose();

        for (let d of modeloCollectionViews) {

            let itemsSave = [];
            let idsDelete: string;

            if (!d.collectionView.trackChanges) {
                console.error("saveModeloCollectionViews() => se ha detectado una CollectionView sin  trackChanges habilitado.");
            }

            if (d.collectionView.itemsEdited.length)
                itemsSave.push.apply(itemsSave, d.collectionView.itemsEdited);

            if (d.collectionView.itemsAdded.length) {
                //marca los guid de los items nuevos.
                for (let i of d.collectionView.itemsAdded) {
                    i.Guid = _.uniqueId('');// Uuid();
                }
                itemsSave.push.apply(itemsSave, d.collectionView.itemsAdded);
            }

            if (d.collectionView.itemsRemoved.length) {
                idsDelete = _.pluck(d.collectionView.itemsRemoved, d.primaryKey).toString();

                console.log("grid", idsDelete, itemsSave);
                this.colaFunciones.agregar(() => {

                    //Guarda los datos de la tabla manager
                    if (d.modelo == "UsuarioManager" || d.modelo == "ColegioManager")
                        this.sModelo.deleteManager(d.modelo.replace("Manager", ""), idsDelete).subscribe(() => {
                            //si ok limpia los items
                            d.collectionView.itemsRemoved.clear();
                            this.colaFunciones.ejecutar();
                        }, (error) => {
                            this.colaFunciones.terminar();
                            callbackErr(error);

                            throw new Error(error);
                        });

                    //Guarda los datos de la tablas de recaudo
                    else
                        this.sModelo.delete(d.modelo, idsDelete).subscribe(() => {
                            //si ok limpia los items
                            d.collectionView.itemsRemoved.clear();
                            this.colaFunciones.ejecutar();
                        }, (error) => {
                            this.colaFunciones.terminar();
                            callbackErr(error);

                            throw new Error(error);
                        });
                });
            }

            if (d.collectionView.itemsEdited.length || d.collectionView.itemsAdded.length)
                this.colaFunciones.agregar(() => {
                    //console.info("guardando :" + d.modelo, itemsSave);
                    this.sModelo.save(d.modelo, itemsSave).subscribe((data) => {
                        if (d.collectionView.itemsAdded.length) {
                            let item;
                            //realiza el intercambio de datos
                            for (let i of d.collectionView.itemsAdded) {
                                item = _.findWhere(data, { Guid: i.Guid });

                                if (item) {
                                    i[d.primaryKey] = item.Id;
                                }

                            }

                            d.collectionView.refresh();
                        }
                        //si ok limpia los items
                        d.collectionView.itemsAdded.clear();
                        d.collectionView.itemsEdited.clear();
                        this.colaFunciones.ejecutar();
                    }, (error) => {
                        this.colaFunciones.terminar();
                        callbackErr(error);

                        throw new Error(error);
                    });
                });
        }

        //la última función en ejecutarse es el callback
        this.colaFunciones.agregar(() => {
            this.colaFunciones.terminar();
            callback();
        });

        //inicia la cola de funciones construida.
        this.colaFunciones.ejecutar();
    }

    /**
     * retorna un booleano indicando si alguna de las collectionViews tiene cambios.
     * @param collectionViews lista de collectionViews
     */
    getHayCambiosCollectionViews(collectionViews: Array<CollectionView>): boolean {
        let ret: boolean = false;

        for (let collectionView of collectionViews) {
            if (collectionView.itemsAdded.length || collectionView.itemsEdited.length || collectionView.itemsRemoved.length)
                ret = true;
        }

        return ret;
    }

    /**
     * retorna un booleano indicando si alguna de las collectionViews tiene cambios.
     * @param collectionViews lista de collectionViews
     */
    getHayCambiosModeloCollectionViews(modeloCollectionViews: Array<ModeloCollectionView>): boolean {
        let ret: boolean = false;

        for (let modeloCollectionView of modeloCollectionViews) {

            //Verifica los cambios realizados sobre la propiedad activo de la matrícula,
            if (modeloCollectionView.collectionView && modeloCollectionView["modelo"] == "Matricula")
                this.actualizarMatricula(modeloCollectionView);

            if (modeloCollectionView.collectionView &&
                (modeloCollectionView.collectionView.itemsAdded.length || modeloCollectionView.collectionView.itemsEdited.length || modeloCollectionView.collectionView.itemsRemoved.length))
                ret = true;
        }

        return ret;
    }

    /**
     * Verifica si la mátricula está activa o no y asi coloca la fecha
     */
    actualizarMatricula(modeloCollectionView) {

        if (modeloCollectionView.collectionView.itemsEdited.length != 0)
            _.each(modeloCollectionView.collectionView.itemsEdited, (item) => {

                if (item.Activo && item.FechaRetiro != null)
                    item.FechaRetiro = null;

                else if (!item.Activo && item.FechaRetiro == null)
                    item.FechaRetiro = recaudoUI.getFechaActual();
            });
    }
}

/**
 * Representa una collectionView asociada a un modelo.
 */
export class ModeloCollectionView {
    public collectionView: CollectionView;
    public wjFlexGrid: WjFlexGrid;
    public modelo: string;
    public primaryKey: string;

    /**
     * Representa una collectionView asociada a un modelo.
     * @param modelo Nombre del modelo
     * @param primaryKey Nombre de la clave primaria
     * @param collectionView Objecto de tipo CollectionView
     * @param wjFlexGrid Objecto de tipo WjFlexGrid
     */
    constructor(modelo: string, primaryKey: string, collectionView: CollectionView, wjFlexGrid: WjFlexGrid = null) {
        this.modelo = modelo;
        this.primaryKey = primaryKey;
        this.collectionView = collectionView;
        this.wjFlexGrid = wjFlexGrid;
    }
}
