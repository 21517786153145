import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as _ from 'node_modules/underscore';
import { ToastrService } from 'ngx-toastr';
import { TareaServidor } from 'src/app/_models/tareaServidor';
import { recaudoUI } from 'src/app/helpers/RecaudoUI';
import { AuthenticationService } from './authentication.service';
import { ModeloService } from './services';

@Injectable()
export class TableService {

    public tablasManager = {
        Usuario:
        [
            "IdColegio",
            "Nombre",
            "Apellido",
            "Rol",
            "Email",
            "Password",
        ],
        ColegioManager: [
            "Nombre",
            "Conexion",
        ]
    }

    //Contiene las propiedades obligatorias de todas las tablas
    public tablas = {
        Año: [
            "IdColegio",
            "Numero",
            "Etiqueta",
            "NumeroRecibo",
            "TasaInteres",
        ],
        Cobro: [
            "IdMatricula",
            "Debito",
            "Credito",
            "TotalDebitado",
            "TotalAcreditado",
            "IdTipoConcepto",
            "EnCobroPrejuridico",
        ],
        Colegio: [
            "Nombre",
            "FuenteNombre",
            "FuenteItalica",
            "FuenteNegrita",
        ],
        CostoEducativo: [
            "IdTipoCostoEducativo",
            "Valor",
        ],
        Cuenta: [
            "Nombre",
            "Numero",
            "Banco",
            "RecaudoEmpresarial",
            "IdColegio",
        ],
        DefinicionGrado: [
            "IdColegio",
            "Numero",
            "Nombre",
            "Graduacion",
            "Orden",
        ],
        DetalleRecibo: [
            "IdRecibo",
            "IdCobro",
            "Debito",
            "Credito",
        ],
        EstadoMes: [
            "Nombre",
        ],
        EstadoMovimiento: [
            "Nombre",
            "Error",
        ],
        Estudiante: [
            "IdColegio",
            "Codigo",
            "Apellidos",
            "Nombres",
        ],
        EstudiantePersona: [
            "IdEstudiante",
            "IdPersona",
        ],
        Extracto: [
            "IdCuenta",
            "Desde",
            "Hasta",
            "TipoExtracto",
            "Cargado",
            "Path",
            "Nombre",
        ],
        Grado: [
            "IdAnio",
            "IdSedeJornada",
            "IdDefinicionGrado",
        ],
        Grupo: [
            "IdGrado",
            "Numero",
            "Nombre",
            "Etiqueta",
        ],
        Informe: [
            "Nombre",
            "Etiqueta",
            "VisibleMenu",
        ],
        InscripcionServicio: [
            "IdServicio",
            "IdMatricula",
        ],
        Matricula: [
            //"Id",
            "IdEstudiante",
            "IdGrado",
            "Activo",
            "Matriculado",
            "Nuevo",
            "Becado",
            "Ruta",
            "Comedor",
        ],
        Mes: [
            "IdAnio",
            "Numero",
            "Nombre",
            "TipoActividad",
        ],
        Movimiento: [
            "IdExtracto",
            "Referencia1",
            "Efectivo",
            "Cheque",
            "Total",
            "Fecha",
            "Naturaleza",
            "IdEstadoMovimiento",
        ],
        Parametro: [
            "Nombre",
            "Valor",
            "Tipo",
        ],
        Persona: [
            "Nombre",
            "EmailVerificado",
            "IdColegio",
        ],
        Recibo: [
            "NumeroRecibo",
            "Referencia1",
            "Creado",
            "NotificacionCreacion",
            "NotificacionPago",
            "IdMes",
            "Timestamp",
            "Vigente",
            "Anulado",
            "IdMatricula",
            "AgendaVisible",
        ],
        ResponsablePago: [
            "IdAño",
            "IdPersona",
            "DescuentoPorNomina",
        ],
        SedeJornada: [
            "Nombre",
            "IdColegio",
        ],
        Servicio: [
            "Nombre",
            "IdTipoServicio",
            "ValorReferencia",
            "IdAño",
        ],
        TareaServidor: [
            "Fecha",
            "Nombre",
            "Estado",
            "Usuario",
        ],
        TipoConcepto: [
            "IdColegio",
            "CargaManual",
            "GeneraInteres",
        ],
        TipoCostoEducativo: [
            "IdColegio",
        ],
        TipoRecibo: [
            "Tipo",
            "Clase",
            "Orden",
            "AutoCrearNuevos"
        ],
        TipoServicio: [
            "Nombre",
            "IdColegio",
        ],
        UnidadNegocio: [
            "IdColegio",
            "Nombre",
            "Nit",
            "FacturacionElectronica",
        ],
        Rango: [
            "IdUnidadNegocio",
            "TipoDocumento",
            "Prefijo",
            "Desde",
            "Hasta",
            "Siguiente",
            "FechaResolucion",
            "Activo"
        ]
    }


    constructor(private http: HttpClient,
        public toastr: ToastrService,
        private _sAuth: AuthenticationService,
        private _sModelo: ModeloService,
    ) { }

    /**
     * Obtene las propiedades obligatorias de la tabla seleccionada de la DB Recaudo
     * @param modelo 
     */
    public getPropiedadesObligatorias(modelo: string) {
        return this.tablas[modelo];
    }

    /**
     * Obtiene las propiedades que son obligatorias de la  tabla seleccionada de la DB RecaudoManager
     * @param modelo 
     */
    public getPropiedadesObligatoriasRecaudoManagerDB(modelo: string){
        return this.tablasManager[modelo];
    }

    /**
    * Genera el objeto con las propiedades de la tarea servidor
    * @param nombre 
    * @param p1 
    * @param p2 
    * @param p3 
    * @param p4 
    * @param p5 
    * @param p6 
    */
    public generarObjectTareaServidor(nombre, p1, p2, p3, p4, p5, p6) {
        return {
            Nombre: nombre,
            Parametro1: p1,
            Parametro2: p2,
            Parametro3: p3,
            Parametro4: p4,
            Parametro5: p5,
            Parametro6: p6,
            Usuario: this._sAuth.getUser().Email,
            Fecha: recaudoUI.getFechaActual()
        };
    }
}