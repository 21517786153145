import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NgbModalRef, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-carga-cobro',
  templateUrl: './modal-carga-cobro.component.html',
  styleUrls: ['./modal-carga-cobro.component.css']
})
export class ModalCargaCobroComponent implements OnInit {


  @ViewChild('cargarCobroModal') private cargarCobroModal;

  private tiposConcepto = [];
  private meses = [];
  private oprimioBotonAceptar = false;

  private cobro = {
    codigo: 0,
    nombre: "",
    idMatricula: 0,
    idTipoConcepto: 0,
    idMesDesde: 0,
    idMesHasta: 0,
    descripcion: "",
    valor: 0
  };


  modalRef: NgbModalRef;

  constructor(
    private _modalService: NgbModal,
    private toastr: ToastrService,
    private router: Router,
    public changeDetector: ChangeDetectorRef,
    private config: NgbModalConfig,
  ) { }

  ngOnInit() {
  }

  /**
   * Abre la modal para seleccionar el mes y generar el ajuste de interes descuento 
   * @param meses 
   */
  public abrirCargarCobro(idMatricula: number, nombre: string, codigo: number, tiposConcepto: any[], meses: any[]): NgbModalRef {

    this.oprimioBotonAceptar = false;

    this.cobro.idMatricula = idMatricula;
    this.cobro.nombre = nombre;
    this.cobro.codigo = codigo;

    this.tiposConcepto = tiposConcepto;
    this.meses = meses;

    //Abre la modal con su respectiva configuración
    this.config.backdrop = "static";
    this.config.keyboard = false;

    this.modalRef = this._modalService.open(this.cargarCobroModal, { size: "lg" });

    return this.modalRef;
  }

  /**
   * Retorna el mes seleccionado
   */
  private aceptar() {

    this.oprimioBotonAceptar = true;

    if (this.cobro.idMesDesde == 0 || this.cobro.idMesHasta == 0 || this.cobro.idTipoConcepto == 0)
      return;

    this.modalRef.close(this.cobro);
  }

  /**
   * Cierra la modal
   */
  private cerrarModal() {
    this.modalRef.dismiss("Cerrar");
  }

}
