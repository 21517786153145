import { Injectable, ViewContainerRef } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ToastrService  } from 'ngx-toastr';
//import { ToastrService } from 'ngx-toastr';
//import { Amplify } from 'amplify';
//import * as amplify from 'libs/amplify.js';
import * as _ from 'underscore';


//https://stackoverflow.com/questions/44396890/angular-4-http-interceptor
@Injectable()
export class Interceptor implements HttpInterceptor {
  private headers: Array<Header> = [];

  constructor(
    private toastr: ToastrService 
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.headers = [];

    if (sessionStorage.getItem("token")) {
      this.headers.push(new Header('Authorization', sessionStorage.getItem("token")))
    }

    //agrega los headers al request
    let request = req.clone({
      headers: new HttpHeaders(
        JSON.parse("{" + _.map(this.headers, (header: Header) => {
          return '"' + header.name + '" :' + '"' + header.value + '"'
        }).join(',') + "}")
      )
    });

    return next.handle(request);
    /*
    return next.handle(request).catch((err, caught) => {  //control de errores
      console.info("err", err);
      //error de servidor no disponible
      if (err && err.statusText == "Unknown Error" && err.status == 0) {
        this.toastr.error("Error: No se ha logrado establecer una conexión con el servidor.");
      }
      //errores de EF
      else if (err && err.error && err.error.InnerException)
        this.toastr.error("Ha ocurrido un error: " + (err.error.InnerException.InnerException.ExceptionMessage));
      //errores de webservice
      else if (err && err.error && err.error.Message)
        this.toastr.error("Ha ocurrido un error: " + (err.error.ExceptionMessage || err.error.Message));
      //otros errores
      else
        this.toastr.error("Ha ocurrido un error inesperado al realizar la acción solicitada.");

      return Observable.throw(err);
    });*/
  }
}

class Header {
  name: string;
  value: string;

  constructor(name: string, value: string) {
    this.name = name;
    this.value = value;
  }
}
