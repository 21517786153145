import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-exportar-informes',
  templateUrl: './exportar-informes.component.html',
  styleUrls: ['./exportar-informes.component.css']
})
export class ExportarInformesComponent implements OnInit {


  DB = this.activeRoute.snapshot.params['DB'];
  Nombre = this.activeRoute.snapshot.params['Nombre'];

  constructor(private activeRoute: ActivatedRoute) { }

  ngOnInit() {

    console.log("DB", this.DB);
    console.log("Nombre", this.Nombre);
  }

}
