import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-cargando',
  templateUrl: './cargando.component.html',
  styleUrls: ['./cargando.component.css']
})
export class CargandoComponent implements OnInit {

  //Controla el estado de visibilidad
  @Input() public cargando: boolean;

  //Obtiene el objeto de la tarea la cual se está realizando
  @Input() set tareaEnCurso(tarea: string) {
    if (tarea != null)
      this.texto = tarea["Nombre"];
    else
      this.texto = "Cargando";
  };

  //Texto a mostrar mientras carga
  @Input() public texto?: string = "Cargando";

  //Clase adicional para el div de cargando
  @Input() public class?: string;

  constructor() { }

  ngOnInit() {
  }

}
