import { Component, AfterViewInit, EventEmitter, Output, Input, ViewEncapsulation } from '@angular/core';
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
declare var $: any;

import { AuthenticationService } from '../services/authentication.service';
import { UsuarioAuth } from 'src/app/_models/usuarioAuth';
import { UsuarioComponent } from 'src/app/component/usuario/usuario.component';
import { recaudoUI } from 'src/app/helpers/RecaudoUI';
import { Tab } from 'src/app/helpers/Models';
import { BuscarMatriculasComponent } from 'src/app/component/buscar-matriculas/buscar-matriculas.component';
import { BuscarResponsablesComponent } from 'src/app/component/buscar-responsables/buscar-responsables.component';
import { BuscarRecibosComponent } from 'src/app/component/buscar-recibos/buscar-recibos.component';
import { BuscarEstudiantesComponent } from 'src/app/component/buscar-estudiantes/buscar-estudiantes.component';
import { OtrasPersonasComponent } from 'src/app/component/otras-personas/otras-personas.component';
import { RecaudoComponent } from 'src/app/component/recaudo/recaudo.component';
import { AdministracionComponent } from 'src/app/component/administracion/administracion.component';
import { ExtractosComponent } from 'src/app/component/extractos/extractos.component';
import { InformesComponent } from 'src/app/component/informes/informes.component';
import { AdministracionInformesComponent } from 'src/app/component/administracion-informes/administracion-informes.component';
import { AsistenteNuevaMatriculaComponent } from 'src/app/component/asistente-nueva-matricula/asistente-nueva-matricula.component';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class NavigationComponent implements AfterViewInit {
  @Output() toggleSidebar = new EventEmitter<void>();

  @Input()
  public hideToggle = false;

  public config: PerfectScrollbarConfigInterface = {};

  public showSearch = false;
  proyectoEnProduccion;

  usuarioLogeado: UsuarioAuth;

  constructor(
    private modalService: NgbModal,
    private sAuth: AuthenticationService,
    private Satenticacion: AuthenticationService
  ) {
    this.usuarioLogeado = sAuth.getUser();
    this.proyectoEnProduccion = recaudoUI.getProyectoEstaEnProduccion();
    
    /*
    //Obtiene los datos del usuario que está logeado
    this.Satenticacion.currentUser.subscribe((data: UsuarioAuth) => {
      this.usuarioLogeado = data;
    });*/
  }

  /**
   * Direcciona a la vista de edición del usuario logeado
   */
  editarUsuario() {

    if (this.usuarioLogeado)
      recaudoUI.tabsManager.agregar("Usuario - " + this.usuarioLogeado.Nombre, "fa fa-address-card-o", UsuarioComponent, { idUsuario: this.usuarioLogeado.IdUsuario });
  }

  /**
   * Abre la tab de buscar matriculas
   */
  buscarMatricula() {
    recaudoUI.tabsManager.agregarTab(new Tab("Matrículas " + recaudoUI.getAñoTrabajo().Etiqueta, "fa-address-book-o", BuscarMatriculasComponent, null, false));
  }

  buscarResponsables() {
    recaudoUI.tabsManager.agregarTab(new Tab("Responsables", "fa fa-search", BuscarResponsablesComponent, { anio: recaudoUI.getAñoTrabajo() }, false));
  }

  buscarRecibos() {
    recaudoUI.tabsManager.agregarTab(new Tab("Buscar Recibos", "fa fa-search-plus", BuscarRecibosComponent, { anio: recaudoUI.getAñoTrabajo() }, false));
  }

  buscarEstudiantes() {
    recaudoUI.tabsManager.agregarTab(new Tab("Estudiantes", "fa fa-user-circle-o", BuscarEstudiantesComponent, { anio: recaudoUI.getAñoTrabajo() }, false));
  }

  buscarOtrasPersonas() {
    recaudoUI.tabsManager.agregarTab(new Tab("Otras Personas", "fa fa-users", OtrasPersonasComponent, { anio: recaudoUI.getAñoTrabajo() }, false));
  }

  buscarRecaudo() {
    recaudoUI.tabsManager.agregarTab(new Tab("Recaudo", "fa fa-barcode", RecaudoComponent, null, false));
  }

  generarNuevaMatricula() {
    recaudoUI.tabsManager.agregar("Asistente Nueva Matrícula", null, AsistenteNuevaMatriculaComponent, { anio: recaudoUI.getAñoTrabajo() });
  }

  abrirFacturacion() {
    recaudoUI.tabsManager.agregarTab(new Tab("Administración " + recaudoUI.getAñoTrabajo().Etiqueta, "fa fa-newspaper-o", AdministracionComponent, { anio: recaudoUI.getAñoTrabajo() }, false));
  }
  abrirExtractos() {
    recaudoUI.tabsManager.agregarTab(new Tab("Extractos ", "fa-archive", ExtractosComponent, { anio: recaudoUI.getAñoTrabajo() }, false));
  }
  abrirInformes() {
    recaudoUI.tabsManager.agregarTab(new Tab("Informes ", "fa fa-folder-open-o", InformesComponent, { anio: recaudoUI.getAñoTrabajo() }, false));
  }
  abrirAdministraciones() {
    recaudoUI.tabsManager.agregarTab(new Tab("Administración Informes ", "", AdministracionInformesComponent, { anio: recaudoUI.getAñoTrabajo() }, false));
  }

  /**
   * Abre la ayuda 
   */
  abrirAyuda() {
    window.open("https://github.com/docappspv/RecaudoDoc/wiki", "_blank");
  }

  salir() {
    this.sAuth.logout();
  }

  // This is for Notifications
  notifications: Object[] = [
    {
      btn: 'btn-danger',
      icon: 'ti-link',
      title: 'Luanch Admin',
      subject: 'Just see the my new admin!',
      time: '9:30 AM'
    },
    {
      btn: 'btn-success',
      icon: 'ti-calendar',
      title: 'Event today',
      subject: 'Just a reminder that you have event',
      time: '9:10 AM'
    },
    {
      btn: 'btn-info',
      icon: 'ti-settings',
      title: 'Settings',
      subject: 'You can customize this template as you want',
      time: '9:08 AM'
    },
    {
      btn: 'btn-primary',
      icon: 'ti-user',
      title: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];

  // This is for Mymessages
  mymessages: Object[] = [
    {
      useravatar: 'assets/images/users/1.jpg',
      status: 'online',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:30 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'busy',
      from: 'Sonu Nigam',
      subject: 'I have sung a song! See you at',
      time: '9:10 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'away',
      from: 'Arijit Sinh',
      subject: 'I am a singer!',
      time: '9:08 AM'
    },
    {
      useravatar: 'assets/images/users/4.jpg',
      status: 'offline',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];

  ngAfterViewInit() { }



  /**
   * Cierra la sesión
   */
  logout() {
    this.sAuth.logout();
  }
}
