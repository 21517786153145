import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { WjFlexGrid, WjFlexGridColumn } from 'wijmo/wijmo.angular2.grid';
import { CollectionView } from 'wijmo/wijmo';
import { AutoSizeMode, DataMap } from 'wijmo/wijmo.grid';
import { Año, ObjetoModelo, EstadoMovimiento } from '../../helpers/Models';
import { ToastrService } from 'ngx-toastr';
import { WijmoHelper, ModeloCollectionView } from '../../helpers/WijmoHelper';
import { ModeloService, EstudianteService, MatriculaService, ResponsablePagoService, OtrasPersonasService, CobrosService, RecibosService, ExtractoService, TareaServidorService } from '../../shared/services/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RecaudoFormHelper } from '../../helpers/RecaudoFormHelper';
import * as _ from 'underscore'
import { recaudoUI } from '../../helpers/RecaudoUI';
import { TableService } from 'src/app/shared/services/table';
import { ReciboComponent } from '../recibo/recibo.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { WjFlexGridFilter } from 'wijmo/wijmo.angular2.grid.filter';


@Component({
  selector: 'app-extracto',
  templateUrl: './extracto.component.html',
  styleUrls: ['./extracto.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExtractoComponent extends RecaudoFormHelper implements OnInit {

  //@Input('anio') public anio: Año;
  anio: Año = recaudoUI.getAñoTrabajo();
  @Input('extracto') public extracto: any;
  @Input('estadoMovimiento') public estadoMovimiento: any;

  hayCambios = false;
  botonIgnorarHabilitado = false;

  cvMovimiento: CollectionView;


  public extractoObjectoModelo: ObjetoModelo;

  //CollectionViews
  //Referencia a los controles de wijmo
  @ViewChild('flexMovimientos') flexMovimientos: WjFlexGrid;
  @ViewChild('filter') filter: WjFlexGridFilter;

  //Contiene los filtros del estracto
  filtroExtracto: any = {
    filtroEstadoEs: null,
    filtroEstadoNoEs: null,
    filtroReferencia: ""
  }

  //declaración de servicios
  constructor(
    private _toastr: ToastrService,
    private _wjHelper: WijmoHelper,
    private _changeDetector: ChangeDetectorRef,
    private _sTable: TableService,
    private _sAuth: AuthenticationService,

    private _sModelo: ModeloService,
    private _sMatricula: MatriculaService,
    private _sResponsablePago: ResponsablePagoService,
    private _sEstudiante: EstudianteService,
    private _sOtrasPersonas: OtrasPersonasService,
    private _sCobro: CobrosService,
    private _sRecibo: RecibosService,
    private _sExtracto: ExtractoService,
    private _sTarea: TareaServidorService
  ) {
    super(_toastr, _wjHelper, _sModelo, _changeDetector, _sTable, _sAuth, _sMatricula,
      _sResponsablePago, _sEstudiante, _sOtrasPersonas, _sCobro, _sRecibo, _sExtracto,_sTarea);
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {

    //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
    this.setModelosTrabajo2({
      identificador: "Odata",
      odata: [
        'Extracto;$filter=Id eq ' + this.extracto.Id,
        'Movimiento;$filter=IdExtracto eq ' + this.extracto.Id,
        'EstadoMovimiento'
      ]
    });

    //Carga la consulta con identificador 'Odata'
    this.cargarDatos("Odata", () => { });
  }

  /**
   * Trae los datos de las consultas realizadas
   * @param data El array u objeto que se esta consultado
   * @param identificador El identificador que ha sigo asignado a cada consulta
   */
  onDatosObtenidos(data: any, identificador: string) {

    //Carga los datos de la consulta de Odata
    if (identificador == "Odata") {

      //Guarda los datos del extracto
      this.extracto = _.first(data['Extracto']);

      //Formatea las fechas del estracto
      this.extracto.Desde = recaudoUI.formatDate(this.extracto['Desde']);
      this.extracto.Hasta = recaudoUI.formatDate(this.extracto['Hasta']);
      this.extracto.Cargado = recaudoUI.formatDate(this.extracto['Cargado']);

      //Guarda el estadoMovimiento 
      this.estadoMovimiento = data['EstadoMovimiento'];

      //Genera la cantidad de grids que van a ser utilizados en la vista
      this.cvMovimiento = new CollectionView(data['Movimiento']);
      this.cvMovimiento.trackChanges = true;

      //Cuando se hace focus a alguna celda
      this.flexMovimientos.focus = () => {
        this.puedeIgnorarMovimiento();
      }

      //Realiza la copia de los modelos de grid
      this.registrarModeloCollectionViews([
        new ModeloCollectionView('Movimiento', 'Id', this.cvMovimiento, this.flexMovimientos)
      ]);

      //Si el estado del movimiento es sinRecibo, el id recibo es eliminado del movimiento
      //this.flexMovimientos.onRowEditEnding = (e) => {
        this.flexMovimientos.onCellEditEnded = (e) => {

          //por medio del row trae el registro que se está editando
          var item = this.flexMovimientos.itemsSource.items[e.row];
          let em = _.find(this.estadoMovimiento, (_em) => { return _em.Nombre == "SinRecibo"; });
  
          if (item.IdEstadoMovimiento == em.Id)
            item.IdRecibo = null
  
          //Actualiza el registro
          this.flexMovimientos.itemsSource.items[e.row] = item;
          this.changeDetector.detectChanges();
        }
    }
  }

  /**
   * Realiza el filtro personalizado por row 
   */
  filtrarRowsGrid() {

    var rows = this.flexMovimientos.rows;

    _.each(rows, (row) => {

      let registroVisible = true;

      let item = row.dataItem;

      if (this.filtroExtracto.filtroEstadoEs == null && this.filtroExtracto.filtroEstadoNoEs == null && !this.filtroExtracto.filtroReferencia)
        registroVisible = true;

      if (this.filtroExtracto.filtroReferencia &&
        (item["Referencia1"] && item["Referencia1"].toString().indexOf(this.filtroExtracto.filtroReferencia.toString()) == -1 ||
          item["Referencia2"] && item["Referencia2"].toString().indexOf(this.filtroExtracto.filtroReferencia.toString()) == -1))
        registroVisible = false;

      else if (this.filtroExtracto.filtroEstadoEs != null && item["IdEstadoMovimiento"] != this.filtroExtracto.filtroEstadoEs)
        registroVisible = false;

      else if (this.filtroExtracto.filtroEstadoNoEs != null && item["IdEstadoMovimiento"] == this.filtroExtracto.filtroEstadoNoEs)
        registroVisible = false;

      row.visible = registroVisible;
    });
  }

  /**
   * Actualiza las columnas que va tener el grid
   * @param column dato de la columna
   * @param modelo nombre del primer parametro en el new ModeloCollectionView
   */
  onAjusteColumna(column: WjFlexGridColumn, modelo: string) {
    if (modelo == "Movimiento") {
      switch (column.binding) {
        case "IdEstadoMovimiento":
          //crea el select de movimientos
          column.dataMap = new DataMap(this.estadoMovimiento, "Id", "Nombre");
          break;
        default:
      }
    }
  }

  /**
   * Abre el tap del recibo
   */
  verRecibo() {

    let item = this.obtenerRegistroSeleccionado(this.flexMovimientos);

    console.log("Recibo", item);
    if (!this.verificarSiHayCambios() && item)
      recaudoUI.tabsManager.agregar("Recibo - " + (item["NumeroRecibo"] || item["Referencia1"] || item["Referencia2"]), "fa-file-text-o", ReciboComponent, { idRecibo: item.IdRecibo, anio: this.anio });
  }

  /**
   * Ignora el estracto seleccionado 
   */
  ignorarMovimiento() {

    let items = this.flexMovimientos.selectedItems; this.obtenerRegistroSeleccionado(this.flexMovimientos);

    if (items.length == 0)
      return;

    else {
      //Ignora todos los movimientos seleccionados
      _.each(items, (item) => {

        let estadoMovimineto = _.find(this.estadoMovimiento, (em) => { return em.Nombre == "Ignorado"; });

        item["IdEstadoMovimiento"] = estadoMovimineto.Id;

        //Agrega el item que se acaba de editar al edited del wijmo
        if (!_.find(this.cvMovimiento.itemsEdited, (e) => { return e.Id == item.Id; }))
          this.cvMovimiento.itemsEdited.push(item);

        this.flexMovimientos.refresh();

        this.puedeIgnorarMovimiento();

      });
    }
  }

  /**
   * Habilita y deshabilita el boton para ignorar el movimiento
   */
  puedeIgnorarMovimiento() {

    //Si no hay item seleccionado, se deshabilita el botón
    if (!this.flexMovimientos || !this.flexMovimientos.selectedItems.length) {
      this.botonIgnorarHabilitado = false;
      this.changeDetector.markForCheck();
      return;
    }

    //Obtiene el primer registro que está seleccionado
    let item = this.obtenerRegistroSeleccionado(this.flexMovimientos);

    let estadoMovimineto = _.find(this.estadoMovimiento, (em) => { return em.Id == item["IdEstadoMovimiento"]; });

    if (estadoMovimineto && estadoMovimineto["Nombre"] == "OK")
      this.botonIgnorarHabilitado = false;
    else
      this.botonIgnorarHabilitado = true;

    this.changeDetector.markForCheck();
  }

  /**
   * Permite descargar el extracto
   */
  descargar() {

    var url = this.extracto.Path.substring(this.extracto.Path.indexOf("Extractos"));

    if (this.extracto.Path.indexOf("recaudoapp.vpsnotas.com") != -1)
      window.open(recaudoUI.getBaseUrl() + url, '_blank');

    else if (this.extracto.Path.indexOf("recaudo.vpsnotas.com") != -1)
      window.open("http://recaudo.vpsnotas.com/" + url, '_blank');

    //Solo debug
    else if (this.extracto.Path.indexOf("Proyectos\Recaudo2\VPS.Recaudo.Web") != -1)
      window.open(recaudoUI.getBaseUrl() + url, '_blank');
  }

  /**
   * Permite al tap detectar si hay cambios o no, para el momento de ser cerrado
   */
  consultarSiHayCambios() {
    this.hayCambios = this.getTieneCambios();
    return this.hayCambios;
  }
}