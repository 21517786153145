import { Component, OnInit, Input, ViewChild, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'underscore';
import { WjFlexGrid, WjFlexGridColumn } from 'wijmo/wijmo.angular2.grid';
import { AutoSizeMode, DataMap } from 'wijmo/wijmo.grid';
import { CollectionView } from 'wijmo/wijmo';

import { ModeloService, MatriculaService, ResponsablePagoService, EstudianteService, OtrasPersonasService, CobrosService, RecibosService, ExtractoService, TareaServidorService } from '../../shared/services/services';
import { ColaFunciones } from '../../helpers/ColaFunciones';
import { WijmoHelper, ModeloCollectionView } from '../../helpers/WijmoHelper';
import { recaudoUI } from '../../helpers/RecaudoUI';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatriculaComponent } from '../matricula/matricula.component';
import { GrupoComponent } from '../grupo/grupo.component';
import { RecaudoFormHelper } from '../../helpers/RecaudoFormHelper';
import { Año, ObjetoModelo } from '../../helpers/Models';
import { TableService } from 'src/app/shared/services/table';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ModalGestorCostosEducativosComponent } from 'src/app/shared/components/modal-gestor-costos-educativos/modal-gestor-costos-educativos.component';
import { DirectiveRegistryValuesIndex } from '@angular/core/src/render3/interfaces/styling';

@Component({
    selector: 'app-grado',
    templateUrl: './grado.component.html',
    styleUrls: ['./grado.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GradoComponent extends RecaudoFormHelper implements OnInit, AfterViewInit {


    @ViewChild(ModalGestorCostosEducativosComponent) modalCostoEducativo: ModalGestorCostosEducativosComponent;

    @Input('gradoDto') public gradoDto: object;
    gradoObjetoModelo = new ObjetoModelo('Grado');
    @Input('anio') public anio: object;

    grado: any;

    //CollectionViews
    cvGrupos: CollectionView;
    cvCostosEducativos: CollectionView;
    cvMatriculas: CollectionView;

    //Referencia a los controles de wijmo
    @ViewChild('flexGrupos') flexGrupos: WjFlexGrid;
    @ViewChild('flexCostosEducativos') flexCostosEducativos: WjFlexGrid;
    @ViewChild('flexMatriculas') flexMatriculas: WjFlexGrid;

    //Datos de trabajo auxiliares
    modelsDatos: Array<string> = [];
    meses: Array<any> = [];
    tiposCostosEducativos: Array<any> = [];
    definicionesGrado: Array<any> = [];
    sedesJornadas: Array<any> = [];
    anios: Array<any> = [];
    grupos: Array<any> = [];
    unidadesNegocio: Array<any> = [];

    hayCambios = false;

    //Filtro del grid
    filtro = {
        buscarGrupos: "",
        buscarCostoEducativo: "",
        buscarMatriculas: "",
    };


    //declaración de servicios
    constructor(
        private modalService: NgbModal,
        private _toastr: ToastrService,
        private _wjHelper: WijmoHelper,
        private _changeDetector: ChangeDetectorRef,

        private _sTable: TableService,
        private _sModelo: ModeloService,
        private _sAuth: AuthenticationService,
        private _sMatricula: MatriculaService,
        private _sResponsablePago: ResponsablePagoService,
        private _sEstudiante: EstudianteService,
        private _sOtrasPersonas: OtrasPersonasService,
        private _sCobro: CobrosService,
        private _sRecibo: RecibosService,
        public _sExtracto: ExtractoService,
        private _sTarea: TareaServidorService
    ) {
        super(_toastr, _wjHelper, _sModelo, _changeDetector, _sTable, _sAuth, _sMatricula,
            _sResponsablePago, _sEstudiante, _sOtrasPersonas, _sCobro, _sRecibo, _sExtracto, _sTarea);
    }

    /**
     * Los componentes generados dinámicamente deben implementar esta interfaz
     */
    ngAfterViewInit() {
    }

    ngOnInit(): void {

        //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
        this.setModelosTrabajo2({
            identificador: "Odata",
            odata: [
                'Grado;$filter=Id eq ' + this.gradoDto["Id"] + '&$expand=Grupos',
                'Mes;$filter=IdAnio eq ' + this.anio["Id"],
                'TipoCostoEducativo',
                'DefinicionGrado;$orderby=Orden',
                'SedeJornada',
                'Año',
                'Matricula;$expand=Estudiante&$filter=IdGrado eq ' + this.gradoDto["Id"],
                'CostoEducativo;$filter=IdGrado eq ' + this.gradoDto["Id"] + ' &$orderby=IdTipoCostoEducativo,IdMes',
                'UnidadNegocio;$filter=IdColegio eq ' + this.anio["IdColegio"]       
            ]
        });

        this.setAñoTrabajo(this.anio as Año);

        //Carga la consulta con identificador 'Odata'
        this.cargarDatos("Odata", () => { });
    }

    /**
     * Trae los datos de las consultas realizadas
     * @param data El array u objeto que se esta consultado
     * @param identificador El identificador que ha sigo asignado a cada consulta
     */
    onDatosObtenidos(data: any, identificador: string) {

        //Carga los datos de la consulta de Odata
        if (identificador == "Odata") {

            this.meses = data['Mes'];
            this.tiposCostosEducativos = data['TipoCostoEducativo'];
            this.definicionesGrado = data['DefinicionGrado'];
            this.sedesJornadas = data['SedeJornada'];
            this.anios = data['Año'];
            this.unidadesNegocio = data['UnidadNegocio'];

            this.cvCostosEducativos = new CollectionView(data['CostoEducativo']);
            this.cvCostosEducativos.trackChanges = true;

            //Genera el objeto con el que se crea el año
            this.cvCostosEducativos.newItemCreator = () => {
                return { IdGrado: this.gradoDto["Id"], IdTipoCostoEducativo: this.tiposCostosEducativos[0].Id }
            }

            _.each(data['Matricula'], (m) => {
                m.Grupo = _.findWhere(this.grupos, { Id: m.IdGrupo });
            });

            this.cvMatriculas = new CollectionView(data['Matricula']);
            this.cvMatriculas.trackChanges = true;

            if (data['Grado'] && data['Grado'][0]) {
                this.grado = _.first(data['Grado']);
                this.gradoObjetoModelo.objeto = this.grado;
                this.gradoObjetoModelo.actualizarCopia();

                this.grupos = this.grado["Grupos"];
                this.cvGrupos = new CollectionView(this.grupos)
                this.cvGrupos.trackChanges = true;

                //Genera el objeto con el que se crea el año
                this.cvGrupos.newItemCreator = () => {
                    return { IdGrado: this.gradoDto["Id"] }
                }
            }

            //Realiza una copia del formulario para saber si hubieron cambios
            this.registrarObjetosModelo([this.gradoObjetoModelo])

            //Realiza una copia de cada grid para saber si hubieron cambios
            this.registrarModeloCollectionViews([
                new ModeloCollectionView('Grupo', 'Id', this.cvGrupos, this.flexGrupos),
                new ModeloCollectionView('CostoEducativo', 'Id', this.cvCostosEducativos, this.flexCostosEducativos),
                new ModeloCollectionView('Matricula', 'Id', this.cvMatriculas, this.flexMatriculas)
            ]);
        }
    }

    /**
     * Asigna los selects al grid
     * @param column 
     * @param modelo 
     */
    onAjusteColumna(column: WjFlexGridColumn, modelo: string) {
        if (modelo == "CostoEducativo") {
            // flex.autoSizeColumn(column.index);

            switch (column.binding) {
                case "IdTipoCostoEducativo":
                    //crea el select de meses
                    column.dataMap = new DataMap(this.tiposCostosEducativos, "Id", "Nombre");
                    break;
                case "IdMes":
                    //crea el select de meses
                    column.dataMap = new DataMap(this.meses, "Id", "Nombre");
                    break;
                default:
            }
        }
    }

    /**
     * Dulica el costo educativo con distinto mes
     */
    duplicarCostoEducativo() {

        let item = this.flexCostosEducativos.selectedItems[0];

        if (!item)
            return;

        item = recaudoUI.copyObject(item);

        let mesEncontrado = false;
        let siguienteMes = _.find(this.meses, (m) => {

            if (mesEncontrado)
                return true;

            if (m.Id == item.IdMes)
                mesEncontrado = true;
        });

        if (!siguienteMes)
            return this.toastr.error("No se a encontrado el siguiente mes");

        item.IdMes = siguienteMes["Id"];
        item.Id = 0;

        var itemNew = this.cvCostosEducativos.addNew();

        //Genera una copia con las mismas propiedades
        for (var key in item)
            itemNew[key] = item[key];

        this.cvCostosEducativos.commitNew();
    }

    /**
     * Permite copiar conceptos de otro grupo
     */
    copiarConceptos() {

        this.modalCostoEducativo.abrirModal(this.anio);
        this.modalCostoEducativo.modalRef.result.then((dataResult) => {

            console.log("Result", dataResult);

            //Realiza una copia de los comceptos educativos 
            _.each(dataResult, (c) => {

                for (var key in c)
                    if (_.isArray(c[key]) || _.isObject(c[key]))
                        c[key] = null;

                c["IdGrado"] = this.gradoDto["Id"];
                c["Id"] = 0;

                var itemNew = this.cvCostosEducativos.addNew();

                //Genera una copia con las mismas propiedades
                for (var key in c)
                    itemNew[key] = c[key];

                this.cvCostosEducativos.commitNew();
                this.cvCostosEducativos.refresh();
            });

        }, (info) => {
            console.log("Info", info);
        });
    }

    /**
     * abre el detalle de la matrícula
     * @param item grado
     */
    verMatricula() {
        let item = this.obtenerRegistroSeleccionado(this.flexMatriculas);

        if (!this.verificarSiHayCambios() && item)
            recaudoUI.tabsManager.agregar("Matrícula - " + item["Estudiante"]["Codigo"], "fa-user", MatriculaComponent, { idMatricula: item["Id"], anio: this.anio });
    }

    /**
     * abre el detalle de un grupo
     * @param item grupo
     */
    verGrupo() {
        let item = this.obtenerRegistroSeleccionado(this.flexGrupos);

        if (!this.verificarSiHayCambios() && item)
            recaudoUI.tabsManager.agregar("Grupo - " + item["Nombre"] + " - " + this.anio["Numero"], null, GrupoComponent, { grupo: item, anio: this.anio });
    }

    /**
     * Permite al tap detectar si hay cambios o no, para el momento de ser cerrado
     */
    consultarSiHayCambios() {
        this.hayCambios = this.getTieneCambios();
        return this.hayCambios;
    }
}
