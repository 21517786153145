import { ChangeDetectorRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'node_modules/underscore';
import { CollectionView } from 'wijmo/wijmo';
import { WjFlexGrid, WjFlexGridColumn } from 'wijmo/wijmo.angular2.grid';

import { WijmoHelper, ModeloCollectionView } from './../helpers/WijmoHelper';
import { ObjetoModelo, Año } from './Models';
import { recaudoUI } from './../helpers/RecaudoUI';
import { ColaFunciones } from './ColaFunciones';
import { ModeloService, MatriculaService, ResponsablePagoService, EstudianteService, OtrasPersonasService, CobrosService, RecibosService, ExtractoService, TareaServidorService } from '../shared/services/services';
import { AutoSizeMode, CellRange } from 'wijmo/wijmo.grid';
import { fn } from '@angular/compiler/src/output/output_ast';
import { TableService } from '../shared/services/table';
import { AuthenticationService } from '../shared/services/authentication.service';
import { UsuarioAuth } from '../_models/usuarioAuth';
import { callbackify } from 'util';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { ModeloDatos } from '../_models/modeloDatos';
import { ModalConfirmacionComponent } from '../shared/components/modal-confirmacion/modal-confirmacion.component';
import { forkJoin } from 'rxjs';

/**
 * define el comportamiento general de un formulario en Recaudo, clases asociadas a componentes pueden heredar esta clase para heredar sus comportamientos
 * o sobre-escribirlos según sea conveniente.
 */
export class RecaudoFormHelper {

    @ViewChild(ModalConfirmacionComponent) modalConfirmacion: ModalConfirmacionComponent;

    //Verifica si el timeout de buscar tarea está activado
    private buscandoTarea = false;

    //Almacena las tareas que están en curso
    private colaTareasEnCurso = [];
    tareaEnCurso = null;

    mostrarProgresBarTareas = false;

    public cargando: boolean = false;
    //año de trabajo para la instancia actual, es usado en los request
    private año: Año;

    private modelosDatos: ModeloDatos[] = [];

    //modelos de datos que se van a trabajar
    private modelosDatosOdata: string[] = [];
    //objetos asociados a modelos
    private objetosModelo: Array<ObjetoModelo> = [];
    //collectionsViews asociadas a modelos
    private modelosCollectionViews: Array<ModeloCollectionView> = [];

    private cargaDatosFunciones: Array<Function> = [];

    private colaFunciones: ColaFunciones;
    //almacena datos de caché de las páginas que se cargan
    private paginasModelosCache: object = {};

    //Tiene los datos del usuario autenticado
    public usuarioAuth: UsuarioAuth;

    private gridsSelectionMode = [];

    porcentaje?: number;

    //Estas variables definen los permisos por cada rol de forma decendente a la importancia de este,
    // ejemplo tienePermisoSuperAdminDesc significa que tiene todos los permisos a cambio tienePermisoSecretariaDesc
    // no hay otro rol que este por debajo a éste entonces este rol solo hara lo que corresponda como secretaria
    public tienePermisoMinimoDeSecretaria;
    public tienePermisoMinimoDeContador;
    public tienePermisoMinimoDeAdmin;
    public tienePermisoMinimoDeSuperAdmin;


    //declaración de servicios
    constructor(
        public toastr: ToastrService,
        public wjHelper: WijmoHelper,
        public sModelo: ModeloService,
        public changeDetector: ChangeDetectorRef,

        public sTabla: TableService,
        public sAuth: AuthenticationService,
        public sMatricula: MatriculaService,
        public sResponsablePago: ResponsablePagoService,
        public sEstudiante: EstudianteService,
        public sOtrasPersonas: OtrasPersonasService,
        public sCobro: CobrosService,
        public sRecibo: RecibosService,
        public sExtracto: ExtractoService,
        public STarea: TareaServidorService
    ) {
        this.usuarioAuth = this.sAuth.getUser();

        let rol = sAuth.getRol();

        this.tienePermisoMinimoDeAdmin = rol == "SuperAdmin" ? true : false;
        this.tienePermisoMinimoDeAdmin = rol == "Administrador" || rol == "SuperAdmin" ? true : false;
        this.tienePermisoMinimoDeContador = rol == "Contador" || rol == "Administrador" || rol == "SuperAdmin" ? true : false;
        this.tienePermisoMinimoDeSecretaria = rol == "Secretaria" || rol == "Secretaria auxiliar" || rol == "Contador" || rol == "Administrador" || rol == "SuperAdmin" ? true : false;

        //Se actualiza la fecha guardada
        this.sModelo.getFechaActual().subscribe(data => {

            recaudoUI.setFechaActual(data["Fecha"]);

        }, (error) => {
            this.mostrarError(error, "Error al obtener la fecha actual");
        });
    }


    /**
     * Carga los datos de trabajo principales
     * @param callback
     */
    cargarDatos(modeloParaCargar: string, callbackErr: Function) {

        //Carga los datos
        let cargarDatosPendientes = () => {

            this.cargando = true;
            //
            this.guardarLosFocosGrids();

            this.colaFunciones = new ColaFunciones();

            //agrega a la cola las funciones adicionales
            if (this.cargaDatosFunciones.length)
                _.each(this.cargaDatosFunciones, (fnc: Function) => {
                    this.colaFunciones.agregar(() => {
                        fnc.call(this, this.colaFunciones.ejecutar);
                    });
                });

            //obtiene los datos de trabajo a partir de los modelos
            this.colaFunciones.agregar(() => {

                let consultasJoin = [];
                let identificacionJoin = [];

                _.each(this.modelosDatos, (md) => {

                    //Recarga la query que se especifique, si no se especifica, toma todas por defecto
                    if (modeloParaCargar && md.identificador == modeloParaCargar || !modeloParaCargar) {

                        //Realiza la consulta de odata
                        if (md.odata && md.odata.length) {
                            consultasJoin.push(this.sModelo.getQueries(md.odata, this.año));
                            identificacionJoin.push(md.identificador);
                        }

                        //Realiza la consulta personalizada
                        else if (md.consulta) {
                            consultasJoin.push(this[md.servicio][md.metodo](md.consulta));
                            identificacionJoin.push(md.identificador);
                        }

                        /*//Realiza la consulta de odata
                        if (md.odata && md.odata.length) {
                            this.sModelo.getQueries(md.odata, this.año).subscribe((data: object) => {
                                this.onDatosObtenidos(data, md.identificador);
                                this.colaFunciones.ejecutar();
                            }, (error) => {
                                this.mostrarError(error, "Error al realizar la consulta");
                                this.errorRequest(callbackErr);
                                this.colaFunciones.ejecutar();
                            });
                        }

                        //Realiza la consulta personalizada
                        else if (md.consulta) {
                            this[md.servicio][md.metodo](md.consulta).subscribe((data) => {
                                this.onDatosObtenidos(data, md.identificador);
                                this.colaFunciones.ejecutar();
                            }, (error) => {
                                this.mostrarError(error, "Error al realizar la consulta");
                                this.errorRequest(callbackErr);
                                this.colaFunciones.ejecutar();
                            })
                        }

                        //Si no es una consulta personalizada ni Odata
                        else {
                            this.onDatosObtenidos({}, md.identificador);
                            this.colaFunciones.ejecutar();
                        }*/
                    }
                });

                //Realiza todas las consultas
                forkJoin(
                    consultasJoin
                ).subscribe(results => {

                    //Envia las consultas
                    for (var i = 0; i < results.length; i++)
                        this.onDatosObtenidos(results[i], identificacionJoin[i]);

                    //Desbloquea el boton de cargar y actualiza el html
                    setTimeout(() => {
                        this.cargando = false;
                        this.changeDetector.detectChanges();
                    }, 200);

                    this.colaFunciones.ejecutar();

                }, (error) => {
                    this.mostrarError(error, "Error al realizar la consulta");
                    this.errorRequest(callbackErr);
                    this.colaFunciones.ejecutar();
                });
            });

            //Desbloquea los botones y recarga el html
            this.colaFunciones.agregar(() => {
                this.changeDetector.detectChanges();
            });

            this.colaFunciones.ejecutar();
        }

        //Primero revisa si hay cambios pendientes por guardar antes de actualizar la página
        if (this.getTieneCambios()) {

            try {
                this.modalConfirmacion.abrirConfirmar({ textoHeader: "Antes de actualizar debe guardar los cambios realizados. Al actualizar, se descartan los cambios no guardados." });
                this.modalConfirmacion.modalRef.result.then((success: boolean) => {

                    //Si acepto la modal
                    if (success)
                        cargarDatosPendientes();

                }, (info) => {
                    console.log("Info modal", info);
                });
            } catch (error) {
                console.warn("Es necesario implementar la etiqueta <app-modal-confirmacion></app-modal-confirmacion> para utilizar la modal de confirmación");
            }
        }
        else
            cargarDatosPendientes();
    }

    /**
     * Guarda los cambios realizados en la base de datos Manager
     * @param nombreTabla
     * @param array
     */
    guardarManager(callback: Function, callbackErr: Function) {

        let puedeGuardar = this.validarFormulario("RecaudoManager");

        //No permite guardar por que hay una propiedad con error en el grid
        if (!puedeGuardar)
            return;

        //Realiza los respectivos cambios
        //let realizarGuardado = () => {
        this.cargando = true;
        this.colaFunciones = new ColaFunciones();

        if (this.objetosModelo.length)
            //guarda cambios en formularios
            _.each(this.objetosModelo, (objetoModelo: ObjetoModelo) => {
                if (objetoModelo.getTieneCammbios())
                    this.colaFunciones.agregar(() => {
                        this.sModelo.saveAuth(objetoModelo.modelo, objetoModelo.objeto).subscribe((data) => {

                            //actualiza los datos de la copia
                            objetoModelo.objeto[`Id${objetoModelo.modelo}`] = data["Id"];

                            objetoModelo.actualizarCopia();
                            this.colaFunciones.ejecutar();

                            this.mostrarMensajeExito("Se han guardado los cambios.");
                            //this.toastr.success("Se han guardado los cambios.");
                            //this.cargando = false;

                        }, (error) => {
                            this.mostrarError(error, "Error al guardar los cambios");

                            this.errorRequest(callbackErr);
                            this.colaFunciones.ejecutar();
                        });
                    });
            });

        this.colaFunciones.agregar(() => {

            this.cargando = false;
            if (callback)
                callback();
            this.changeDetector.detectChanges();

            //Despues de que guarda los cambios
            //this.actualizarDatosDespuesDeGuardar(null);
        });

        this.colaFunciones.ejecutar();
    }

    /**
     * guarda los cambios realizados a los datos.
     * @param callback
     * @param callbackErr
     */
    guardar(callback: Function, callbackErr: Function) {

        let puedeGuardar = this.validarGrid();

        if (puedeGuardar)
            puedeGuardar = this.validarFormulario();

        //No permite guardar por que hay una propiedad con error en el grid
        if (!puedeGuardar)
            return;

        this.cargando = true;
        this.colaFunciones = new ColaFunciones();

        if (this.objetosModelo.length)

            //guarda cambios en formularios
            _.each(this.objetosModelo, (objetoModelo: ObjetoModelo) => {
                if (objetoModelo.getTieneCammbios())

                    this.colaFunciones.agregar(() => {

                        this.sModelo.save(objetoModelo.modelo, [objetoModelo.objeto]).subscribe(() => {


                            objetoModelo.actualizarCopia();

                            //actualiza los datos de la copia
                            this.colaFunciones.ejecutar();
                            this.mostrarMensajeExito("Se han guardado los cambios.");

                            if (!this.getTieneCambios())
                                this.actualizarDatosDespuesDeGuardar(objetoModelo.modelo);

                        }, (error) => {
                            this.mostrarError(error, "Error al guardar los cambios");

                            this.errorRequest(callbackErr);
                            this.colaFunciones.ejecutar();
                        });
                    });
            });

        if (this.modelosCollectionViews.length)

            //guarda cambios en las collectionViews
            this.colaFunciones.agregar(() => {
                if (this.wjHelper.getHayCambiosModeloCollectionViews(this.modelosCollectionViews)) {

                    let modelos = this.obtenerCambiosModelosGrids();

                    this.wjHelper.saveModeloCollectionViews(this.modelosCollectionViews, () => {


                        this.colaFunciones.ejecutar();
                        this.mostrarMensajeExito("Se han guardado los cambios.");

                        if (!this.getTieneCambios())
                            this.actualizarDatosDespuesDeGuardar(modelos);

                    }, (error) => {
                        this.mostrarError(error, "Error al guardar los cambios");

                        this.errorRequest(callbackErr);
                        this.colaFunciones.ejecutar();
                    });
                }
            });

        this.colaFunciones.agregar(() => {

            this.cargando = false;
            if (callback)
                callback();
            this.changeDetector.detectChanges();
        });

        this.colaFunciones.ejecutar();
    }

    /**
     * Avisa que los datos han sido guardados
     */
    actualizarDatosDespuesDeGuardar(modulos) {
        //console.warn("Para recargar los datos despues de guardar es necesario imlementar la funcion actualizarDatosDespuesDeGuardar");
    }

    /**
     * Verifica si hay cambios antes de abrir un nuevo tap
     */
    verificarSiHayCambios() {

        if (this.getTieneCambios()) {
            this.toastr.warning("Debe guardar los cambios primero");
            return true;
        }

        return false;
    }

    /**
     * Obtiene el primer registro que ha sido seleccionado en el grid
     * @param modeloCollection
     */
    obtenerRegistroSeleccionado(modeloCollection, muestraError?) {

        let item = modeloCollection.selectedItems[0];

        if (!item) {
            if ((muestraError === undefined || muestraError === null || muestraError === true) && modeloCollection.itemsSource.items.length != 0)
                this.toastr.warning("Es necesario seleccionar un registro");
            return;
        }
        return item;
    }

    /**
     * Valida el formulario HTML
     * @param nombreDB Especfica a que base de datos pertenece las tablas
     */
    validarFormulario(nombreDB?): boolean {

        for (let m of this.objetosModelo) {

            let propiedadesTabla;

            //Obtiene las propiedades que son obligatorias de la tabla que se está validando
            if (nombreDB == "RecaudoManager")
                propiedadesTabla = this.sTabla.getPropiedadesObligatoriasRecaudoManagerDB(m.modelo);
            else if (!nombreDB || nombreDB == "Recaudo")
                propiedadesTabla = this.sTabla.getPropiedadesObligatorias(m.modelo);

            //Almacena las propiedades que hacen falta llenar
            let propiedadesConError = [];

            //Si un valor está lleno obliga a que los otros dos se llenen, si el modelo es matrícula
            if(m.modelo == 'Matricula'){
                //Si hay algo en SaldoAFavor
                if(m.objeto['SaldoAFavor']){
                    if(!m.objeto['SaldoAFavorFecha']){
                        propiedadesConError.push('SaldoAFavorFecha');
                    }
                    if(!m.objeto['SaldoAFavorObservacion']){
                        propiedadesConError.push('SaldoAFavorObservacion');
                    }
                }
                //Si hay algo en SaldoAFavorFecha
                else if(m.objeto['SaldoAFavorFecha']){

                    if(!m.objeto['SaldoAFavor']){
                        propiedadesConError.push('SaldoAFavor');
                    }
                    if(!m.objeto['SaldoAFavorObservacion']){
                        propiedadesConError.push('SaldoAFavorObservacion');
                    }
                }
                //Si hay algo en SaldoAFavorObservacion
                else if(m.objeto['SaldoAFavorObservacion']){

                    if(!m.objeto['SaldoAFavor']){
                        propiedadesConError.push('SaldoAFavor');
                    }
                    if(!m.objeto['SaldoAFavorFecha']){
                        propiedadesConError.push('SaldoAFavorFecha');
                    }
                }
            }

            for (var j = 0; j < propiedadesTabla.length; j++)
                if (m.objeto[propiedadesTabla[j]] === undefined || m.objeto[propiedadesTabla[j]] === null || m.objeto[propiedadesTabla[j]] === "")
                    propiedadesConError.push(propiedadesTabla[j]);

            //Si encontro por lo menos una propiedad en blanco al ser editada
            if (propiedadesConError.length > 0) {
                this.toastr.error(`${propiedadesConError[0]} es requerido`);
                return false;
            }
        }

        return true;
    }

    /**
     * Obtiene los modelos que se han editado, modificado o eliminado
     */
    obtenerCambiosModelosGrids() {
        let modelos = [];
        for (let m of this.modelosCollectionViews)
            if (m.collectionView.itemsEdited.length || m.collectionView.itemsAdded.length || m.collectionView.itemsRemoved)
                modelos.push(m.modelo);

        return modelos;
    }

    /**
     * Valida las propiedades del grid
     */
    validarGrid(nombreDB?): boolean {

        for (let m of this.modelosCollectionViews) {

            let itemsSave = [];

            if (m.collectionView.itemsEdited.length)
                itemsSave.push.apply(itemsSave, m.collectionView.itemsEdited);

            if (m.collectionView.itemsAdded.length)
                itemsSave.push.apply(itemsSave, m.collectionView.itemsAdded);

            let propiedadesTabla;

            //Obtiene las propiedades que son obligatorias de la tabla que se está validando
            if (nombreDB == "RecaudoManager")
                propiedadesTabla = this.sTabla.getPropiedadesObligatoriasRecaudoManagerDB(m.modelo);
            else if (!nombreDB || nombreDB == "Recaudo")
                propiedadesTabla = this.sTabla.getPropiedadesObligatorias(m.modelo);

            //Almacena las propiedades que hacen falta llenar
            let propiedadesConError = [];

            //Obtiene el primer registro que tenga un error
            let hayPropiedadConError = _.find(itemsSave, (item) => {

                for (var j = 0; j < propiedadesTabla.length; j++)
                    if (item[propiedadesTabla[j]] === undefined || item[propiedadesTabla[j]] === null || item[propiedadesTabla[j]] === "")
                        propiedadesConError.push(propiedadesTabla[j]);

                return propiedadesConError.length != 0;
            });

            //Si encontro por lo menos una propiedad en blanco al ser editada
            if (hayPropiedadConError) {
                this.toastr.error(`${propiedadesConError[0]} es requerido`, `En la tabla ${m.modelo}`);
                return false;
            }
        }

        return true;
    }

    /**
     * se invoca cuando un request falla
     * @param callback
     */
    errorRequest(callback: Function = null): void {
        this.cargando = false;
        if (callback)
            callback();
    }

    /**
     * Guarda el foco de cada grid
     */
    guardarLosFocosGrids() {

        if (this.modelosCollectionViews.length)
            _.each(this.modelosCollectionViews, (mcv: ModeloCollectionView) => {

                var modelo = _.find(this.gridsSelectionMode, (fs) => { return fs.modelo == mcv.modelo; });

                //Si no existe, guarda el indice de la celda seleccionada y el modelo del grid
                if (!modelo)
                    this.gridsSelectionMode.push({ selection: mcv.wjFlexGrid.selection, modelo: mcv.modelo });

                //Si existe solo edita el indice de la celda seleccionada
                else
                    modelo.selection = mcv.wjFlexGrid.selection
            });
    }

    /**
     * Reestablece la posición donde estaba el foco en cada grid cada vez que se recarga la página
     */
    reestablecerLosFocosGrid() {
        if (this.modelosCollectionViews.length)
            _.each(this.modelosCollectionViews, (mcv: ModeloCollectionView) => {

                var modelo = _.find(this.gridsSelectionMode, (fs) => { return fs.modelo == mcv.modelo; });

                if (modelo) {

                    //Si hay tareas en curso espera 5 segundos para denuevo ver el estado de la tarea
                    setTimeout(() => {

                        var s = modelo["selection"];
                        mcv.wjFlexGrid.selection = new CellRange(s.row != -1 ? s.row : -0, s.col, s.row2 != -1 ? s.row2 : 0, s.col2);

                        this.changeDetector.detectChanges();
                    }, 20);
                }
            });
    }

    /**
     * ajusta las columnas de las wjFlexGrid de las collectionviews registradas
     */
    ajustarColumnas() {

        _.each(this.modelosCollectionViews, (mcv: ModeloCollectionView) => {
            if (!mcv.wjFlexGrid)
                throw "Los objetos de tipo ModeloCollectionView usados en RecaudoFormHelper deben definir la propiedad wjFlexGrid";

            mcv.wjFlexGrid.autoSizeMode = AutoSizeMode.Both;

            _.each(mcv.wjFlexGrid.columns, (c: WjFlexGridColumn) => {
                c.quickAutoSize = true;
                this.onAjusteColumna(c, mcv.modelo);
            });

            //https://us.grapecitydev.com/wijmo/demos/Grid/Editing/EditingEvents/angular
            //soluciona problemas de actualización de la vista para las wjFlexGrid por la estrategia de detección de cambios OnPush
            mcv.wjFlexGrid.onCellEditEnded.apply = (s, e) => {

                var val = s.getCellData(e["row"], e["col"], false);
                s.setCellData(e["row"], e["col"], val.toUpperCase());

            }

            mcv.wjFlexGrid.onItemsSourceChanged = () => {
                this.changeDetector.markForCheck();
            }

            mcv.wjFlexGrid.onPrepareCellForEdit = () => {

                this.changeDetector.markForCheck();
            };

            mcv.wjFlexGrid.refresh();
        });
    }

    /**
     * Agrega un item nuevo a la collectionV indicada
     * @param flex
     * @param cv
     */
    agregar(flex: WjFlexGrid, cv: CollectionView) {

        let i = cv.addNew();

        cv.commitNew();
    }

    /**
     * Elimina un item de la collectionV indicada
     * @param flex
     * @param cv
     */
    eliminar(flex: WjFlexGrid, cv: CollectionView, sinConfirmar?: boolean) {

        //Elimina sin confirmación
        if (sinConfirmar)
            for (let i of flex.selectedItems)
                cv.remove(i);

        //Realiza la confirmación antes de eliminar
        else {
            //Configura el texto que se va a mostrar antes de eliminar el/los rejistro(s)
            let texto = "¿Está seguro de eliminar ";

            if (flex.selectedItems.length > 1)
                texto += `los ${flex.selectedItems.length} registros seleccionados`;

            else if (flex.selectedItems.length == 1) {
                if(flex.selectedItems[0].Movimientos)
                    texto += `el extracto: ${flex.selectedItems[0]["Nombre"]}`;
                else if (flex.selectedItems[0]["Nombre"])
                    texto += `el registro con nombre ${flex.selectedItems[0]["Nombre"]}`;
                else if (flex.selectedItems[0]["Id"])
                    texto += `el registro con el id ${flex.selectedItems[0]["Id"]}`;
                else
                    texto += `el registro seleccionado`;
            }

            try {
                //Abre la modal de comfirmación
                this.modalConfirmacion.abrirConfirmar({ textoHeader: texto });
                this.modalConfirmacion.modalRef.result.then((success: boolean) => {

                    //Si se acepto eliminar el registro
                    if (success) {
                        for (let i of flex.selectedItems)
                            cv.remove(i);
                    }
                }, (info) => {
                    console.log("Modal info", info);
                });
            } catch (error) {
                console.warn("Es necesario implementar la etiqueta <app-modal-confirmacion></app-modal-confirmacion> para utilizar la modal de confirmación");
            }
        }
    }

    /**
     * indica si hay cambios en los datos
     */
    getTieneCambios(): boolean {

        let ret: boolean = false;

        //verifica si hay cambios en las grids de trabajo
        if (!ret)
            ret = this.wjHelper.getHayCambiosModeloCollectionViews(this.modelosCollectionViews);

        if (!ret)
            ret = _.find(this.objetosModelo, (om: ObjetoModelo) => { return om.getTieneCammbios(); });

        return ret;
    }

    /**
     * Verifica si la mátricula está activa o no y asi coloca la fecha
     */
    actualizarMatricula(objetoModelo) {
        if (objetoModelo.objeto.Activo && objetoModelo.objeto.FechaRetiro != null) {
            objetoModelo.objeto.FechaRetiro = null;
            this.changeDetector.detectChanges();
        }

        else if (!objetoModelo.objeto.Activo && objetoModelo.objeto.FechaRetiro == null) {
            objetoModelo.objeto.FechaRetiro = recaudoUI.getFechaActual();
            this.changeDetector.detectChanges();
        }
    }

    /**
     * registra los objetos modelo de trabajo
     * @param objetosModelo objetos modelo de trabajo
     */
    registrarObjetosModelo(objetosModelo: Array<ObjetoModelo>) {

        this.objetosModelo = objetosModelo;
    }

    /**
     * registra los ModeloCollectionViews de trabajo
     * @param modelosCollectionViews ModeloCollectionViews de trabajo
     */
    registrarModeloCollectionViews(modelosCollectionViews: Array<ModeloCollectionView>) {

        this.modelosCollectionViews = modelosCollectionViews;
        this.ajustarColumnas();
        this.reestablecerLosFocosGrid();
    }

    /**
     * establece los nombres de los modelos que se van a consultar, estos deben ser nombrados exactamente como se nombran
     * en el Core del back y opcionalmente se puede especificar una query Odata seguida de un ";" ej: Grupo;$expand=Matriculas
     * @param models nombres de los modelos de los datos que se van a consultar
     */
    setModelosTrabajo(models: string[]) {
        this.modelosDatosOdata = models;
    }

    /**
     * @param model - Odata: establece los nombres de los modelos que se van a consultar, estos deben ser nombrados exactamente como se nombran
     * en el Core del back y opcionalmente se puede especificar una query Odata seguida de un ";" ej: Grupo;$expand=Matriculas
     * - consulta: los parametros que se va a enviar al metodo
     * - servicio: el nombre del servicio que va ser utilizado para la consulta
     * - metodo: especifica el methodo del back a la cual se va realizar la consulta
     * - identificador: el nombre que se le va dar a la consulta
     */
    setModelosTrabajo2(model: ModeloDatos) {

        let modeloDatos = _.find(this.modelosDatos, (md) => { return md.identificador == model.identificador; });

        //Agrega una nueva consulta
        if (!modeloDatos)
            this.modelosDatos.push(model);

        //Sobreescribe la consulta
        else {
            modeloDatos.consulta = model.consulta;
            modeloDatos.identificador = model.identificador;
            modeloDatos.metodo = model.metodo;
            modeloDatos.odata = model.odata;
            modeloDatos.servicio = model.servicio;
        }
    }

    /**
     * establece el año con respecto al cual se van a realizar las consultas
     * @param año
     */
    setAñoTrabajo(año: Año) {
        this.año = año;
    }

    /**
     * Agrega una función a la cola de carga del método CargarDatos(), las funciones deben invocar ejecutar() desde la cola
     * que se les agrega como parámetro de lo contrario la carga de datos se colgará.
     * @param fnc función a agregar
     */
    cargaDatosAgregarFuncion(fnc: Function) {
        this.cargaDatosFunciones.push(fnc);
    }

    /**
    * se invoca cunado los datos de los modelos se han consultado
    * @param data
    */
    onDatosObtenidos(data: any, identificador: string) { }

    /**
     * se invoca cuando se va ajustar una columna inmediatamente después de registrar los ModeloCollectionViews
     * @param column columna
     * @param modelo nombre del modelo asociado
     */
    onAjusteColumna(column: WjFlexGridColumn, modelo: string) { }

    /**
     * carga la página de registros indicada se según el modelo y cantidad de registros indicados.
     * @param modelo modelo a partir del cual se cargarán los datos
     * @param nPagina número de página a cargar
     * @param nRegistros número de registros que va a tener la página
     * @param callback función que se ejecuta al terminar la carga de datos
     * @param cache indica si los datos se deben almacenar en caché para acelerar cargas en el futuro
     */
    cargarPaginaModelo(modeloQuery: string, nPagina: number, nRegistros, callback: Function, cache: boolean) {

        //obtiene los registros de la página, ommite los de páginas anteriores
        modeloQuery += '&$top=' + nRegistros + (nPagina > 1 ? "&$skip=" + ((nPagina * nRegistros) - nRegistros) : "");

        //si es de caché y ya se había cargado antes...
        if (cache && this.paginasModelosCache[modeloQuery]) {
            callback(recaudoUI.copyObject(this.paginasModelosCache[modeloQuery]));
            return;
        }

        this.sModelo.getQueries([modeloQuery]).subscribe((data) => {
            let modeloNombre = modeloQuery.split(';')[0];

            if (cache)
                this.paginasModelosCache[modeloQuery] = recaudoUI.copyObject(data[modeloNombre]);

            callback(recaudoUI.copyObject(data[modeloNombre]));
        }, (error) => {
            this.mostrarError(error, "Ha ocurrido un error");
        });
    }

    /**
     * carga los registros correspondientes a la página indicada en la lista indicada.
     * @param list lista de registros
     * @param nPagina número de página a obtener
     * @param nRegistros número de registros de la página
     */
    cargarPaginaDeLista(list: Array<any>, nPagina: number, nRegistros) {
        if (list.length < (nPagina * nRegistros) - nRegistros)
            //this.mostrarError(null, null, "la lista no tiene suficientes items para obtener la página " + nPagina);
            this.toastr.error("la lista no tiene suficientes items para obtener la página " + nPagina);

        return _.filter(list, (i, index: number) => {
            //retorna los items que se encuentren en el rango
            return index + 1 <= nPagina * nRegistros && index + 1 >= (nPagina * nRegistros) - nRegistros;
        });
    }

    /**
     * Crea la tarea sincrona con su respectivo resultado
     */
    crearTareaSync(nombre, p1, p2, p3, p4, p5, p6) {

        let tarea = this.sTabla.generarObjectTareaServidor(nombre, p1, p2, p3, p4, p5, p6);

        this.cargando = true;
        this.changeDetector.detectChanges();

        this.STarea.postTareaServicioSinc(tarea).subscribe(dataTarea => {

            this.mostrarMensajeTareaRevisada(dataTarea);

        }, (error) => {
            this.mostrarError(error, "Error  al crear la tarea");
        })
    }

    /**
     * Crea la tarea Para lugo ser consultada para saber su respectivo estado
     */
    crearTarea(nombre, p1, p2, p3, p4, p5, p6) {

        this.cargando = true;
        this.changeDetector.detectChanges();

        let tarea = this.sTabla.generarObjectTareaServidor(nombre, p1, p2, p3, p4, p5, p6);
        this.mostrarProgresBarTareas = true;

        this.sModelo.save("TareaServidor", [tarea]).subscribe(data => {

            this.tareaEnCurso = data[0].Object;
            this.changeDetector.detectChanges();

            this.revisarTareasEnCurso();

        }, (error) => {

            this.mostrarProgresBarTareas = false;
            this.mostrarError(error, "Error  al crear la tarea");
        });
    }

    /**
     * Revisar tarea en curso
     */
    private revisarTareasEnCurso() {

        this.buscandoTarea = true;

        //this.sModelo.getById("TareaServidor", this.colaTareasEnCurso[0]["Id"] + "").subscribe(dataTareaServidor => {
        this.sModelo.getById("TareaServidor", this.tareaEnCurso["IdTareaServidor"] + "").subscribe(dataTareaServidor => {

            if (dataTareaServidor["Porcentaje"])
                this.porcentaje = Number(dataTareaServidor["Porcentaje"]);

            //Verifica que la tarea ya haya terminado
            if (dataTareaServidor["Estado"] != 0) {

                this.mostrarProgresBarTareas = false;
                this.changeDetector.detectChanges();

                this.cargando = false;
                this.changeDetector.detectChanges();

                //Muestra un mensaje para informar al usuario si la tarea termino y su estado (success, error)
                this.mostrarMensajeTareaRevisada(dataTareaServidor);

                //La tarea terminado de procesar
                this.tareaEnCurso = null;
                this.porcentaje = null;
                this.changeDetector.detectChanges();
            }
            else
                this.tareaEnCurso = dataTareaServidor;

            //Si hay tareas en curso espera 5 segundos para denuevo ver el estado de la tarea
            setTimeout(() => {

                if (this.tareaEnCurso == null)
                    this.changeDetector.detectChanges();

                else
                    this.revisarTareasEnCurso();
            }, 5000);

        }, (error) => {
            this.mostrarError(error, "Ha ocurrido un error al realizar la tarea");
        });
    }

    /**
     * Genera el mensaje al cual se va a mostrar al usuario cuando la tarea sea finalizada
     * @param tareaServidor
     */
    public mostrarMensajeTareaRevisada(tareaServidor) {
        //console.warn("Para poder trabajar con la tareaServidor es necesario implementar la función 'mostrarMensajeTareaRevisada'");
    }


    /**
     * Muestra un mensaje de éxito
     * @param mensaje
     */
    mostrarMensajeExito(mensaje) {

        this.toastr.success(mensaje);

        this.cargando = false;
        this.changeDetector.detectChanges();
    }

    /**
     * Muestra el mensaje de error
     * @param error
     */
    mostrarError(error, mensaje, excepcionControlada?) {

        //Arma el mensaje de error
        let textoError = excepcionControlada;

        console.log("error", error);
        try {

            if (!textoError)
                textoError = error.error.InnerException.InnerException ? error.error.InnerException.InnerException.ExceptionMessage : '';

            if (!textoError)
                textoError = error.error.InnerException.InnerException ? error.error.InnerException.InnerException.ExceptionMessage : '';

            if (!textoError)
                textoError = error.error.InnerException && error.error.InnerException.InnerException ? error.error.InnerException.InnerException : '';

            if (!textoError)
                textoError = error.error.ExceptionMessage ? error.error.ExceptionMessage : '';

            if (!textoError)
                textoError = error.ExceptionMessage ? error.ExceptionMessage : '';

            if (!textoError && mensaje != "Error al realizar la consulta")
                textoError = "Error al guardar los cambios";

            if (textoError.indexOf("DELETE statement conflicted") != -1)
                textoError = "No se puede eliminar el registro, hay otra tabla que lo está utilizando";

        } catch (err) {

            if (!textoError)
                textoError = error.error.ExceptionMessage ? error.error.ExceptionMessage : '';

            if (!textoError)
                textoError = recaudoUI.convertirAString(error);
        }

        //Muestra el mensaje de error
        if (mensaje)
            this.toastr.error(textoError, mensaje);
        else
            this.toastr.error(textoError);

        this.cargando = false;
        this.changeDetector.detectChanges();
    }


}
