import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {  CommonModule} from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';

import { WjGridModule, WjFlexGrid } from 'wijmo/wijmo.angular2.grid';
import { WjInputModule } from 'wijmo/wijmo.angular2.input';
import { WjFlexGridFilter, WjGridFilterModule } from 'wijmo/wijmo.angular2.grid.filter';
import 'wijmo/cultures/wijmo.culture.es-MX';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';

import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';

import { Approutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';
import { LoginComponent } from './component/login/login.component';
import { AuthGuard } from './security/auth.guard';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

//Shared modulo 
import { SharedModule } from './shared/shared.module';

//directivas
//import { ComponentLoaderDirective } from './shared/directives/component-loader/component-loader.directive';
//import { ResizeHeightToWindowDirective } from './shared/directives/resize-height-to-window/resize-height-to-window.directive';
//import { RefreshWindowOnClickDirective } from './shared/directives/refresh-window-on-click/refresh-window-on-click.directive'

//services
import { AñoService, MatriculaService, RecibosService, EstudianteService, ModeloService, OtrasPersonasService, ResponsablePagoService, ExtractoService, ManagerService } from './shared/services/services';
import { Interceptor } from './shared/services/interceptor';
import { WijmoHelper } from './helpers/WijmoHelper';

//Component 
import { ExportarInformesComponent } from './component/exportar-informes/exportar-informes.component';

// Firebase modules
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';

//Components
/*
//import { NgbdDatepickerBasicComponent } from './component/datepicker/datepicker.component';
import { TabsPrincipalComponent } from './component/tabs-principal/tabs-principal.component';
import { AnioComponent } from './component/anio/anio.component';
import { AniosComponent } from './component/anios/anios.component';
import { BusquedaEstudiantesComponent } from './component/busqueda-estudiantes/busqueda-estudiantes.component';
import { EstudianteComponent } from './component/estudiante/estudiante.component';
import { ExtractoComponent } from './component/extracto/extracto.component';
import { ExtractosComponent } from './component/extractos/extractos.component';
import { FacturacionComponent } from './component/facturacion/facturacion.component';
import { GradoComponent } from './component/grado/grado.component';
import { GrupoComponent } from './component/grupo/grupo.component';
import { HomeComponent } from './component/home/home.component';
import { InicioComponent } from './component/inicio/inicio.component';
import { MatriculasComponent } from './component/matriculas/matriculas.component';
//import { } from './component/panel-opciones-grid/panel-opciones-grid.component';
import { ReciboComponent } from './component/recibo/recibo.component';
*/
import { setLicenseKey } from 'wijmo/wijmo';
setLicenseKey('diegomesata@gmail.com,recaudo.vpsnotas.com|evalweb.vpsnotas.com|vpsnotas.com|www.vpsnotas.com|cafeteriapp.vpsnotas.com|agenda.vpsnotas.com|agendavirtual.vpsnotas.com|recaudoapp.vpsnotas.com|estudiantes.vpsnotas.com|localhost,177964815498175#B0K7oeut4aBFkahhFThlFNw86aOd5KBtCWW3UQhB7QF5GczADNqF5Yw5GZl9EN8EUYmVGZpVkVChldvEETGtmaINVax8UbI3iURZHO8YzM7w6V694SWF5Y7MmbXhTeGlTZ0tWTVpVMs54M9lWYJBHUlh5TU94TxdjYhNVZt36d7UDa7MzY8hXd7NmYHJjTK9mcZR4dI3EO6tiMaR6RFJVYNpVOLdGSqdVNOJnRTdUbTFHRnRWUVF5YLNUNkdnWEJUYsVnSFdWW9gFTygUZ0hXMSp6TyUDaJFERWtkaDRjZ0FVM8p4MPN6Y4JFOx2kYIZEVwZGTsN4QhpFSVljVDV7dvxUUxcTNHxGSQVnepdVN4sycjJnNMNFSmZ4bxhHOnhmUll5VpVlewFlQEZlS7IWWYpUcCFHSmhWOzFUQSJ6bvhWZTN7Yr4kYDdTa5gzb9IGeQ3mUiojITJCLiQjMzMjRzIjI0ICSiwiN4MTMwMjMxMTM0IicfJye#4Xfd5nIzMEMCJiOiMkIsISZy36Qg2Wbql6ViojIOJyebpjIkJHUiwiI4UTNzATMgMTM8ATOxAjMiojI4J7QiwiI4N7boxWYj3Gbs46bj9ychR7buNHc69yclRnbhlGZ5R7clxSbvNmLzFGdv96cwZnLwBXYvRWdhNWZyxSbvNmLzFGdv96cwZnLsFWd4JXa6FGZuV6ZhxSbvNmLzFGdv96cwZnLhRmbldWYs46bj9ychR7buNHc69CcwFWayVGdlZWYjxSbvNmLzFGdv96cwZnL7d7ds46bj9ychR7buNHc6xSbvNmLzFGdv96cwZnLiV6dsFmdlxSbvNmLzFGdv96cwZnLvRWdhNWZyJiOiMXbEJCLi46bj9CbpFWbnBUY4F6cl56bnVWakJiOiEmTDJCLiUzNxgTO4UTM8QjN9czNxIiOiQWSiwSfiEjd8EDMyIiOiIXZ6JCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TP3dNV');

//pipes
import { FilterPipe } from './shared/pipes/filter'
import { from } from 'rxjs';

import { UnidadNegocioComponent } from './component/unidad-negocio/unidad-negocio.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
  minScrollbarLength: 20
};

@NgModule({
  declarations: [

    //directivas
    /*ComponentLoaderDirective,
    ResizeHeightToWindowDirective,
    RefreshWindowOnClickDirective,
*/
    AppComponent,
    //NgbdDatepickerBasicComponent,
    SpinnerComponent,
    FullComponent,
    BlankComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    LoginComponent,

    //Mis components
    /* 
    HomeComponent,
    TabsPrincipalComponent,
    AnioComponent,
    AniosComponent,
    BusquedaEstudiantesComponent,
    EstudianteComponent,
    ExtractoComponent,
    ExtractosComponent,
    FacturacionComponent,
    GradoComponent,
    GrupoComponent,
    InicioComponent,
    MatriculasComponent,
    ReciboComponent,
    FilterPipe*/
    ExportarInformesComponent,
    UnidadNegocioComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    RouterModule.forRoot(Approutes),
    PerfectScrollbarModule,

    SharedModule,

    ToastrModule.forRoot(),
    WjGridModule,
    WjInputModule,
    WjGridFilterModule,    
    AngularFireModule.initializeApp(environment.firebaseConfig, 'recaudoapp'), // Required for everything
    AngularFireAuthModule, // Only required for auth features,
  ],
  exports: [
    /*AnioComponent,
    AniosComponent,
    BusquedaEstudiantesComponent,
    EstudianteComponent,
    ExtractoComponent,
    ExtractosComponent,
    FacturacionComponent,
    GradoComponent,
    GrupoComponent,
    InicioComponent,
    MatriculasComponent,
    ReciboComponent*/
    ExportarInformesComponent,
    UnidadNegocioComponent
  ],
  entryComponents: [
    /*AnioComponent,
    AniosComponent,
    BusquedaEstudiantesComponent,
    EstudianteComponent,
    ExtractoComponent,
    ExtractosComponent,
    FacturacionComponent,
    GradoComponent,
    GrupoComponent,
    InicioComponent,
    MatriculasComponent,
    ReciboComponent*/
    ExportarInformesComponent,
    UnidadNegocioComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true,
      //provide: PERFECT_SCROLLBAR_CONFIG,
      //useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    AuthGuard,
    EstudianteService,
    AñoService,
    MatriculaService,
    RecibosService,
    ModeloService,
    ManagerService,
    WijmoHelper,
    OtrasPersonasService,
    ResponsablePagoService,
    ExtractoService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
