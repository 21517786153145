import { Component, OnInit, Input, Output, EventEmitter, NgZone } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';

import { ManagerService } from '../../shared/services/services';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { JsonPipe } from '@angular/common';
import { ifStmt } from '@angular/compiler/src/output/output_ast';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  usuario: any = { Password: "", Email: "" };
  colegios: object = [];
  cargando = false;

  //Contiene el mensaje de error
  mensaje = '';
  args : any = null;

  constructor(
    private sManager: ManagerService,
    private sAutenticacion: AuthenticationService,
    private routes: Router,
    private route: ActivatedRoute,
    private ngZone: NgZone
  ) { }

  ngOnInit() {

    console.log("cargando login");

    //Si encuentra argumentos los recupera
    if(sessionStorage["args"]) {
      this.args = JSON.parse(sessionStorage["args"]);
      sessionStorage.removeItem("args");
    }

    if (this.getQueryArg("authToken")) {
      this.cargando = true;
      this.sAutenticacion.login({ AuthToken: this.getQueryArg("authToken") }).subscribe(

        data => {
          this.loginOk(data);
          this.cargando = false;
        },
        error => {
          this.loginError(error);
          this.cargando = false;
        });
    }

    //Verifica si existe una sesión iniciada
    this.sAutenticacion.currentUser.subscribe(data => {

      if (data != null)
        this.routes.navigate(['/principal']);
    });
  }

  getQueryArg(name:string) {
    
    if(this.args)
      return this.args[name];
    else
      this.route.snapshot.queryParamMap.get(name);
  }

  /**
   * Realiza el logeo del usuario
   */
  entrar() {

    //Si el usuario escribio la contraseña y el Email 
    if (this.usuario["Email"] && this.usuario["Password"]) {

      this.cargando = true;

      this.sAutenticacion.login(this.usuario).subscribe((data: any) => {

        //Contraseña o email incorrecto
        if (data["Error"]) {
          this.mensaje = data["Error"];
          this.cargando = false;
        }

        //Si fue un exito logea el usuario y guarda el token
        else {
          this.mensaje = '';

          this.ngZone.run(() => {
            this.routes.navigate(['/principal']);
          });
        }

      }, (error) => {
        this.mensaje = "El usuario ó contraseña son invalidas.";
        this.cargando = false;
      });
    } else
      this.mensaje = "Ingrese Email y Contraseña.";
  }

  loginOk(data) {
    //Si login ok, se verifica auth exitosa antes de hacer el redirect

    this.ngZone.run(() => {

      let url = null;

      if (this.getQueryArg("anio") && this.getQueryArg("codigo"))
        url = {
          anio: this.getQueryArg("anio"),
          codigo: this.getQueryArg("codigo")
        };
      //url = `?anio=${this.route.snapshot.queryParamMap.get("anio")}&codigo=${this.route.snapshot.queryParamMap.get("codigo")}`;

      this.mensaje = data["Error"];

      //Abre una matricula por defecto
      if (url != null) {
        console.log(data, url);
        this.routes.navigate(['/principal', url]);
      }


      else if (!this.mensaje)
        this.routes.navigate(['/principal']);

      this.cargando = false;
    });
  }

  loginError(error) {
    this.ngZone.run(() => {

      var msj = error;
      if (error.code) {
        msj = error.code + " " + error.message;

        if (error.code == "auth/account-exists-with-different-credential" && error.credential) {
          if (error.credential.providerId == "facebook.com")
            msj = "Su cuenta " + error.email + " ya está asociada con Google, no puede usar Facebook para iniciar sesión, debe usar Google";
          else
            msj = "Su cuenta " + error.email + " ya está asociada con otro proveedor, use el otro proveedor para iniciar sesión";
        }
      }
      else if(error.error && error.error.ExceptionMessage)
        msj = error.error.ExceptionMessage;
      else
        msj = JSON.stringify(error);

      this.mensaje = msj;
      this.cargando = false;
    });
  }

  //Autentica al usuario desde google
  firebaseAuth(provider) {
    this.mensaje = "";
    this.cargando = true;
    //Envía el request con callback de ok y error
    this.sAutenticacion.firebaseAuth(
      provider,
      false,
      data => this.loginOk(data),
      error => this.loginError(error)).then(res => {

      }).catch(error => {
        this.mensaje = error;
        this.cargando = false;
      });
  }
}