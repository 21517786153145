import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import * as _ from 'underscore';

@Component({
  selector: 'app-modal-ajustar-interes-descuento',
  templateUrl: './modal-ajustar-interes-descuento.component.html',
  styleUrls: ['./modal-ajustar-interes-descuento.component.css']
})
export class ModalAjustarInteresDescuentoComponent implements OnInit {

  @ViewChild('ajustaInteresDescuentoModal') private ajustaInteresDescuentoModal;

  private anio: object;
  private meses = [];
  private mesSeleccionado;

  modalRef: NgbModalRef;

  constructor(
    private _modalService: NgbModal,
    private toastr: ToastrService,
    private router: Router,
    public changeDetector: ChangeDetectorRef,
    private config: NgbModalConfig,
  ) { }

  ngOnInit() {
  }

  /**
   * Abre la modal para seleccionar el mes y generar el ajuste de interes descuento 
   * @param meses 
   */
  public abrirAjusteInteresDescuento(meses): NgbModalRef {
    this.meses = meses;

    if (this.meses.length)
      this.mesSeleccionado = this.meses[0];

    //Abre la modal con su respectiva configuración
    this.config.backdrop = "static";
    this.config.keyboard = false;

    this.modalRef = this._modalService.open(this.ajustaInteresDescuentoModal, { size: "lg" });

    return this.modalRef;
  }

  /**
   * Retorna el mes seleccionado
   */
  private aceptar() {
    if (!this.mesSeleccionado) {
      this.toastr.error("Es necesario seleccionar un mes");
      return;
    }

    this.modalRef.close(this.mesSeleccionado);
  }

  /**
   * Cierra la modal
   */
  private cerrarModal() {
    this.modalRef.dismiss("Cerrar");
  }
}
