import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Año, ObjetoModelo } from 'src/app/helpers/Models';
import { recaudoUI } from 'src/app/helpers/RecaudoUI';
import { RecaudoFormHelper } from 'src/app/helpers/RecaudoFormHelper';
import { CollectionView } from 'wijmo/wijmo';
import { WjFlexGrid } from 'wijmo/wijmo.angular2.grid';
import { ToastrService } from 'ngx-toastr';
import { WijmoHelper, ModeloCollectionView } from 'src/app/helpers/WijmoHelper';
import { ModeloService, MatriculaService, ResponsablePagoService, EstudianteService, OtrasPersonasService, CobrosService, RecibosService, ExtractoService, TareaServidorService } from 'src/app/shared/services/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AutoSizeMode } from 'wijmo/wijmo.grid';
import * as _ from 'underscore';
import { AnioComponent } from '../anio/anio.component';
import { UnidadNegocioComponent } from '../unidad-negocio/unidad-negocio.component';
import { TableService } from 'src/app/shared/services/table';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
  selector: 'app-colegio',
  templateUrl: './colegio.component.html',
  styleUrls: ['./colegio.component.css']
})
export class ColegioComponent extends RecaudoFormHelper implements OnInit {

  anio: Año = recaudoUI.getAñoTrabajo();
  hayCambios = false;

  colegioObjetoModelo = new ObjetoModelo('Colegio');

  @Input('idColegio') public idColegio: any;

  colegio: object;

  //CollectionViews
  cvAnios: CollectionView;
  cvTiposConceptos: CollectionView;
  cvTiposCostosEducativos: CollectionView;
  cvTiposServicios: CollectionView;
  cvTiposRecibos: CollectionView;
  cvCuentas: CollectionView;
  cvSedeJornadas: CollectionView;
  cvDefinicionesGrado: CollectionView;
  cvParametros: CollectionView;
  cvUnidadesNegocio: CollectionView;

  //Referencia a los controles de wijmo
  @ViewChild('flexAnios') flexAnios: WjFlexGrid;
  @ViewChild('flexTiposConceptos') flexTiposConceptos: WjFlexGrid;
  @ViewChild('flexTiposRecibos') flexTiposRecibos: WjFlexGrid;
  @ViewChild('flexTiposCostosEducativos') flexTiposCostosEducativos: WjFlexGrid;
  @ViewChild('flexTiposServicios') flexTiposServicios: WjFlexGrid;
  @ViewChild('flexCuentas') flexCuentas: WjFlexGrid;
  @ViewChild('flexSedeJornadas') flexSedeJornadas: WjFlexGrid;
  @ViewChild('flexDefinicionesGrado') flexDefinicionesGrado: WjFlexGrid;
  @ViewChild('flexParametros') flexParametros: WjFlexGrid;
  @ViewChild('flexUnidadesNegocio') flexUnidadesNegocio: WjFlexGrid;

  //Filtro del grid
  filtro = {
    buscaAnio: "",
    buscaTiposConceptos: "",
    buscaTiposCostoEducativo: "",
    buscaTiposServicios: "",
    buscaSedeJornada: "",
    buscaDefinicionesGrado: ""
  };

  constructor(
    private _toastr: ToastrService,
    private _wjHelper: WijmoHelper,
    private _changeDetector: ChangeDetectorRef,
    private _modalService: NgbModal,

    private _sModelo: ModeloService,
    private _sTable: TableService,
    private _sAuth: AuthenticationService,
    private _sMatricula: MatriculaService,
    private _sResponsablePago: ResponsablePagoService,
    private _sEstudiante: EstudianteService,
    private _sOtrasPersonas: OtrasPersonasService,
    private _sCobro: CobrosService,
    private _sRecibo: RecibosService,
    private _sExtracto: ExtractoService,
    private _sTarea: TareaServidorService
  ) {
    super(_toastr, _wjHelper, _sModelo, _changeDetector, _sTable, _sAuth, _sMatricula,
      _sResponsablePago, _sEstudiante, _sOtrasPersonas, _sCobro, _sRecibo, _sExtracto, _sTarea);
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {

    //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
    this.setModelosTrabajo2({
      identificador: "Odata",
      odata: [
        'Colegio;$filter=Id eq ' + this.idColegio +
        '&$expand=Años,Cuentas,DefinicionesGrado,Parametros,SedeJornadas,TiposConcepto,TiposCostoEducativo,TiposServicio,UnidadesNegocio',
        'TipoRecibo;$orderby=Orden'
      ]
    });

    //Carga la consulta con identificador 'Odata'
    this.cargarDatos("Odata", () => { });
  }


  /**
   * Trae los datos de las consultas realizadas
   * @param data El array u objeto que se esta consultado
   * @param identificador El identificador que ha sigo asignado a cada consulta
   */
  onDatosObtenidos(data: any, identificador: string) {
    
    //Carga los datos de la consulta de Odata
    if (identificador == "Odata") {
      
      this.colegio = _.first(data['Colegio']);

      //Actualiza el modelo con el que se va a trabajar
      this.colegioObjetoModelo.objeto = this.colegio;
      this.colegioObjetoModelo.actualizarCopia();
      this.registrarObjetosModelo([this.colegioObjetoModelo]);

      //console.log('onDatosObtenidos', data, this.idColegio, this.colegioObjetoModelo);
      //Asigna los datos a los grids
      this.cvAnios = new CollectionView(this.colegio["Años"]);
      this.cvCuentas = new CollectionView(this.colegio["Cuentas"]);
      this.cvDefinicionesGrado = new CollectionView(this.colegio["DefinicionesGrado"]);
      this.cvParametros = new CollectionView(this.colegio["Parametros"]);
      this.cvSedeJornadas = new CollectionView(this.colegio["SedeJornadas"]);
      this.cvTiposConceptos = new CollectionView(this.colegio["TiposConcepto"]);
      this.cvTiposCostosEducativos = new CollectionView(this.colegio["TiposCostoEducativo"]);
      this.cvTiposServicios = new CollectionView(this.colegio["TiposServicio"]);
      this.cvTiposRecibos = new CollectionView(data["TipoRecibo"]);
      this.cvUnidadesNegocio = new CollectionView(this.colegio["UnidadesNegocio"]);

      //Permite la creacion de nuevos items
      this.cvAnios.newItemCreator = () => {
        return { IdColegio: this.idColegio };
      };
      this.cvCuentas.newItemCreator = () => {
        return { IdColegio: this.idColegio, RecaudoEmpresarial: false };
      };
      this.cvDefinicionesGrado.newItemCreator = () => {
        return { IdColegio: this.idColegio, Graduacion: false };
      };
      this.cvParametros.newItemCreator = () => {
        return { IdColegio: this.idColegio };
      };
      this.cvSedeJornadas.newItemCreator = () => {
        return { IdColegio: this.idColegio };
      };
      this.cvTiposConceptos.newItemCreator = () => {
        return { IdColegio: this.idColegio, CargaManual: false, GeneraInteres: false };
      };
      this.cvTiposCostosEducativos.newItemCreator = () => {
        return { IdColegio: this.idColegio };
      };
      this.cvTiposServicios.newItemCreator = () => {
        return { IdColegio: this.idColegio };
      };
      this.cvTiposRecibos.newItemCreator = () => {
        return { AutoCrearNuevos: false };
      };
      //envia datos predeterminados
      this.cvUnidadesNegocio.newItemCreator = () => {
        return { IdColegio: this.idColegio};
      };

      this.cvAnios.trackChanges = true;
      this.cvCuentas.trackChanges = true;
      this.cvDefinicionesGrado.trackChanges = true;
      this.cvParametros.trackChanges = true;
      this.cvSedeJornadas.trackChanges = true;
      this.cvTiposConceptos.trackChanges = true;
      this.cvTiposCostosEducativos.trackChanges = true;
      this.cvTiposServicios.trackChanges = true;
      this.cvTiposRecibos.trackChanges = true;
      this.cvUnidadesNegocio.trackChanges = true;

      //Genera la copia de los flexgrids 
      this.registrarModeloCollectionViews([
        new ModeloCollectionView('Año', 'Id', this.cvAnios, this.flexAnios),
        new ModeloCollectionView('Cuenta', 'Id', this.cvCuentas, this.flexCuentas),
        new ModeloCollectionView('DefinicionGrado', 'Id', this.cvDefinicionesGrado, this.flexDefinicionesGrado),
        new ModeloCollectionView('Parametro', 'Id', this.cvParametros, this.flexParametros),
        new ModeloCollectionView('SedeJornada', 'Id', this.cvSedeJornadas, this.flexSedeJornadas),
        new ModeloCollectionView('TipoConcepto', 'Id', this.cvTiposConceptos, this.flexTiposConceptos),
        new ModeloCollectionView('TipoCostoEducativo', 'Id', this.cvTiposCostosEducativos, this.flexTiposCostosEducativos),
        new ModeloCollectionView('TipoServicio', 'Id', this.cvTiposServicios, this.flexTiposServicios),
        new ModeloCollectionView('TipoRecibo', 'IdTipoRecibo', this.cvTiposRecibos, this.flexTiposRecibos),
        new ModeloCollectionView('UnidadNegocio', 'IdUnidadNegocio', this.cvUnidadesNegocio, this.flexUnidadesNegocio)
      ]);
    }
  }

  /**
   * Actualiza las columnas que va tener el grid
   * @param column dato de la columna
   * @param modelo nombre del primer parametro en el new ModeloCollectionView
   *
  onAjusteColumna(column: WjFlexGridColumn, modelo: string) {
    if (modelo == "Colegio") {
      switch (column.binding) {
        default:
      }
    }
  }

  /**
   * Edita/crea Años
   * @param esParaEdicion 
   */
  editarAnio() {

    let item = this.obtenerRegistroSeleccionado(this.flexAnios);

    if (!this.verificarSiHayCambios() && item)
      recaudoUI.tabsManager.agregar("Año - " + item['Etiqueta'], "fa-calendar-o", AnioComponent, { anioDto: item });
  }

  
  /**
   * Edita unidad de negocio
   * @param esParaEdicion 
   */
  editarUnidadNegocio() {
    
    let item = this.obtenerRegistroSeleccionado(this.flexUnidadesNegocio);
    //console.log(item);
    if (!this.verificarSiHayCambios() && item)
      recaudoUI.tabsManager.agregar("Unidad de negocio - " + item['Nombre'], null, UnidadNegocioComponent, { idUnidadNegocio: item.IdUnidadNegocio });
  }

  /**
   * Permite al tap detectar si hay cambios o no, para el momento de ser cerrado
   */
  consultarSiHayCambios() {
    this.hayCambios = this.getTieneCambios();
    return this.hayCambios;
  }
}
