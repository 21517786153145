import { Component, OnInit, Input, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { RecaudoFormHelper } from 'src/app/helpers/RecaudoFormHelper';
import { ObjetoModelo } from 'src/app/helpers/Models';
import { ToastrService } from 'ngx-toastr';
import { WijmoHelper } from 'src/app/helpers/WijmoHelper';
import { ModeloService, ManagerService, MatriculaService, ResponsablePagoService, EstudianteService, OtrasPersonasService, CobrosService, RecibosService, ExtractoService, TareaServidorService } from 'src/app/shared/services/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TableService } from 'src/app/shared/services/table';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import * as _ from 'underscore';
import { Router } from '@angular/router';
import { ModalConfirmacionComponent } from 'src/app/shared/components/modal-confirmacion/modal-confirmacion.component';
import { UsuarioAuth } from 'src/app/_models/usuarioAuth';
import { recaudoUI } from 'src/app/helpers/RecaudoUI';
import { ModalCambiarPasswordComponent } from 'src/app/shared/components/modal-cambiar-password/modal-cambiar-password.component';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.css']
})
export class UsuarioComponent extends RecaudoFormHelper implements OnInit {

  @ViewChild(ModalConfirmacionComponent) modalConfirmacion: ModalConfirmacionComponent;
  @ViewChild(ModalCambiarPasswordComponent) modalCambioPassword: ModalCambiarPasswordComponent;

  @Input('idUsuario') public idUsuario: any;

  showPassword1: boolean = false;
  showPassword2: boolean = false;
  hayCambios = false;

  //Roles del usuario
  roles = [
    { etiqueta: "SuperAdmin" },
    { etiqueta: "Administrador" },
    { etiqueta: "Contador" },
    { etiqueta: "Secretaria" },
    { etiqueta: "Secretaria auxiliar" }
  ];

  usuarioObjetoModelo = new ObjetoModelo('Usuario');
  usuario: Object = null;
  colegios: Object;

  //Tiene los datos del usuario autenticado
  public usuarioAuth: UsuarioAuth;

  constructor(
    private sManager: ManagerService,
    //private location: Location,
    private _toastr: ToastrService,
    private _wjHelper: WijmoHelper,
    private _changeDetector: ChangeDetectorRef,
    private _sTable: TableService,

    private _sModelo: ModeloService,
    private _sAuth: AuthenticationService,
    private _sMatricula: MatriculaService,
    private _sResponsablePago: ResponsablePagoService,
    private _sEstudiante: EstudianteService,
    private _sOtrasPersonas: OtrasPersonasService,
    private _sCobro: CobrosService,
    private _sRecibo: RecibosService,
    public _sExtracto: ExtractoService,
    private _sTarea: TareaServidorService
  ) {
    super(_toastr, _wjHelper, _sModelo, _changeDetector, _sTable, _sAuth, _sMatricula,
      _sResponsablePago, _sEstudiante, _sOtrasPersonas, _sCobro, _sRecibo, _sExtracto, _sTarea);
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {

    //Espera 2 segundos para volver habilitar el botón de guardar y el de actualizar
    setTimeout(() => {
      this.cargando = false;
      this.changeDetector.detectChanges();
    }, 2000);

    //Contiene los datos del usuario que está logeado
    this.usuarioAuth = this.sAuth.getUser();

    if (this.usuarioAuth.Rol === 'Administrador')
      this.roles = _.filter(this.roles, (r) => { return r.etiqueta != "SuperAdmin" });

    this.actualizarDatosUsuario();
  }

  /**
   * Realiza la consulta del usuario que está logeado
   */
  actualizarDatosUsuario() {

    if (this.usuario && this.usuario["IdUsuario"])
      this.idUsuario = this.usuario["IdUsuario"];

    this.sManager.getColegios().subscribe(dataColegios => {

      this.colegios = dataColegios;

      //Para crear un usuario
      if (this.idUsuario == 0) {
        this.usuario = {
          IdUsuario: 0,
          Nombre: "",
          Apellido: "",
          Email: "",
          Password: "",
          ConfirmacionPassword: "",
          Rol: "Contador",
          IdColegio: this.colegios[0].IdColegio
        }

        this.onDatosObtenidos(null);
      }

      //Para editar un usuario
      else {
        this.sManager.getUsuario(this.idUsuario).subscribe(dataUsuarios => {

          this.usuario = dataUsuarios;

          //Se asigna un password provicional para saltar la validacion del formulario,
          //pero esto no quiere decir que se va a cambiar el password en el servidor
          this.usuario["Password"] = "******";

          //Genera la consulta en el servicio RecaudoFormHelper
          this.onDatosObtenidos([]);

        }, (error) => { console.log("Error", error); });
      }
    }, (error) => { console.log("Error", error); });
  }

  /**
   * Obtiene la consulta de la funcion cargarDatos()
   * @param data
   */
  onDatosObtenidos(data: object) {

    this.usuarioObjetoModelo.objeto = this.usuario;
    this.usuarioObjetoModelo.actualizarCopia();
    this.registrarObjetosModelo([this.usuarioObjetoModelo]);

    this.changeDetector.detectChanges();
  }

  /**
   * Abre una modal para el cambio de password
   */
  cambiarPassword() {
    //this.modal.abrirCambiarPassword(this.usuario["IdUsuario"]);
    this.modalCambioPassword.abrirCambiarPassword(this.usuario);

    //this.modal.modalRef.result.then((data) => {
    this.modalCambioPassword.modalRef.result.then((data) => {

    }, (info) => {
      console.log("info", info);
    });
  }

  /**
   * Permite al tap detectar si hay cambios o no, para el momento de ser cerrado
   */
  consultarSiHayCambios() {
    this.hayCambios = this.getTieneCambios();
    return this.hayCambios;
  }

  /**
   * Permite cambiar de colegio al superadministrador
   */
  cambiarColegio() {

    if (!this.usuario["IdUsuario"] || this.usuario["IdUsuario"] != this.usuarioAuth.IdUsuario)
      return;

    this.modalConfirmacion.abrirConfirmar({ textoHeader: "Ha realizado el cambio de colegio, aconsejamos guardar todos los cambios realizados porque el navegador va ser recargado, ¿está seguro de continuar?." })
    this.modalConfirmacion.modalRef.result.then((result) => {

      //Realiza el cambio en la base de datos y luego recarga la página
      if (result) {
        this.sManager.postCambioColegio(this.usuario["IdColegio"]).subscribe((data) => {


          sessionStorage.setItem("token", data['Token']);
          sessionStorage.setItem("user", data['Usuario']);
          sessionStorage.setItem("currentUser", JSON.stringify(data['Usuario']));

          this.toastr.warning("Se ha cambiado de colegio, Es necesario actualizar la tab principal");
          recaudoUI.tabsManager.cerrarTodasLasTabs();
          //location.reload();

        }, (error) => {
          console.log("Se ha generado un error ", error);
        });
      }

      //Si cancelo el cambio de colegio
      else
        this.usuario["IdColegio"] = this.usuarioAuth.IdColegio;

    }, (error) => {
      console.log("Error", error);
    });
  }

  /**
   * Actualiza la tap
   */
  actualizarDatosDespuesDeGuardar(modulos) {

    let tabActual = recaudoUI.tabsManager.getTabActual();
    tabActual.header = `Usuario - ${this.usuario["Nombre"]}`;

    recaudoUI.tabsManager.setTab(tabActual["guid"], null, tabActual);

    let user = {
      Apellido: this.usuario["Apellido"],
      Email: this.usuario["Email"],
      IdColegio: 6,
      IdUsuario: 1,
      Nombre: this.usuario["Nombre"],
      Nombres: this.usuario["Apellido"] + " " + this.usuario["Nombre"],
      Rol: "SuperAdmin"
    };

    sessionStorage.setItem("currentUser", JSON.stringify(user));

  }
}
