import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { RecaudoFormHelper } from 'src/app/helpers/RecaudoFormHelper';
import { Año } from 'src/app/helpers/Models';
import { recaudoUI } from 'src/app/helpers/RecaudoUI';
import { CollectionView } from 'wijmo/wijmo';
import { WjFlexGrid, WjFlexGridColumn } from 'wijmo/wijmo.angular2.grid';
import { ToastrService } from 'ngx-toastr';
import { WijmoHelper, ModeloCollectionView } from 'src/app/helpers/WijmoHelper';
import { ModeloService, MatriculaService, ResponsablePagoService, EstudianteService, CobrosService, RecibosService, ExtractoService, TareaServidorService } from 'src/app/shared/services/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AutoSizeMode, DataMap } from 'wijmo/wijmo.grid';
import { OtrasPersonasService } from '../../shared/services/services';
import { PersonaComponent } from '../persona/persona.component';
import { TableService } from 'src/app/shared/services/table';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ModalAlertaComponent } from 'src/app/shared/components/modal-alerta/modal-alerta.component';
import { GestorNombres } from 'src/app/helpers/gestorNombres';
import * as _ from 'underscore';

@Component({
  selector: 'app-otras-personas',
  templateUrl: './otras-personas.component.html',
  styleUrls: ['./otras-personas.component.css']
})
export class OtrasPersonasComponent extends RecaudoFormHelper implements OnInit {

  anio: Año = recaudoUI.getAñoTrabajo();

  @Input('listaPersonas') public listaPersonas: any; //ListaPersonas[];

  personaDto = {
    Nombre: null,
    Identificacion: null,
    IdColegio: null
  }
  hayCambios = false;

  //CollectionViews
  cvListaPersonas: CollectionView = new CollectionView([]);

  //Referencia a los controles de wijmo
  @ViewChild('flexListaPersonas') flexListaPersonas: WjFlexGrid;
  
  @ViewChild(ModalAlertaComponent) modalAlerta: ModalAlertaComponent;

  constructor(
    private gestorNombres: GestorNombres,
    private _toastr: ToastrService,
    private _wjHelper: WijmoHelper,
    private _changeDetector: ChangeDetectorRef,
    private _modalService: NgbModal,

    //private sOtrasPersonas: OtrasPersonasService,
    private _sModelo: ModeloService,
    private _sTable: TableService,
    private _sAuth: AuthenticationService,
    private _sMatricula: MatriculaService,
    private _sResponsablePago: ResponsablePagoService,
    private _sEstudiante: EstudianteService,
    private _sOtrasPersonas: OtrasPersonasService,
    private _sCobro: CobrosService,
    private _sRecibo: RecibosService,
    public _sExtracto: ExtractoService,
    private _sTarea: TareaServidorService
  ) {
    super(_toastr, _wjHelper, _sModelo, _changeDetector, _sTable, _sAuth, _sMatricula,
      _sResponsablePago, _sEstudiante, _sOtrasPersonas, _sCobro, _sRecibo, _sExtracto,_sTarea);
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {

    setTimeout(() => {
      this.cvListaPersonas.trackChanges = true;

      //Permite crear nuevos registros de persona
      this.cvListaPersonas.newItemCreator = () => {
        return { IdColegio: this.anio["IdColegio"], EmailVerificado: false }
      };

      //Crea una copia del flexgrid para saber si hubo cambios
      this.registrarModeloCollectionViews([
        new ModeloCollectionView('Persona', 'Id', this.cvListaPersonas, this.flexListaPersonas)
      ]);
    }, 500);
  }

  /**
   * Trae los datos de las consultas realizadas
   * @param data El array u objeto que se esta consultado
   * @param identificador El identificador que ha sigo asignado a cada consulta
   */
  onDatosObtenidos(data: any, identificador: string) {

    //Carga los datos de la consulta de Odata
    if (identificador == "buscarPersonas") {

      this.cvListaPersonas = new CollectionView(data);
      
      //Permite crear nuevos registros de persona
      this.cvListaPersonas.newItemCreator = () => {
        return { IdColegio: this.anio["IdColegio"], EmailVerificado: false }
      };

      this.cvListaPersonas.trackChanges = true;

      //Crea una copia del flexgrid para saber si hubo cambios
      this.registrarModeloCollectionViews([
        new ModeloCollectionView('Persona', 'Id', this.cvListaPersonas, this.flexListaPersonas)
      ]);
    }
  }

  /**
   * Realiza la busqueda de acuerdo al filtro
   */
  buscar() {

    this.personaDto.IdColegio = this.anio.IdColegio;
    this.flexListaPersonas.autoSizeMode = AutoSizeMode.Both;

    //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
    this.setModelosTrabajo2({
      servicio: "sOtrasPersonas",
      identificador: "buscarPersonas",
      metodo: "buscarPersonas",
      consulta: this.personaDto
    });

    //Carga solo la consulta con identificador 'busca las personas relacionadas'
    this.cargarDatos("buscarPersonas", () => { });

  }

  /**
   * edita un registro
   */
  editar(esParaEdicion: boolean) {

    //Edita la persona seleccionada
    let item = this.flexListaPersonas.selectedItems[0];

    if (!this.verificarSiHayCambios())
      recaudoUI.tabsManager.agregar("Persona - " + item["Nombre"], "fa fa-users", PersonaComponent, { idPersona: item.Id });
  }

  /**
   * Actualiza las columnas que va tener el grid
   * @param column dato de la columna
   * @param modelo nombre del primer parametro en el new ModeloCollectionView
   */
  onAjusteColumna(column: WjFlexGridColumn, modelo: string) {
    if (modelo == "Persona") {
      switch (column.binding) {
        case "TipoDocumento":
          column.dataMap = new DataMap(["CC", "NIT", "PEP", "CE", "PAS", "PPT"]);
          break;
        default:
      }
    }
  }

  /**
   * Separa el nombre y los apellidos en el lado del cliente
   */
  separarNombres(formatoSeparacion) {

    let cantidad = 0;

    var textoUsuariosSeparados = "";

    _.each(this.cvListaPersonas.sourceCollection, (item) => {

      //Si el responsable no tiene dividido los nombres y apellidos
      if (!item.Nombre1) {

        var personaDto = this.gestorNombres.separarNombres(item.Nombre, formatoSeparacion);

        if (personaDto["Nombre1"] != "" && personaDto["Nombre1"]) {

          cantidad++;

          textoUsuariosSeparados += "<br>" + item.Nombre;

          this.cvListaPersonas.editItem(item);

          item.Nombre1 = personaDto["Nombre1"];
          item.Nombre2 = personaDto["Nombre2"];
          item.Apellido1 = personaDto["Apellido1"];
          item.Apellido2 = personaDto["Apellido2"];
        }
      }
    });

    this.modalAlerta.abrirConfirmar(
      {
        textHeader: "Se han separado " + cantidad + " nombres de responsables, con el formato " + formatoSeparacion.toLowerCase(),
        textBody: textoUsuariosSeparados,
      });
    this.modalAlerta.modalRef.result.then((result: boolean) => {
    }, (info) => {
      console.log("info", info);
    });

    this.cvListaPersonas.refresh();
    //this.toastr.success(`Se han separado ${cuenta} nombres`);
  }

  /**
   * Permite al tap detectar si hay cambios o no, para el momento de ser cerrado
   */
  consultarSiHayCambios() {
    this.hayCambios = this.getTieneCambios();
    return this.hayCambios;
  }
}
