import { Component, OnInit, ViewChild, ChangeDetectorRef, Input } from '@angular/core';
import { CollectionView } from 'wijmo/wijmo';
import { WjFlexGrid, WjFlexGridColumn } from 'wijmo/wijmo.angular2.grid';
import { ToastrService } from 'ngx-toastr';
import { WijmoHelper, ModeloCollectionView } from 'src/app/helpers/WijmoHelper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModeloService, MatriculaService, ResponsablePagoService, EstudianteService, OtrasPersonasService, CobrosService, RecibosService, ExtractoService, TareaServidorService } from 'src/app/shared/services/services';
import { TableService } from 'src/app/shared/services/table';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { RecaudoFormHelper } from 'src/app/helpers/RecaudoFormHelper';
import { recaudoUI } from 'src/app/helpers/RecaudoUI';
import * as _ from 'underscore';
import { DataMap } from 'wijmo/wijmo.grid';
import { ReciboComponent } from '../recibo/recibo.component';
import { ExtractoComponent } from '../extracto/extracto.component';

@Component({
  selector: 'app-movimientos',
  templateUrl: './movimientos.component.html',
  styleUrls: ['./movimientos.component.css']
})
export class MovimientosComponent extends RecaudoFormHelper implements OnInit {

  @Input('Movimientos') public Movimientos: any;


  copiaFiltro: any;
  hayCambios = false;
  countItems: number = 0;
  currentPage: number = 1;

  filtro = {
    FechaDesde: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
    FechaHasta: new Date(),
    EstadoEs: null,
    EstadoNoEs: null,
    Referencia: "",
    Pagina: 1,
    Top: 50,
    Oficina: null,
  };
  estadoMovimientos: Array<Object> = [];
  
  //select predeterminado del filtro oficina
  oficinas = [
    {Nombre: 'Recaudo', Id: 'Recaudo'},
    {Nombre: 'PSE', Id: 'PSE'},
  ];

  //CollectionViews
  cvMovimientos: CollectionView = new CollectionView([]);

  //Referencia a los controles de wijmo
  @ViewChild('flexMovimientos') flexMovimientos: WjFlexGrid;
  @ViewChild('ngbPaginationElm') ngbPaginationElm: any;

  constructor(
    private _toastr: ToastrService,
    private _wjHelper: WijmoHelper,
    private _changeDetector: ChangeDetectorRef,
    private _modalService: NgbModal,

    //private sOtrasPersonas: OtrasPersonasService,
    private _sModelo: ModeloService,
    private _sTable: TableService,
    private _sAuth: AuthenticationService,
    private _sMatricula: MatriculaService,
    private _sResponsablePago: ResponsablePagoService,
    private _sEstudiante: EstudianteService,
    private _sOtrasPersonas: OtrasPersonasService,
    private _sCobro: CobrosService,
    private _sRecibo: RecibosService,
    public _sExtracto: ExtractoService,
    private _sTarea: TareaServidorService
  ) {
    super(_toastr, _wjHelper, _sModelo, _changeDetector, _sTable, _sAuth, _sMatricula,
      _sResponsablePago, _sEstudiante, _sOtrasPersonas, _sCobro, _sRecibo, _sExtracto, _sTarea);
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {

    this.copiaFiltro = recaudoUI.copyObject(this.filtro);

    //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
    this.setModelosTrabajo2({
      identificador: "estadoMovimiento",
      odata: [
        'EstadoMovimiento'
      ]
    });

    //Carga la consulta con identificador 'Odata'
    this.cargarDatos("estadoMovimiento", () => { });
  }

  /**
   * Trae los datos de las consultas realizadas
   * @param data El array u objeto que se esta consultado
   * @param identificador El identificador que ha sigo asignado a cada consulta
   */
  onDatosObtenidos(data: any, identificador: string) {
    //console.log('movimientos', data, identificador);

    //Actualiza la lista de movimientos del filtro
    if (identificador == "estadoMovimiento")
      this.estadoMovimientos = data["EstadoMovimiento"];

    //Carga el grid de todos los movimientos filtrados 
    else if (identificador == "buscarMovimientos") {

      this.copiaFiltro = recaudoUI.copyObject(this.filtro);

      this.countItems = data["count"];

      this.cvMovimientos = new CollectionView(data["results"]);

      this.cvMovimientos.trackChanges = true;

      //console.log(data["results"]);
      var ofs = this.oficinas;
      //agrega las oficinas que estan en la consulta
      data["results"].forEach(function (result) {
        //busca si ya avia agregado uno igual
        var encontreOficina = ofs.find(function (of) {
          return of.Nombre == result['Oficina'];
        });
        //si no encuentra nada agrega el nuevo al select
        if(!encontreOficina)
          ofs.push({Nombre: result['Oficina'], Id: result['Oficina']});//result['Id']
      });

      //Crea una copia del flexgrid para saber si hubo cambios
      this.registrarModeloCollectionViews([
        new ModeloCollectionView('Movimiento', 'Id', this.cvMovimientos, this.flexMovimientos)
      ]);

      //Si el estado del movimiento es sinRecibo, el id recibo es eliminado del movimiento
      //this.flexMovimientos.onRowEditEnding = (e) => {
      this.flexMovimientos.onCellEditEnded = (e) => {

        //por medio del row trae el registro que se está editando
        var item = this.flexMovimientos.itemsSource.items[e.row];
        let em = _.find(this.estadoMovimientos, (_em) => { return _em.Nombre == "SinRecibo"; });

        if (item.IdEstadoMovimiento == em.Id)
          item.IdRecibo = null

        //Actualiza el registro
        this.flexMovimientos.itemsSource.items[e.row] = item;
        this.changeDetector.detectChanges();
      }
    }
  }

  /**
   * Permite ver el extracto que esta relacionado al movimiento
   * @param movimiento 
   */
  verExtracto(movimiento) {
    recaudoUI.tabsManager.agregar("Extracto - " + movimiento.Extracto.Nombre, null, ExtractoComponent, { extracto: movimiento.Extracto });
  }

  /**
   * Realiza la busqueda de acuerdo al filtro
   */
  buscar() {

    this.filtro.Pagina = this.ngbPaginationElm.page;

    if (!_.isEqual(this.filtro, this.copiaFiltro)) {

      //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
      this.setModelosTrabajo2({
        servicio: "sExtracto",
        identificador: "buscarMovimientos",
        metodo: "buscarMovimientos",
        consulta: this.filtro
      });

      //Carga solo la consulta con identificador 'busca las personas relacionadas'
      this.cargarDatos("buscarMovimientos", () => { });
    }
  }

  /**
   * Actualiza las columnas que va tener el grid
   * @param column dato de la columna
   * @param modelo nombre del primer parametro en el new ModeloCollectionView
   */
  onAjusteColumna(column: WjFlexGridColumn, modelo: string) {
    if (modelo == "Movimiento") {
      switch (column.binding) {
        case "IdEstadoMovimiento":
          column.dataMap = new DataMap(this.estadoMovimientos, "Id", "Nombre");
          break;
        default:
      }
    }
  }

  /**
   * Abre el tap del recibo
   */
  verRecibo() {
    let item = this.obtenerRegistroSeleccionado(this.flexMovimientos);

    if (!item["IdRecibo"]) {
      this.toastr.warning("No se encontró recibo asociado al movimiento seleccionado");
      return;
    }

    if (!this.verificarSiHayCambios() && item)
      recaudoUI.tabsManager.agregar("Recibo - " + (item["Referencia1"] || item["Referencia2"]), "fa-file-text-o", ReciboComponent, { idRecibo: item["IdRecibo"], anio: recaudoUI.getAñoTrabajo });
  }

  /**
   * Permite al tap detectar si hay cambios o no, para el momento de ser cerrado
   */
  consultarSiHayCambios() {
    this.hayCambios = this.getTieneCambios();
    return this.hayCambios;
  }

}
