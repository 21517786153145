import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { WjFlexGrid, WjFlexGridColumn } from 'wijmo/wijmo.angular2.grid';
import { CollectionView } from 'wijmo/wijmo';
import { ObjetoModelo, Año } from '../../helpers/Models';
import { ToastrService } from 'ngx-toastr';
import { WijmoHelper, ModeloCollectionView } from '../../helpers/WijmoHelper';
import { ModeloService, EstudianteService, MatriculaService, ResponsablePagoService, OtrasPersonasService, CobrosService, RecibosService, ExtractoService, TareaServidorService } from '../../shared/services/services';
import { RecaudoFormHelper } from '../../helpers/RecaudoFormHelper';
import * as _ from 'underscore'
import { recaudoUI } from '../../helpers/RecaudoUI';
import { MatriculaComponent } from '../matricula/matricula.component';
import { TableService } from 'src/app/shared/services/table';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ModalCrearMatriculaComponent } from 'src/app/shared/components/modal-crear-matricula/modal-crear-matricula.component';

@Component({
  selector: 'app-estudiante',
  templateUrl: './estudiante.component.html',
  styleUrls: ['./estudiante.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EstudianteComponent extends RecaudoFormHelper implements OnInit, AfterViewInit {

  @ViewChild(ModalCrearMatriculaComponent) modaCrearMatricula: ModalCrearMatriculaComponent;

  @Input('estudiante') public estudiante: object;
  @Input('anio') public anio: object;

  estudianteObjetoModelo = new ObjetoModelo('Estudiante');
  hayCambios = false;

  //CollectionViews
  cvMatriculas: CollectionView;

  //Referencia a los controles de wijmo
  @ViewChild('flexMatriculas') flexMatriculas: WjFlexGrid;

  //declaración de servicios
  constructor(
    private _toastr: ToastrService,
    private _wjHelper: WijmoHelper,
    private _sModelo: ModeloService,
    private _changeDetector: ChangeDetectorRef,
    private _sTable: TableService,
    //private sEstudiante: EstudianteService,
    private _sAuth: AuthenticationService,
    private _sMatricula: MatriculaService,
    private _sResponsablePago: ResponsablePagoService,
    private _sEstudiante: EstudianteService,
    private _sOtrasPersonas: OtrasPersonasService,
    private _sCobro: CobrosService,
    private _sRecibo: RecibosService,
    private _sExtracto: ExtractoService,
    private _sTarea: TareaServidorService
  ) {
    super(_toastr, _wjHelper, _sModelo, _changeDetector, _sTable, _sAuth, _sMatricula,
      _sResponsablePago, _sEstudiante, _sOtrasPersonas, _sCobro, _sRecibo, _sExtracto,_sTarea);
  }

  ngAfterViewInit(): void {
  }


  ngOnInit(): void {

    //Genera el objeto con el cual va a generar la consulta
    let objEstudiante = { idEstudiante: this.estudiante["Id"] }

    //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
    this.setModelosTrabajo2({
      servicio: "sEstudiante",
      identificador: "detalleEstudiante",
      metodo: "getDetallesEstudiante",
      consulta: objEstudiante
    });

    //Carga solo la consulta con identificador 'buscar recibo'
    this.cargarDatos("detalleEstudiante", () => { });
  }

  /**
  * Trae los datos de las consultas realizadas
  * @param data El array u objeto que se esta consultado
  * @param identificador El identificador que ha sigo asignado a cada consulta
  */
  onDatosObtenidos(data: any, identificador: string) {

    //Carga los datos de la consulta el detalle del estudiante
    if (identificador == "detalleEstudiante") {

      //Agrega las propiedades del grado y el responsable para verlos en el grid
      _.each(data['Matriculas'], function (m) {
        m.GradoNombre = m.Grado ? m.Grado.DefinicionGrado.Nombre + " - " + m.Grado.Año.Etiqueta : "";
        m.ResponsableNombre = m.ResponsablePago ? m.ResponsablePago.Persona.Nombre : "";
      });

      this.cvMatriculas = new CollectionView(data['Matriculas']);
      this.cvMatriculas.trackChanges = true;

      this.estudiante = recaudoUI.copyObject(data);
      this.estudianteObjetoModelo.objeto = this.estudiante;
      this.estudianteObjetoModelo.actualizarCopia();

      this.registrarObjetosModelo([this.estudianteObjetoModelo]);
      this.registrarModeloCollectionViews([
        new ModeloCollectionView('Matricula', 'Id', this.cvMatriculas, this.flexMatriculas)
      ]);
    }
  }

  /**
   * Convierte el texto ingresado en mayuscula
   */
  convertirMayuscula(texto) {
    return texto.toUpperCase();
  }

  /**
   * Actualiza las columnas que va tener el grid
   * @param column dato de la columna
   * @param modelo nombre del primer parametro en el new ModeloCollectionView
   */
  onAjusteColumna(column: WjFlexGridColumn, modelo: string) {
    if (modelo == "Matricula") {
      switch (column.binding) {
        default:
      }
    }
  }


  /**
   * Agrega una matricula nueva
   */
  agregar() {

    this.modaCrearMatricula.abrirCrearMatricula(this.estudiante, this.anio);
    this.modaCrearMatricula.modalRef.result.then((matricula) => {

      if (matricula.Id) {
        this.cvMatriculas.sourceCollection.push(matricula);

        this.cvMatriculas.refresh();
      }
    });
  }

  verMatricula() {
    let item = this.obtenerRegistroSeleccionado(this.flexMatriculas);

    if (!this.verificarSiHayCambios() && item)
      recaudoUI.tabsManager.agregar("Matrícula - " + item["Estudiante"]["Codigo"], "fa-user", MatriculaComponent, { idMatricula: item["Id"], anio: item.Grado.Año });
  }

  /**
   * Permite al tap detectar si hay cambios o no, para el momento de ser cerrado
   */
  consultarSiHayCambios() {
    this.hayCambios = this.getTieneCambios();
    return this.hayCambios;
  }
}
