import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { RecaudoFormHelper } from '../../helpers/RecaudoFormHelper';
import { ToastrService } from 'ngx-toastr';
import { WijmoHelper } from '../../helpers/WijmoHelper';
import { TableService } from '../../shared/services/table';
import { ModeloService, MatriculaService, ResponsablePagoService, EstudianteService, OtrasPersonasService, CobrosService, RecibosService, ExtractoService, TareaServidorService, ManagerService } from '../../shared/services/services';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { Tooltip, CollectionView } from 'wijmo/wijmo';
import * as _ from 'underscore';
import * as moment from 'moment';
import { recaudoUI } from '../../helpers/RecaudoUI';
import { FlexGridXlsxConverter } from 'wijmo/wijmo.grid.xlsx';
import { WjFlexGrid } from 'wijmo/wijmo.angular2.grid';


declare var bootbox: any;

@Component({
  selector: 'app-revisiones',
  templateUrl: './revisiones.component.html',
  styleUrls: ['./revisiones.component.css']
})
export class RevisionesComponent extends RecaudoFormHelper implements OnInit {

    private _tooltip: Tooltip;

    constructor(
        private _toastr: ToastrService,
        private _wjHelper: WijmoHelper,
        private _changeDetector: ChangeDetectorRef,
        private _sTable: TableService,

        private _sModelo: ModeloService,
        private _sAuth: AuthenticationService,
        private _sMatricula: MatriculaService,
        private _sResponsablePago: ResponsablePagoService,
        private _sEstudiante: EstudianteService,
        private _sOtrasPersonas: OtrasPersonasService,
        private _sCobro: CobrosService,
        private _sRecibo: RecibosService,
        private _sExtracto: ExtractoService,
        private _sTarea: TareaServidorService,
        private managerService: ManagerService
    ) {
        super(_toastr, _wjHelper, _sModelo, _changeDetector, _sTable, _sAuth, _sMatricula,
            _sResponsablePago, _sEstudiante, _sOtrasPersonas, _sCobro, _sRecibo, _sExtracto, _sTarea);

        this._tooltip = new Tooltip();
    }

    revisiones: any[] = null;
    revision: any = "";
    parametros: any[] = null;
    meses : any[] = null;
    anios : any[] = null;
    cvResultados: CollectionView;


    @ViewChild('flexResultados') flexResultados: WjFlexGrid;


    ngOnInit() {

        //Obtiene las revisiones
        this.managerService.getRevisiones().subscribe(data => {
            this.revisiones = [];

            //Arma la lista de revisiones
            var grp = _.groupBy(data, "Tipo");
            Object.keys(grp).forEach(k => {
                this.revisiones.push({Nombre: k, disabled: true});
                grp[k].forEach(i => { i.Etiqueta = i.Nombre; i.Nombre = " -" + i.Nombre; this.revisiones.push(i); });
            });

        });

        this._sModelo.getQueries(["Mes", "Año"], null).subscribe(data => {
            console.log(data);
            this.meses = _.sortBy(data["Mes"], m => { return -m.Id});
            this.anios = _.sortBy(data["Año"], m => { return -m.Numero});;
        });
    }

    onChangeRevision() {
        console.log(this.revision);
        //var r = this.revisiones.find(_r => _r.IdRevision == rev);
        this.cvResultados = new CollectionView([]);
        this.totales = [];

        //Carga los parámetros para la revisión
        var r = this.revision;
        if (r) {
            this.managerService.getParametros(r.Consulta).subscribe(data => {
                this.parametros = data;

                if (this.parametros && this.parametros.length > 0) {

                    //Valores de parámetros conocidos
                    this.parametros.forEach(p => {
                        if (p.Nombre == "Desde")
                            p.Valor = moment().startOf('day').toDate();
                            //p.Valor = moment().subtract(8, "days").startOf('day').toDate();

                        if (p.Nombre == "Hasta")
                            p.Valor = moment().startOf('day').toDate();

                        if (p.Nombre == "idAño" || p.Nombre == "IdAño"){
                            p.Valor = recaudoUI.getAñoTrabajo().Id;

                            //Para archivo pse se sugiere desde el año anterior
                            if(r.Nombre.toLowerCase().indexOf("pse") >= 0)
                                if(this.anios.length > 1)
                                    p.Valor = this.anios[1].Id;
                        }
                    });

                    console.log(this.parametros);
                }
                else
                    this.ejecutar();
            });
        }
        else
            this.parametros = [];
    }

    totales: any[] = [];

    ejecutar() {
        console.log("ejecutar", this.revision);
        if (this.revision) {
            this.cargando = true;
            this.managerService.postRevision(this.revision.IdRevision, this.parametros).subscribe(res => {
                var data = res.Resultado;
                this.cvResultados = new CollectionView(data);
                this.totales = [];

                if (data && data.length) {

                    Object.keys(data[0]).forEach(k => {
                        if (k && (k.indexOf("Fecha") >= 0 || k == "AprobadoDian" || k == "Creado" || k == "Modificado")){
                            data.forEach(d => {

                                //Convierte las fechas en el tipo Date
                                if(d[k]){
                                    var fecha = new Date(d[k]);

                                    //Valida acepta el formato de la fecha
                                    if(d[k] && !isNaN(fecha.getTime()))
                                        d[k] = new Date(d[k]);
                                    else if(d[k].indexOf(":") < 0 && d[k].indexOf("-") > 0){
                                        //Convierte a fechas en el formato yyyy-mm-dd
                                        var fechaSeparada = d[k].split('-');
                                        d[k] = new Date(fechaSeparada[2], fechaSeparada[1] - 1, fechaSeparada[0]);
                                    }
                                }
                            });
                        }
                    });
                }

                if (this.revision.Totales) {

                    var totales = JSON.parse(this.revision.Totales);

                    totales.forEach(t => {

                        //Si no se define el tipo se toma como suma
                        if (!t.Tipo || t.Tipo == "Suma") {

                            var grp = _.groupBy(data, t.Columna);

                            Object.keys(grp).forEach(k => {
                                var total = _.reduce(grp[k], (memo, num) => { return memo + num[t.Resumen]; }, 0);

                                this.totales.push({ Nombre: t.Etiqueta ? t.Etiqueta : k, Cantidad: grp[k].length, Total: total });
                            });

                        }
                        //Cuenta los nulos
                        else if (t.Tipo == 'CuentaNull') {
                            var sinEnviar = _.reduce(data, (memo, num) => { return memo + (!num[t.Resumen] ? 1 : 0); }, 0);
                            if (sinEnviar)
                                this.totales.push({ Nombre: t.Etiqueta ? t.Etiqueta : "No " + t.Columna, Cantidad: sinEnviar });
                        }
                        else if (t.Tipo == 'Cuenta') {
                            var sinEnviar = _.reduce(data, (memo, num) => { return memo + (num[t.Resumen] ? 1 : 0); }, 0);
                            if (sinEnviar)
                                this.totales.push({ Nombre: t.Etiqueta ? t.Etiqueta : t.Columna, Cantidad: sinEnviar });
                        }

                    });
                }

                this.cargando = false;
            }, err => {
                    this.cargando = false;
            });
        }
    }

    exportarExcel() {
        this.toastr.info("Exportando...");

        FlexGridXlsxConverter.saveAsync(this.flexResultados, { includeColumnHeaders: true, includeCellStyles: true }, this.revision.Etiqueta.replace(/ /g, "_") + "_" + moment().format("YYMMDD_HHmm") + ".xlsx");
    }

    exportarTexto() {
        //this.toastr.info("Exportando...");

        this.cargando = true;
        this.managerService.postRevision(this.revision.IdRevision, this.parametros, true).subscribe(res => {
            this.cargando = false;
            if(res.Log)
                bootbox.alert({title:"Advertencias", message:"<textarea style='width:100%' readonly rows='5'>" + res.Log  + "</textarea>"});

            //formato asobancaria
            if(this.revision.Etiqueta.replace(/ /g, "_") == 'Baloto'){
                var codigoEntidadAdministradora = '_000000_';
                var codigoBancoRecaudador = '052';
                this.downloadFile(moment().format("YYYYMMDD") + codigoEntidadAdministradora + codigoBancoRecaudador + "_F.TXT", res.Resultado);
            }else {
                if(res.Path)
                    this.downloadFile2(res.Path);
                else
                    this.downloadFile(this.revision.Etiqueta.replace(/ /g, "_") + "_" + moment().format("YYMMDD_HHmm") + res.Extension, res.Resultado);
            }
        }, err => { this.mostrarError(err, "Error al generar archivo", true); });

        //FlexGridXlsxConverter.saveAsync(this.flexResultados, { includeColumnHeaders: true, includeCellStyles: true }, this.revision.Nombre.replace(/ /g, "_") + "_" + moment().format("YYMMDD_HHmm") + ".xlsx");
    }

    //Descarga el archivo al navegador
    //https://medium.com/octopus-labs-london/downloading-a-base-64-pdf-from-an-api-request-in-javascript-6b4c603515eb
    downloadFile(fileName, file) {
        const linkSource = `data:text/plain,${ encodeURIComponent(file)}`;
        const downloadLink = document.createElement("a");
        //const fileName = fileName;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    downloadFile2(path) {
        //const linkSource = `data:text/plain,${ encodeURIComponent(file)}`;
        const downloadLink = document.createElement("a");
        //const fileName = fileName;

        downloadLink.href = path;
        //downloadLink.target = "_blank";
        //downloadLink.download = fileName;
        downloadLink.click();
    }


    ngAfterViewInit(): void {
    }

}
