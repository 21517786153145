import { Component, OnInit, Input, ViewChild, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChildren } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'underscore';
import { WjFlexGrid, WjFlexGridColumn, WjGridModule, WjFlexGridCellTemplate } from 'wijmo/wijmo.angular2.grid';
import { DataMap, CellRange } from 'wijmo/wijmo.grid';
import { CollectionView } from 'wijmo/wijmo';

import { ModeloService, RecibosService, MatriculaService, ResponsablePagoService, EstudianteService, OtrasPersonasService, CobrosService, ExtractoService, TareaServidorService } from '../../shared/services/services';
import { WijmoHelper, ModeloCollectionView } from '../../helpers/WijmoHelper';
import { recaudoUI } from '../../helpers/RecaudoUI';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RecaudoFormHelper } from '../../helpers/RecaudoFormHelper';
import { ObjetoModelo } from '../../helpers/Models';
import { TableService } from 'src/app/shared/services/table';
import { EstudianteComponent } from '../estudiante/estudiante.component';
import { ResponsableComponent } from '../responsable/responsable.component';
import { ReciboComponent } from '../recibo/recibo.component';
import { CobroComponent } from '../cobro/cobro.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ModalAjustarInteresDescuentoComponent } from 'src/app/shared/components/modal-ajustar-interes-descuento/modal-ajustar-interes-descuento.component';
import { ModalCargaCobroComponent } from 'src/app/shared/components/modal-carga-cobro/modal-carga-cobro.component';
import { ModalBuscarEstudianteComponent } from 'src/app/shared/components/modal-buscar-estudiante/modal-buscar-estudiante.component';
import { ModalFormularioComponent } from 'src/app/shared/components/modal-formulario/modal-formulario.component';
import { Router } from '@angular/router';
import { ConfiguracionInput } from 'src/app/_models/modals.model';
import { ModalBuscarResponsablePagoComponent } from 'src/app/shared/components/modal-buscar-responsable-pago/modal-buscar-responsable-pago.component';
import { ModalEliminarRecibosComponent } from 'src/app/shared/components/modal-eliminar-recibos/modal-eliminar-recibos.component';
import { ModalAlertaComponent } from 'src/app/shared/components/modal-alerta/modal-alerta.component';
import { WjGridFilterModule } from 'wijmo/wijmo.angular2.grid.filter';
import { ModalGenerarRecibosComponent } from 'src/app/shared/components/modal-generar-recibos/modal-generar-recibos.component';
import { timeout } from 'rxjs/operators';

declare var bootbox: any;

@Component({
  selector: 'app-matricula',
  templateUrl: './matricula.component.html',
  styleUrls: ['./matricula.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MatriculaComponent extends RecaudoFormHelper implements OnInit, AfterViewInit {

  //@Input('matricula') public matricula: object;
  @Input('idMatricula') public idMatricula: object;
  @Input('anio') public anio: object;

  hayCambios = false;
  parametros = [];

  private saldoPendiente;

  //Permite habilitar o deshabilitar los botones del tap de recibo
  deshabilitaBotonesRecibo =
    {
      descuento: false,
      imprimir: false,
      pagarNormal: true,
      pagarConInteres2DaFecha: true,
      pagarConInteresDespues2DaFecha: true,
      abrirSoporte: true,
      abonar: true,
      eliminado: false
    }

  matriculaObjetoModelo = new ObjetoModelo('Matricula');
  matricula: any;

  //Almacena las matrículas relacionadas de los demás años del estudiantes
  otrasMatriculas: Array<Object> = [];

  recibosCopia = [];

  //CollectionViews
  cvRecibos: CollectionView;
  cvCobros: CollectionView;
  cvServicios: CollectionView;

  //Referencia a los controles de wijmo
  @ViewChild('flexRecibos') flexRecibos: WjFlexGrid;
  @ViewChild('flexCobros') flexCobros: WjFlexGrid;
  @ViewChild('flexServicios') flexServicios: WjFlexGrid;

  @ViewChild(ModalBuscarResponsablePagoComponent) modalBuscarResponsablePago: ModalBuscarResponsablePagoComponent;
  @ViewChild(ModalAjustarInteresDescuentoComponent) modalAjusteDescuento: ModalAjustarInteresDescuentoComponent;
  @ViewChild(ModalCargaCobroComponent) modalCargarCobro: ModalCargaCobroComponent;
  @ViewChild(ModalBuscarEstudianteComponent) modalBuscarEstudiante: ModalBuscarEstudianteComponent;
  @ViewChild(ModalFormularioComponent) modalFormulario: ModalFormularioComponent;
  @ViewChild(ModalEliminarRecibosComponent) modalEliminarRecibos: ModalEliminarRecibosComponent;
  @ViewChild(ModalAlertaComponent) modalAlerta: ModalAlertaComponent;
  @ViewChild(ModalGenerarRecibosComponent) modalGenerarRecibo: ModalGenerarRecibosComponent;

  //Datos de trabajo auxiliares
  meses: Array<any> = [];
  grupos: Array<any> = [];
  grados: Array<any> = [];
  responsablesPago: Array<any> = [];
  tiposConcepto: Array<any> = [];
  tiposRecibo: Array<any> = [];
  servicios: Array<any> = [];

  //Filtro del cobro
  filtroCobros = {
    idTipoConcepto: 0,
    idMes: 0
  }

  mediosPago = [
    { propiedad: "", etiqueta: "" },
    { propiedad: "Banco", etiqueta: "Banco" },
    { propiedad: "PSE", etiqueta: "PSE" },
    { propiedad: "Datafono", etiqueta: "Datafono" },
    { propiedad: "Colegio", etiqueta: "Colegio" },
    { propiedad: "Transferencia", etiqueta: "Transferencia" },
  ];

  respaldoTipoRecibo: object[] = [
    { IdTipoRecibo: 0, Tipo: "Estado de Cuenta", Clase: "EstadoCuenta", TiposConcepto: "", TipoCarga: 0 },
    { IdTipoRecibo: 0, Tipo: "Abono", Clase: "Abono", TiposConcepto: "", TipoCarga: 0 },
    { IdTipoRecibo: 0, Tipo: "Pago Adelantado", Clase: "PagoAdelantado", TiposConcepto: "", TipoCarga: 0 },
    { IdTipoRecibo: 0, Tipo: "Cobro Prejurídico", Clase: "CobroPrejuridico", TiposConcepto: "", TipoCarga: 0 },
    { IdTipoRecibo: 0, Tipo: "Recibo Matrícula", Clase: "Matricula", TiposConcepto: "", TipoCarga: 0 },
  ];
  generaRecibos = {
    tipoRecibo: {},
    idMes: 0
  };
  //Contiene el id grado de la matrícula actual
  idGradoMatricula;
  deuda = {AnioActual: 0, AniosAnteriores: 0, DetalleDeuda: []};
  //declaración de servicios
  constructor(
    private routes: Router,

    private _toastr: ToastrService,
    private _wjHelper: WijmoHelper,
    private _changeDetector: ChangeDetectorRef,
    private _sTable: TableService,

    private _sModelo: ModeloService,
    private _sAuth: AuthenticationService,
    private _sMatricula: MatriculaService,
    private _sResponsablePago: ResponsablePagoService,
    private _sEstudiante: EstudianteService,
    private _sOtrasPersonas: OtrasPersonasService,
    private _sCobro: CobrosService,
    private _sRecibo: RecibosService,
    public _sExtracto: ExtractoService,
    private _sTarea: TareaServidorService
  ) {
    super(_toastr, _wjHelper, _sModelo, _changeDetector, _sTable, _sAuth, _sMatricula,
      _sResponsablePago, _sEstudiante, _sOtrasPersonas, _sCobro, _sRecibo, _sExtracto, _sTarea);
  }
  ngAfterViewInit(): void {
  }

  ngOnInit(): void {

    let user = this.sAuth.getUser();

    //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
    this.setModelosTrabajo2({
      identificador: "oData",
      odata: [
        'Matricula;$filter=Id eq ' + this.idMatricula + '&$expand=Cobros.CostoEducativo,Estudiante,ResponsablePago.Persona,InscripcionesServicio',
        'Recibo;$filter=IdMatricula eq ' + this.idMatricula + '&$expand=DetallesRecibo',
        `Mes;$filter=IdAnio eq ${this.anio["Id"]}`,
        `Grado;$filter=IdAnio eq ${this.anio["Id"]}&$expand=DefinicionGrado,Grupos,Año`,
        'TipoConcepto',
        'TipoRecibo;$orderby=Orden',
        `Servicio;$filter=IdAño eq ${this.anio["Id"]}`,
        'Parametro'
      ]
    });

    //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
    this.setModelosTrabajo2({
      servicio: "sMatricula",
      metodo: "getOtrasMatriculasEstudiante",
      identificador: "otrasMatriculas",
      consulta: { idMatricula: this.idMatricula }
    });

    /*//Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
    this.setModelosTrabajo2({
      servicio: "sMatricula",
      metodo: "getSaldoPendiente",
      identificador: "saldoPendiente",
      consulta: { idMatricula: this.idMatricula, idAnio: this.anio["Id"] }
    });*/

    //Carga la consulta con identificador 'Odata'
    this.cargarDatos(null, () => { });

  }

  /**
   * Trae los datos de las consultas realizadas
   * @param data El array u objeto que se esta consultado
   * @param identificador El identificador que ha sigo asignado a cada consulta
   */
  onDatosObtenidos(data: any, identificador: string) {

    if (identificador == "saldoPendiente")
      this.saldoPendiente = data["Saldo"];

    if (identificador == "otrasMatriculas") {
      this.otrasMatriculas = data["Matriculas"];
    }

    //Carga los datos de la consulta de oData
    if (identificador == "oData") {

      this.grupos = [];

      this.parametros = data["Parametro"];

      this.meses = data['Mes'];
      this.grados = data['Grado'];
      this.responsablesPago = _.sortBy(data['ResponsablePago']);
      this.tiposConcepto = data['TipoConcepto'];
      this.servicios = data['Servicio'];

      //Se arma los grupos por medio de los grados
      _.each(this.grados, (g) => {
        g.DefinicionGrado.Nombre = g.DefinicionGrado.Nombre + "-" + g.Año.Etiqueta;
        this.grupos = this.grupos.concat(g.Grupos);
      });

      //Asigna el tipo recibo de la base de datos, o por el tipoRecibo provicional
      if (data["TipoRecibo"].length == 0)
        this.tiposRecibo = this.respaldoTipoRecibo;
      else
        this.tiposRecibo = data["TipoRecibo"];

      //Formulario de matricula
      this.matricula = _.first(data['Matricula']);
      _.each(this.matricula["Cobros"], (c) => {
        c.nombreCostoEducativo = c.CostoEducativo && c.CostoEducativo.Descripcion ? c.CostoEducativo.Descripcion : "";
        c["Creado"] = recaudoUI.formatDate(c["Creado"]);
        c["Modificado"] = recaudoUI.formatDate(c["Modificado"]);
      });

      //formato a las fechas
      this.matricula['FechaRetiro'] = recaudoUI.formatDate(this.matricula['FechaRetiro']);
      this.matricula['FechaMatricula'] = recaudoUI.formatDate(this.matricula['FechaMatricula']);
      this.matricula['Creado'] = recaudoUI.formatDate(this.matricula['Creado']);
      this.matricula['Modificado'] = recaudoUI.formatDate(this.matricula['Modificado']);

      this.idGradoMatricula = this.matricula["IdGrado"];

      var is = this.matricula["InscripcionesServicio"];

      this.matricula["InscripcionesServicio"] = null;

      this.matriculaObjetoModelo.objeto = this.matricula;
      this.matriculaObjetoModelo.actualizarCopia();
      this.registrarObjetosModelo([this.matriculaObjetoModelo]);

      //Obtiene el parametro
      var ordenRecibo = _.find(this.parametros, function(parametro){ return parametro["Nombre"] == "OrdenRecibo"});
      if(ordenRecibo)
        ordenRecibo = ordenRecibo["Valor"];
      //Da un orden a los recibos
      _.each(data["Recibo"], function (r) {

        r["copiaTipoRecibo"] = r["TipoRecibo"].replace("ReciboMatricula", "1").replace("Matricula", "1").replace("Abono", "");

        console.log("TipoRecibo ", r["copiaTipoRecibo"], r["TipoRecibo"]);

        r["Creado"] = recaudoUI.formatDate(r["Creado"]);
        r["Modificado"] = recaudoUI.formatDate(r["Modificado"]);

      });

      //Ordena los recibos
      if(ordenRecibo != "1")
        data["Recibo"] = _.chain(data["Recibo"]).sortBy('copiaTipoRecibo').sortBy('IdMes').value();

      _.each(data["Recibo"], function (r) {

        r["Creado"] = recaudoUI.formatDate(r["Creado"]);
          r["Modificado"] = recaudoUI.formatDate(r["Modificado"]);
          if (r["AprobadoDian"])
              r["AprobadoDian"] = new Date(r["AprobadoDian"]);
          if (r["Prefijo"] && r["NumeroFactura"])
              r["NumFact"] = r["Prefijo"] + r["NumeroFactura"];


      });
      /*
      data["Recibo"] = _.sortBy(data["Recibo"], function (r) {

        r["Creado"] = recaudoUI.formatDate(r["Creado"]);
        r["Modificado"] = recaudoUI.formatDate(r["Modificado"]);

        return parseInt( r.IdMes +  r.TipoRecibo, 10);
      });*/

      this.cvRecibos = new CollectionView(data["Recibo"]);
      this.cvRecibos.trackChanges = true;

      this.cvCobros = new CollectionView(this.matricula["Cobros"]);
      this.cvCobros.trackChanges = true;
      this.cvCobros.newItemCreator = () => {
        return {
          Id: 0,
          IdMatricula: this.matricula['Id'],
          IdMes: _.first(this.meses).Id,
          IdTipoConcepto: _.first(this.tiposConcepto).Id,
          EnCobroPrejuridico: false
        };
      }

      this.cvServicios = new CollectionView(is);
      this.cvServicios.trackChanges = true;
      this.cvServicios.newItemCreator = () => {
        return {
          Id: 0, IdServicio: _.first(this.servicios).Id, Observaciones: null, Inactivo: null, IdMatricula: this.matricula['Id']
        };
      }


      //Cuando se hace focus a alguna celda, esto para habilitar o deshabilitar los botones
      this.flexRecibos.focus = () => {
        let item = this.obtenerRegistroSeleccionado(this.flexRecibos);

        //Depende del registro seleccionado habilita el botón para realizar una accion como tambien lo puede habilitar
        if (item)
          this.deshabilitarBotonesTapRecibo(item);
      }

      this.registrarModeloCollectionViews([
        new ModeloCollectionView('Recibo', 'Id', this.cvRecibos, this.flexRecibos),
        new ModeloCollectionView('Cobro', 'Id', this.cvCobros, this.flexCobros),
        new ModeloCollectionView('InscripcionServicio', 'Id', this.cvServicios, this.flexServicios),
      ]);

      //Actuali los recibos cuando algun registro se modifica
      this.actualizarRecibo();

      this.verDeudas();
    }
  }

  onAjusteColumna(column: WjFlexGridColumn, modelo: string) {
    if (modelo == "Recibo") {
      switch (column.binding) {
        case "IdMes":
          column.width = 130;
          //crea el select de meses
          column.dataMap = new DataMap(this.meses, "Id", "Nombre");
          break;
        case "MedioPago":
          column.dataMap = new DataMap(this.mediosPago, "propiedad", "etiqueta");
          break;
        default:
      }
    } else if (modelo == "Cobro") {
      switch (column.binding) {
        case "IdMes":
          column.dataMap = new DataMap(this.meses, "Id", "Nombre");
          break;
        case "IdDelMes":
          column.dataMap = new DataMap(this.meses, "Id", "Nombre");
          break;
        case "IdTipoConcepto":
          column.dataMap = new DataMap(this.tiposConcepto, "Id", "Nombre");
          break;
        default:
      }
    } else if (modelo == "InscripcionServicio") {
      switch (column.binding) {
        case "IdServicio":
          column.dataMap = new DataMap(this.servicios, "Id", "Nombre");
          break;
      }
    }
  }

  /**
   * Actualiza los recibos cuando éstos son modificados
   */
  actualizarRecibo() {

    //Guarda una copia del recibo para saber si algún recibo se modificó
    this.recibosCopia = [];
    _.each(this.cvRecibos.items, (r) => { this.recibosCopia.push(recaudoUI.copyObject(r)); });

    //Actualiza los valores cuando el recibo cambia el valor
    this.flexRecibos.onRowEditEnded = (e) => {

      let r = this.cvRecibos.items[e.row];
      let _r = _.find(this.recibosCopia, (rec) => { return rec.Id == r.Id; });

      //Si se cambio la fecha de pago del recibo
      if (recaudoUI.formatDate(r.FechaPago) != recaudoUI.formatDate(_r.FechaPago)) {
        //Obtiene el medio de pago del parametro
        var parametroMedioPago = this.getMedioPago();
        //Obtiene el medio de pago
        var medioPago = parametroMedioPago? parametroMedioPago : "Colegio";
        r.MedioPago = recaudoUI.formatDate(r.FechaPago) ? medioPago : "";

        _r.FechaPago = r.FechaPago;
      }
    };
  }

  /**
   * Obtiene las deudas de los anios anteriores y del anio donde se encuentra la matricula
   */
  verDeudas(){
    //Obtiene la deuda de los años anteriores y el actual
    this.sCobro.getDeudaEstudiante(this.matricula["Codigo"].toString(), this.anio["Numero"]).subscribe(data=>{
        this.deuda.AnioActual = data["AnioActual"];
        this.deuda.AniosAnteriores = data["AniosAnteriores"];
        this.deuda.DetalleDeuda = data["DetalleDeuda"];
        //Actualiza el html
        this.changeDetector.detectChanges();
    });
  }

  /**
   * habilita o deshabilita los botones acorde con el registro seleccionado
   * @param item
   */
  deshabilitarBotonesTapRecibo(item) {

    //Deshabilita los botones
    if (item.FechaPago) {
      this.deshabilitaBotonesRecibo.pagarNormal = true;
      this.deshabilitaBotonesRecibo.pagarConInteres2DaFecha = true;
      this.deshabilitaBotonesRecibo.pagarConInteresDespues2DaFecha = true;
      this.deshabilitaBotonesRecibo.abonar = true;
      this.deshabilitaBotonesRecibo.eliminado = true;
    }

    //Habilita los botones
    else {
      this.deshabilitaBotonesRecibo.pagarNormal = false;
      this.deshabilitaBotonesRecibo.pagarConInteres2DaFecha = false;
      this.deshabilitaBotonesRecibo.pagarConInteresDespues2DaFecha = false;
      this.deshabilitaBotonesRecibo.abonar = false;
      this.deshabilitaBotonesRecibo.eliminado = false;
    }

    //Actualiza el html
    this.changeDetector.detectChanges();
  }

  /**
   * Permite ver otra matrícula en la misma tap
   * @param idMatricula
   */
  verOtraMatricula() {

    let matricula = _.find(this.otrasMatriculas, (m) => { return m.IdGrado == this.idGradoMatricula; });

    if (!this.verificarSiHayCambios()) {

      this.modalConfirmacion.abrirConfirmar({ textoHeader: `Está seguro de cambiar a la matrícula ${matricula.NombreGrado} - ${matricula.Anio.Etiqueta} , ésta se cargará en la misma tab` });
      this.modalConfirmacion.modalRef.result.then((success: boolean) => {

        //Si acepto la modal
        if (success) {

          this.idMatricula = matricula.Id;
          this.anio = matricula.Anio;

          this.ngOnInit();
        }
        else
          this.idGradoMatricula = this.matricula["IdGrado"];

      }, (info) => {
        console.log("Info modal", info);
        this.idGradoMatricula = this.matricula["IdGrado"];
      });

    }
  }

  /**
   * Abre el tap del estudiante
   */
  verEstudiante() {
    if (!this.verificarSiHayCambios())
      recaudoUI.tabsManager.agregar("Estudiante - " + this.matricula["Estudiante"]["Codigo"], "fa fa-users", EstudianteComponent, { estudiante: this.matricula["Estudiante"], anio: this.anio });
  }

  /**
   * Abre el tap del responsable
   */
  verResponsable() {
    if (!this.verificarSiHayCambios())
      recaudoUI.tabsManager.agregar("Responsable - " + this.matricula["ResponsablePago"].Persona.Nombre, "fa fa-users", ResponsableComponent, { personaDto: this.matricula["ResponsablePago"].Persona, anioDto: this.anio });
  }

  /**
   * Abre el tap del recibo
   */
  verRecibo() {
    let item = this.obtenerRegistroSeleccionado(this.flexRecibos);

    if (!this.verificarSiHayCambios() && item)
      recaudoUI.tabsManager.agregar("Recibo - " + (item["NumeroRecibo"] || item["Referencia1"] || item["Referencia2"]), "fa-file-text-o", ReciboComponent, { idRecibo: item["Id"], anio: recaudoUI.copyObject(this.anio) });
  }

  /**
   * Abre el Tap de cobro
   */
  verCobros() {
    let item = this.obtenerRegistroSeleccionado(this.flexCobros);

    if (!this.verificarSiHayCambios() && item)
      recaudoUI.tabsManager.agregar("Cobro - " + item["Id"], "", CobroComponent, { idCobro: item.Id, anio: this.anio });
  }

  /**
   * Geenra el descuento del recibo seleccionado
   */
  /*generarDescuentoRecibo() {
    let item = this.obtenerRegistroSeleccionado(this.flexRecibos);

    if (!(!this.verificarSiHayCambios() && item))
      return;

    //Obtiene el abono máximo que se puede realizar
    let valorMaximoAbono = 0;
    if (item.TotalConIntereses2)
      valorMaximoAbono = item.TotalConIntereses2;
    else if (item.TotalConIntereses)
      valorMaximoAbono = item.TotalConIntereses;
    else if (item.TotalAPagar)
      valorMaximoAbono = item.TotalAPagar;

    let titulo = "Ingrese el Valor del Descuento " + (item["NumeroRecibo"] ? item["NumeroRecibo"] : "");

    //Crea el formulario
    this.modalFormulario.abrirIngresarValor({
      titulo: titulo,
      input: [
        { property: "valorDescuento", label: "Valor del descuento", type: "number", required: true, min: 0, max: (valorMaximoAbono - 1), placeholder: "Valor del descuento", messageErrorMax: `El valor del descuento no debe ser igual o mayor al valor del recibo. Recibo ${valorMaximoAbono}` }
      ]
    });
    this.modalFormulario.modalRef.result.then((dataValor) => {

      this.crearTareaSync("AplicarDescuento", item["NumeroRecibo"], dataValor["valorDescuento"], null, null, null, null);
      //this.crearTarea("AplicarDescuento", item["NumeroRecibo"], dataValor["valorDescuento"], null, null, null, null);

    }, (info) => {
      console.log("Info", info);
    });

  }*/
  /**
   * Genera un descuento al recibo según la seleccion del campo
   */
  generarDescuentoRecibo() {
    //recoge los items seleccionados
    let items = this.flexRecibos.selectedItems;
    let invalido = false;
    let numRecibos = [];

    _.each(items, function(i){
        //Si alguno de los valores de tipo recibo es abono o AbonoFactura lo marca como error para cortar el flujo
        if (i.TipoRecibo == 'Abono' || i.TipoRecibo == 'AbonoFactura')
            invalido = true;
    });

    //Los nombres de los campos totales
    var camposTotales = ["TotalAPagar", "TotalConDescuento", "TotalConIntereses", "TotalConIntereses2"];
    //Indica si hay valores distintos
    var valoresDiferentes = false;

    //Busca los valores de los campos
    _.each(camposTotales, function(c){
        //Obtiene los valores del campoTotal
        var valoresTotales = _.pluck(items, c);
        //Indica la cantidad de valores distintos
        if(_.uniq(valoresTotales).length != 1){
            valoresDiferentes= true;
            return;
        }
    });

    if(valoresDiferentes)
        return this.toastr.error("Uno o varios recibos seleccionados no coinciden o no tienen el mismo valor")

    //Obtienen los valores de los totales
    var item = _.first(items);
    //Obtiene el numero de recibos
    numRecibos = _.pluck(items, "NumeroRecibo");

    var totales = null;
    let valorMaximoAbono = item.TotalAPagar;

    if(item){
        totales = {
            TotalAPagar : item.TotalAPagar,
            TotalConDescuento: item.TotalConDescuento,
            TotalConIntereses: item.TotalConIntereses,
            TotalConIntereses2: item.TotalConIntereses2
        };

    }else{
        return this.toastr.error("No se ha selecciono ningún recibo");
    }
    //Verifica que tenga cambios
    if (this.verificarSiHayCambios())
      return;
    //Si no hay cambios, mira que entre los escogidos no haya ningún recibo de tipo Abono o AbonoFactura, si encuentra uno pausa el flujo y muestra mensaje
    else if(invalido)
      return this.toastr.error("Inválida selección, puede que haya seleccionado un recibo de tipo Abono o AbonoFactura");

    //Selecciona el título dependiendo y hay uno o varios elementos en numRecibos
    let titulo = "";
    if(numRecibos.length > 1)
      titulo = "Ingrese el valor del descuento para los siguientes recibos: " + (numRecibos ? numRecibos.join(', ') + ' de igual monto' : "");
    else
      titulo = "Ingrese el valor de descuento del siguiente recibo: " + (numRecibos ? numRecibos.join(', ') : "");

    //Crea el formulario
    this.modalFormulario.abrirIngresarValor({
      titulo: titulo,
      input: [
        { property: "valorDescuento", label: "Valor del descuento", type: "number", required: true, min: 0, max: item.TotalAPagar, placeholder: "Valor del descuento", messageErrorMax: `El valor del descuento no debe ser mayor al valor del recibo. Valor $` + valorMaximoAbono, messageErrorRequired: "El campo del descuento es requerido" }
      ],
      valor: {Totales: totales}
    });

    this.modalFormulario.modalRef.result.then((dataValor) => {
      //console.log(dataValor);
      var campos = null;
      var valores = null;

      if(dataValor["Totales"]){
        //console.log(dataValor["Totales"]);
        campos = _.pluck(dataValor["Totales"], "Tipo");
        valores = _.pluck(dataValor["Totales"], "Descuento");

        if(campos.length == 1 || campos.length == 1){
            campos = _.first(campos);
            valores = _.first(valores);
        }
        else{
            campos = campos.join(",");
            valores = valores.join(",");
        }
      }


      this.crearTareaSync("AplicarDescuento", numRecibos.join(', '), valores, campos, dataValor["Observacion"], null, null);
    }, (info) => {
      console.log("Info", info);
    });
  }

  /**
   * Imprime el recibo seleccionado
   */
  imprimirRecibo() {

    let item = this.obtenerRegistroSeleccionado(this.flexRecibos);

    var ids = "";
    if (this.flexRecibos.selectedItems && this.flexRecibos.selectedItems.length > 0) {
      this.flexRecibos.selectedItems.forEach(i => {

        if (ids)
          ids += ",";

        ids += i["Id"];
      });
    }

    if (!(!this.verificarSiHayCambios() && item))
      return;

      let parametro = _.find(this.parametros, (p) => { return p.Nombre == 'NombreDB'; });

      var cargar = true; console.log(item);
      if ((item.TipoRecibo == "Factura" || item.TipoRecibo == "NC" || item.TipoRecibo == "ND")) {
          cargar = false;
          if (item.Pdf) {
              window.open(item.Pdf, "_blank");
          } else {

              if (item.Qr) {
                  //Crea el recibo del abono
                  this.sRecibo.postGenerarPdf(item.Id).subscribe(data => {

                      console.log(data);

                      if (data["Pdf"]) {
                          window.open(data["Pdf"], "_blank");
                      }
                      else
                          bootbox.alert("No se pudo generar el pdf");

                      //this.cargarDatos(null, () => { });
                      //this.crearTareaSync("PagarRecibo", dataReciboNuevo["Id"], dataReciboNuevo["TotalAPagar"], dataResult.fecha, item.TipoRecibo, null, null);
                      //this.crearTarea("PagarRecibo", dataReciboNuevo["Id"], dataReciboNuevo["TotalAPagar"], dataResult.fecha, null, null, null);

                  }, (error) => {
                      bootbox.alert("Error: " + error);

                      console.log(error);
                  });
              }
              else
                  cargar = true;
          }
      }

      if(cargar)
          window.open(recaudoUI.getBaseUrl() + "Informes/Informe.aspx?DB=" + parametro.Valor + "&Nombre=" + item["TipoRecibo"] + "&IdRecibo=" + ids + "&Exportar=PDF", '_blank');

    //window.open("Informes/Informe.aspx?DB=" + parametro.Valor + "&Nombre=" + item["TipoRecibo"] + "&NumeroRecibo=" + item["NumeroRecibo"] + "&Exportar=PDF", '_blank');

    //let url = this.routes.createUrlTree([`/RecaudoInf/Informes`, { DB: parametro.Valor, Nombre: item["TipoRecibo"], NumeroRecibo: item["NumeroRecibo"], Exportar: "PDF" }]);

    //window.open(url.toString(), '_blank');

  }

  /**
   * Realiza el pago del recibo que aun no ha sido pagado
   */
  pagarNormalRecibo() {

    let item = this.obtenerRegistroSeleccionado(this.flexRecibos);

    if (!(!this.verificarSiHayCambios() && item))
      return;

    var mediosPago = [];
    //Obtiene los medios de pago
    _.each(this.mediosPago, function(m){
        mediosPago.push({Nombre: m.etiqueta, Valor: m.propiedad});
    });
    //Obtiene el parametro
    var parametroMedioPago = this.getMedioPago();
    //Crea el formulario
    this.modalFormulario.abrirIngresarValor({
      titulo: "Pago normal recibo: referencia " + item.Referencia1,
      input: [
        { property: "valorPago", label: "Valor ", type: "number", required: true, min: 0, placeholder: "Valor" },
        { property: "fechaPago", label: "Fecha ", type: "date", required: true, messageErrorRequired: "La fecha de pago es requeria" },
        { property: "medioPago", label: "Medio de pago", type: "select", required: true, }
      ],
      valor: {
        valorPago: item["TotalConDescuento"]? item["TotalConDescuento"] : item["TotalAPagar"],
        fechaPago: recaudoUI.getFechaActual(),
        medioPago: !parametroMedioPago ? "Colegio" : parametroMedioPago,
        arraySelectmedioPago: mediosPago
      }
    });
    this.modalFormulario.modalRef.result.then((dataResult) => {

      this.crearTareaSync("PagarRecibo", item["Id"], dataResult["valorPago"], dataResult["fechaPago"], dataResult["medioPago"], null, null);

      //this.crearTarea("PagarRecibo", item["Id"], dataResult["valorPago"], dataResult["fechaPago"], null, null, null);
    }, (info) => {
      console.log("Info", info);
    });
  }

  /**
   * Realiza el pago con interes
   */
  pagarConInteres2FechaRecibo() {
    let item = this.obtenerRegistroSeleccionado(this.flexRecibos);

    if (!(!this.verificarSiHayCambios() && item))
      return;
    //Obtiene los medios de pago
    var mediosPago = [];

    _.each(this.mediosPago, function(m){
        mediosPago.push({Nombre: m.etiqueta, Valor: m.propiedad});
    });
    //Obtiene el parametro
    var parametroMedioPago = this.getMedioPago();
    //Crea el formulario
    this.modalFormulario.abrirIngresarValor({
      titulo: "Pago con Interés, recibo referencia " + item.Referencia1,
      input: [
        { property: "valorPagoConInteres", label: "Valor ", type: "number", required: true, min: 0, placeholder: "Valor" },
        { property: "fechaPago", label: "Fecha ", type: "date", required: true, messageErrorRequired: "La fecha de pago es requeria" },
        { property: "medioPago", label: "Medio de pago", type: "select", required: true }
      ],
      valor: {
        arraySelectmedioPago: mediosPago,
        valorPagoConInteres: item["TotalConIntereses"],
        fechaPago: recaudoUI.getFechaActual(),
        medioPago: !parametroMedioPago ? "Colegio" : parametroMedioPago
      }
    });

    this.modalFormulario.modalRef.result.then((dataResult) => {
      this.crearTareaSync("PagarRecibo", item["Id"], dataResult["valorPagoConInteres"], dataResult["fechaPago"], dataResult["medioPago"], null, null);
      //this.crearTarea("PagarRecibo", item["Id"], dataResult["valorPagoConInteres"], dataResult["fechaPago"], null, null, null);
    }, (info) => {
      console.log("Info", info);
    });
  }

  /**
   * Realiza el pago despuesde la segunda fecha
   */
  pagarConInteresDespues2FechaRecibo() {

    //Obtiene el item seleccionado del flex grid
    let item = this.obtenerRegistroSeleccionado(this.flexRecibos);

    if (!(!this.verificarSiHayCambios() && item))
      return;
    //Obtiene los medios de pago
    var mediosPago = [];

    _.each(this.mediosPago, function(m){
        mediosPago.push({Nombre: m.etiqueta, Valor: m.propiedad});
    });

    //Contiene la configuración de la modal para mostrar las etiquetas asignadas
    let configuracionDeInputsModal: ConfiguracionInput[] = [
      { property: "valorPago", label: "Valor pagado", type: "number", required: true, min: 0, placeholder: "Valor del pago", messageErrorMin: "El valor de pago no debe ser menor a cero" }
    ];

    //Si no es rol de secretaria se muestra el input para seleccionar la fecha de pago
    if (this.usuarioAuth.Rol == 'Administrador' || this.usuarioAuth.Rol == 'SuperAdmin' || this.usuarioAuth.Rol == 'Contador')
      configuracionDeInputsModal.push(
        { property: "fechaPago", label: "Fecha", type: "date", required: true, messageErrorRequired: "La fecha de pago es requeria" }
      );
    //Agrega el select de los medios de pago
    configuracionDeInputsModal.push(
        { property: "medioPago", label: "Medio de pago", type: "select", required: true });
    //Obtiene el medio de pago del parametro
    var parametroMedioPago = this.getMedioPago();

    //Configura como desea la modal y la cantidad de inputs
    this.modalFormulario.abrirIngresarValor(
      {
        titulo: "Pago con interés, recibo refetencia " + item.Referencia1,
        input: configuracionDeInputsModal,
        valor: {
          valorPago: item.TotalConIntereses2,
          fechaPago: recaudoUI.getFechaActual(),
          arraySelectmedioPago: mediosPago,
          medioPago: !parametroMedioPago ? "Colegio" : parametroMedioPago
        }
      }
    );
    this.modalFormulario.modalRef.result.then((dataValor) => {
      console.log("Result", dataValor);
      this.crearTareaSync("PagarRecibo", item.Id, dataValor["valorPago"], dataValor["fechaPago"], dataValor["medioPago"], null, null);
      //this.crearTarea("PagarRecibo", item.Id, dataValor["valorPago"], dataValor["fechaPago"], null, null, null);

    }, (info) => {
      console.log("Info", info);
    });
  }

  abrirSoporteRecibo() { }

  /**
   * Realiza el abono al recibo seleccionado
   */
  abonarRecibo() {

    //Obtiene el item seleccionado del flex grid
    let item = this.obtenerRegistroSeleccionado(this.flexRecibos);
    if (!(!this.verificarSiHayCambios() && item))
      return;

    console.log("Recibo ", item);

    //Obtiene el abono máximo que se puede realizar
    let valorMaximoAbono = 0;
    if (item.TotalConIntereses2)
      valorMaximoAbono = item.TotalConIntereses2;
    else if (item.TotalConIntereses)
      valorMaximoAbono = item.TotalConIntereses;
    else if (item.TotalAPagar)
      valorMaximoAbono = item.TotalAPagar;

      var mediosPago = [];
      //Obtiene los medios de pago
      _.each(this.mediosPago, function(m){
          mediosPago.push({Nombre: m.etiqueta, Valor: m.propiedad});
      });

    //Contiene la configuración de la modal para mostrar las etiquetas asignadas
    let configuracionDeInputsModal: ConfiguracionInput[] = [
      { property: "valorAbono", label: "Valor del abono", type: "price", required: true, min: 0, max: (valorMaximoAbono - 1), placeholder: "Valor del  abono", messageErrorMax: `El valor del abono no debe ser igual o mayor al valor del recibo. Recibo ${valorMaximoAbono}` }
    ];

    //Si no es rol de secretaria se muestra el input para seleccionar la fecha de pago
    if (this.usuarioAuth.Rol == 'Administrador' || this.usuarioAuth.Rol == 'SuperAdmin' || this.usuarioAuth.Rol == 'Contador')
      configuracionDeInputsModal.push(
        { property: "fecha", label: "Fecha", type: "date", required: true, messageErrorRequired: "La fecha de pago es requeria" }
      );
    //Agrega la lista de los medios de pago para seleccionar
    configuracionDeInputsModal.push(
        { property: "medioPago", label: "Medio de pago", type: "select", required: true }
      );

    //Obtiene el parametro
    var parametroMedioPago = this.getMedioPago();
    //Configura como desea la modal y la cantidad de inputs
    this.modalFormulario.abrirIngresarValor(
      {
        titulo: "Realizar el abono, recibo referencia " + item.Referencia1,
        input: configuracionDeInputsModal,
        valor: {
          valorAbono: 0,
          fecha: recaudoUI.getFechaActual(),
          medioPago: !parametroMedioPago ? "Colegio" : parametroMedioPago,
            arraySelectmedioPago: mediosPago
        }
      }
    );
    this.modalFormulario.modalRef.result.then((dataResult) => {

      //Crea el recibo del abono
      this.sRecibo.postRealizarAbono({ IdRecibo: item.Id, ValorAbono: dataResult.valorAbono }).subscribe(dataReciboNuevo => {

        this.crearTareaSync("PagarRecibo", dataReciboNuevo["Id"], dataReciboNuevo["TotalAPagar"], dataResult.fecha, dataResult["medioPago"], null, null);
        //this.crearTarea("PagarRecibo", dataReciboNuevo["Id"], dataReciboNuevo["TotalAPagar"], dataResult.fecha, null, null, null);

      }, (error) => {
        console.log(error);
      });
    }, (info) => {
      console.log("Info", info);
    });

  }

  /**
   * Obtiene el parametro de medio de pago, si existe en los medios de pago
   */
  getMedioPago(){
        //Obtiene el parametro
        var parametro = _.find(this.parametros, function(p){ return p.Nombre == "MedioPago"});
        if(!parametro)
            return null;
        //Valida si existe en los medios de pago
        var medioPago = _.find(this.mediosPago, function(m){ return m.propiedad == parametro.Valor});
        if(!medioPago)
            return medioPago;

        return medioPago.propiedad;
    }


    enviarDian(idItem) {
        //Obtiene el item seleccionado
        let item = null
        if(!idItem){
            item = this.obtenerRegistroSeleccionado(this.flexRecibos);
            if (!(!this.verificarSiHayCambios() && item))
                return;
        }else{
            item = {Id: idItem}
        }
        this.cargando = true;
      //Crea el recibo del abono
      this.sRecibo.postEnviarDian(item.Id).subscribe(data => {
          this.cargando = false;
          console.log(data);

          if(data == null){
            this.cargando = false;
            this.cargarDatos(null, () => { });
            return;
          }

          if (!data["AprobadoDian"]) {
              bootbox.alert(data["Error"] + ". " + data["RespuestaDian"])
          }
          else {
              bootbox.alert("Documento enviado a la Dian");
              window.open(data["Pdf"], "_blank");
          }

          this.cargarDatos(null, () => { });
      //this.crearTareaSync("PagarRecibo", dataReciboNuevo["Id"], dataReciboNuevo["TotalAPagar"], dataResult.fecha, item.TipoRecibo, null, null);
      //this.crearTarea("PagarRecibo", dataReciboNuevo["Id"], dataReciboNuevo["TotalAPagar"], dataResult.fecha, null, null, null);

      }, (error) => {
              this.cargando = false;
              bootbox.alert("Error: " + error);

      console.log(error);
    });

    }

    /**
     * Muestra la modal que se visualizan y redireciona a los metodos correspondientes
     */
    crearNotas(tipo){
        let item = this.obtenerRegistroSeleccionado(this.flexRecibos);

        //Validacion para la creacion de las notas debito y credito
        if (tipo == "NC" || tipo == "ND"){
            if(item.TipoRecibo == "NC" || item.TipoRecibo == "ND" || item.Anulado || item.FechaPago){
                bootbox.alert("No se puede crear una nota a este documento");
                return;
            }

            if(!item.AprobadoDian && item.TipoRecibo == 'Factura'){
                bootbox.alert("Sólo se puede crear notas en facturas aprobadas por la Dian");
                return;
            }
        }

        //Validacion para la anulacion de los recibos
        if ((item.TipoRecibo == "NC" || item.Anulado || item.FechaPago) && tipo == "anular") {
            bootbox.alert("No se puede anular este documento");
            return;
        }

        var codigosConcepto = [{Nombre: "Anulación de factura electrónica", Valor: '01'}];
        var titulo = "Anular"
        var valorDefecto = 0;

        //Define los inputs a utilizar
        let configuracionDeInputsModal: ConfiguracionInput[] = [
          { property: "codigoConcepto", label: "Concepto ", type: "select", required: true, disabled: false, messageErrorRequired: "El concepto es requerido" },
          { property: "valorNota", label: "Valor", type: "number", required: true, disabled: false, min: 1, max: item.TotalAPagar, messageErrorRequired: "El valor es requerido" }
        ];

        //Pone la mayoria de los inputs en disabled
        if(tipo == "anular"){
            valorDefecto = item.TotalAPagar;
            //deja los campos en disabled

            _.each(configuracionDeInputsModal, function(input){
              input.disabled = true;
              input.required = false;
              //Deja como valor maximo el valor completo
              if(input.property == "valorNota")
                input.max = item.TotalAPagar;
            })

            configuracionDeInputsModal.push({ property: "observacion", label: "Observación ", type: "textarea", required: true})
        }

        //Obtiene los conceptos de las notas debito
        if(tipo == "ND"){
            codigosConcepto = [{Nombre: "Intereses", Valor: '01'},
                            {Nombre: "Gastos por Cobrar", Valor: '02'},
                            {Nombre: "Cambio Valor", Valor: '03'},
                            {Nombre: "Otros", Valor: '03'}];
            titulo = "Nota débito";
        }

        //Obtiene los conceptos de las notas credito
        if(tipo == "NC"){
            codigosConcepto = [
                {Nombre: "Devolución parcial de los bienes y/o no aceptación parcial del servicio", Valor: '01'},
                {Nombre: "Rebaja o descuento parcial o total", Valor: '03'},
                {Nombre: "Ajuste de precio", Valor: '04'},
                {Nombre: "Otros", Valor: '05'}
            ];
            titulo = "Nota crédito";
            configuracionDeInputsModal.push({ property: "observacion", label: "Observación ", type: "textarea", required: true})
        }

        var enviarDian = false;
        if(item.AprobadoDian)
            configuracionDeInputsModal.push({ property: "enviarDian", label: "Enviar automáticamente a la Dian", type: "checkbox", disabled: false, required: false});

        //Modal para crear la nota credito
        this.modalFormulario.abrirIngresarValor({
            titulo: titulo,
            input: configuracionDeInputsModal,
            valor: {valorNota: valorDefecto,
                    codigoConcepto: '01',
                    enviarDian: enviarDian,
                    arraySelectcodigoConcepto: codigosConcepto}
        });

        //Guarda los datos
        this.modalFormulario.modalRef.result.then((dataResult) => {
            var codigoConcepto = dataResult.codigoConcepto;
            var valor = dataResult.valorNota;

            if(tipo == "anular")
                this.anular(item, dataResult.observacion, dataResult.enviarDian);

            if(tipo == "ND")
                this.crearNotaDebito(item, codigoConcepto, valor, dataResult.enviarDian);

            if(tipo == "NC")
                this.crearNotaCredito(item, codigoConcepto, valor, dataResult.observacion, dataResult.enviarDian);

            if(!dataResult.enviarDian){
                this.cargando = false;
                this.cargarDatos(null, () => { });
            }

        },(info) => {
            console.log("Info", info);
        });
    }

    anular(item, observacion, envioDian) {
        /*
        let item = this.obtenerRegistroSeleccionado(this.flexRecibos);
        if (!(!this.verificarSiHayCambios() && item))
            return;

        if (item.TipoRecibo == "NC" || item.Anulado || item.FechaPago) {
            bootbox.alert("No se puede anular este documento");
            return;
        }*/

        this.cargando = true;
        //Crea el recibo del abono
        this.sRecibo.anular(item.Id, observacion).subscribe(data => {

            console.log(data);

            if (!data["IdRecibo"]) {
                bootbox.alert("Error al anular");
            }
            else{
                this.toastr.success("Documento anulado");
                //Envia a la dian
                if(envioDian){
                    setTimeout(() => {
                        this.toastr.info("Enviando Dian");
                        console.log("Enviando a la dian...");
                        this.enviarDian(data["IdRecibo"]);
                    }, 50);
                }else{
                    this.cargando = false;
                }
            }

            //this.crearTareaSync("PagarRecibo", dataReciboNuevo["Id"], dataReciboNuevo["TotalAPagar"], dataResult.fecha, item.TipoRecibo, null, null);
            //this.crearTarea("PagarRecibo", dataReciboNuevo["Id"], dataReciboNuevo["TotalAPagar"], dataResult.fecha, null, null, null);

        }, (error) => {
            this.cargando = false;

            error = error.error;
            error = error.ExceptionMessage ? error.ExceptionMessage : error;

            if(typeof(error) != "string")
                error = JSON.stringify(error);

            bootbox.alert("Error: " + error);

            console.log(error);
        });

    }

    crearNotaDebito(item, codigoConcepto, valor, envioDian) {
        /*
        let item = this.obtenerRegistroSeleccionado(this.flexRecibos);
        if (!(!this.verificarSiHayCambios() && item))
            return;

        if (item.TipoRecibo == "NC" || item.TipoRecibo == "ND" || item.Anulado || item.FechaPago){
            bootbox.alert("No se puede crear una nota a este documento");
            return;
        }*/
        var c = codigoConcepto;
        var v = valor;

        let val = { IdRecibo: item.Id, Valor: v, CodigoConcepto: c };

        console.log(val);

        this.cargando = true;
        //Crea el recibo del abono
        this.sRecibo.crearNotaDebito(val).subscribe(data => {

            console.log(data);

            if (!data["IdRecibo"])
                bootbox.alert("Error al crear nota");
            else{
                this.toastr.success("Nota creada");
                //Envia a la dian
                if(envioDian){
                    setTimeout(() => {
                        this.toastr.info("Enviando Dian");
                        console.log("Enviando a la dian...");
                        this.enviarDian(data["IdRecibo"]);
                    }, 50);
                }
            }

        }, (error) => {
            this.cargando = false;

            error = error.error;
            error = error.ExceptionMessage ? error.ExceptionMessage : error;

            if(typeof(error) != "string")
                error = JSON.stringify(error);

            bootbox.alert("Error: " + error);

            console.log(error);
        });
    }

    /**
     * Crea una nota crédito
     */
    crearNotaCredito(item, codigoConcepto, valor, observacion, envioDian){

        let val = { IdRecibo: item.Id, Valor: valor, Observacion: observacion, CodigoConcepto: codigoConcepto};

        //Crea el recibo del abono
        this.cargando = true;

        this.sRecibo.crearNotaCredito(val).subscribe(data => {

            if (!data["IdRecibo"]){
                bootbox.alert("Error al crear nota");
            }
            else{
                this.toastr.success("Nota creada");
                //Envia a la dian
                if(envioDian){
                    setTimeout(() => {
                        this.toastr.info("Enviando Dian");
                        console.log("Enviando a la dian...");
                        this.enviarDian(data["IdRecibo"]);
                    }, 50);
                }
            }

        }, (error) => {
            this.cargando = false;

            error = error.error;
            error = error.ExceptionMessage ? error.ExceptionMessage : error;

            if(typeof(error) != "string")
                error = JSON.stringify(error);

            bootbox.alert("Error: " + error);

            console.log(error);
        });
    }

  /**
   * Genera el ajuste del descuento del interes
   */
  ajustarInteresDescuentoCobro() {

    let item = this.obtenerRegistroSeleccionado(this.flexCobros);

    if (!(!this.verificarSiHayCambios() && item))
      return;

    let meses = _.filter(this.meses, (m) => { return m.IdAnio == this.anio["Id"]; });

    this.modalAjusteDescuento.abrirAjusteInteresDescuento(meses);
    this.modalAjusteDescuento.modalRef.result.then(dataMes => {

      this.crearTarea("AjustarInteresDescuentos", this.matricula["Id"], dataMes["Id"], null, null, null, null);

    }, (dismiss) => {
      console.log("dismiss", dismiss);
    });
  }

  /**
   * Carga los cobros
   */
  cargarCobros() {

    let item = this.obtenerRegistroSeleccionado(this.flexCobros);

    if (!(!this.verificarSiHayCambios() && item))
      return;

    let meses = _.filter(this.meses, (m) => { return m.IdAnio == this.anio["Id"]; });

    this.modalCargarCobro.abrirCargarCobro(this.matricula["Id"], this.matricula["Estudiante"].Nombres, this.matricula["Estudiante"].Codigo, this.tiposConcepto, meses);
    this.modalCargarCobro.modalRef.result.then(dataCobros => {

      this.crearTareaSync("CargarCobro", dataCobros.idMatricula, dataCobros.idTipoConcepto, dataCobros.idMesDesde, dataCobros.idMesHasta, dataCobros.valor, dataCobros.descripcion);
      //this.crearTarea("CargarCobro", dataCobros.idMatricula, dataCobros.idTipoConcepto, dataCobros.idMesDesde, dataCobros.idMesHasta, dataCobros.valor, dataCobros.descripcion);

    }, (dismiss) => {
      console.log("dismiss", dismiss);
    });

  }

  /**
   * Carga todos los cobros de la matrícula
   */
  cargarCobrosGenerales() {

    if (this.verificarSiHayCambios())
      return this.toastr.error("Hay cambios pendientes por guardar");

    this.modalConfirmacion.abrirConfirmar({ textoHeader: "¿Está seguro de cargar todos los cobros de la matrícula?" });
    this.modalConfirmacion.modalRef.result.then((dataResult) => {

      //Crea todos los cobros de una matrícula
      if (dataResult) {
        let idMesFebrero = _.find(this.meses, (m) => { return m.Numero == 2; }).Id;

        this.crearTareaSync("CargarCobrosMatricula", this.matricula["Id"], idMesFebrero, null, null, null, null);
        //this.crearTarea("CargarCobrosMatricula", this.matricula["Id"], idMesFebrero, null, null, null, null);
      }

    }, (error) => {
      console.log("Cancelado", error);
    });
  }

  /**
   * Cambia el responsable de pago
   */
  cambiarResponsablePago() {

    this.modalBuscarResponsablePago.abrirConsultaResponsablePago(this.anio);

    //Capta el resultado de la modal
    this.modalBuscarResponsablePago.modalRef.result.then((dataResponsablePago) => {

      this.matricula["IdResponsablePago"] = dataResponsablePago.Id;
      this.matricula["ResponsablePago"] = dataResponsablePago;

    }, (info) => {
      console.log("Info", info);
    });
  }

  /**
   * Permite al tap detectar si hay cambios o no, para el momento de ser cerrado
   */
  consultarSiHayCambios() {
    this.hayCambios = this.getTieneCambios();
    return this.hayCambios;
  }

  /**
   * Permite eliminar un recibo que aun no haya sido pago
   * @param flexRecibo
   * @param cvRecibo
   */
  eliminarRecibo(flex: WjFlexGrid, cv: CollectionView) {

    if (this.verificarSiHayCambios())
      return;

    this.modalEliminarRecibos.abrirConfirmar();
    this.modalEliminarRecibos.modalRef.result.then((dataResult) => {

      //Elimina los recibos sin la modal de confirmación
      if (dataResult == "Recibos")
        this.eliminar(flex, cv, true);

      //Elimina los recibos y sus cobros
      else if (dataResult == "RecibosCobros") {

        //Obtiene los ids de los recibos que se van a eliminar con sus respectivos cobros
        let idsRecibosDelete = _.pluck(flex.selectedItems, "Id");
        this.cargando = true;

        this.sRecibo.eliminarRecibosYCobros(idsRecibosDelete).subscribe((dataResult) => {

          this.mostrarMensajeAlerta("Éxito", dataResult["Mensaje"]);
          //Carga la consulta con identificador 'Odata'
          this.cargarDatos("Odata", () => { });
          this.cargando = false;

        }, (error) => {
          this.mostrarMensajeAlerta("Error", error);
          console.log("Error", error);
          this.cargando = false;
        });
      }

    }, (dataInfo) => {
      console.log("dataInfo", dataInfo);
    });
  }

  /**
   * Genera los recibos acorde al tipo de seleccion y el mes seleccionados
   */
  generarRecibos() {

    console.log("???", this.generaRecibos);



    //Valida la seleccion obligatoria
    if (this.generaRecibos.idMes == 0)
      this.toastr.warning("Es necesario seleccionar el mes");

    //Valida la seleccion obligatoria
    else if (!this.generaRecibos.tipoRecibo || !this.generaRecibos.tipoRecibo["Clase"])
      this.toastr.warning("Es necesario seleccionar el tipo de recibo");

    //Abre un formulario en el cual puede seleccionar los tipos de recibo que sean != 0
    else if (this.generaRecibos.tipoRecibo["TipoCarga"] != 0) {

      console.log("tipoRecibo", this.generaRecibos);
      let propiedadesTipoConcepto = _.indexBy(this.generaRecibos["tipoRecibo"]["TiposConcepto"].split(","));

      //Almacena el formulario para la modal de los tiposConceptos
      let tipoConceptoCarga = [];

      //Arma el formulario y a su vez crea el valor de
      _.each(this.tiposConcepto, (tc) => {

        if (propiedadesTipoConcepto[tc.Id]) {
          tc.seleccionado = false;
          tipoConceptoCarga.push(recaudoUI.copyObject(tc));
        }
      });

      this.modalGenerarRecibo.abrirModal(tipoConceptoCarga, "Generar recibos");
      this.modalGenerarRecibo.modalRef.result.then((dataValor) => {

        let tipoConceptoSeleccionados = [];

        _.each(dataValor, (dv) => { tipoConceptoSeleccionados.push(_.pick(dv, "Id", "Valor")); });

        let p4 = JSON.stringify(tipoConceptoSeleccionados);

        if (this.generaRecibos.tipoRecibo["Clase"] == "EstadoCuenta")
          this.crearTareaSync("GenerarEstadoCuenta", this.matricula["Id"], this.generaRecibos.idMes, this.generaRecibos.tipoRecibo["Tipo"], p4, null, null);

        console.log(tipoConceptoSeleccionados, p4);

      }, (info) => {
        console.log("Info", info);
      });
    }

    else if (this.generaRecibos.tipoRecibo && this.generaRecibos.tipoRecibo["Clase"] == "Abono") {

      //Configura como desea la modal y la cantidad de inputs
      this.modalFormulario.abrirIngresarValor({
        titulo: "Valor",
        input: [
          { property: "valorAbono", label: "Valor del abono", type: "number", required: true, min: 0, placeholder: "Valor del abono" }
        ]
      });
      this.modalFormulario.modalRef.result.then((dataValor) => {

        this.crearTareaSync("GenerarAbono", this.matricula["Id"], this.generaRecibos.idMes, dataValor["valorAbono"], this.generaRecibos.tipoRecibo["Tipo"], null, null);

      }, (info) => {
        console.log("Info", info);
      });
    }

    else {

      //Genera la tarea correspondiente
      if (this.generaRecibos.tipoRecibo["Clase"] == "EstadoCuenta")
        this.crearTareaSync("GenerarEstadoCuenta", this.matricula["Id"], this.generaRecibos.idMes, this.generaRecibos.tipoRecibo["Tipo"], null, null, null);

      else if (this.generaRecibos.tipoRecibo["Clase"] == "CobroPrejuridico")
        this.crearTareaSync("GenerarCobroPrejuridico", this.matricula["Id"], this.generaRecibos.idMes, null, null, null, null);

      else if (this.generaRecibos.tipoRecibo["Clase"] == "PagoAdelantado")
        this.crearTareaSync("GenerarPagoAdelantado", this.matricula["Id"], this.generaRecibos.idMes, null, null, null, null);

      else if (this.generaRecibos.tipoRecibo["Clase"] == "Matricula")
        this.crearTareaSync("GenerarReciboMatricula", this.matricula["Id"], this.generaRecibos.tipoRecibo["Tipo"], null, null, null, null);

      else
        this.toastr.error("Tipo de recibo no soportado");
    }
  }

  /**
   * Genera el mensaje al cual se va a mostrar al usuario cuando la tarea sea finalizada
   * @param tareaServidor
   */
  public mostrarMensajeTareaRevisada(tareaServidor) {

    let titulo = "";
    let mensaje = "";

    if (tareaServidor["Estado"] == 1) {

      titulo = "Éxito";

      if (tareaServidor["Nombre"] == "AplicarDescuento")
        mensaje = `Se ha realizado el descuento exitosamente`;

      else if (tareaServidor["Nombre"] == "PagarRecibo")
        mensaje = `Se ha realizado el pago del recibo`;

      else if (tareaServidor["Nombre"] == "AjustarInteresDescuentos")
        mensaje = `Cobros cargados: ${tareaServidor["Resultado"]}`;

      else if (tareaServidor["Nombre"] == "CargarCobro")
        mensaje = `${tareaServidor["Resultado"]} cobros cargados`;

      else if (tareaServidor["Nombre"] == "CargarCobrosMatricula")
        mensaje = `${tareaServidor["Resultado"]} cobros cargados`;

      else if (tareaServidor["Nombre"] == "GenerarEstadoCuenta" || tareaServidor["Nombre"] == "GenerarCobroPrejuridico" ||
        tareaServidor["Nombre"] == "GenerarPagoAdelantado" || tareaServidor["Nombre"] == "GenerarReciboMatricula" ||
        tareaServidor["Nombre"] == "GenerarAbono") {

        if (tareaServidor["Resultado"]) {

          if (tareaServidor["Resultado"] != 0)
            mensaje = `Se ha creado el recibo # ${tareaServidor["Resultado"]}`;

          else
            mensaje = `Se ha creado 0 recibos`;
        }
        else
          mensaje = `No se han generado recibos`;
      }

    }
    else {
      titulo = "Error";
      mensaje = tareaServidor["Excepcion"];
    }

    this.mostrarMensajeAlerta(titulo, mensaje);
  }

  /**
   * Muestra un mensaje de alerta al usuario sobre el estado de la petición realizada en el servidor
   * @param titulo
   * @param mensaje
   */
  mostrarMensajeAlerta(titulo, mensaje) {

    this.cargarDatos(null, () => { });

    this.modalAlerta.abrirConfirmar({ textHeader: titulo, textBody: mensaje });
    this.modalAlerta.modalRef.result.then((dataResult) => {
    }, (info) => {
      console.log("Info", info);
    });
  }

  /**
   * Abre una modal con los detalles de la deuda
   */
  mostrarDetallesDeuda(){
    var valores = [];
    //Obtiene los detalles de la deuda
    _.each(this.deuda.DetalleDeuda, function(detalle){
        valores.push({Concepto: detalle["Nombre"], Valor: detalle["Valor"]});
    })
    //Abre la modal
    this.modalFormulario.abrirIngresarValor({
        titulo: "Deuda años anteriores",
        muestraDetalles: true,
        input: [{ property: "detalleDeuda", label: "", type: "lista"}],
        valor:{
            listadetalleDeuda: valores
        }
    });
    //Cierra la modal
    this.modalFormulario.modalRef.result.then((dataValor) => {
        console.log(dataValor);
    }, (info) => {
        console.log("Info", info);
      });
  }


  /**
   * Realiza el filtro personalizado por row
   */
  filtrarRowsGrid() {

    var rows = this.flexCobros.rows;

    _.each(rows, (row) => {

      let registroVisible = false;

      let item = row.dataItem;

      if (!this.filtroCobros.idTipoConcepto && !this.filtroCobros.idMes)
        registroVisible = true;

      if (this.filtroCobros.idTipoConcepto && this.filtroCobros.idMes == 0 &&
        item["IdTipoConcepto"] == this.filtroCobros.idTipoConcepto)
        registroVisible = true;

      else if (this.filtroCobros.idMes != 0 && this.filtroCobros.idTipoConcepto == 0 &&
        item["IdMes"] == this.filtroCobros.idMes)
        registroVisible = true;

      else if (this.filtroCobros.idTipoConcepto != 0 && this.filtroCobros.idMes != 0 &&
        item["IdTipoConcepto"] == this.filtroCobros.idTipoConcepto && item["IdMes"] == this.filtroCobros.idMes) registroVisible = true;


      row.visible = registroVisible;
    });
  }

  /**
   * Permite editar la fecha de pago solamente si esta con algun valor para el rol de secretaria auxiliar
   */
  permitirEditarFechaPago(cell){
    //Obtiene el permiso de editar
    if(this.usuarioAuth.Rol == "Secretaria auxiliar"){
        var editar = false;

        if(cell != null)
            editar = true;
        return editar;
    }
    return !this.tienePermisoMinimoDeContador;
  }

  actualizarDatosDespuesDeGuardar(modulos) {

    this.cargarDatos(null, () => { });
  }
}
