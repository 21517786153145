import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { WjFlexGrid } from 'wijmo/wijmo.angular2.grid';
import { CollectionView, DateTime, createElement, format, Globalize, Tooltip } from 'wijmo/wijmo';
import { Calendar, FormatItemEventArgs } from 'wijmo/wijmo.input'
import { Año } from '../../helpers/Models';
import { ToastrService } from 'ngx-toastr';
import { WijmoHelper, ModeloCollectionView } from '../../helpers/WijmoHelper';
import { ModeloService, EstudianteService, MatriculaService, ResponsablePagoService, OtrasPersonasService, CobrosService, RecibosService, ExtractoService, TareaServidorService } from '../../shared/services/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RecaudoFormHelper } from '../../helpers/RecaudoFormHelper';
import * as _ from 'underscore'
import { recaudoUI } from '../../helpers/RecaudoUI';
import { ExtractoComponent } from '../extracto/extracto.component';
import { TableService } from 'src/app/shared/services/table';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { AsistenteExtractoComponent } from '../asistente-extracto/asistente-extracto.component';
import { ModalAlertaComponent } from 'src/app/shared/components/modal-alerta/modal-alerta.component';
import { MovimientosComponent } from '../movimientos/movimientos.component';
import * as moment from 'moment';

@Component({
  selector: 'app-extractos',
  templateUrl: './extractos.component.html',
  styleUrls: ['./extractos.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExtractosComponent extends RecaudoFormHelper implements OnInit {

  @Input('anio') public anio: Año;

  cuentas: Array<any>;
  anios: Array<any>;
  countItems: number = 0;
  currentPage: number = 1;
  extractosCargados = false;
  hayCambios = false;

  //Almacena las fechas de los extractos que estan en el mes seleccionado
  extractosDelMes = [];
  private _tooltip: Tooltip;
  fecahCalendario;

  //Contiene el filtro para realizar la consulta de los extractos
  filtro = {
    idColegio: 0,
    pagina: 1,
    top: 50,
    desde: null,
    hasta: null
  };

  filtroFecha = {
    desde: null,
    hasta: null
  }

  //Maneja el filtro de la tab del calendario
  seleccionCalendario: object = {
    anio: null,
    mes: null,
    cuenta: null
  };

  query;

  //CollectionViews
  cvExtractos: CollectionView;
  //Referencia a los controles de wijmo
  @ViewChild('flexExtractos') flexExtractos: WjFlexGrid;
  @ViewChild('ngbPaginationElm') ngbPaginationElm: any;

  @ViewChild(ModalAlertaComponent) modalAlerta: ModalAlertaComponent;

  //declaración de servicios
  constructor(
    private _toastr: ToastrService,
    private _wjHelper: WijmoHelper,
    private _changeDetector: ChangeDetectorRef,
    private _sTable: TableService,

    private _sModelo: ModeloService,
    private _sAuth: AuthenticationService,
    private _sMatricula: MatriculaService,
    private _sResponsablePago: ResponsablePagoService,
    private _sEstudiante: EstudianteService,
    private _sOtrasPersonas: OtrasPersonasService,
    private _sCobro: CobrosService,
    private _sRecibo: RecibosService,
    private _sExtracto: ExtractoService,
    private _sTarea: TareaServidorService
  ) {
    super(_toastr, _wjHelper, _sModelo, _changeDetector, _sTable, _sAuth, _sMatricula,
      _sResponsablePago, _sEstudiante, _sOtrasPersonas, _sCobro, _sRecibo, _sExtracto, _sTarea);

    this._tooltip = new Tooltip();
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {

    this.filtro.idColegio = this.anio.IdColegio;
    this.query = [
      'Cuenta'
    ];

    //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
    this.setModelosTrabajo2({
      identificador: "Odata",
      odata: this.query
      //'Extracto;$orderby=Cargado desc&$filter=Cuenta.IdColegio eq ' + this.anio.IdColegio + '&$top=50&$inlinecount=allpages', 'Cuenta'
    });

    //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
    this.setModelosTrabajo2({
      servicio: "sExtracto",
      identificador: "extracto",
      metodo: "getPaginacionExtracto",
      consulta: this.filtro
    });

    //Carga la consulta con identificador 'Odata'
    this.cargarDatos(null, () => { });
    this.fecahCalendario = moment(recaudoUI.getFechaActual());

    this.seleccionCalendario["anio"] = this.fecahCalendario.year();// _.find(this.anios, (a) => { return a.Id == this.anio.Id; });
    this.seleccionCalendario["mes"] = this.fecahCalendario.month() + 1;

    this.buscarExtractosPorMes(null);

  }

  /**
   * Trae los datos de las consultas realizadas
   * @param data El array u objeto que se esta consultado
   * @param identificador El identificador que ha sigo asignado a cada consulta
   */
  onDatosObtenidos(data: any, identificador: string) {

    //Trae la consulta de los extractos que pertenecen a un mes predeterminado
    if (identificador == "getExtractosPorMes") {

      this.extractosDelMes = data;

      //Crea el calendario
      this.generarCalendario();
    }

    //Carga los datos de la consulta de Odata
    else if (identificador == "Odata")
      this.cuentas = data['Cuenta'];


    else if (identificador == "extracto") {

      this.extractosCargados = true;

      this.countItems = data["count"];

      this.cruzarExtractosConCuentas(data["results"], this.cuentas);

      this.cvExtractos = new CollectionView(data['results']);
      
      //para poder eliminar
      this.cvExtractos.trackChanges = true;

      this.registrarModeloCollectionViews([
        new ModeloCollectionView('Extracto', 'Id', this.cvExtractos, this.flexExtractos)
      ]);
    }
  }

  /**
   * asigna a cada extracto su cuenta
   * @param extractos 
   * @param cuentas 
   */
  cruzarExtractosConCuentas(extractos: Array<any>, cuentas: Array<any>) {
    let cuenta = null;

    _.each(extractos, (extracto) => {
      cuenta = _.findWhere(cuentas, { Id: extracto.IdCuenta });

      if (cuenta)
        extracto.Cuenta = cuenta;
    });
  }

  cargarPagina() {
    this.buscarExtractos();
  }

  //abre la edición de un extracto
  verGrado(item) {
    //si no llega el item el item llega en true y si llega en true busca el item para abrir la vista correspondiente
    if(item === true){
      let item1 = this.obtenerRegistroSeleccionado(this.flexExtractos);
      if (!this.verificarSiHayCambios() && item1)
        recaudoUI.tabsManager.agregar("Extracto - " + (item1.Nombre), "fa-file-o", ExtractoComponent, { extracto: item1, anio: this.anio });
    }
    else
      recaudoUI.tabsManager.agregar("Extracto - " + (item.Nombre), "fa-file-o", ExtractoComponent, { extracto: item, anio: this.anio });
  }

  /**
   * Permite subir archivos al extracto
   */
  cargarExtracto() {
      recaudoUI.tabsManager.agregar("Asistente Extracto", "fa fa-cloud-upload", AsistenteExtractoComponent, {  anio: this.anio });
  }

  /**
   * Abre la tab de todos los movimientos
   */
  buscarMovimientos() {
    let item = this.obtenerRegistroSeleccionado(this.flexExtractos);

    if (!this.verificarSiHayCambios() && item)
      recaudoUI.tabsManager.agregar("Buscar Movimientos", "fa fa-cloud-upload", MovimientosComponent);
  }

  /**
   * Realiza el cruce de los movimientos
   */
  cruzarMovimientos() {
    this.crearTarea("CruzarMovimientos", this.anio.IdColegio, null, null, null, null, null);
  }

  /**
   * Genera el mensaje al cual se va a mostrar al usuario cuando la tarea sea finalizada
   * @param tareaServidor 
   */
  public mostrarMensajeTareaRevisada(tareaServidor) {

    let titulo = "";
    let mensaje = "";

    if (tareaServidor["Estado"] == 1) {
      titulo = "Éxito";

      if (tareaServidor["Nombre"] == "CruzarMovimientos")
        mensaje = tareaServidor["Resultado"];//`Se ha cruzado: ${tareaServidor["Resultado"]} movimientos`;
      //this.toastr.success(`Se ha cruzado: ${ tareaServidor["Resultado"] } movimientos`);

    }

    else {
      titulo = "Error";
      mensaje = tareaServidor["Excepcion"];
      //this.toastr.error("Error, " + tareaServidor["Excepcion"]);
    }

    this.modalAlerta.abrirConfirmar({ textHeader: titulo, textBody: mensaje });
    this.modalAlerta.modalRef.result.then((dataResult) => {
    }, (info) => {
      console.log("Info", info);
    });
  }

  /**
   * Realiza la consulta de los extractos por medio de la fecha desde y hasta
   * @param a 
   */
  buscarExtractos() {
    if (!this.extractosCargados)
      return;

    this.filtro.pagina = this.ngbPaginationElm.page;
    this.filtro.desde = recaudoUI.formatDate(this.filtroFecha.desde, "f");
    this.filtro.hasta = recaudoUI.formatDate(this.filtroFecha.hasta, "f");

    //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
    this.setModelosTrabajo2({
      servicio: "sExtracto",
      identificador: "extracto",
      metodo: "getPaginacionExtracto",
      consulta: this.filtro
    });

    //Carga la consulta con identificador 'Odata'
    this.cargarDatos("extracto", () => { });
  }

  /**
   * Permite al tap detectar si hay cambios o no, para el momento de ser cerrado
   */
  consultarSiHayCambios() {
    this.hayCambios = this.getTieneCambios();
    return this.hayCambios;
  }

  /**
   * Realiza la busqueda de los extractos que pertenecen a un mes
   */
  buscarExtractosPorMes(direccion) {

    if (direccion) {
      if (direccion == "adelante") {
        this.fecahCalendario = this.fecahCalendario.add(1, 'month');

        this.seleccionCalendario["anio"] = this.fecahCalendario.year();
        this.seleccionCalendario["mes"] = this.fecahCalendario.month() + 1;
      }
      else if (direccion == "atras") {
        this.fecahCalendario = this.fecahCalendario.add(-1, 'month');

        this.seleccionCalendario["anio"] = this.fecahCalendario.year();
        this.seleccionCalendario["mes"] = this.fecahCalendario.month() + 1;
      }
      else if (direccion == "actual") {
        this.fecahCalendario = moment(recaudoUI.getFechaActual(), "YYYY-MM-DD");

        this.seleccionCalendario["anio"] = this.fecahCalendario.year();
        this.seleccionCalendario["mes"] = this.fecahCalendario.month() + 1;
      }

      this.changeDetector.detectChanges();
    }

    //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
    this.setModelosTrabajo2({
      servicio: "sExtracto",
      identificador: "getExtractosPorMes",
      metodo: "getExtractosPorMes",
      consulta: {
        anio: this.seleccionCalendario["anio"],
        mes: this.seleccionCalendario["mes"],
        idCuenta: this.seleccionCalendario["cuenta"]
      }
    });

    //Carga la consulta con identificador 'Odata'
    this.cargarDatos("getExtractosPorMes", () => { });
  }

  /**
   * Genera el calendario por mes
   */
  generarCalendario() {

    //Obtiene la etiqueta del calendario
    let calendar = document.querySelector('#calendar');

    //Cada vez que se cambia de mes se limpia el div para crear un nuevo calendario
    if (calendar.childNodes[0])
      calendar.removeChild(calendar.childNodes[0]);

    //Genera el calendario

    let fechaSeleccionada = new Date(`${this.seleccionCalendario["anio"]}/${this.seleccionCalendario["mes"]}/${1}`);
    let month = this.crearMesCalendario(DateTime.addMonths(fechaSeleccionada, 0));
    calendar.appendChild(month);
  }

  /**
   * Crea el mes para el calendario
   * @param date 
   */
  private crearMesCalendario(date: Date) {

    //Crea el mes
    let mes = createElement('<div class="month"></div>'),
      calendario = new Calendar(mes, {
        showHeader: false,
        selectionMode: 'None',
        value: date,
        formatItem: this.formatearCeldaDia.bind(this)
      });

    calendario.refresh();

    //Personaliza la cabezera del calendario
    let fmt = format('<div class="month-header">' +
      '<div class="month-title">{header}</div>' +
      //'<div class="month-status">{uptime}% uptime</div>' +
      '</div>', {
      //Nombre de la cabecera
      header: Globalize.format(date, 'MMMM / yyyy'),

      //uptime: this._getUptime()
    });

    let newHeader = createElement(fmt);

    //Busca la clase en el div del calendario
    let hdr = calendario.hostElement.querySelector('.wj-calendar-header');

    //Agrega la etiqueta de la cabecera personalizada
    hdr.parentElement.insertBefore(newHeader, hdr);

    //Muestra la primera letra del dia
    let cells = calendario.hostElement.querySelectorAll('table tr.wj-header td');
    for (let i = 0; i < 7; i++) {
      cells[i].textContent = cells[i].textContent.substr(0, 1);
    }

    return mes;
  }

  /**
   * Crea las celdas del calendario
   * @param sender 
   * @param e 
   */
  private formatearCeldaDia(sender: Calendar, e: FormatItemEventArgs) {

    //Obtiene los dias del mes que no tiene extracto y Arma los días del calendario 
    let diaSinMovimiento = this.generarDiasCalendario(e.data), html = `<div>${e.data.getDate()}</div>`;

    var icons = "";
    var mensajeDia = "";

    if (diaSinMovimiento && diaSinMovimiento["dia"] == e.data.getDate()) {

      if (diaSinMovimiento["cantidad"]) {
        icons += `<i class="fa fa-check" style="color: #19bc00;" aria-hidden="true"></i>`;
        mensajeDia += diaSinMovimiento["cantidad"] + ' Horario normal';
      }

      if (diaSinMovimiento["adicional"]) {
        icons += `${(icons == "" ? "" : "&nbsp;")}<i class="fa fa-moon-o" style="color: #0049ff;" aria-hidden="true"></i>`;
        mensajeDia += "<br>" + diaSinMovimiento["adicional"] + ' Horario adicional';
      }
    }
    else {
      icons += `<i class="fa fa-asterisk" style="color: #ffffff;" aria-hidden="true"></i>`;
      mensajeDia = 'No se ha encontrado movimientos';
    }

    //Se coloca el icono al día que no se ha cargado el extracto
    html += icons //diaSinMovimiento && diaSinMovimiento["dia"] == e.data.getDate() ? `<i class="fa fa-check" style="color: #19bc00;" aria-hidden="true"></i>` : ``;

    //formatea la celda del contenedor
    e.item.innerHTML = html;

    //Personaliza el tooltip a la celda
    let tip = format('<div class="event-tip event-type-{eventType}">' +
      '<div>{date:dd/MM/yyyy}</div>' +
      '<div class="event">{eventMessage}</div>' +
      '</div>', {
      date: e.data,
      eventMessage: mensajeDia, // diaSinMovimiento ? `${(diaSinMovimiento["cantidad"] != 0 ? diaSinMovimiento["cantidad"] + ' Movimientos' : '')} 
      //${(diaSinMovimiento["cantidad"] != 0 && diaSinMovimiento["adicional"] != 0 ? "<br>" : "")} ${(diaSinMovimiento["adicional"] != 0 ? diaSinMovimiento["adicional"] + ' Movimientos adicionales' : '')}` : ,
      eventType: '2'
    });

    //Genera el tooltip
    this._tooltip.setTooltip(e.item, tip)
  }

  /**
   * Crea un array con todos los días del calendario
   */
  generarDiasCalendario(data) {

    let existeExtracto = _.find(this.extractosDelMes, (fm) => {
      return moment(data).format("YYYY-MM-DD") == moment(fm.Fecha).format("YYYY-MM-DD");
    });

    if (existeExtracto)
      return { dia: data.getDate(), id: 0, cantidad: existeExtracto["Cantidad"], adicional: existeExtracto["Adicional"], msg: `${existeExtracto.Cantidad} Movimientos`, };
    //return { dia: data.getDate(), id: 0, msg: `${existeExtracto.Cantidad} Movimientos`,  };

    return;

  }
}
