import { Component, OnInit, ChangeDetectorRef, Input, ViewChild } from '@angular/core';
import { RecaudoFormHelper } from 'src/app/helpers/RecaudoFormHelper';
import { GestorCodigoBarras } from 'src/app/helpers/gestorCodigoBarras';
import { ToastrService } from 'ngx-toastr';
import { TableService } from 'src/app/shared/services/table';
import { WijmoHelper } from 'src/app/helpers/WijmoHelper';
import { ModeloService, MatriculaService, ResponsablePagoService, EstudianteService, OtrasPersonasService, CobrosService, RecibosService, ExtractoService, TareaServidorService } from 'src/app/shared/services/services';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { CollectionView } from 'wijmo/wijmo';
import { WjFlexGrid } from 'wijmo/wijmo.angular2.grid';
import { ModalFormularioComponent } from 'src/app/shared/components/modal-formulario/modal-formulario.component';
import * as _ from 'underscore';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { recaudoUI } from 'src/app/helpers/RecaudoUI';
import { ResponsableComponent } from '../responsable/responsable.component';
import { ModalAlertaComponent } from 'src/app/shared/components/modal-alerta/modal-alerta.component';

@Component({
  selector: 'app-asistente-nueva-matricula',
  templateUrl: './asistente-nueva-matricula.component.html',
  styleUrls: ['./asistente-nueva-matricula.component.css']
})
export class AsistenteNuevaMatriculaComponent extends RecaudoFormHelper implements OnInit {

  @Input('anio') public anio: object;

  //Almacena el mes 2 para crear los recibos de la matrícula (GenerarEstadoCuenta)
  mesFebrero: object;

  //Contiene los parametros que se necesitan para buscar las personas que coinciden
  personaBusqueda: Object;

  //Contiene los parametros que se necesitan para buscar a los estudiantes que coincidan
  estudianteBusqueda: Object;

  //Permite crea una persona
  personaCreada: object;
  tipoConcepto = [];

  //Permite crea un estudiante
  estudianteCreado: object;
  grados: Object[];
  gradoSeleccionado: Object = {
    Grupos: []
  };

  //Los datos de la matricula para ésta ser creada
  matricula = {
    IdResponsablePago: 0,
    personaSeleccionada: {},
    IdEstudiante: 0,
    Estudiante: {},
    IdGrado: null,
    IdGrupo: null,
    Activo: true,
    Matriculado: false,
    Nuevo: false,
    Becado: false,
    Ruta: false,
    Comedor: false,
  }

  //CollectionViews
  cvEstudiantes: CollectionView;
  cvPersonas: CollectionView;

  //Referencia a los controles de wijmo
  @ViewChild('flexEstudiantes') flexEstudiantes: WjFlexGrid;
  @ViewChild('flexPersonas') flexPersonas: WjFlexGrid;

  @ViewChild(ModalFormularioComponent) modalFormulario: ModalFormularioComponent;
  @ViewChild(ModalAlertaComponent) modalAlerta: ModalAlertaComponent;
  @ViewChild('tapConfig') tapConfig: NgbTabset;

  //Controla que tap se desea ver
  idTap = 1;
  hayCambios = false;
  mensaje = "";
  tiposRecibo = [];
  respaldoTipoRecibo: object[] = [
    { IdTipoRecibo: 0, Tipo: "Estado de Cuenta", Clase: "any" },
    { IdTipoRecibo: 0, Tipo: "Abono", Clase: "Abono" },
    { IdTipoRecibo: 0, Tipo: "Pago Adelantado", Clase: "PagoAdelantado" },
    { IdTipoRecibo: 0, Tipo: "Cobro Prejurídico", Clase: "CobroPrejuridico" },
    { IdTipoRecibo: 0, Tipo: "Recibo Matrícula", Clase: "Matricula" },
  ];

  //declaración de servicios
  constructor(
    private gestorCodigo: GestorCodigoBarras,
    private _toastr: ToastrService,
    private _sTable: TableService,
    private _wjHelper: WijmoHelper,
    private _changeDetector: ChangeDetectorRef,

    private _sModelo: ModeloService,
    private _sAuth: AuthenticationService,
    //private sRecibo: RecibosService,
    private _sMatricula: MatriculaService,
    private _sResponsablePago: ResponsablePagoService,
    private _sEstudiante: EstudianteService,
    private _sOtrasPersonas: OtrasPersonasService,
    private _sCobro: CobrosService,
    private _sRecibo: RecibosService,
    private _sExtracto: ExtractoService,
    private _sTarea: TareaServidorService
  ) {
    super(_toastr, _wjHelper, _sModelo, _changeDetector, _sTable, _sAuth, _sMatricula,
      _sResponsablePago, _sEstudiante, _sOtrasPersonas, _sCobro, _sRecibo, _sExtracto, _sTarea);
  }

  /**
   * Los componentes generados dinámicamente deben implementar esta interfaz
   */
  ngAfterViewInit() {

  }

  ngOnInit() {

    //Contiene los parametros que se necesitan para buscar las personas que coinciden
    this.personaBusqueda = {
      Nombre: "",
      Identificacion: "",
      IdColegio: this.anio["IdColegio"],
      IdAnio: this.anio["Id"],
    };

    //Contiene los parametros que se necesitan para buscar a los estudiantes que coincidan
    this.estudianteBusqueda = {
      Apellidos: "",
      Nombres: "",
      Codigo: "",
      IdColegio: this.anio["IdColegio"],
      Identificacion: null
    };

    //Permite crea una persona
    this.personaCreada = {
      Nombre: "",
      Identificacion: "",
      IdColegio: this.anio["IdColegio"],
      EmailVerificado: false,
      ResponsablesPago: [
        { DescuentoPorNomina: false, IdAño: this.anio["Id"], }
      ]
    };

    //Permite crea un estudiante
    this.estudianteCreado = {
      Apellidos: "",
      Nombres: "",
      Codigo: "",
      IdColegio: this.anio["IdColegio"],
    }

    //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
    this.setModelosTrabajo2({
      identificador: "Odata",
      odata: [
        `Grado;$filter=IdAnio eq ${this.anio["Id"]}&$expand=Año,DefinicionGrado,Grupos`,
        `Mes;$filter=Numero eq 2 and IdAnio eq ${this.anio["Id"]}`,
        `TipoConcepto`,
        "TipoRecibo;$orderby=Orden"
      ]
    });

    //Carga la consulta con identificador 'Odata'
    this.cargarDatos("Odata", () => { });

  }

  /**
   * Trae los datos de las consultas realizadas
   * @param data El array u objeto que se esta consultado
   * @param identificador El identificador que ha sigo asignado a cada consulta
   */
  onDatosObtenidos(data: any, identificador: string) {

    //Obtiene los grados consultados por OData
    if (identificador == "Odata") {
      this.grados = data["Grado"];
      //this.matricula.IdGrado = _.first(this.grados)["Id"];
      //this.gradoSeleccionado = _.first(this.grados)["Id"];

      this.mesFebrero = _.first(data["Mes"]);
      this.tipoConcepto = data["TipoConcepto"];

      if (data["TipoRecibo"].length != 0)
        this.tiposRecibo = data["TipoRecibo"];
      else
        this.tiposRecibo = this.respaldoTipoRecibo;
    }

    //Carga los datos de la busqueda de los estudiantes
    else if (identificador == "buscarEstudiante") {

      this.cvEstudiantes = new CollectionView(data);

      this.cvEstudiantes.trackChanges = true;

      this.cvEstudiantes.refresh();


      this.matricula.Estudiante = data.length ? _.first(data) : {};
      this.matricula.IdEstudiante = this.matricula.Estudiante ? this.matricula.Estudiante["Id"] : 0;


      //Cuando se hace focus al registro del estudiante al cual se desea crear la matrícula
      this.flexEstudiantes.onSelectionChanged = () => {
        let item = this.flexEstudiantes.selectedItems[0];

        this.matricula.Estudiante = item;

        //Obtiene el id del estudiante seleccionado
        this.matricula.IdEstudiante = item ? item["Id"] : 0;
      }
    }

    //Carga los datos de la consulta de las matriculas
    else if (identificador == "buscarPersonas") {

      this.cvPersonas = new CollectionView(data);

      this.cvPersonas.trackChanges = true;

      this.cvPersonas.refresh();
      this.matricula.personaSeleccionada = data.length ? _.first(data) : {};
      this.matricula.IdResponsablePago = this.matricula.personaSeleccionada && this.matricula.personaSeleccionada["ResponsablePago"] && this.matricula.personaSeleccionada["ResponsablePago"].length != 0 ? this.matricula.personaSeleccionada["ResponsablePago"][0].Id : 0;

      //Cuando se hace focus a alguna celda, esto para habilitar o deshabilitar los botones
      this.flexPersonas.onSelectionChanged = () => {
        let item = this.flexPersonas.selectedItems[0];

        this.matricula.personaSeleccionada = item;

        //Obtiene el id de la persona seleccionada
        this.matricula.IdResponsablePago = item && item.ResponsablePago && item.ResponsablePago.length != 0 ? item.ResponsablePago[0].Id : 0;
      }
    }
  }

  /**
   * Permite la creación del estudiante
   */
  crearEstudiante() {

    //Crea el formulario 
    this.modalFormulario.abrirIngresarValor({
      titulo: "Crear Estudiante",
      input: [
        { property: "Apellidos", label: "Apellidos ", type: "text", required: true, placeholder: "Apellidos del estudiante", upperCase: true },
        { property: "Nombres", label: "Nombres ", type: "text", required: true, placeholder: "Nombres del estudiante", upperCase: true },
        { property: "Codigo", label: "Código ", type: "number", required: true, placeholder: "Código  del estudiante", min: 0, max: 21474836 }
      ],
      valor: _.clone(this.estudianteCreado)
    });
    this.modalFormulario.modalRef.result.then((dataResult) => {

      //Crea el estudiante ingresado
      this.sModelo.save("Estudiante", [dataResult]).subscribe((data) => {

        //Llena la consulta del estudiante
        this.estudianteBusqueda = dataResult;

        this.buscarEstudiantes();
        //console.log("Estu", this.cvEstudiantes);
        //this.cvEstudiantes = new CollectionView(_.first(data).Object);
        //this.cvEstudiantes.sourceCollection.push(_.first(data).Object);

      }, (error) => {
        console.log("Error", error);
        this.toastr.error(error.error.ExceptionMessage, "Error");
      })

      console.log("Estudiante", dataResult, this.estudianteCreado);

    }, (info) => {
      console.log("info", info);
    });

  }

  /**
   * Busca las matriculas que coinciden con el código del estudiante o 
   */
  buscarEstudiantes() {

    if (!this.estudianteBusqueda["Apellidos"] && !this.estudianteBusqueda["Nombres"] && !this.estudianteBusqueda["Codigo"])
      return;

    //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
    this.setModelosTrabajo2({
      servicio: "sEstudiante",
      identificador: "buscarEstudiante",
      metodo: "buscarEstudiantes",
      consulta: this.estudianteBusqueda
    });

    //Carga solo la consulta con identificador 'buscar las matriculas'
    this.cargarDatos("buscarEstudiante", () => { });
  }

  /**
   * Permite la creación de la persona
   */
  crearPersona() {

    //Crea el formulario 
    this.modalFormulario.abrirIngresarValor({
      titulo: "Crear Responsable de Pago",
      input: [
        { property: "Nombre", label: "Nombres ", type: "text", required: true, placeholder: "Nombres y Apellidos", upperCase: true },
        { property: "Identificacion", label: "Cedula ", type: "text", required: true, placeholder: "Cedula" }
      ],
      valor: _.clone(this.personaCreada)
    });
    this.modalFormulario.modalRef.result.then((dataResult) => {

      //Crea el estudiante ingresado
      this.sModelo.save("Persona", [dataResult]).subscribe((data) => {

        //Llena la consulta del estudiante
        this.personaBusqueda = dataResult;

        this.buscarPersonas();

      }, (error) => {
        console.log("Error", error);
        this.toastr.error("Error", error.ExceptionMessage);
      });

      console.log("Estudiante ** ", dataResult, this.personaCreada);

    }, (info) => {
      console.log("info", info);
    });

  }

  /**
   * Busca todas las personas que están relacionadas
   */
  buscarPersonas() {

    //Genera el objeto con el cual va traer las respectivas consultas que se está solicitando
    this.setModelosTrabajo2({
      servicio: "sOtrasPersonas",
      identificador: "buscarPersonas",
      metodo: "buscarPersonas",
      consulta: this.personaBusqueda
    });

    //Carga solo la consulta con identificador 'buscar las matriculas'
    this.cargarDatos("buscarPersonas", () => { });
  }

  /**
   * Direcciona a la siguiente tap
   * @param id 
   */
  siguienteTap(taps) {

    //Direcciona a otra tap
    this.tapConfig.select(taps);
  }


  /**
   * Permite crear la matrícula al estudiante y al responsable de pago
   */
  crearMatricula() {

    //Valida que se haya seleccionado un estudiante
    if (!this.matricula.Estudiante || !this.matricula.Estudiante["Nombres"]) {
      this.toastr.warning("Es necesario seleccionar un estudiante para la creación de la matrícula");
      this.siguienteTap("estudiantes");
    }

    else if (!this.matricula.personaSeleccionada || !this.matricula.personaSeleccionada["Nombre"]) {
      this.toastr.warning("Es necesario seleccionar una persona");
      this.siguienteTap("Personas");
    }

    else if (!this.matricula.personaSeleccionada["ResponsablePago"] || this.matricula.personaSeleccionada["ResponsablePago"].length == 0) {
      this.toastr.warning("La persona seleccionada no tiene responsable de pago del año " + this.anio["Etiqueta"]);
      this.siguienteTap("Personas");
    }

    else if (!this.matricula.IdGrado) {
      this.toastr.warning("Es obligatorio seleccionar el grado");
      this.siguienteTap("Matricula");
    }

    //Crea la matrícula
    else {

      let omitePropiedades = ['personaSeleccionada', 'Estudiante'];
      if (this.matricula.IdGrupo == 0 || !this.matricula.IdGrupo)
        omitePropiedades.push("IdGrupo");

      this.cargando = true;

      this.sModelo.save("Matricula", [_.omit(this.matricula, omitePropiedades)]).subscribe((data) => {

        this.matricula["Id"] = _.first(data).Id;

        this.mensaje = "Se creo la matrícula<br>";

        //Si tiene tipo Guia trae los recibos de guias
        if (this.getTipoRecibo("GUIAS", "GUÍAS") && this.matricula.IdGrupo)
          this.crearTareaSync("GenerarReciboMatricula", this.matricula["Id"], this.getTipoRecibo("GUIAS", "GUÍAS"), this.matricula.Estudiante["Codigo"], this.anio["Etiqueta"], null, null);
        //this.crearTarea("GenerarReciboMatricula", this.matricula["Id"], this.getTipoRecibo("GUIAS", "GUÍAS"), this.matricula.Estudiante["Codigo"], this.anio["Etiqueta"], null, null);
        else
          this.crearTareaSync("GenerarReciboMatricula", this.matricula["Id"], this.getTipoRecibo("MATRÍCULA", "MATRICULA"), this.matricula.Estudiante["Codigo"], this.anio["Etiqueta"], null, null);
        //this.crearTarea("GenerarReciboMatricula", this.matricula["Id"], this.getTipoRecibo("MATRÍCULA", "MATRICULA"), this.matricula.Estudiante["Codigo"], this.anio["Etiqueta"], null, null);

      }, (error) => {
        this.mostrarError(error, null);
        this.cargando = false;
      });
    }
  }

  /**
   * Obtiene el tipo de recibo para realizar la tarea
   * @param tipo 
   * @param otroTipo 
   */
  getTipoRecibo(tipo, otroTipo?) {

    let tc = _.find(this.tipoConcepto, (_tc) => { return _tc.Nombre.toUpperCase().indexOf(tipo) != -1 || otroTipo && _tc.Nombre.toUpperCase().indexOf(otroTipo) != -1; });

    let t = _.find(this.tiposRecibo, (tr) => { return tc.Id == tr.TiposConcepto || tr.IdTipoRecibo == 0 && (tr.Tipo.toUpperCase().indexOf(tipo) != -1 || otroTipo && tr.Tipo.toUpperCase().indexOf(otroTipo) != -1); });

    return t ? t.Tipo : null;
  }

  /**
     * Permite al tap detectar si hay cambios o no, para el momento de ser cerrado
     */
  consultarSiHayCambios() {
    this.hayCambios = this.getTieneCambios();
    return this.hayCambios;
  }

  /**
   * Genera el mensaje al cual se va a mostrar al usuario cuando la tarea sea finalizada
   * @param tareaServidor 
   */
  public mostrarMensajeTareaRevisada(tareaServidor) {

    let titulo = "";

    //Muestra los recibos cargados
    if (tareaServidor["Estado"] == 1) {

      titulo = "Éxito";

      //Despues de generar los recibos de guías genera los recibos de matrícula
      if (tareaServidor["Parametro2"] == this.getTipoRecibo("GUIAS", "GUÍAS")) {

        setTimeout(() => {
          this.crearTareaSync("GenerarReciboMatricula", this.matricula["Id"], this.getTipoRecibo("MATRÍCULA", "MATRICULA"), this.matricula.Estudiante["Codigo"], this.anio["Etiqueta"], null, null);
          //this.crearTarea("GenerarReciboMatricula", this.matricula["Id"], this.getTipoRecibo("MATRÍCULA", "MATRICULA"), this.matricula.Estudiante["Codigo"], this.anio["Etiqueta"], null, null);
        }, 400);

        if (tareaServidor["Resultado"] != 0)
          this.mensaje += `\nRecibos guías cargados: ${tareaServidor["Resultado"]}<br>`;
      }

      //Despues de generar los recibos de matrícula, genera los recibos de pención
      else if (tareaServidor["Parametro2"] == this.getTipoRecibo("MATRICULA", "MATRÍCULA")) {

        setTimeout(() => {
          this.crearTareaSync("GenerarEstadoCuenta", this.matricula["Id"], this.mesFebrero["Id"], this.getTipoRecibo("PENSION", "PENSIÓN"), null, null, null);
          //this.crearTarea("GenerarEstadoCuenta", this.matricula["Id"], this.mesFebrero["Id"], this.getTipoRecibo("PENSION", "PENSIÓN"), this.matricula.Estudiante["Codigo"], this.anio["Etiqueta"], null);
        }, 400);

        this.mensaje += `\nRecibos matrículas cargados: ${tareaServidor["Resultado"]}<br>`;
      }

      else if (tareaServidor["Nombre"] == this.getTipoRecibo("PENSION", "PENSIÓN")) {
        this.mensaje += `\nRecibos de estado de cuenta cargados: ${tareaServidor["Resultado"]}<br>`;
      }

    }
    else {
      titulo = "Error";
      this.mensaje += `\n${tareaServidor["Excepcion"]}`;
      this.cargando = true;
    }

    //Muestra el mensaje de las tareas creadas para la matrícula  y al aceptar la modal cierra el tab de crear matrícula
    if (tareaServidor["Excepcion"] != null || tareaServidor["Nombre"] == "GenerarEstadoCuenta") {

      this.modalAlerta.abrirConfirmar({ textHeader: titulo, textBody: this.mensaje });
      this.modalAlerta.modalRef.result.then((dataResult) => {

        this.cargando = false;
        recaudoUI.tabsManager.eliminar(recaudoUI.tabsManager.getTabActual()["guid"]);
        recaudoUI.tabsManager.agregar("Responsable - " + this.matricula.personaSeleccionada["Nombre"], "fa fa-users", ResponsableComponent, { personaDto: this.matricula.personaSeleccionada, anioDto: this.anio });

      }, (info) => {
        this.cargando = false;
        console.log("Info", info);
        recaudoUI.tabsManager.eliminar(recaudoUI.tabsManager.getTabActual()["guid"]);
        recaudoUI.tabsManager.agregar("Responsable - " + this.matricula.personaSeleccionada["Nombre"], "fa fa-users", ResponsableComponent, { personaDto: this.matricula.personaSeleccionada, anioDto: this.anio });

      });
    }
  }
}
